
  import Vue, { CreateElement, PropType, VNode } from 'vue';
  import draggableContainerElement from '@/main/webapp/vue/components/ui/draggable/element/index.vue';
  import draggableContainerImplementation from '@/main/webapp/vue/components/ui/draggable/implementation/index.vue';

  export const enum DraggableGroupNames {
    WIDGETS = 'widgets'
  }

  export interface DraggableGroup {
    name: DraggableGroupNames | string;
    pull?: true | false | 'clone' | string[];
    put?: true | false | string[];
    revertClone?: boolean;
  }

  type DefaultSlotProps = {
    dragging: number | undefined;
  };

  export default Vue.extend({
    name: 'DraggableContainer',
    /**
     * Use a render function to wrap all child components with DraggableCotainerElement (not possible
     * using a Vue template). As a template, this would look something like
     * <DraggableContainerImplementation
     *   :value="value"
     *   :disabled="disabled"
     *   v-on="$listeners"
     * >
     *   <template #default="{ dragging }"
     *     ** NOT EXPRESSIBLE AS A VUE TEMPLATE
     *     this.$slots.default.map((child, index) => (
     *       <DraggableContainerElement
     *         :key="`draggable-${child.key}`"
     *         :isDragging="index === dragging"
     *       >
     *         <child />
     *       </DraggableContainerElement>
     *     );
     *     **
     *   </template>
     * </DraggableContainerImplementation>
     */
    render(h: CreateElement): VNode {
      return h(draggableContainerImplementation,
               {
                 props: {
                   value: this.value,
                   disabled: this.disabled,
                   group: this.group,
                   containerId: this.containerId,
                   padding: this.padding,
                   showPlaceholder: this.showPlaceholder
                 },
                 on: this.$listeners,
                 scopedSlots: {
                   default: ({ dragging }: DefaultSlotProps) => (this.$slots.default
                     ? this.$slots.default
                       .map((child, index) => h(draggableContainerElement,
                                                {
                                                  // @ts-ignore
                                                  key: `draggable-${child.key}-${index}`,
                                                  props: {
                                                    isDragging: index === dragging
                                                  }
                                                },
                                                [child]))
                     : [])
                 }
               });
    },
    props: {
      value: {
        type: Array as PropType<any[]>,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      group: {
        type: [Object, String] as PropType<DraggableGroup | string>,
        default: () => ({
          name: DraggableGroupNames.WIDGETS
        })
      },
      containerId: {
        type: String,
        default: null
      },
      padding: {
        type: Boolean,
        default: true
      },
      showPlaceholder: {
        type: Boolean,
        default: false
      }
    }
  });
