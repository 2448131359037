
  import Vue from 'vue';
  import { Prop } from "vue/types/options";
  import { mapState } from "vuex";

  import PictureInput from 'vue-picture-input';

  import FormCard from '@/main/webapp/vue/components/ui/form/card/index.vue';

  import { ImageUtil } from "@/main/webapp/vue/util/ImageUtil";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { Entity } from "@/main/webapp/vue/model/api/Entity";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

  import messages from "./messages.json";
  import notification from "@/main/webapp/vue/notification";

  export default Vue.extend({
    components: {
      PictureInput,
      FormCard
    },
    props: {
      title: {
        type: String,
        default: null
      },
      holderEntity: {
        type: Object as Prop<Entity>,
        required: true
      },
      itemContentEntity: {
        type: Object as Prop<ItemContentEntity>,
        required: true
      },
      supportedTypes: {
        type: String,
        default: "image/jpeg,image/png"
      },
      showFooter: {
        type: Boolean,
        default: true
      },
      alwaysSave: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        existingContent: null as unknown as File,
        selectedContent: null as unknown as File,
        updating: false,
        changed: false,
        pictureInputTexts: {
          remove: this.$t('button.unset')
        },
        downloadLink: '' as String
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      onChange(base64Content: string): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('content-upload - onChange()');
        }

        this.changed = true;
        this.selectedContent = ImageUtil.base64ToFile(base64Content, (this.$refs.pictureInput as any).fileName);
        if (this.alwaysSave) {
          this.update();
        }
      },
      onUnset(): void {
        this.changed = true;
      },
      update(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('content-upload - update()');
        }

        if (this.selectedContent) {
          this.uploadSelectedContent();
        } else {
          this.deletePreviousContent();
        }
      },
      loadPreviousContent(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('content-upload - loadPreviousContent()');
        }

        this.updating = true;
        BackendIntegrationService.fetchItemContentEntity(this.holderEntity, this.itemContentEntity)
          .then((response: Blob) => {
            this.existingContent = ImageUtil.blobToFile(response);
            this.setDownloadLink();
          }).catch(() => {
            // No content defined, ignore
          }).finally(() => {
            this.updating = false;
          });
      },
      uploadSelectedContent(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('content-upload - uploadSelectedContent()');
        }

        this.updating = true;
        BackendIntegrationService.postItemContentEntity(this.holderEntity, this.itemContentEntity, this.selectedContent)
          .then((response: ItemContentEntity) => {
            this.$emit('content-updated', response, (this.$refs.pictureInput as any).fileName);
          }).catch(() => {
            notification.fail(this.$t('text.error.post'));
          }).finally(() => {
            this.updating = false;
          });
      },
      deletePreviousContent(): void {
        this.updating = true;
        BackendIntegrationService.deleteItemContentEntity(this.holderEntity, this.itemContentEntity)
          .then(() => {
            this.$emit('content-updated');
          }).catch(() => {
            notification.fail(this.$t('text.error.delete'));
          }).finally(() => {
            this.updating = false;
          });
      },
      setDownloadLink(): void {
        let link: NavigationLink | undefined =
          NavigationLinks.getLinkFromLinks(NavigationLinkType.PAGE_SELF, this.itemContentEntity.links);
        if (link) {
          this.downloadLink = link.href;
        }
      }
    },
    mounted(): void {
      if (this.itemContentEntity) {
        this.loadPreviousContent();
      }
    },
    i18n: {
      messages: messages
    }
  });
