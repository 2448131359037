export class CollapsibleControl {
  text: string;
  icon: string;
  color: string;
  collapsed: boolean | null;
  buttonStyle: string;
  buttonOutlineStyle: string;

  constructor(text: string, icon: string, collapsed: boolean | null) {
    this.text = text;
    this.collapsed = collapsed;
    this.icon = icon;
    this.color = '#6c757d';
    this.buttonStyle = 'btn-secondary';
    this.buttonOutlineStyle = 'btn-outline-secondary';
  }

  isActive(that: CollapsibleControl): boolean {
    if (that && this.text === that.text) {
      return true;
    }

    return false;
  }

  public getColor(that: CollapsibleControl): string | null {
    if (this.isActive(that)) {
      return "white";
    }

    return this.color;
  }

  public getStyleClass(that: CollapsibleControl): string {
    if (this.isActive(that)) {
      return this.buttonStyle;
    }

    return this.buttonOutlineStyle;
  }
}

export default class CollapsibleControlFactory {
  DEFAULT(): CollapsibleControl {
    return new CollapsibleControl("Default", "show-thumbnails-with-lines", null);
  }

  COLLAPSE(): CollapsibleControl {
    return new CollapsibleControl("Collapse", "resize-small", true);
  }

  EXPAND(): CollapsibleControl {
    return new CollapsibleControl("Expand", "resize-full", false);
  }
}
