
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';
  import WidgetModel from '@/main/webapp/vue/model/learning/WidgetModel';
  import { getWidgetRegistration } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";


  export default Vue.extend({
    name: 'PropertiesSideBar',
    props: {
      model: {
        type: Object as PropType<WidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      buttonFixed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      component() {
        const widgetRegistration = getWidgetRegistration(this.model);
        if (widgetRegistration) {
          return widgetRegistration.propertiesComponent;
        }
        return undefined;
      }
    },
    i18n: {
      messages: messages
    }
  });
