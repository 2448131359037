import { JsonObject, JsonProperty } from "json2typescript";
import { LatLng } from "leaflet";
import { MapAddress } from "@/main/webapp/vue/model/api/Map/MapAddress";

@JsonObject('MapPropertiesItem')
export class MapPropertiesItem {
    @JsonProperty('shid', Number, true)
    shid?: number = undefined;

    @JsonProperty('chids', [Number], true)
    chids?: number[] = undefined;

    @JsonProperty('pid', Number, true)
    pid?: number = undefined;

    @JsonProperty('name', String, true)
    name?: string = undefined;

    @JsonProperty('latLng', LatLng, true)
    latLng?: LatLng = undefined;

    @JsonProperty('ps', String, true)
    ps?: string = undefined;

    @JsonProperty('address', MapAddress, true)
    address?: MapAddress = undefined;
}
