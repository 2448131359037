
  import Vue from 'vue';

  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      display: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        show: this.display as boolean
      };
    },
    methods: {
      close() {
        this.show = false;
        this.$emit("close");
      }
    }
  });
