import { JsonObject, JsonProperty } from "json2typescript";
import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";

@JsonObject('ItemContentEntityContainer')
export class ItemContentEntityContainer {

  @JsonProperty('list', [ItemContentEntity], false)
  list: ItemContentEntity[] = [];

}
