import { JsonObject, JsonProperty } from "json2typescript";
import { DateTime } from "@/main/webapp/vue/model/api/DateTime";
import { MapShop } from "@/main/webapp/vue/model/api/Map/MapShop";
import { ProjectStatusDefinition } from "@/main/webapp/vue/model/api/ProjectStatusDefinition";
import { User } from "@/main/webapp/vue/model/api/User";
import { MapDefaultEntity } from "@/main/webapp/vue/model/api/Map/MapDefaultEntity";
import { GeoLocation } from "@/main/webapp/vue/model/api/GeoLocation";
import { MapAddress } from "@/main/webapp/vue/model/api/Map/MapAddress";
import { MapSubmissionDetails } from "@/main/webapp/vue/model/api/Map/MapSubmissionDetails";

@JsonObject('MapProjectDetails')
export class MapProjectDetails extends MapDefaultEntity {
    @JsonProperty('created', DateTime, true)
    created?: DateTime = undefined;

    @JsonProperty('shop', MapShop, true)
    shop?: MapShop = undefined;

    @JsonProperty('user', User, true)
    user?: User = undefined;

    @JsonProperty('value', ProjectStatusDefinition, true)
    value?: ProjectStatusDefinition = undefined;

    @JsonProperty('chainName', String, true)
    chainName?: string = undefined;

    @JsonProperty('description', String, true)
    description?: string = undefined;

    @JsonProperty('location', GeoLocation, true)
    location?: GeoLocation = undefined;

    @JsonProperty('address', MapAddress, true)
    address?: MapAddress = undefined;

    @JsonProperty('responsibleUsers', [User], true)
    responsibleUsers?: User[] = undefined;

    @JsonProperty('latestSubmissions', [MapSubmissionDetails], true)
    latestSubmissions?: MapSubmissionDetails[] = undefined;
}
