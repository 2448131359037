
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import buttonStyle from "@/main/webapp/vue/components/ui/style/button/container/index.vue";
  import textStyle from "@/main/webapp/vue/components/ui/style/text/container/index.vue";
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import ShareWidgetModel from "@/main/webapp/vue/components/learning/widget/widgets/share/common/model/ShareWidgetModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'ShareWidgetProperties',
    props: {
      model: {
        type: Object as PropType<ShareWidgetModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      buttonStyle,
      textStyle,
      genericStyle
    },
    data() {
      return {
        googleFontUrl: 'https://fonts.google.com/icons' as string
      };
    },
    i18n: {
      messages: messages
    }
  });
