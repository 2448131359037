import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('ProjectStatusLabel')
export class ProjectStatusLabel {

  @JsonProperty('name', String, false)
  name: string = "";

  @JsonProperty('text', String, false)
  text: string = "";

  @JsonProperty('color', String, false)
  color: string = "";

  @JsonProperty('style', String, false)
  style: string = "";

}
