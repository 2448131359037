
  import Vue from 'vue';
  import { mapState } from "vuex";

  import themable from '../themable/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { ThemableComponents, ThemablePlatform } from "@/main/webapp/vue/model/api/ThemableComponents";
  import { ThemableComponent } from "@/main/webapp/vue/model/api/ThemableComponent";
  import { StringValue } from "@/main/webapp/vue/model/api/StringValue";

  export default Vue.extend({
    components: {
      themable
    },
    props: {
      platform: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        theme: {} as ThemableComponents,
        organizationBrandingCss: null as null | String
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    head: { // Part of vue-head package to dynamically inject elements in <head />
      style: function() {
        return [
          // When updating need to first reset the backend-generated CSS
          { type: 'text/css', href: '', id: 'theme-css' },
          // @ts-ignore
          { type: 'text/css', inner: this.organizationBrandingCss, id: 'theme-css-updated' }
        ];
      }
    },
    methods: {
      fetchTheme(platform: string): void {
        BackendIntegrationService.fetchThemeForPlatform(this.userDetails.organization, platform).then((response: ThemableComponents) => {
          this.theme = response;
        });
      },
      refreshComponent(updatedComponent: ThemableComponent): void {
        if (this.platform === ThemablePlatform.WEB) {
          this.fetchUpdatedThemeCss();
        }
      },
      fetchUpdatedThemeCss() {
        BackendIntegrationService.fetchThemeCssForPlatform(this.userDetails.organization, this.platform).then((response: StringValue) => {
          this.organizationBrandingCss = response.value;
          this.$emit('updateHead');
        });
      }
    },
    mounted(): void {
      this.fetchTheme(this.platform);
    }
  });
