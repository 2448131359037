import { JsonObject, JsonProperty } from "json2typescript";
import { ProjectDocumentationLink } from "./ProjectDocumentationLink";
import { ProjectDocumentationAttachment } from "./ProjectDocumentationAttachment";

@JsonObject('ProjectDocumentation')
export class ProjectDocumentation {

  @JsonProperty('description', String, true)
  description: string | undefined = undefined;

  @JsonProperty('attachments', [ProjectDocumentationAttachment], true)
  attachments: [ProjectDocumentationAttachment] | undefined = undefined;

  @JsonProperty('links', [ProjectDocumentationLink], true)
  links: ProjectDocumentationLink[] | undefined = undefined;
}
