
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';
  import notification from "@/main/webapp/vue/notification";

  export enum msgBoxType {
    CONFIRM = "confirm",
    OK = "ok"
  }

  export default Vue.extend({
    props: {
      boxType: {
        type: String as PropType<msgBoxType>,
        default: msgBoxType.CONFIRM
      },
      title: {
        type: String
      },
      okText: {
        type: String,
        default: "OK"
      },
      cancelText: {
        type: String,
        default: "Cancel"
      }
    },
    methods: {
      showMessageComfirm(): void {
        this.$bvModal.msgBoxConfirm(this.title, {
          size: 'sm',
          buttonSize: 'sm',
          footerClass: 'p-2',
          centered: true,
          okTitle: this.okText,
          cancelTitle: this.cancelText
        }).then((confirm: boolean) => {
          this.$emit('user-confirm', confirm);
        }).catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Delete activity failed', err);
          }
          notification.fail('text.error.confirm');
        });
      },
      showMessageOk() {
        this.$bvModal.msgBoxOk(this.title, {
          size: 'sm',
          buttonSize: 'sm',
          footerClass: 'p-2',
          centered: true,
          okTitle: this.okText
        }).then((confirm: boolean) => {
          this.$emit('user-confirm', confirm);
        }).catch(err => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Delete activity failed', err);
          }
          notification.fail('text.error.confirm');
        });
      }
    },
    mounted() {
      if (this.boxType === msgBoxType.CONFIRM) {
        this.showMessageComfirm();
      } else if (this.boxType === msgBoxType.OK) {
        this.showMessageOk();
      }
    },
    i18n: {
      messages: messages
    }
  });
