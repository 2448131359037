
  import Vue, { PropType } from 'vue';

  import { Metadata } from "@/main/webapp/vue/model/api/Metadata";

  import glyphiconSymbol from '../../glyphicon/glyphicon-symbol/index.vue';
  import messages from './messages.json';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      metadata: {
        type: Object as PropType<Metadata>,
        required: true
      },
      link: {
        type: String,
        required: true
      },
      showText: {
        type: Boolean,
        default: true
      }
    },
    i18n: {
      messages: messages
    }
  });
