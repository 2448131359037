import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('ProjectDocumentationLink')
export class ProjectDocumentationLink {

  @JsonProperty('label', String, true)
  label: string | undefined = undefined;

  @JsonProperty('target', String, true)
  target: string | undefined = undefined;

}
