import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks, NavigationLinkType } from "./NavigationLinks";
import { NavigationLink } from "./NavigationLink";
import { AttributeEntityValue } from "@/main/webapp/vue/model/api/AttributeEntity";

export enum ItemContentType {
  THUMBNAIL = "thumbnail",
  STANDARD = "standard",
  UNPROCESSED = "unprocessed"
}

@JsonObject('ItemContent')
export class ItemContent implements AttributeEntityValue {
  @JsonProperty('id', Number, true)
  id?: number = undefined;

  @JsonProperty('token', String, true)
  token?: string = undefined;

  @JsonProperty('filename', String, true)
  filename?: string = undefined;

  @JsonProperty('mimetype', String, true)
  mimetype?: string = undefined;

  @JsonProperty('filename', String, true)
  fileExtension?: string = undefined;

  @JsonProperty('image', Boolean, true)
  image?: boolean = true;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

  constructor(filename: string, mimetype: string, image: boolean, nav: NavigationLinks) {
    this.filename = filename;
    this.mimetype = mimetype;
    this.image = image;
    this.nav = nav;
  }

  public generateNoImageContent(image: boolean, target: string, webLinkUrl: string): ItemContent {
    this.image = image;

    this.nav = new NavigationLinks();

    let webLink = new NavigationLink();
    webLink.rel = NavigationLinkType.WEB;
    webLink.href = webLinkUrl;
    this.nav.addLink(webLink);

    let noContentImageUrl = new NavigationLink();
    noContentImageUrl.rel = ItemContentType.THUMBNAIL;
    noContentImageUrl.href = target;
    this.nav.addLink(noContentImageUrl);

    return this;
  }
}
