
  import Vue from 'vue';

  import textButton from '@/main/webapp/vue/components/ui/text/text-button/index.vue';
  import collapsibleControl from '@/main/webapp/vue/components/ui/collapsible/collapsible-control/index.vue';
  import messages from './messages.json';

  export default Vue.extend({
    components: {
      textButton,
      collapsibleControl
    },
    i18n: {
      messages: messages
    }
  });
