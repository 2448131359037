import WidgetModel from './WidgetModel';

import ActivityProperties from "@/main/webapp/vue/model/learning/ActivityProperties";

import ActivityModel, { ActivityIdType } from "@/main/webapp/vue/model/learning/ActivityModel";
import CourseProperties from "@/main/webapp/vue/model/api/learning/CourseProperties";

export default class ActivityPublicModel extends ActivityModel {

  courseProperties: CourseProperties;

  constructor(public id: ActivityIdType,
              name: string,
              sequence: number = 0,
              urlPathFragment: string = "/",
              urlPath: string = '',
              active: boolean = true,
              properties: ActivityProperties = new ActivityProperties(),
              courseProperties: CourseProperties = new CourseProperties(),
              subActivities: ActivityModel[] = [],
              widgets: WidgetModel[] = [],
              baseUrl: string = "") {
    super(id, name, sequence, urlPathFragment, urlPath, active, properties, subActivities, widgets, baseUrl);
    this.courseProperties = courseProperties;
  }

}
