import { JsonObject, JsonProperty } from "json2typescript";
import { Shop } from "./Shop";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('ShopContainer')
export class ShopContainer {

  @JsonProperty('shops', [Shop], false)
  list: Shop[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
