import { Any, JsonObject, JsonProperty } from "json2typescript";
import { Address } from './Address';
import { UserContact } from "./UserContact";
import { LastSubmission } from "./LastSubmission";
import { Shop } from "@/main/webapp/vue/model/api/Shop";

@JsonObject('ShopDetails')
export class ShopDetails extends Shop {

  @JsonProperty('active', Boolean, true)
  active?: boolean = undefined;

  @JsonProperty('chainName', String, true)
  chainName?: string = undefined;

  @JsonProperty('provider', String, true)
  provider?: string = undefined;

  @JsonProperty('description', String, true)
  description?: string = undefined;

  @JsonProperty('address', Address, true)
  address?: Address = undefined;

  @JsonProperty('additionalData', Any, true)
  additionalData?: Map<string, string> = new Map<string, string>();

  @JsonProperty('responsibleUsers', [UserContact], true)
  responsibleUsers?: UserContact[] = [];

  @JsonProperty('latestSubmissions', [LastSubmission], true)
  latestSubmissions?: LastSubmission[] = [];

}
