import { JsonObject, JsonProperty } from "json2typescript";
import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";

@JsonObject('UserRegistryClient')
export class UserRegistryClient extends EntityRegistryClient {

  @JsonProperty('registration', String, false)
  registration: string = "";

}
