import WidgetModel, {
  WidgetIdType,
  DraggableSection,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import ButtonStyleModel from '@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel';
import navigationMenuWidget from
  '@/main/webapp/vue/components/learning/widget/widgets/navigation-menu/widget/index.vue';
import navigationMenuWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/navigation-menu/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class NavigationMenuData {
  constructor(@JsonField('selectedActivityIds', [Number])
              public selectedActivityIds: number[] = [],
              @JsonField('widgets', [WidgetModel])
              public widgets: WidgetModel[] = []) {}
}

export class NavigationMenuProperties {
  constructor(@JsonField('menuButtonStyle', ButtonStyleModel, true)
              public menuButtonStyle = new ButtonStyleModel(),
              @JsonField('buttonStyle', ButtonStyleModel, true)
              public buttonStyle = new ButtonStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'navigationMenu')
export default class NavigationMenuWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.HEADER,
              metadata = new WidgetMetadata(),
              @JsonField('data', NavigationMenuData)
              public data = new NavigationMenuData(),
              @JsonField('properties', NavigationMenuProperties)
              public properties = new NavigationMenuProperties(),
              @JsonField('defaultProperties', NavigationMenuProperties, true)
              public defaultProperties = new NavigationMenuProperties(
                new ButtonStyleModel('#ffffff', '#000000'),
                new ButtonStyleModel('#17a2b8', '#ffffff', 0),
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.NAVIGATION,
  NavigationMenuWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'menu',
  navigationMenuWidget,
  navigationMenuWidgetProperties
);
