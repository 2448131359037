import { JsonObject, JsonProperty } from "json2typescript";
import { DateTime } from "./DateTime";
import { User } from "./User";
import { ItemContent } from "./ItemContent";
import { Shop } from "./Shop";
import { Project } from "./Project";

@JsonObject('LastSubmission')
export class LastSubmission {

  @JsonProperty('created', DateTime, false)
  created?: DateTime = undefined;

  @JsonProperty('author', User, true)
  author?: User = undefined;

  @JsonProperty('shop', Shop, true)
  shop?: Shop = undefined;

  @JsonProperty('project', Project, true)
  project?: Project = undefined;

  @JsonProperty('contents', [ItemContent], false)
  contents: ItemContent[] = [];


}

