import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';

import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import translationUtil from "@/main/webapp/vue/util/translationUtil";

import tableWidget from '@/main/webapp/vue/components/learning/widget/widgets/table/widget/index.vue';
import tableWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/table/properties/index.vue';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class TableWidgetData {
  constructor(@JsonField('html', String)
              public html: string = "") {}
}

export class TableWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'Table')
export default class TableWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection: DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', TableWidgetData)
              public data = new TableWidgetData(),
              @JsonField('properties', TableWidgetProperties)
              public properties = new TableWidgetProperties(),
              @JsonField('defaultProperties', TableWidgetProperties, true)
              public defaultProperties = new TableWidgetProperties(),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.ADVANCED,
  TableWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'table_view',
  tableWidget,
  tableWidgetProperties,
  (id: WidgetIdType) => new TableWidgetModel(id, DraggableSection.BODY, new WidgetMetadata())
);
