
  import Vue from 'vue';

  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      icon: {
        type: String
      },
      iconColor: {
        type: String
      },
      badge: {
        type: Number
      },
      badgeStyle: {
        type: String
      },
      size: {
        type: String,
        default: 'medium'
      },
      text: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      },
      title: {
        type: String
      },
      hoverEffect: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        hover: false as boolean
      };
    },
    methods: {
      onIconClick(): void {
        if (!this.disabled) {
          this.$emit('icon-clicked');
        }
      },
      onTextClick(): void {
        if (!this.disabled) {
          this.$emit('text-clicked');
        }
      },
      onMouseEnter() {
        if (this.hoverEffect) {
          this.hover = true;
        }
      },
      onMouseLeave() {
        if (this.hoverEffect) {
          this.hover = false;
        }
      }
    }
  });
