
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      icon: {
        type: String,
        required: true
      },
      color: {
        type: String,
        default: ""
      },
      theme: {
        type: String,
        default: "dark"
      },
      size: {
        type: String,
        default: "medium"
      },
      rotateAnimation: {
        type: Boolean,
        default: false
      }
    }
  });
