
  import Vue from 'vue';
  import messages from "./messages.json";

  import bootstrapPopover from '@/main/webapp/vue/components/ui/bootstrap-popover/index.vue';
  import dataTableLazy from '@/main/webapp/vue/components/ui/table/lazy/index.vue';
  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";

  export default Vue.extend({
    components: {
      bootstrapPopover,
      dataTableLazy,
      glyphiconSymbol
    },
    props: {
      uid: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        fields: [{
          key: 'name',
          label: this.$t('text.responsible-shops')
        }] as Field[],
        nextPage: null as NavigationLink | null,
        show: false as boolean
      };
    },
    methods: {
      showContent(): void {
        setTimeout(() => { // This is to close modal when click outside of modal
          this.show = true;
          let bodyContent: Element | Vue | (Element | Vue)[] | undefined = this.$refs.bodyContent;
          if (bodyContent) {
            (bodyContent as HTMLDivElement).focus();
          }
        }, 100);
      },
      hideContent(): void {
        this.show = false;
      }
    },
    created(): void {
      if (!this.nextPage && this.uid) {
        this.nextPage = NavigationLink.weblink(`/shops/users/${this.uid}/responsible`);
      }
    },
    i18n: {
      messages: messages
    }
  });
