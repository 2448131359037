import { JsonObject, JsonProperty } from "json2typescript";
import { ReportType } from "@/main/webapp/vue/model/api/report/ReportType";
import { ReportFilter } from "@/main/webapp/vue/model/api/report/ReportFilter";

@JsonObject('WebSocketReportRequest')
export class WebSocketReportRequest {

  @JsonProperty('reportType', ReportType)
  reportType: ReportType | undefined = undefined;

  @JsonProperty('destination', String)
  destination: string | undefined = undefined;

  @JsonProperty('reportFilter', ReportFilter)
  reportFilter: ReportFilter | undefined = undefined;
}
