
  import Vue, { PropType } from 'vue';
  import { LMarker, LPopup } from "vue2-leaflet";
  import L, { Icon, Marker } from 'leaflet';
  import 'leaflet.awesome-markers';

  import mapConfiguration from "@/main/webapp/vue/components/map/mapConfiguration";

  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";

  export default Vue.extend({
    components: {
      LMarker,
      LPopup
    },
    props: {
      location: {
        type: Object as PropType<MapMarker>,
        default: null
      },
      useDefaultPopup: {
        type: Boolean,
        default: true
      },
      openPopup: {
        type: Boolean,
        default: false
      },
      draggable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        marker: null as Marker | null,
        markerDetails: null as any | null
      };
    },
    computed: {
      getIcon(): Icon {
        return (L as any).AwesomeMarkers.icon({
          prefix: 'glyphicons',
          markerColor: this.location.markerData ? this.location.markerData.color : mapConfiguration.config.iconTypes.shop.color,
          icon: this.location.markerData ? this.location.markerData.icon : ''
        });
      }
    },
    methods: {
      onMarkerClick(): void {
        if (this.marker && this.location && this.location.html) {
          this.$emit('marker-clicked', this.location.html.item, this.marker);
        }
      },
      listenToDragEvent(): void {
        if (this.draggable && this.marker) {
          this.marker.on('dragend', (e: any) => {
            if (this.marker) {
              this.marker.openPopup();
              this.$emit('drag-end', e.target._latlng);
            }
          });
        }
      },
      markerPopupOpen(): void {
        if (this.openPopup && this.marker) {
          this.marker.openPopup();
          this.listenToDragEvent();
        }
      }
    },
    mounted(): void {
      this.marker = (this as any).$refs.marker.mapObject;
      this.listenToDragEvent();
    },
    watch: {
      location(): void {
        this.markerPopupOpen();
      }
    }
  });
