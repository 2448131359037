
  import Vue, { PropType } from 'vue';
  import VueI18n from "vue-i18n";
  import TranslateResult = VueI18n.TranslateResult;

  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';

  import { OrganizationModuleFeature } from "@/main/webapp/vue/model/api/OrganizationModuleFeature";

  import messages from "@/main/webapp/vue/components/admin/module-configurator/module-configuration/module-feature/messages.json";

  enum OrganizationURLValidationResult {
    VALID = 'VALID',
    TOO_SHORT = 'TOO_SHORT',
    MISSING_SLASH = 'MISSING_SLASH',
    ALREADY_IN_USE = 'ALREADY_IN_USE'
  }

  enum OrganizationModuleFeatureType {
    ORGANIZATION_URL_PATH_FRAGMENT = 'ORGANIZATION_URL_PATH_FRAGMENT'
  }

  enum OrganizationModuleAttributeType {
    BOOLEAN = 'BOOLEAN',
    STRING = 'STRING'
  }

  export default Vue.extend({
    components: {
      formSwitch
    },
    props: {
      feature: {
        type: Object as PropType<OrganizationModuleFeature>,
        required: true
      }
    },
    data() {
      return {
        featureActive: this.feature.value === '1',
        minimumUrlLength: 2,
        OrganizationModuleFeatureType,
        OrganizationModuleAttributeType
      };
    },
    computed: {
      state(): boolean | null {
        if (this.feature.name === OrganizationModuleFeatureType.ORGANIZATION_URL_PATH_FRAGMENT) {
          return this.organizationUrlValidation === OrganizationURLValidationResult.VALID ? null : false;
        }

        return null;
      },
      organizationUrlValidation(): OrganizationURLValidationResult {
        if (!this.feature.value || this.feature.value.length < this.minimumUrlLength) {
          return OrganizationURLValidationResult.TOO_SHORT;
        }

        return OrganizationURLValidationResult.VALID;
      },
      invalidFeedbackText(): TranslateResult {
        return this.$t(`text.organization-url-validation.${this.organizationUrlValidation}`);
      }
    },
    methods: {
      featureBooleanValueUpdated: function(value: boolean) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`featureBooleanValueUpdated - OrganizationModuleFeature - value: '${value}'`);
          console.log(`feature.value '${this.feature.value}'`);
        }

        // The feature's system uses "0" and "1", so we need to convert
        if (value) {
          this.feature.value = "1";
        } else {
          this.feature.value = "0";
        }

        this.$emit('feature-value-updated', this.feature);
      },
      featureStringValueUpdated: function() {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`featureStringValueUpdated - OrganizationModuleFeature - feature.value: '${this.feature.value}'`);
        }

        if (this.feature.name === OrganizationModuleFeatureType.ORGANIZATION_URL_PATH_FRAGMENT) {
          if (this.state !== false) {
            // local validation succeeds, pass to backend
            this.$emit('feature-value-updated', this.feature);
          } else {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`Validation failed for URL path: '${this.feature.value}'`);
            }
          }
        } else {
          this.$emit('feature-value-updated', this.feature);
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
