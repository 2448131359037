var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.activeActivity)?_c('div',{staticClass:"course-activity-builder w-100 p-0 m-0 d-flex"},[_c('b-card',{staticClass:"activity-builder-column widgets-column",attrs:{"id":"activity-builder-column-0","no-body":""}},[_c('b-container',{staticClass:"activity-builder p-0",class:{'position-fixed': _vm.propertiesBar.top !== 'auto'},style:({
                    top: _vm.propertiesBar.top,
                    height: `calc(100% - ${_vm.propertiesBar.heightFromBottom})`,
                    width: 250 + 'px'
                 })},[_c('activity-list',{staticClass:"activity-builder-activities",attrs:{"activity":_vm.mainActivity,"course":_vm.course,"active-activity":_vm.activeActivity},on:{"activate-activity":_vm.activateActivity,"edited":_vm.editActivity,"delete":_vm.deleteActivity}}),_c('widget-side-bar',{staticClass:"activity-builder-contents",on:{"widget-selected":_vm.onWidgetSelectedInSideBar,"widget-ended":_vm.onWidgetEndedInSideBar}})],1)],1),_c('b-container',{staticClass:"activity-builder-column canvas-column",attrs:{"id":"activity-builder-column-1"}},[_c('div',{staticClass:"activity",on:{"blur":_vm.checkClickingOutsideOfEditingWidget}},[_c('div',{ref:"sectionHeader",staticClass:"activity-header",class:_vm.checkSectionCompatibilityForClass(_vm.section.header)},[_c('div',{staticClass:"section-wrapper section-header"},[(_vm.course && _vm.course.id && (!_vm.activeActivity.properties || _vm.activeActivity.properties.showLogo))?_c('course-logo',{attrs:{"course":_vm.course,"alt-text":_vm.activeActivity.name,"type":_vm.courseLogo.type,"link-enabled":_vm.activeActivity && _vm.activeActivity.properties && _vm.activeActivity.properties.logoLinkMainActivity,"link-target-blank":true,"show-default":false,"max-width":"200"}}):_vm._e(),_c('draggable-container',{attrs:{"value":_vm.activeActivity.widgets,"disabled":false,"group":{
                                  name: `${_vm.section.header} widgets`,
                                  section: _vm.section.header,
                                  pull: true,
                                  put() {
                                    return _vm.selectingWidgetCompatibleSection === _vm.section.header
                                        || _vm.selectingWidgetCompatibleSection === _vm.section.all;
                                  }
                               },"show-placeholder":_vm.headerWidgets.length === 0},on:{"input":(widgets) => _vm.updateSectionWidgets(widgets, _vm.section.header),"widget-created":_vm.onWidgetCreated,"widget-selected":_vm.onWidgetSelected,"widget-ended":_vm.onWidgetEnded}},_vm._l((_vm.activeActivity.widgets),function(widget){return _c('div',{key:widget.id},[(widget.section === _vm.section.header)?_c('widget-component',{staticClass:"main-draggable-widget",attrs:{"widget":widget,"course":_vm.course,"activity":_vm.activeActivity,"editing":_vm.editingWidget !== undefined && widget.id === _vm.editingWidget.id,"editingWidget":_vm.editingWidget,"editable":true,"widget-from-side-bar":_vm.widgetFromSidebar},on:{"create":_vm.onWidgetCreated,"edit":_vm.onEdit,"delete":_vm.onDelete,"edited":_vm.onEdited,"moved":_vm.onWidgetEnded,"activate-activity":_vm.activateActivity,"stop-editing":function($event){return _vm.onStopEditing(false)},"unlink":_vm.onUnlink}}):_vm._e()],1)}),0)],1)]),_c('div',{ref:"sectionBody",staticClass:"activity-body d-flex",class:_vm.checkSectionCompatibilityForClass(_vm.section.body)},[_c('div',{staticClass:"section-wrapper w-100 d-flex"},[_c('draggable-container',{staticClass:"d-flex",attrs:{"value":_vm.activeActivity.widgets,"disabled":false,"group":{
                                 name: `${_vm.section.body} widgets`,
                                 section: _vm.section.body,
                                 pull: true,
                                 put(to, from) {
                                   return _vm.selectingWidgetCompatibleSection === _vm.section.body
                                       || _vm.selectingWidgetCompatibleSection === _vm.section.all;
                                 }
                               },"show-placeholder":_vm.bodyWidgets.length === 0},on:{"input":(widgets) => _vm.updateSectionWidgets(widgets, _vm.section.body),"widget-created":_vm.onWidgetCreated,"widget-selected":_vm.onWidgetSelected,"widget-ended":_vm.onWidgetEnded}},_vm._l((_vm.activeActivity.widgets),function(widget){return _c('div',{key:widget.id},[(widget.section === _vm.section.body)?_c('widget-component',{staticClass:"main-draggable-widget",attrs:{"widget":widget,"course":_vm.course,"activity":_vm.activeActivity,"editing":_vm.editingWidget !== undefined && widget.id === _vm.editingWidget.id,"editingWidget":_vm.editingWidget,"editable":true,"widget-from-side-bar":_vm.widgetFromSidebar},on:{"create":_vm.onWidgetCreated,"edit":_vm.onEdit,"delete":_vm.onDelete,"edited":_vm.onEdited,"moved":_vm.onWidgetEnded,"activate-activity":_vm.activateActivity,"stop-editing":function($event){return _vm.onStopEditing(false)},"unlink":_vm.onUnlink}}):_vm._e()],1)}),0)],1)]),_c('div',{ref:"sectionFooter",staticClass:"activity-footer"},[_c('div',{staticClass:"section-wrapper"},[_c('organization-footer',{attrs:{"data":_vm.organizationData}})],1)])])]),_c('b-card',{staticClass:"activity-builder-column properties-column",attrs:{"id":"activity-builder-column-2","no-body":""}},[_c('b-container',{staticClass:"activity-builder p-0",class:{'position-fixed': _vm.propertiesBar.top !== 'auto'},style:({
                   top: _vm.propertiesBar.top,
                   height: `calc(100% - ${_vm.propertiesBar.heightFromBottom})`,
                   width: 275 + 'px'
                 })},[(_vm.editingWidget !== undefined)?_c('properties-side-bar',{attrs:{"model":_vm.editingWidget,"course":_vm.course,"activity":_vm.activeActivity,"button-fixed":_vm.propertiesBar.heightFromBottom !== '125px'},on:{"delete":_vm.onDelete,"edited":_vm.onEdited,"cancel":_vm.onCancel}}):_c('activity-side-bar',{attrs:{"model":_vm.activeActivity,"is-root":_vm.activeActivity.id === _vm.mainActivity.id},on:{"edited":_vm.editActivity}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }