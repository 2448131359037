
  import Vue from 'vue';
  import { oc as Optional } from "ts-optchain";
  import L, { Map, Marker } from 'leaflet';
  import { mapState } from "vuex";
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';
  import PruneCluster from '@/main/webapp/vue/components/map/prune-cluster/index.vue';
  import markers from '@/main/webapp/vue/components/map/marker/index.vue';
  import textButton from '@/main/webapp/vue/components/ui/text/text-button/index.vue';
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import ShopPopup from "@/main/webapp/vue/components/map/marker/marker-popup/shop-popup/index.vue";
  import notification from "@/main/webapp/vue/notification";

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import mapHtmlConfiguration from "@/main/webapp/vue/components/map/mapHtmlConfiguration";
  import markerConfig from "@/main/webapp/vue/components/map/markerConfig";

  import { MapShop } from "@/main/webapp/vue/model/api/Map/MapShop";
  import { MapMarkerContainer } from "@/main/webapp/vue/model/api/Map/MapMarkerContainer";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
  import { MapShopContainer } from "@/main/webapp/vue/model/api/Map/MapShopContainer";
  import { IconTypes } from "@/main/webapp/vue/model/api/Map/IconTypes";
  import { MapProperties } from "@/main/webapp/vue/model/api/Map/MapProperties";
  import { MapProjectDetails } from "@/main/webapp/vue/model/api/Map/MapProjectDetails";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  export default Vue.extend({
    components: {
      baseMap,
      PruneCluster,
      markers,
      textButton,
      loadingIcon
    },
    props: {
      allowEditShops: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        locations: [] as MapMarker[],
        nextPage: undefined as NavigationLink | undefined,
        mapObject: null as Map | null,
        mapHeight: `0px` as string, // thumbs map page - map size window.innerHeight * 0.4
        markerColors: mapConfiguration.config.markerColors as string[],
        viewInClusters: true as boolean,
        isMapShown: false as boolean,
        showButton: true as boolean,
        zoomInThisLocation: 0 as number,
        iconTypes: mapConfiguration.config.iconTypes as IconTypes,
        initHTML: false as boolean,
        enableMap: false as boolean,
        loading: false as boolean
      };
    },
    computed: {
      ...mapState([
        'mapProperties'
      ])
    },
    methods: {
      clearLocations(): void {
        this.locations = [];
        this.nextPage = undefined;
      },
      viewWithoutCluster(): void {
        this.viewInClusters = false;
      },
      fitBounds(): void {
        if (this.mapObject) {
          mapConfiguration.fitBounds(this.mapObject, this.locations);
        }
      },
      validateScreenSize(): void {
        setTimeout(() => {
          if (this.mapObject) {
            this.mapObject.invalidateSize();
          }
        }, 100);
      },
      showMap(fullscreen: boolean = false): void {
        let mapHeight: number = window.innerHeight * 0.4;
        if (fullscreen) {
          mapHeight = window.innerHeight * 0.75;
        }

        this.mapHeight = `${mapHeight}px`;
        this.isMapShown = true;
        this.validateScreenSize();
      },
      hideMap(): void {
        this.mapHeight = `0px`;
        this.isMapShown = false;
      },
      toggleMap(): void {
        if (this.enableMap) {
          if (this.isMapShown) {
            this.hideMap();
          } else {
            this.showMap(false);
          }
        } else {
          this.enableMapComponent();
        }
      },
      addMarkers(transformedMarkers: MapMarkerContainer, fitBounds: Boolean): void {
        if (transformedMarkers.markers) {
          this.locations.push.apply(this.locations, transformedMarkers.markers);
        }

        if (transformedMarkers.markerColors) {
          this.markerColors = transformedMarkers.markerColors;
        }

        if (fitBounds) {
          this.fitBounds();
        }
      },
      lazilyLoadMoreShopsByChainId(chainId: number): void {
        if (this.isMapShown) {
          if (process.env.NODE_ENV === 'development') {
            console.log(`Map is shown, lazily loading more shops by chain id '${chainId}'`);
          }
          this.loading = true;

          BackendIntegrationService.fetchMapShopsByChain(this.nextPage, chainId).then((response: MapShopContainer) => {
            if (response.list && response.list.length > 0) {
              let transformedMarkers: MapMarkerContainer = markerConfig.transformToMarkers(response.list, this.markerColors);
              if (transformedMarkers) {
                this.addMarkers(transformedMarkers, this.locations.length === 0);
              }
            }

            let nav: NavigationLinks | undefined = Optional(response).nav();
            if (nav) {
              let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
              if (nextLink) {
                this.nextPage = nextLink;

                var that = this;
                setTimeout(function() {
                  that.lazilyLoadMoreShopsByChainId(chainId);
                }, mapConfiguration.config.moreShopsByChainRequestDelay);
              } else {
                this.loadNoMore();
              }
            } else {
              this.loadNoMore();
            }
          }).catch((error: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Get shops by chain failed', error);
            }
            notification.fail(this.$t('text.error.location'));
            this.loadNoMore();
          });
        }
      },
      loadNoMore(): void {
        this.nextPage = undefined;
        this.loading = false;
      },
      setPopupTemplate(shop: MapShop, marker: Marker, tempMarker: boolean = false) {
        // TODO nextLink should be from backend
        let nextLink: NavigationLink = new NavigationLink();
        nextLink.href = `/shops/${shop.id}/details`;
        nextLink.rel = 'shop';

        BackendIntegrationService.fetchMapShopDetails(nextLink, MapProjectDetails).then((response: MapProjectDetails) => {
          // Trick to use vue component html
          let chid: number = parseInt(this.$route.query.chid.toString());
          const shopPopupElement: string = new ShopPopup({
            propsData: {
              item: response,
              chid: chid,
              editShop: this.allowEditShops,
              editLocationText: this.$t('text.edit-location')
            }
          }).$mount().$el.outerHTML;

          marker.bindPopup(shopPopupElement, { minWidth: 200 });
          if (tempMarker && this.mapObject) {
            marker.addTo(this.mapObject);
          }
          marker.openPopup();
        }).catch((e: Error) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Get details failed:", e);
          }
          notification.fail(this.$t('text.error.detail'));
        });
      },
      markerClicked(shop: MapShop, leafletMarker: Marker): void {
        if (!this.viewInClusters && this.mapObject) { // If not viewInCluster, manually zoom
          this.mapObject.setView(leafletMarker.getLatLng(), mapConfiguration.config.defaultCloseZoom);
        }

        if (shop && shop.id) {
          if (leafletMarker.getPopup() === undefined) {
            this.setPopupTemplate(shop, leafletMarker);
          }

          mapHtmlConfiguration.highlightShopRowInTable(shop.id, parseFloat(this.mapHeight), true);
        }
      },
      showTempMarker(marker: any): void {
        // OpenPopup trick (show only popup)
        // Getting leafletMarker render all the marker again, to avoid show temp marker
        if (this.mapObject) {
          let map: Map = this.mapObject;
          let tempMarker: Marker = new L.Marker(marker.position, { opacity: 0 });
          this.setPopupTemplate(marker.data.popup.item, tempMarker, true);

          map.on('zoomend', (e) => { // remove tempMarker
            if (tempMarker && e.target._zoom <= mapConfiguration.config.defaultCloseZoom - 2) {
              map.removeLayer(tempMarker);
            }
          });
        }
      },
      zoomInThisShop(shid: number): void {
        if (this.zoomInThisLocation === shid) {
          this.zoomInThisLocation = 0;
          setTimeout(() => {
            this.zoomInThisLocation = shid;
          }, 300);
        } else {
          this.zoomInThisLocation = shid;
        }

        // TODO comeback with snapshop.js shopListTableMapScroll()
        mapHtmlConfiguration.highlightShopRowInTable(shid, parseFloat(this.mapHeight), false);
      },
      listeningToHtml(): void {
        let mapViewButton: HTMLElement | null = document.querySelector("#map-view");
        if (mapViewButton) {
          mapViewButton.addEventListener('click', () => {
            this.enableMapComponent(true);
            this.showButton = false;
          });
        }

        let shopViewButton: HTMLElement | null = document.querySelector("#shop-view");
        if (shopViewButton) {
          shopViewButton.addEventListener('click', () => {
            this.hideMap();
            this.showButton = true;
          });
        }
      },
      enableMapComponent(fullscreen: boolean = false): void {
        this.enableMap = true;
        this.showMap(fullscreen);
      },
      initMap(map: Map): void {
        this.mapObject = map;
        this.lazilyLoadMoreShopsByChainId(parseInt(this.$route.query.chid.toString()));
      }
    },
    mounted(): void {
      this.listeningToHtml();
    },
    watch: {
      mapProperties(newValue: MapProperties, oldValue: MapProperties) {
        if (this.$route.query.shid && newValue.item && newValue.item.shid) {
          let shid: number = newValue.item.shid;
          if (!this.isMapShown) {
            this.enableMapComponent();

            setTimeout(() => {
              this.zoomInThisShop(shid);
            }, 1000);
          } else {
            this.zoomInThisShop(shid);
          }
        }
      },
      isMapShown(newValue: boolean, oldValue: boolean) {
        if (process.env.NODE_ENV === 'development') {
          console.log(`Watching isMapShown property value, it has changed from '${oldValue}' to '${newValue}'`);
        }
        if (newValue) {
          this.lazilyLoadMoreShopsByChainId(parseInt(this.$route.query.chid.toString()));
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
