import Vue from 'vue';
import VueI18n from "vue-i18n";
import internationalization from "@/main/webapp/vue/config/internationalization/internationalization";

Vue.use(VueI18n);

// This is for the translation out of Vue component
export default class TranslationUtil {
  static translate(key: string, messages: Object, addition: string | null = null): string {
    const i18n: VueI18n = internationalization.createI18n(messages);
    if (addition) {
      return i18n.t(key, [addition]).toString();
    }

    return i18n.t(key).toString();
  }
}
