
  import Vue from 'vue';
  import { mapState } from "vuex";
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";
  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";

  export default Vue.extend({
    props: {
      title: {
        type: String
      },
      itemCount: {
        type: Number,
        default: 0
      }
    },
    components: {
      glyphiconSymbol
    },
    computed: {
      ...mapState([
        'lastFilterAttributes'
      ])
    },
    methods: {
      prepareQuery(): string {
        return this.lastFilterAttributes.parameters.replace("?", "");
      },
      listeningModal(): void { // TODO get it from the store
        const that = this;
        $('#slideshow-modal').on('show.bs.modal', function() { // From snapshop.js
          initializeSlideshow(that.itemCount, that.prepareQuery());
        });
      }
    },
    mounted(): void {
      this.listeningModal();
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
