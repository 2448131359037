
  import Vue from 'vue';
  import messages from '../../messages.json';

  export default Vue.extend({
    props: {
      icon: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        googleFontUrl: 'https://fonts.google.com/icons' as string
      };
    },
    i18n: {
      messages: messages
    }
  });
