import { JsonObject, JsonProperty } from "json2typescript";
import { Course } from "@/main/webapp/vue/model/api/learning/Course";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('Courses')
export class Courses {

  @JsonProperty('list', [Course], false)
  list?: Course[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
