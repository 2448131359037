
  import Vue, { PropType } from 'vue';
  import notification from "@/main/webapp/vue/notification";
  import messages from '../messages.json';

  import closeButton from '@/main/webapp/vue/components/ui/button/close-button/index.vue';
  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";
  import ImageWidgetModel, {
    ImageSourceType,
    ImageWidgetProperties
  } from '@/main/webapp/vue/components/learning/widget/widgets/image/model/ImageWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'ImageWidget',
    props: {
      model: {
        type: Object as PropType<ImageWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editing: {
        type: Boolean,
        default: false
      }
    },
    components: {
      closeButton
    },
    computed: {
      component(): string {
        if (!this.model.data.fullscreen && (this.model.data.externalLink && this.model.data.externalLink.length > 0)) {
          return 'a';
        }

        return 'div';
      },
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return {
          ...computedStyleUtil.getStyles(
            ["margin"], this.model.properties.genericStyle, this.computedDefaultStyle.genericStyle),
          ...computedStyleUtil.getStyles(
            ["width"], this.model.properties.imageStyle, this.computedDefaultStyle.imageStyle),
          ...computedStyleUtil.getStyles(
            ["horizontal"], this.model.properties.imageStyle.alignment, this.computedDefaultStyle.imageStyle.alignment)
        };
      },
      computedDefaultStyle(): ImageWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      imageSrc(): string {
        if (this.model.data.imageSourceType === ImageSourceType.URL) {
          return this.model.data.externalImageSrc;
        }

        if (this.model.data.src) { // ImageSourceType === FILE
          return `${this.model.data.src}${this.model.data.lastUpdated ? `?${this.model.data.lastUpdated}` : ''}`;
        }
        return '';
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      imageClicked(): void {
        if (this.model.data.fullscreen) {
          if (this.imageSrc) {
            this.showFullscreenImage();
          } else {
            notification.warn(this.$t('text.click-target.fullscreen.error'));
          }
        }
      },
      closeFullscreenImage(): void {
        (this as any).$refs['image-modal'].hide();
      },
      showFullscreenImage(): void {
        (this as any).$refs['image-modal'].show();
      }
    },
    i18n: {
      messages: messages
    }
  });
