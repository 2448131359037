import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('ProjectDocumentationAttachment')
export class ProjectDocumentationAttachment {

  @JsonProperty('filename', String, true)
  filename: string | undefined = undefined;

  @JsonProperty('mimeType', String, true)
  mimeType: string | undefined = undefined;

  @JsonProperty('image', Boolean, true)
  image: boolean | undefined = undefined;

  @JsonProperty('nav', NavigationLinks, true)
  nav: NavigationLinks | undefined = undefined;
}
