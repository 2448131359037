
  import Vue from 'vue';

  import glyphiconSymbol from '../../glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      text: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      },
      textColor: {
        type: String
      },
      icon: {
        type: String
      },
      iconColor: {
        type: String
      },
      badge: {
        type: Number
      },
      badgeStyle: {
        type: String,
        default: 'dark'
      },
      large: {
        type: Boolean
      },
      styleClass: {
        type: String
      },
      enableDisplayNone: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      sizeStyle: function(): string {
        if (this.large) {
          return 'btn-lg';
        }

        return 'btn-sm';
      }
    },
    methods: {
      checkHandleClick() {
        if (!this.$props.disabled) {
          this.$emit('on-click');
        }
      }
    }
  });
