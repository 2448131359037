
  import Vue from 'vue';
  import messages from './messages.json';

  import themeComponent from './theme-component/index.vue';
  import navigationSection from '@/main/webapp/vue/components/ui/navigation/section/index.vue';
  import navigationSectionElement from '@/main/webapp/vue/components/ui/navigation/section/element/index.vue';

  import { ThemablePlatform } from '@/main/webapp/vue/model/api/ThemableComponents';

  export default Vue.extend({
    components: {
      themeComponent,
      navigationSection,
      navigationSectionElement
    },
    computed: {
      platforms(): string[] {
        return Object.values(ThemablePlatform);
      }
    },
    i18n: {
      messages: messages
    }
  });
