
  import Vue from 'vue';

  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      placeholderText: {
        type: String,
        default: ""
      },
      resultsText: {
        type: String,
        default: ""
      },
      totalResults: {
        type: Number,
        default: -1
      }
    },
    data() {
      return {
        searchString: "" as string
      };
    },
    methods: {
      onSearch(): void {
        if (this.searchString) {
          this.$emit("search", this.searchString);
        }
      },
      onSearchClear(): void {
        this.searchString = "";
        this.$emit("clear", this.searchString);
      }
    }
  });
