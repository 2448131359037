import WidgetUtil from "@/main/webapp/vue/components/learning/util/widgetUtil";
import store from "@/main/webapp/vue/store";
import { CourseBrandingStyle } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";

export default class ComputedStyleUtil {

  public getStyles(styleKeys: string[],
                   properties: any,
                   defaultStyle: any): Object {
    let newStyle = {};

    styleKeys.forEach((styleKey: string, index: number) => {
      let styleValue: any = properties[styleKey] ? properties[styleKey] : defaultStyle[styleKey];

      if (styleKey === "margin" || styleKey === "padding") {
        if (styleValue === undefined) {
          styleValue = '0px';
        } else {
          styleValue = `${styleValue.split(' ').join('px ')}px`;
        }
      } else if (styleKey === "columnRatio") {
        styleValue = `${styleValue}%`;
      } else if (typeof styleValue === "number") {
        styleValue = `${styleValue}px`;
      }

      // @ts-ignore
       newStyle[`--${styleKey}`] = styleValue;
    });

    return newStyle;
  }

  public getValue(valueKey: string,
                  properties: any,
                  defaultStyle: any): any {
    return properties[valueKey] ? properties[valueKey] : defaultStyle[valueKey];
  }

  public getFromGenericStyle(styleKey: string, model: any, defaultStyle: any): string {
    const value: string = this.getValue(
      styleKey,
      model.properties.genericStyle,
      defaultStyle.genericStyle);

    return value;
  }

  public getDefaultProperties(model: any): any {
    const courseDefaultStyles: CourseBrandingStyle[] = store.state.courseDefaultStyles;

    if (model.allowGlobalDefaultStyle) { // From branding
      model.defaultProperties = WidgetUtil.setDefaultStyles({ ...model.defaultProperties }, courseDefaultStyles);
    }

    // From model
    return model.defaultProperties;
  }

}

export const computedStyleUtil = new ComputedStyleUtil();
