import { AttributeEntity, AttributeEntityValue } from "@/main/webapp/vue/model/api/AttributeEntity";
import { JsonDefaultSubclass } from "@znapio/znapson";

export class UnsupportedAttributeEntityValue implements AttributeEntityValue {
  constructor(public type: string) {
  }
}

@JsonDefaultSubclass(AttributeEntity)
export class UnsupportedAttributeEntity extends AttributeEntity<AttributeEntityValue> {
  public readonly values: AttributeEntityValue[];

  constructor(id: number, name: string, type: string) {
    super(id, name, type, undefined, undefined, undefined);
    this.values = [new UnsupportedAttributeEntityValue(type)];
  }
}
