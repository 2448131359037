import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('SearchData')
export class SearchData {

  // TODO value to id for selection list to match id
  @JsonProperty('value', String, true)
  id?: string = undefined;

  @JsonProperty('label', String, true)
  label?: string = undefined;

  @JsonProperty('labelKey', String, true)
  labelKey?: string = undefined;

  @JsonProperty('labelSelected', String, true)
  labelSelected?: string = undefined;

  @JsonProperty('count', Number, true)
  count?: number = undefined;

  @JsonProperty('data', [SearchData], true)
  data: SearchData[] = [];
}
