import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";
import { Organization } from "./Organization";
import { DomainOrganizations } from "@/main/webapp/vue/model/api/DomainOrganizations";

@JsonObject('User')
export class User extends NamedEntity {

  @JsonProperty('locale', String, true)
  locale?: string = undefined;

  @JsonProperty('timeZone', String, true)
  timeZone?: string = undefined;

  @JsonProperty('department', NamedEntity, true)
  department?: NamedEntity = undefined;

  @JsonProperty('organization', Organization, true)
  organization?: Organization = undefined;

  @JsonProperty('domainOrganizations', DomainOrganizations, true)
  domainOrganizations?: DomainOrganizations = undefined;

}
