
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import buttonStyle from "@/main/webapp/vue/components/ui/style/button/container/index.vue";
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';
  import iconStyle from '@/main/webapp/vue/components/ui/style/icon/container/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';

  import ActivityListWidgetModel
    from "@/main/webapp/vue/components/learning/widget/widgets/activity-list/model/ActivityListWidgetModel";

  export default Vue.extend({
    name: 'ActivityListWidgetProperties',
    props: {
      model: {
        type: Object as PropType<ActivityListWidgetModel>,
        required: true
      }
    },
    components: {
      textTitle,
      buttonStyle,
      genericStyle,
      iconStyle,
      collapsibleContainer
    },
    i18n: {
      messages: messages
    }
  });
