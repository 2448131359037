import { JsonObject, JsonProperty } from "json2typescript";
import { Item } from "./Item";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('SearchResult')
export class SearchResult {

  @JsonProperty('list', [Item], false)
  list: Item[] = [];

  @JsonProperty('nav', NavigationLinks, false)
  nav: NavigationLinks = new NavigationLinks();

}
