import store from '@/main/webapp/vue/store';

export class GenerateLinearPathService {

  public prepareParams(link: string, index: number): string {
    let separator: string = "?";
    let itemIndex: string = "";
    let filterCriteria: string = "";

    if (link.indexOf(separator) > -1) {
      separator = "&";
    }

    if (index !== null && index !== undefined) {
      itemIndex = `iidx=${index}`;
    }

    if (store && store.state && store.state.lastFilterAttributes) {
      filterCriteria = store.state.lastFilterAttributes.parameters.replace("?", "&");
      if (itemIndex === "") {
        filterCriteria.replace("&", "");
      }
    }

    if (itemIndex || filterCriteria) {
      return `${separator}${itemIndex}${filterCriteria}`;
    }

    return '';
  }
}

export const generateLinearPathService: GenerateLinearPathService = new GenerateLinearPathService();
