
  import Vue from 'vue';
  import { mapState } from "vuex";

  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { OrganizationBilling } from "@/main/webapp/vue/model/api/OrganizationBilling";
  import { OrganizationUserStats } from "@/main/webapp/vue/model/api/OrganizationUserStats";

  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';
  import selectList from '@/main/webapp/vue/components/ui/select/select-list/index.vue';
  import dataTable, { Field, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';

  export default Vue.extend({
    components: {
      selectList,
      formSwitch,
      dataTable
    },
    data() {
      return {
        billing: {} as OrganizationBilling,
        userStatsFields: [{
          key: 'registered',
          type: FieldType.NUMBER
        },
        {
          key: 'active',
          type: FieldType.NUMBER
        },
        {
          key: 'locked',
          type: FieldType.NUMBER
        },
        {
          key: 'expired',
          type: FieldType.NUMBER
        },
        {
          key: 'inactive',
          type: FieldType.NUMBER
        },
        {
          key: 'interactiveThisMonth',
          type: FieldType.NUMBER
        },
        {
          key: 'interactiveLast30Days',
          type: FieldType.NUMBER
        },
        {
          key: 'interactiveLast90Days',
          type: FieldType.NUMBER
        }] as Field[]
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      userStatsArray(): OrganizationUserStats[] {
        let result: OrganizationUserStats[] = [];
        result.push(this.billing.userStats);
        return result;
      }
    },
    methods: {
      billingStatusChanged(newStatus: string): void {
        this.billing.status = newStatus;
        this.updateBillingData();
      },
      passwordLoginEnabledChanged(newStatus: boolean): void {
        this.billing.passwordLoginEnabled = newStatus;
        this.updateBillingData();
      },
      updateBillingData(): void {
        BackendIntegrationService.updateOrganizationBilling(this.userDetails.organization, this.billing).then((billing: OrganizationBilling) => {
          this.billing = billing;
        }).catch((error: Error) => {
          notification.fail("Could not update organization billing");
        });
      },
      loadBillingData(): void {
        BackendIntegrationService.fetchOrganizationBilling(this.userDetails.organization).then((billing: OrganizationBilling) => {
          this.billing = billing;
        }).catch((error: Error) => {
          notification.fail("Could not fetch organization billing");
        });
      }
    },
    mounted() {
      this.loadBillingData();
    }
  });
