
  import Vue, { PropType } from 'vue';
  import { v1 as uuidv1 } from 'uuid';
  import messages from '../messages.json';

  import draggableContainer from '@/main/webapp/vue/components/ui/draggable/container/index.vue';
  import widgetComponent from '@/main/webapp/vue/components/learning/widget/component/WidgetComponent.vue';
  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import WidgetModel from '@/main/webapp/vue/model/learning/WidgetModel';
  import CollapsibleWidgetModel, { CollapsibleWidgetProperties, DefaultValues }
    from '@/main/webapp/vue/components/learning/widget/widgets/collapsible/model/CollapsibleWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'CollapsibleWidget',
    components: {
      draggableContainer,
      widgetComponent
    },
    props: {
      model: {
        type: Object as PropType<CollapsibleWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editing: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: false
      },
      editingWidget: {
        type: Object as PropType<WidgetModel | undefined>,
        default: undefined
      },
      widgetFromSideBar: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        visible: this.editing as Boolean,
        collapsibleId: `collapsible-${uuidv1()}` as String
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return {
          '--borderRadius': this.computedCardBorderRadius,
          ...computedStyleUtil.getStyles(
            ["margin"],
            this.model.properties.genericStyle,
            this.computedDefaultStyle.genericStyle)
        };
      },
      computedCardHeaderStyle(): any {
        return { '--borderRadius': this.computedHeaderBorderRadius,
                 ...computedStyleUtil.getStyles(
                   ['backgroundColor', 'textColor', 'borderColor'],
                   this.model.properties.style.headerStyle,
                   this.computedDefaultStyle.style.headerStyle)
        };
      },
      computedCardHeaderButtonStyle(): Object {
        return { '--borderRadius': this.computedHeaderBorderRadius,
                 ...computedStyleUtil.getStyles(
                   ['backgroundColor'],
                   this.model.properties.style.headerStyle,
                   this.computedDefaultStyle.style.headerStyle)
        };
      },
      computedCardHeaderTextStyle(): Object {
        return computedStyleUtil.getStyles(
          ['textColor'],
          this.model.properties.style.headerStyle,
          this.computedDefaultStyle.style.headerStyle);
      },
      computedCardBodyStyle(): Object {
        return { '--borderRadius': this.computedBodyBorderRadius,
                 ...computedStyleUtil.getStyles(
                   ['backgroundColor'],
                   this.model.properties.style.bodyBrand,
                   this.computedDefaultStyle.style.bodyBrand)
        };
      },
      computedDefaultStyle(): CollapsibleWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedCardBorderRadius(): string {
        if (this.visible) {
          const headerRadius: any = computedStyleUtil.getValue(
            'borderRadius',
            this.model.properties.style.headerStyle,
            this.computedDefaultStyle.style.headerStyle);

          const bodyRadius: any = computedStyleUtil.getValue(
            'borderRadius',
            this.model.properties.style.bodyBrand,
            this.computedDefaultStyle.style.bodyBrand);

          if (headerRadius === undefined || bodyRadius === undefined) {
            return this.computedHeaderBorderRadius;
          }

          return `${headerRadius}px ${headerRadius}px ${bodyRadius}px ${bodyRadius}px`;
        }

        return this.computedHeaderBorderRadius;
      },
      computedHeaderBorderRadius(): string {
        const radius: any = computedStyleUtil.getValue(
          'borderRadius',
          this.model.properties.style.headerStyle,
          this.computedDefaultStyle.style.headerStyle);

        if (radius === undefined) {
          return '0px';
        }

        if (this.visible) {
          return `${radius}px ${radius}px 0 0`;
        }
        return `${radius}px`;
      },
      computedBodyBorderRadius(): string {
        const radius: any = computedStyleUtil.getValue(
          'borderRadius',
          this.model.properties.style.bodyBrand,
          this.computedDefaultStyle.style.bodyBrand);

        if (radius === undefined) {
          return '0px';
        }

        if (this.visible) {
          return `0 0 ${radius}px ${radius}px`;
        }
        return `${radius}px`;
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      toggleVisible(): void {
        this.visible = !this.visible;
      },
      onCreate(widget: WidgetModel, index: number): void {
        this.$emit('create', widget, index, this.model);
      },
      onEnd(widget: WidgetModel, index: number): void {
        this.$emit('moved', widget, index, this.model);
      },
      delete(nestedContents: WidgetModel[], widget: WidgetModel): WidgetModel[] {
        return nestedContents.filter((e: any) => {
          if (e.nestedContents && e.nestedContents.length > 0) {
            e.nestedContents = this.delete(e.nestedContents, widget);
          }

          return e.id !== widget.id && e.id !== widget.metadata.originalId;
        });
      },
      deleteNestedContents(widget: WidgetModel): void {
        if (this.model.nestedContents && widget) {
          this.model.nestedContents = this.delete(this.model.nestedContents, widget);
        }
      },
      onDelete(widget: WidgetModel): void {
        this.$emit('delete', widget, this.deleteNestedContents);
      }
    },
    mounted(): void {
      if (this.model && this.model.data.heading === DefaultValues.Heading) {
        this.model.data.heading = this.$t('text.widget-name').toString();
      }
    },
    i18n: {
      messages: messages
    }
  });
