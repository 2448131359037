import reportConfig from "@/main/webapp/vue/config/api/reportConfig";

import { ReportDestination } from "@/main/webapp/vue/model/api/report/ReportDestination";
import { frameCallbackType, FrameImpl, messageCallbackType } from "@stomp/stompjs";
import { WebSocketReportRequest } from "@/main/webapp/vue/model/api/report/WebSocketReportRequest";

const report = {
  active: (): boolean => reportConfig.active(),
  connect: (onConnectCallback: frameCallbackType, onDisconnectCallback: frameCallbackType, onStompErrorCallback: frameCallbackType) => reportConfig.connect(onConnectCallback, onDisconnectCallback, onStompErrorCallback),
  request: (reportRequest: WebSocketReportRequest) => reportConfig.request(reportRequest),
  subscribe: (reportDestination: ReportDestination, onSubscribeCallback: messageCallbackType) => reportConfig.subscribe(reportDestination, onSubscribeCallback),
  unsubscribe: (reportDestination: ReportDestination) => reportConfig.unsubscribe(reportDestination)
};

export default {
  report
};
