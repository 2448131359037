import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import youtubeVideoWidget from '@/main/webapp/vue/components/learning/widget/widgets/youtube-video/widget/index.vue';
import youtubeVideoWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/youtube-video/properties/index.vue';
import YoutubeVideoWidgetAspect
  from '@/main/webapp/vue/components/learning/widget/widgets/youtube-video/model/YoutubeVideoWidgetAspect';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

const DEFAULT_URL_PREFIX: string = 'https://www.youtube-nocookie.com/embed/';
const DEFAULT_ASPECT: YoutubeVideoWidgetAspect = YoutubeVideoWidgetAspect.ASPECT_16_BY_9;
const DEFAULT_PREFERENCES: string =
  'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;';

export class YoutubeVideoWidgetData {
  constructor(@JsonField('src', String)
              public src: string = DEFAULT_URL_PREFIX,
              @JsonField('videoId', String)
              public videoId: string = '',
              @JsonField('aspect', String, true)
              public aspect: YoutubeVideoWidgetAspect = DEFAULT_ASPECT,
              @JsonField('preferences', String)
              public preferences: string = DEFAULT_PREFERENCES) {}
}

export class YoutubeVideoWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'YoutubeVideo')
export default class YoutubeVideoWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection: DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', YoutubeVideoWidgetData)
              public data = new YoutubeVideoWidgetData(),
              @JsonField('properties', YoutubeVideoWidgetProperties)
              public properties = new YoutubeVideoWidgetProperties(),
              @JsonField('defaultProperties', YoutubeVideoWidgetProperties, true)
              public defaultProperties = new YoutubeVideoWidgetProperties(
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.MEDIA,
  YoutubeVideoWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'smart_display',
  youtubeVideoWidget,
  youtubeVideoWidgetProperties,
  (id: WidgetIdType) => new YoutubeVideoWidgetModel(id, DraggableSection.BODY, new WidgetMetadata())
);
