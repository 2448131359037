<template>
  <p></p>
</template>

<script>
  import L from 'leaflet';
  import { PruneClusterForLeaflet, PruneCluster } from 'vue2-leaflet-prunecluster/src/utilsPruneCluster.js';
  import iconFileDefault from 'leaflet/dist/images/marker-icon.png';
  import mapConfiguration from "@/main/webapp/vue/components/map/mapConfiguration.ts";
  import messages from '@/main/webapp/vue/components/map/messages.json';
  import { URL_PATH } from "@/main/webapp/vue/urlPath";

  export default {
    name: "PruneCluster",
    props: {
      mapRef: {},
      items: {},
      setIcon: {},
      markerColors: {},
      zoomInThisLocation: {}
    },
    data() {
      return {
        itemsOrDefault: [],
        pruneCluster: undefined,
        selectedItem: undefined
      };
    },
    computed: {
      setIconOrDefault() {
        let i = this.setIcon;
        let defaultIcon = L.icon({
          iconUrl: iconFileDefault,
          iconSize: [29, 29]
        });
        return (i !== null || i !== undefined) ? i : defaultIcon;
      },
      setIconMarkerClusterOrDefault() {
        let i = this.setIconMarkerCluster;
        let colors = ['#ff4b00', '#bac900', '#EC1813', '#55BCBE', '#D2204C', '#FF0000', '#ada59a', '#3e647e'];
        let pi2 = Math.PI * 2;

        L.Icon.MarkerCluster = L.Icon.extend({
          options: {
            iconSize: new L.Point(44, 44),
            className: 'prunecluster leaflet-markercluster-icon'
          },
          createIcon: function() {
            // based on L.Icon.Canvas from shramov/leaflet-plugins (BSD licence)
            let e = document.createElement('canvas');
            this._setIconStyles(e, 'icon');
            let s = this.options.iconSize;
            e.width = s.x;
            e.height = s.y;
            this.draw(e.getContext('2d'), s.x, s.y);
            return e;
          },
          createShadow: function() {
            return null;
          },
          draw: function(canvas) {
            let start = 0;
            for (let i = 0, l = colors.length; i < l; ++i) {
              let size = this.stats[i] / this.population;
              if (size > 0) {
                canvas.beginPath();
                canvas.moveTo(22, 22);
                canvas.fillStyle = colors[i];
                let from = start + 0.14;
                let to = start + size * pi2;

                if (to < from) {
                  from = start;
                }
                canvas.arc(22, 22, 22, from, to);
                start = start + size * pi2;
                canvas.lineTo(22, 22);
                canvas.fill();
                canvas.closePath();
              }
            }
            canvas.beginPath();
            canvas.fillStyle = '#a174ac';
            canvas.arc(22, 22, 18, 0, Math.PI * 2);
            canvas.fill();
            canvas.closePath();
            canvas.fillStyle = 'white';
            canvas.textAlign = 'center';
            canvas.textBaseline = 'middle';
            canvas.font = 'bold 12px sans-serif';
            canvas.fillText(this.population, 22, 22, 40);
          }
        });

        return (i !== null || i !== undefined) ? i : new L.Icon.MarkerCluster();
      }
    },
    mounted() {
      let pruneCluster = this.pruneCluster;

      if (this.pruneCluster === undefined || this.pruneCluster === null) {
        pruneCluster = new PruneClusterForLeaflet();
      }
      pruneCluster.Cluster.Size = 150;

      let thisVue = this;

      // --------------------------------- icon Cluster configuration
      pruneCluster.PrepareLeafletMarker = function(leafletMarker, data) {
        leafletMarker.setIcon(data.icon);
        leafletMarker.on('click', function() {
          pruneCluster._map.setView(leafletMarker._latlng, mapConfiguration.config.defaultCloseZoom);
          setTimeout(() => {
            thisVue.emitItem(data, leafletMarker);
          }, 300);
        });
      };

      /* pruneCluster.BuildLeafletMarker = function(pruneClusterMarker, position) {
        let newMarker = new L.Marker(position, { icon: pruneClusterMarker.data.icon });
        let options = { minWidth: 200 };

        return thisVue.getTemplate(pruneClusterMarker, newMarker, options);
      }; */

      // --------------------------------- icon Cluster configuration
      pruneCluster.BuildLeafletClusterIcon = function(cluster) {
        let e = thisVue.setIconMarkerClusterOrDefault;
        e.stats = cluster.stats;
        e.population = cluster.population;
        return e;
      };

      pruneCluster.BuildLeafletCluster = function(cluster, position) {
        let m = new L.Marker(position, {
          icon: pruneCluster.BuildLeafletClusterIcon(cluster)
        });

        m.on('click', function() {
          let markersArea = pruneCluster.Cluster.FindMarkersInArea(cluster.bounds);
          let b = pruneCluster.Cluster.ComputeBounds(markersArea);

          if (b) {
            let bounds = new L.LatLngBounds(
              new L.LatLng(b.minLat, b.maxLng),
              new L.LatLng(b.maxLat, b.minLng));

            let zoomLevelBefore = pruneCluster._map.getZoom();
            let zoomLevelAfter = pruneCluster._map.getBoundsZoom(bounds, false, new L.Point(20, 20, null));

            if (zoomLevelAfter === zoomLevelBefore) {
              pruneCluster._map.fire('overlappingmarkers', {
                cluster: pruneCluster,
                markers: markersArea,
                center: m.getLatLng(),
                marker: m
              });

              pruneCluster._map.setView(position, zoomLevelAfter);
            } else {
              pruneCluster._map.fitBounds(bounds);
            }
          }
        });

        return m;
      };

      this.pruneCluster = pruneCluster;
      let map = this.mapRef;
      map.addLayer(this.pruneCluster);

      this.reDraw();
    },
    methods: {
      emitItem(item, leafletMarker) {
        this.$emit('click-on-item', item.popup.item, leafletMarker);
      },
      activeZoom(shid) {
        let pruneCluster = this.pruneCluster;
        let thisVue = this;

        pruneCluster.Cluster._markers.forEach((marker, index) => {
          if (marker.data.popup.item.id === shid) {
            pruneCluster._map.setView(marker.position, mapConfiguration.config.defaultCloseZoom);
            setTimeout(() => {
              thisVue.$emit('show-temp-marker', marker);
            }, 300);
          }
        });
      },
      reDraw() {
        let pruneCluster = this.pruneCluster;

        if (pruneCluster !== undefined) {
          pruneCluster.RemoveMarkers();
          pruneCluster.RedrawIcons();
        }

        this.setIconMarkerCluster = mapConfiguration.registerCustomClusterMarker(pruneCluster, this.markerColors);

        if (this.itemsOrDefault && Array.isArray(this.itemsOrDefault)) {
          this.markerColors = mapConfiguration.initPruneClusterMarkerColors(this.itemsOrDefault, this.markerColors);
        }

        if (this.itemsOrDefault && Array.isArray(this.itemsOrDefault)) {
          this.itemsOrDefault.forEach((item) => {
            let marker = this.createMarker(item);
            pruneCluster.RegisterMarker(marker);
          });
        }

        pruneCluster.ProcessView();
      },
      createMarker(item) {
        let marker = new PruneCluster.Marker(item.lat, item.lng);

        if (item.markerData !== undefined) {
          if (this.$route.path === URL_PATH.SHOPS) {
            marker.category = this.markerColors.indexOf(item.markerData.colorCode);
            marker.data.icon = L.AwesomeMarkers.icon({
              prefix: 'glyphicons',
              icon: mapConfiguration.config.iconTypes.shop.icon,
              markerColor: mapConfiguration.config.iconTypes.shop.color
            });
          } else {
            marker.category = this.markerColors.indexOf(item.markerData.colorCode);
            marker.data.icon = L.AwesomeMarkers.icon({
              markerColor: item.markerData ? item.markerData.color : mapConfiguration.config.iconTypes.shop.color
            });
          }
        }

        let minWidth = 111;
        if (item.imageCount <= 3) {
          minWidth = (item.imageCount * 110) + 1;
        } else if (item.imageCount > 3) {
          minWidth = 351;
        }
        let popupOptions = {
          minWidth: minWidth
        };

        marker.data.popup = item.html;
        marker.data.popupOptions = popupOptions;

        return marker;
      }
    },
    watch: {
      items() {
        if (this.items && Array.isArray(this.items)) {
          this.itemsOrDefault = this.items;
          this.reDraw();
        }
      },
      zoomInThisLocation(newValue, oldValue) {
        this.activeZoom(newValue);
      }
    },
    i18n: {
      messages: messages
    }
  };
</script>
<style>
  .categories {
    overflow: hidden;
  }
  .img-container {
    text-align: center;
    width: 110.0px;
    line-height: 110.0px;
    height: 110.0px;
  }
</style>
