
  import Vue, { PropType } from 'vue';

  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";

  import glyphiconSymbol from '../../glyphicon/glyphicon-symbol/index.vue';
  import textColored from '../../text/text-colored/index.vue';
  import submissionMetadataAttribute from "../submission-metadata-attribute/index.vue";
  import messages from './messages.json';
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import standardImage from "@/main/webapp/vue/components/ui/content/standard-image/index.vue";
  import filetypeIcon from "@/main/webapp/vue/components/ui/content/filetype-icon/index.vue";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

  export default Vue.extend({
    name: 'submissionMetadataElement',
    components: {
      filetypeIcon,
      standardImage,
      glyphiconSymbol,
      textColored,
      submissionMetadataAttribute
    },
    props: {
      element: {
        type: Object as PropType<HierarchicalEntity>,
        default: null
      },
      showOnlyValue: {
        type: Boolean,
        default: false
      },
      disablePictureClickModal: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        previewContent: undefined as ItemContent | undefined,
        showPreviewModal: false
      };
    },
    computed: {
      webLink(): string | undefined {
        return this.getWebLink(this.element);
      }
    },
    methods: {
      getWebLink(element: HierarchicalEntity | undefined): string | undefined {
        if (element && element.nav) {
          return NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, element.nav)?.href;
        }
        return undefined;
      },
      handlePictureClick(value: ItemContent) {
        this.$emit('picture-click', value);
        if (!this.disablePictureClickModal) {
          this.previewContent = value;
          this.showPreviewModal = true;
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
