
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      target: {
        type: String,
        required: true
      },
      triggers: {
        type: String,
        default: 'hover'
      },
      placement: {
        type: String,
        default: 'top'
      }
    }
  });
