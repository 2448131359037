import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

@JsonDiscriminator(StyleModel, 'button')
export default class ButtonStyleModel extends StyleModel {
  constructor(@JsonField('backgroundColor', String, true)
              public backgroundColor?: string,
              @JsonField('textColor', String, true)
              public textColor?: string,
              @JsonField('borderRadius', Number, true)
              public borderRadius?: number,
              @JsonField('borderColor', String, true)
              public borderColor?: string) {
    super();
  }

  default(): ButtonStyleModel {
    return new ButtonStyleModel('#007bff', '#ffffff', 5);
  }
}
