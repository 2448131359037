import { JsonObject, JsonProperty } from "json2typescript";
import { OrganizationModule } from "./OrganizationModule";

@JsonObject('OrganizationModuleContainer')
export class OrganizationModuleContainer {

  @JsonProperty('list', [OrganizationModule], true)
  list?: [OrganizationModule] = undefined;

}
