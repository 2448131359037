
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";

  import { oc as Optional } from "ts-optchain";

  import { FrameImpl, IFrame } from "@stomp/stompjs";

  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';

  import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
  import { ReportStatus } from "@/main/webapp/vue/model/api/report/ReportStatus";
  import { ReportFilter } from "@/main/webapp/vue/model/api/report/ReportFilter";
  import { DownloadType } from "@/main/webapp/vue/model/downloadType";
  import { WebSocketReportResponse } from "@/main/webapp/vue/model/api/report/WebSocketReportResponse";
  import { WebSocketReportRequest } from "@/main/webapp/vue/model/api/report/WebSocketReportRequest";
  import { WebSocketResponseType } from "@/main/webapp/vue/model/api/report/WebSocketResponseType";
  import { WebSocketReportProgressPayload } from "@/main/webapp/vue/model/api/report/WebSocketReportProgressPayload";
  import { WebSocketReportLinkPayload } from "@/main/webapp/vue/model/api/report/WebSocketReportLinkPayload";
  import { ListViewSelectedField } from "@/main/webapp/vue/model/api/ListViewSelectedField";

  import messages from "./messages.json";
  import applicationConfiguration from '@/main/webapp/vue/config/application/configuration';

  const Status = {
    IDLE: ReportStatus.IDLE,
    SUBSCRIBING: ReportStatus.SUBSCRIBING,
    GENERATING: ReportStatus.GENERATING,
    FINISHED: ReportStatus.FINISHED
  };

  export default Vue.extend({
    components: {
      loadingIcon
    },
    props: {
      isListView: {
        type: Boolean,
        required: false
      },
      searchCriteriaSelectedDataValues: {
        type: Array as PropType<SearchCriteriaSelectedDataValue[]>,
        required: false
      },
      listViewSelectedSubmissionIds: {
        type: Array as PropType<Number[]>,
        required: false
      },
      listViewSelectedFields: {
        type: Array as PropType<ListViewSelectedField[]>,
        required: false
      },
      componentDownloadType: {
        type: Object as PropType<DownloadType>,
        required: true
      },
      reportStatus: {
        type: String as PropType<ReportStatus>,
        required: true
      }
    },
    data() {
      return {
        animate: true,
        value: 0 as number,
        max: 100 as number,
        visible: false as boolean,
        reconnections: 1 as number,
        link: "#" as string,
        Status
      };
    },
    watch: {
      reportStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        if (oldValue === ReportStatus.IDLE && newValue === ReportStatus.SUBSCRIBING) {
          this.connectAndSubscribe();
        }
      }
    },
    computed: {
      ...mapState([
        'hostName'
      ]),
      label(): string {
        const value: number = this.value;
        const max: number = this.max;
        return `${((value / max) * 100).toFixed(0)} %`;
      },
      progressText(): string {
        if (this.value === 99) {
          return this.$t(this.componentDownloadType.uploadingText).toString();
        } else if (this.reportStatus === Status.IDLE) {
          return '';
        } else if (this.reportStatus === Status.SUBSCRIBING) {
          return '';
        } else if (this.reportStatus === Status.GENERATING) {
          return this.$t(this.componentDownloadType.generatingText).toString();
        } else if (this.reportStatus === Status.FINISHED) {
          return this.$t(this.componentDownloadType.readyText).toString();
        }

        return '';
      }
    },
    created() {
      if (this.reportStatus === ReportStatus.SUBSCRIBING || this.reportStatus === ReportStatus.GENERATING) {
        this.connectAndSubscribe();
      } else {
        this.close();
      }
    },
    methods: {
      connectAndSubscribe(): void {
        if (!this.$report.report.active()) {
          this.$report.report.connect(this.onConnectCallback, this.onDisconnectCallback, this.onStompErrorCallback);
        } else {
          this.subscribe();
        }
      },
      onConnectCallback(frame: FrameImpl): void {
        this.subscribe();
      },
      onStompErrorCallback(frame: IFrame): void {},
      onDisconnectCallback(frame: FrameImpl): void {},
      subscribe(): void {
        this.$report.report.subscribe(this.componentDownloadType.destination, this.onSubscribeCallback);
      },
      onSubscribeCallback(message: FrameImpl): void {
        let response: WebSocketReportResponse = Object.assign(new WebSocketReportResponse(), JSON.parse(message.body));
        if (response.responseType === WebSocketResponseType.SUBSCRIPTION) {
          let hostName: string = Optional(this.hostName)('');
          if (hostName !== '' && response.hostName !== hostName) {
            if (this.$data.reconnections <= applicationConfiguration.properties.websocket.reconnectionLimit) {
              this.handleReconnection();
              return;
            }

            if (this.$data.reconnections > applicationConfiguration.properties.websocket.reconnectionLimit) {
              this.displayErrorAndClose();
              return;
            }
          }
          this.$data.reconnections = 1;

          if (this.reportStatus === ReportStatus.SUBSCRIBING) {
            this.requestReport(response);
          } else if (this.reportStatus === ReportStatus.GENERATING) {
            this.updateReportStatus(ReportStatus.GENERATING);
          }
        } else if (response.responseType === WebSocketResponseType.PROGRESS) {
          let payload: WebSocketReportProgressPayload = Object.assign(new WebSocketReportProgressPayload(), response.payload);

          if (payload.value !== payload.max) {
            this.$data.value = payload.value;
            this.$data.max = payload.max;
          } else {
            // display 99% with animation until report is downloadable
            this.$data.value = 99;
            this.$data.max = 100;
          }
        } else if (response.responseType === WebSocketResponseType.LINK) {
          let payload: WebSocketReportLinkPayload = Object.assign(new WebSocketReportLinkPayload(), response.payload);
          this.generateDownloadLink(payload);
        } else if (response.responseType === WebSocketResponseType.ERROR) {
          this.displayErrorAndClose();
        }
      },
      requestReport(response: WebSocketReportResponse): void {
        if (this.reportStatus !== ReportStatus.GENERATING) {
          let request: WebSocketReportRequest = new WebSocketReportRequest();
          request.reportType = response.reportType;
          request.destination = response.destination;

          let reportFilter: ReportFilter = new ReportFilter();
          reportFilter.selectedCriteria = this.searchCriteriaSelectedDataValues;
          reportFilter.selectedSubmissionIds = this.listViewSelectedSubmissionIds;

          if (this.isListView) {
            reportFilter.selectedFields = this.listViewSelectedFields;
          }

          request.reportFilter = reportFilter;
          console.log(reportFilter);

          this.$report.report.request(request);

          this.updateHostName(Optional(response.hostName)(''));
          this.updateReportStatus(ReportStatus.GENERATING);
        }
      },
      generateDownloadLink(payload: WebSocketReportLinkPayload): void {
        this.$data.link = payload.reportLink;

        this.updateHostName('');
        this.unsubscribe();

        // display 100% and remove animation once report is downloadable
        this.$data.value = 100;
        this.$data.max = 100;
        this.$data.animate = false;

        this.updateReportStatus(ReportStatus.FINISHED);
      },
      handleReconnection(): void {
        this.unsubscribe();

        const that = this;
        setTimeout(function() {
          that.connectAndSubscribe();
        }, applicationConfiguration.properties.websocket.localReconnectDelay);

        this.$data.reconnections++;
      },
      displayErrorAndClose(): void {
        this.closeAndUnsubscribe();

        let translation: string = this.$t('error').toString();
        this.$bvModal.msgBoxOk(translation)
          .then(value => {})
          .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`Reporting-container-progress component - ${this.componentDownloadType.destination} - ${this.reportStatus} - displayErrorAndClose() - BootstrapVue Modal had a problem: ${err}`);
            }
          });
      },
      close(): void {
        this.$data.reconnections = 1;
        this.value = 0;
        this.max = 100;

        this.updateReportStatus(ReportStatus.IDLE);
      },
      unsubscribe(): void {
        this.$report.report.unsubscribe(this.componentDownloadType.destination);
      },
      closeAndUnsubscribe(): void {
        this.updateHostName('');
        this.unsubscribe();
        this.close();
      },
      displayModalWithText(text: string) {
        this.$bvModal.msgBoxOk(text);
      },
      updateHostName(hostName: string): void {
        this.$store.commit('updateHostName', hostName);
      },
      updateReportStatus(reportStatus: ReportStatus): void {
        this.$emit('update-report-status', reportStatus);
      }
    },
    i18n: {
      messages: messages
    }
  });
