
  import Vue from 'vue';
  import { mapState } from "vuex";

  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";
  import { EntityRegistryClientFilter } from "@/main/webapp/vue/model/api/EntityRegistryClientFilter";

  import dataTable, { ActionType, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';

  export default Vue.extend({
    components: {
      dataTable
    },
    props: {
      client: {
        type: EntityRegistryClient,
        required: true
      }
    },
    data() {
      return {
        data: [] as EntityRegistryClientFilter[],
        fields: [
          {
            key: "field",
            label: "field",
            type: FieldType.TEXT
          },
          {
            key: "value",
            label: "value",
            type: FieldType.TEXT
          },
          {
            key: "actions",
            label: "",
            type: FieldType.ACTIONS,
            actions: [
              {
                type: ActionType.INLINE_EDIT,
                icon: {
                  title: "Edit filter"
                }
              },
              {
                type: ActionType.REMOVE,
                icon: {
                  title: "Remove filter"
                },
                confirmation: (filter: EntityRegistryClientFilter): String => {
                  return `Delete filter '${filter.field}'?`;
                }
              }
            ]
          }
        ]
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      create(filter: EntityRegistryClientFilter): void {
        if (!this.client.id || !filter.field || !filter.value) {
          notification.fail("Could not create filter, missing fields!");
          return;
        }

        filter.id = undefined;
        filter.active = true;

        BackendIntegrationService.createEntityRegistryClientFilter(this.userDetails.organization, this.client.id, filter)
          .then((createdFilter: EntityRegistryClientFilter) => {
            filter.id = createdFilter.id;
            this.$emit("updated");
            notification.success("Filter created!");
          }).catch((error: Error) => {
            this.data = this.data.filter((item, i) => i > 0);
            notification.fail("Could not create filter, backend error");
          });
      },
      update(filter: EntityRegistryClientFilter): void {
        if (!this.client.id || !filter.id) {
          notification.fail("Could not update filter");
          return;
        }

        BackendIntegrationService.updateEntityRegistryClientFilter(this.userDetails.organization, this.client.id, filter)
          .then((updatedFilter: EntityRegistryClientFilter) => {
            this.$emit("updated");
            notification.success("Filter updated!");
          }).catch((error: Error) => {
            notification.fail("Could not update filter, backend error");
          });
      },
      remove(filter: EntityRegistryClientFilter): void {
        if (!this.client.id || !filter.id) {
          notification.fail("Could not delete filter");
          return;
        }

        BackendIntegrationService.deleteEntityRegistryClientFilter(this.userDetails.organization, this.client.id, filter.id).then(() => {
          this.data = this.data.filter((item, i) => item.id !== filter.id);
          this.$emit("updated");
          notification.success("Filter deleted!");
        }).catch((error: Error) => {
          notification.fail("Could not delete filter, backend error");
        });
      }
    },
    mounted() {
      this.data = this.client.filters;
    }
  });
