
  import Vue, { PropType } from 'vue';

  import messages from '../messages.json';

  export class SelectOption {
    constructor(
      readonly value: string | number,
      readonly text: string
    ) {}

    static of(value: string | number) {
      return new SelectOption(value, String(value));
    }
  }

  export default Vue.extend({
    name: 'SettingsSelectGroup',
    props: {
      name: {
        type: String,
        required: true
      },
      options: {
        type: Array as PropType<SelectOption[]>,
        required: true
      },
      value: {
        type: [String, Number],
        required: false
      }
    },
    i18n: {
      messages
    }
  });
