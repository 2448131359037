import { JsonObject, JsonProperty } from "json2typescript";
import { SearchCriterion } from "@/main/webapp/vue/model/api/SearchCriterion";
import { SubmissionContainer } from "@/main/webapp/vue/model/api/SubmissionContainer";

@JsonObject('SearchCriteria')
export class SearchCriteria {

  @JsonProperty('criteria', [SearchCriterion], true)
  criteria?: SearchCriterion[] = [];

  @JsonProperty('results', SubmissionContainer, true)
  results?: SubmissionContainer = undefined;

  @JsonProperty('hits', Number, true)
  hits?: number = undefined;
}

