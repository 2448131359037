
  import Vue from 'vue';

  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    props: {
      icon: {
        type: String,
        default: "remove"
      },
      size: {
        type: String,
        default: "medium"
      },
      color: {
        type: String,
        default: "white"
      }
    },
    components: {
      glyphiconSymbol
    }
  });
