import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";
import { GalleryContent } from "./GalleryContent";

@JsonObject('Gallery')
export class Gallery {

  @JsonProperty('contents', [GalleryContent], false)
  contents: GalleryContent[] = [];

  @JsonProperty('nav', NavigationLinks, false)
  nav: NavigationLinks = new NavigationLinks();

}
