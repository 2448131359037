
  import Vue, { PropType } from 'vue';
  import VCalendar from 'v-calendar';
  import { mapState } from "vuex";
  import materialIcon from "@/main/webapp/vue/components/ui/icon/material-icon/index.vue";
  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";

  export default Vue.extend({
    props: {
      selectedDates: {
        type: Array as PropType<String[]>,
        default: () => []
      },
      minDate: {
        type: String,
        default: ''
      },
      maxDate: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    },
    components: {
      materialIcon,
      glyphiconSymbol
    },
    data() {
      return {
        date: null as Date | null,
        startDate: null as Date | null,
        endDate: null as Date | null,
        allowEmit: true as boolean,
        pageInit: false as boolean,
        masks: {
          input: 'YYYY-MM-DD h:mm A'
        },
        attributes: [
          {
            key: 'today',
            highlight: {
              color: 'blue',
              fillMode: 'outline'
            },
            dates: new Date()
          }
        ],
        popover: {
          visibility: 'hidden'
        }
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      computedLocale(): string {
        if (this.userDetails && this.userDetails.locale !== 'en') {
          return this.userDetails.locale.replace('_', '-');
        }

        return 'en-GB'; // assume en => en-GB, also as fallback
      }
    },
    watch: {
      selectedDates(newDates: string[], oldDates: string[]): void {
        this.setDate(newDates);
      },
      minDate(newDate: string, oldDate: string): void {
        if (newDate) {
          this.startDate = new Date(newDate);
        } else {
          this.startDate = null;
        }
      },
      maxDate(newDate: string, oldDate: string): void {
        if (newDate) {
          this.endDate = new Date(newDate);
        } else {
          this.endDate = this.setTimeToZero(new Date());
        }
      },
      date(newDate: Date, oldDate: Date): void {
        if (newDate) {
          if (this.pageInit) {
            this.onUpdate(newDate.toISOString());
          } else {
            this.allowEmit = true;
            this.pageInit = true;
            this.onUpdate(this.setTimeToZero(newDate).toISOString());
          }
        } else {
          this.allowEmit = true;
          this.pageInit = false;
        }
      }
    },
    methods: {
      clearDate() {
        this.date = null;
        this.allowEmit = true;
        this.onUpdate('');
      },
      setDate(newDates: string[]): void {
        this.allowEmit = false;

        if (newDates && newDates[0].length > 0) {
          this.date = new Date(newDates[0]);
        } else {
          this.date = null;
          if (!this.minDate && !this.maxDate) {
            this.pageInit = false;
          }
        }
      },
      setTimeToZero(newDate: Date): Date {
        // This will set the time to 00:00
        return new Date(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate());
      },
      closeCalendar(): void {
        const datetimePicker: VCalendar = this.$refs.datetimePicker;
        if (datetimePicker) {
          datetimePicker.hidePopover();
        }
      },
      onFocus(togglePopover: Function): void {
        // Show the calendar if no value
        if (this.date === null) {
          togglePopover();
        }
      },
      onUpdate(newDate: string): void {
        if (this.allowEmit) {
          this.$emit('date-changed', newDate);
        }

        this.allowEmit = true;
        setTimeout(() => {
          this.closeCalendar();
        }, 100);
      }
    },
    created(): void {
      this.endDate = this.setTimeToZero(new Date());
      this.setDate(this.selectedDates);
    }
  });
