
  import Vue, { PropType } from 'vue';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import FileAttachmentWidgetModel, { FileAttachmentWidgetProperties }
    from '@/main/webapp/vue/components/learning/widget/widgets/file-attachment/model/FileAttachmentWidgetModel';
  import { Entity } from "@/main/webapp/vue/model/api/Entity";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'FileAttachmentWidget',
    props: {
      model: {
        type: Object as PropType<FileAttachmentWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editing: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showDetails: false as boolean,
        holderEntity: {} as Entity,
        itemContentEntity: {} as ItemContentEntity
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): FileAttachmentWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      getFileSize(size: number): string {
        const bytes: number = size;
        const marker: number = 1024; // Change to 1000 if required
        const decimal: number = 2;
        const kiloBytes: number = marker;
        const megaBytes: number = marker * marker;
        const gigaBytes: number = marker * marker * marker;

        if (bytes < kiloBytes) {
          return `${bytes} Bytes`;
        } else if (bytes < megaBytes) {
          return `${(bytes / kiloBytes).toFixed(decimal)} KB`;
        } else if (bytes < gigaBytes) {
          return `${(bytes / megaBytes).toFixed(decimal)} MB`;
        } else {
          return `${(bytes / gigaBytes).toFixed(decimal)} GB`;
        }
      }
    }
  });
