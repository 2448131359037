import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import ButtonStyleModel from "@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel";
import TextStyleModel, { TEXT_SIZE } from "@/main/webapp/vue/components/ui/style/text/model/TextStyleModel";
import { JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";

import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export abstract class ShareWidgetData {

  abstract icon: string;

  constructor(@JsonField('text', String, true)
              public text = '') {}
}

export class ShareWidgetProperties {
  constructor(@JsonField('buttonStyle', ButtonStyleModel, true)
              public buttonStyle = new ButtonStyleModel(),
              @JsonField('textStyle', TextStyleModel, true)
              public textStyle = new TextStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

export default abstract class ShareWidgetModel extends WidgetModel {

  abstract data: ShareWidgetData;

  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('properties', ShareWidgetProperties)
              public properties = new ShareWidgetProperties(),
              @JsonField('defaultProperties', ShareWidgetProperties, true)
              public defaultProperties = new ShareWidgetProperties(
                new ButtonStyleModel('#ffffff', '#000000', 5, '#ffffff'),
                new TextStyleModel(TEXT_SIZE.TITLE, '#000000'),
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }

  abstract formatUrl(activityUrlPath: string): string;

}
