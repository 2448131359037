
  import Vue from 'vue';
  import messages from '../../messages.json';

  enum IconAlignment {
    LEFT = 'initial',
    TOP = 'column',
    BOTTOM = 'column-reverse',
    RIGHT = 'row-reverse'
  }
  export default Vue.extend({
    name: 'IconAlignmentInput',
    props: {
      position: {
        type: String,
        required: false,
        default: IconAlignment.LEFT
      }
    },
    data() {
      return {
        iconAlignmentValue: {
          left: 'initial',
          right: 'row-reverse',
          top: 'column',
          bottom: 'column-reverse'
        },
        IconAlignment
      };
    },
    methods: {
      setIconAlignment(iconAlignment: IconAlignment): void {
        this.$emit('updated', iconAlignment);
      }
    },
    i18n: {
      messages: messages
    }
  });
