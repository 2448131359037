
  import Vue from 'vue';
  import { mapState } from "vuex";

  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";
  import { EntityRegistryClientMapping } from "@/main/webapp/vue/model/api/EntityRegistryClientMapping";

  import dataTable, { ActionType, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';

  export default Vue.extend({
    components: {
      dataTable
    },
    props: {
      client: {
        type: EntityRegistryClient,
        required: true
      }
    },
    data() {
      return {
        data: [] as EntityRegistryClientMapping[],
        fields: [
          {
            key: "label",
            label: "label",
            type: FieldType.TEXT
          },
          {
            key: "field",
            label: "field",
            type: FieldType.TEXT
          },
          {
            key: "actions",
            label: "",
            type: FieldType.ACTIONS,
            actions: [
              {
                type: ActionType.INLINE_EDIT,
                icon: {
                  title: "Edit mapping"
                }
              },
              {
                type: ActionType.REMOVE,
                icon: {
                  title: "Remove mapping"
                },
                confirmation: (mapping: EntityRegistryClientMapping): String => {
                  return `Delete mapping '${mapping.label}'?`;
                },
                hideIf: (mapping: EntityRegistryClientMapping): boolean => {
                  return mapping.generic;
                }
              }
            ]
          }
        ]
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      create(mapping: EntityRegistryClientMapping): void {
        if (!this.client.id || !mapping.field || !mapping.label) {
          notification.fail("Could not create mapping, missing fields!");
          return;
        }

        mapping.id = undefined;
        mapping.generic = false; // User-created mappings are not generic

        BackendIntegrationService.createEntityRegistryClientMapping(this.userDetails.organization, this.client.id, mapping)
          .then((createdMapping: EntityRegistryClientMapping) => {
            mapping.id = createdMapping.id;
            this.$emit("updated");
            notification.success("Mapping created!");
          }).catch((error: Error) => {
            this.data = this.data.filter((item, i) => i > 0);
            notification.fail("Could not create mapping, backend error");
          });
      },
      update(mapping: EntityRegistryClientMapping): void {
        if (!this.client.id || !mapping.id) {
          if (mapping.generic) { // Editing generic mapping overrides default value
            this.create(mapping);
            return;
          }
          notification.fail("Could not update mapping");
          return;
        }

        BackendIntegrationService.updateEntityRegistryClientMapping(this.userDetails.organization, this.client.id, mapping)
          .then((updatedMapping: EntityRegistryClientMapping) => {
            this.$emit("updated");
            notification.success("Mapping updated!");
          }).catch((error: Error) => {
            notification.fail("Could not update mapping, backend error");
          });
      },
      remove(mapping: EntityRegistryClientMapping): void {
        if (!this.client.id || !mapping.id) {
          notification.fail("Could not delete mapping");
          return;
        }

        BackendIntegrationService.deleteEntityRegistryClientMapping(this.userDetails.organization, this.client.id, mapping.id).then(() => {
          this.data = this.data.filter((item, i) => item.id !== mapping.id);
          this.$emit("updated");
          notification.success("Mapping deleted!");
        }).catch((error: Error) => {
          notification.fail("Could not delete mapping, backend error");
        });
      }
    },
    mounted() {
      this.data = this.client.mappings;
    }
  });
