
  import Vue from 'vue';
  import messages from './messages.json';

  import projectProgressBar from "@/main/webapp/vue/components/project/progress/bar/index.vue";
  import { ProjectProgress } from "@/main/webapp/vue/model/api/ProjectProgress";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import UriUtil from "@/main/webapp/vue/util/uriUtil";

  export default Vue.extend({
    components: {
      projectProgressBar
    },
    props: {
      projectId: {
        type: Number,
        required: false,
        default: null
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: true
      },
      showTarget: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        progress: {} as ProjectProgress,
        loading: false as Boolean
      };
    },
    watch: {
      computedProjectId(computedProjectId): any {
        if (computedProjectId !== -1 && computedProjectId !== null) {
          this.loadProjectProgress();
        }
      }
    },
    computed: {
      computedProjectId(): any {
        return this.projectId === -1 ? (this.$route.query.pid ? this.filteredProjectId(this.$route.query.pid as string) : this.getProjectId()) : this.projectId;
      }
    },
    methods: {
      filteredProjectId(pid: string): string {
        return UriUtil.removeHash(pid);
      },
      getProjectId(): number | undefined {
        // For project map after project modal
        if (this.$route.fullPath.search('pid') > -1 && this.$route.hash) {
          let projectId = parseInt(this.$route.hash.split("&")[0].split("=")[1]);
          return projectId;
        }

        return this.projectId;
      },
      loadProjectProgress() {
        console.log(`Loading project progress with ID: ${this.computedProjectId}`);
        if (this.computedProjectId) {
          this.loading = true;
          BackendIntegrationService.fetchProjectProgress(this.computedProjectId).then((progress: ProjectProgress) => {
            this.progress = progress;
          }).finally(() => {
            this.loading = false;
          });
        }
      }
    },
    mounted() {
      if (this.computedProjectId !== -1 && this.computedProjectId !== null) {
        this.loadProjectProgress();
      }
    },
    created() {
      let that = this;

      window.onpopstate = function(event: any) {
        if (that.computedProjectId !== -1 && that.computedProjectId !== null) {
          that.loadProjectProgress();
        }
      };
    },
    i18n: {
      messages: messages
    }
  });
