import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";

@JsonObject('Address')
export class Address extends NamedEntity {

  @JsonProperty('street', String, true)
  street: string | undefined = undefined;

  @JsonProperty('postalCode', String, true)
  postalCode: string | undefined = undefined;

  @JsonProperty('city', String, true)
  city: string | undefined = undefined;

  @JsonProperty('province', String, true)
  province: string | undefined = undefined;

  @JsonProperty('country', String, true)
  country: string | undefined = undefined;

}
