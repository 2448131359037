import { JsonObject, JsonProperty } from "json2typescript";
import { Project } from "./Project";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('ProjectContainer')
export class ProjectContainer {

  @JsonProperty('list', [Project], false)
  list: Project[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
