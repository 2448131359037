
  import Vue from 'vue';
  import { mapState } from "vuex";

  import { jsonMapToKeyValueString } from "@/main/webapp/vue/util/arrayUtil";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityRegistryDataSourceEvent } from "@/main/webapp/vue/model/api/EntityRegistryDataSourceEvent";
  import { EntityContainer } from "@/main/webapp/vue/model/api/EntityContainer";
  import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";

  import dataTable, { Field, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';
  import notification from "@/main/webapp/vue/notification";
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';

  export default Vue.extend({
    components: {
      dataTable,
      loadingIcon
    },
    props: {
      client: {
        type: EntityRegistryClient,
        required: true
      },
      refresh: {
        type: Boolean,
        default: null
      }
    },
    data() {
      return {
        linkedClient: this.client as EntityRegistryClient,
        events: [] as EntityRegistryDataSourceEvent[],
        syncing: false as boolean,
        syncEvent: null as EntityRegistryDataSourceEvent | null,
        fields: [
          {
            key: "timestamp",
            type: FieldType.TIMESTAMP
          },
          {
            key: "data",
            type: FieldType.TEXT,
            formatter: (value: any, key: any, item: any): string => {
              return jsonMapToKeyValueString(value, (k: string, v: any) => {
                return `${k}: ${v.occurrences > 1 ? v.occurrences : ''} ${v.message ? v.message : ''}\n`;
              });
            }
          }
        ] as Field[]
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    watch: {
      refresh(newValue: boolean, oldValue: boolean) {
        if (newValue) {
          this.refreshEvents(false);
        }
      }
    },
    methods: {
      refreshEvents(watch: boolean): void {
        BackendIntegrationService.getEntityRegistryClientEvents(this.userDetails.organization, this.linkedClient)
          .then((eventContainer: EntityContainer<EntityRegistryDataSourceEvent>) => {
            this.$emit("refreshed");
            this.checkSyncReady(eventContainer.list, watch);
            this.events = eventContainer.list;
          }).catch((error: Error) => {
            notification.fail("Events request failed!");
          });
      },
      scheduleRefreshEvents(): void { // TODO: Replace for SSE-framework, so server pushes updates to the client instead of the client polling...
        setTimeout(() => {
          this.refreshEvents(true);
        }, 5000);
      },
      update(): void {
        this.syncing = true;
        BackendIntegrationService.syncShopRegistryClient(this.userDetails.organization, this.linkedClient, false)
          .then((event: EntityRegistryDataSourceEvent) => {
            this.syncEvent = event;
            notification.success("Update request sent!");
            this.scheduleRefreshEvents();
          }).catch((error: Error) => {
            notification.fail("Update request failed!");
            this.syncing = false;
          });
      },
      sync(): void {
        this.syncing = true;
        BackendIntegrationService.syncShopRegistryClient(this.userDetails.organization, this.linkedClient, true)
          .then((event: EntityRegistryDataSourceEvent) => {
            this.syncEvent = event;
            notification.success("Sync request sent!");
            this.scheduleRefreshEvents();
          }).catch((error: Error) => {
            notification.fail("Sync request failed!");
            this.syncing = false;
          });
      },
      checkSyncReady(events: EntityRegistryDataSourceEvent[], watch: boolean): void {
        if (events) {
          if (this.events.length === events.length) {
            if (watch) {
              this.scheduleRefreshEvents();
            } else {
              notification.success("Refreshed, no new events...");
            }
          } else {
            if (this.syncEvent != null && events.length > 0 && this.syncEvent.uuid === events[0].uuid) {
              this.syncing = false;
              this.syncEvent = null;
              notification.success("Sync ready!");
            }
          }
        }
      },
      syncReady(): void {
        this.syncing = false;
        notification.success("Sync ready!");
      }
    },
    mounted() {
      this.events = this.client.events;
    }
  });
