import L, { LatLng } from 'leaflet';
import { MapAddress } from "@/main/webapp/vue/model/api/Map/MapAddress";
import { LocaleMessage } from "vue-i18n";
import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
import { MapAddressResultByCoordinates } from "@/main/webapp/vue/model/api/Map/MapAddressResultByCoordinates";
import { MapCategory } from "@/main/webapp/vue/model/api/Map/MapCategory";

export const getLookupBtn = (): HTMLButtonElement | null => {
  return document.querySelector("#lookup-btn");
};

export const getCancelLookupBtn = (): HTMLElement | null => {
  return document.querySelector("#cancel-lookup-btn");
};

export const getSubmitEntityBtn = (): HTMLButtonElement | null => {
  return document.querySelector('#submit-entity');
};

export const getEditShopForm = (): HTMLFormElement | null => {
  return document.querySelector('#editShopForm');
};

export const getSelectFromMapBtn = (): HTMLElement | null => {
  return document.querySelector('.select-from-map-tab-button');
};

export const showCancelButton = (cancelLookupButton: HTMLElement): void => {
  cancelLookupButton.style.display = 'inline-block';
};

export const hideCancelButton = (cancelLookupButton: HTMLElement): void => {
  cancelLookupButton.style.display = 'none';
};

export const getPreviousCoordinates = (): LatLng | null => {
  let prevLatElement: HTMLInputElement | null = document.querySelector('#prev-lat');
  let prevLngElement: HTMLInputElement | null = document.querySelector('#prev-lng');

  if ((prevLatElement && prevLatElement.value) && (prevLngElement && prevLngElement.value)) {
    return new L.LatLng(parseFloat(prevLatElement.value), parseFloat(prevLngElement.value));
  }
  return null;
};

export const getCurrentCoordinates = (): LatLng | null => {
  let currentLatElement: HTMLInputElement | null = document.querySelector('#locationLatitude');
  let currentLngElement: HTMLInputElement | null = document.querySelector('#locationLongitude');

  if ((currentLatElement && currentLatElement.value) && (currentLngElement && currentLngElement.value)) {
    return new L.LatLng(parseFloat(currentLatElement.value), parseFloat(currentLngElement.value));
  }

  return null;
};

export const updatePreviousCoordinates = (coordinates: LatLng | null): void => {
  let prevLatElement: HTMLInputElement | null = document.querySelector('#prev-lat');
  let prevLngElement: HTMLInputElement | null = document.querySelector('#prev-lng');

  if (prevLatElement && prevLngElement) {
   if (coordinates && coordinates && coordinates.lat && coordinates.lng) {
     prevLatElement.value = coordinates.lat.toString();
     prevLngElement.value = coordinates.lng.toString();
   } else {
     prevLatElement.value = "";
     prevLngElement.value = "";
   }
  }
};

export const updateNewCoordinates = (coordinates: LatLng | null): void => {
  let currentLatElement: HTMLInputElement | null = document.querySelector('#locationLatitude');
  let currentLngElement: HTMLInputElement | null = document.querySelector('#locationLongitude');

  if (currentLatElement && currentLngElement) {
   if (coordinates && coordinates.lat && coordinates.lng) {
     currentLatElement.value = coordinates.lat.toString();
     currentLngElement.value = coordinates.lng.toString();
   } else {
     currentLatElement.value = "";
     currentLngElement.value = "";
   }
  }
};

export const showGeolocationSpinner = (): void => {
  let geoLocationSpinner: HTMLElement | null = document.querySelector('#geolocation-spinner');
  if (geoLocationSpinner) {
    geoLocationSpinner.style.display = 'block';
  }
};

export const hideGeolocationSpinner = (): void => {
  let geoLocationSpinner: HTMLElement | null = document.querySelector('#geolocation-spinner');
  if (geoLocationSpinner) {
    geoLocationSpinner.style.display = 'none';
  }
};

export const hideFormSpinner = (): void => {
  let processingIcon: HTMLDivElement | null = document.querySelector("#processing-entity");
  setTimeout(() => { // need setTimeout to avoid conflict with Jquery
    if (processingIcon) {
      processingIcon.style.display = 'none';
    }
  }, 100);
};

export const preventFormSubmission = (): void => {
  let editShopForm: HTMLFormElement | null = document.querySelector("#editShopForm");
  if (editShopForm) {
    editShopForm.addEventListener('submit', (e) => {
      e.preventDefault();
      if (process.env.NODE_ENV !== 'production') {
        console.log("Prevented EditShopForm Submit as there is no location");
      }
    }, {
      once: true
    });
  }
};


export const setAddressInHTML = (address: MapAddress): void => {
  const street: HTMLInputElement | null = document.querySelector("#address-street");
  const city: HTMLInputElement | null = document.querySelector("#address-city");
  const province: HTMLInputElement | null = document.querySelector("#address-province");
  const country: HTMLInputElement | null = document.querySelector("#address-country");
  const postalCode: HTMLInputElement | null = document.querySelector("#address-postalCode");

  if (street && city && province && country && postalCode && address) {
    if (address.street) {
      street.value = address.street;
    }

    if (address.city) {
      city.value = address.city;
    }

    if (address.province) {
      province.value = address.province;
    }

    if (address.postalCode) {
      postalCode.value = address.postalCode;
    }

    if (address.country) {
      country.value = address.country;
      country.dispatchEvent(new Event("change"));
    }
  }
};

export const validateAddress = (street: string, city: string, province: string, country: string, postalCode: string): boolean => {
  let valid: boolean = true;

  if (street.length === 0 && city.length === 0 && province.length === 0 &&
    country.length === 0 && postalCode.length === 0) {
    valid = false;
  }

  return valid;
};

export const setAddress = (street: string, city: string, country: string, postalCode: string): string => {
  let address = "";

  if (street.length > 0) {
    address += street;
  }

  if (city.length > 0) {
    address += `, ${city}`;
  }

  if (postalCode.length === 5) {
    address += `, ${postalCode}`;
  }

  if (country.length > 0) {
    address += `, ${country}`;
  }

  return address;
};


export const validateAndSetAddress = (street: string, city: string, province: string, country: string, postalCode: string): string | null => {
  if (validateAddress(street, city, province, country, postalCode)) {
    return setAddress(street, city, country, postalCode);
  }

  return null;
};

export const getValidatedAddress = (): string | null => {
  const street: HTMLInputElement | null = document.querySelector("#address-street");
  const city: HTMLInputElement | null = document.querySelector("#address-city");
  const province: HTMLInputElement | null = document.querySelector("#address-province");
  const country: HTMLInputElement | null = document.querySelector("#address-country");
  const postalCode: HTMLInputElement | null = document.querySelector("#address-postalCode");

  if (street && city && province && country && postalCode) {
    return validateAndSetAddress(street.value, city.value, province.value, country.value, postalCode.value);
  }
  return null;
};

export const submitShopForm = (): void => {
  let editShopForm: HTMLFormElement | null = document.querySelector('#editShopForm');
  if (editShopForm) {
    editShopForm.submit();
  }
};

export const getNameFieldFocus = (): void => {
  let nameField: HTMLInputElement | null = document.querySelector("#name");

  if (nameField) {
    nameField.focus();
  }
};

export const enableNewAddressLookupButton = (markersLength: number): void => {
  let lookupBtn = getLookupBtn();
  let cancelLookupBtn = getCancelLookupBtn();

  let ready = false;

  (document.querySelectorAll(".address-field")).forEach((eachField: Element) => {
    if ((<HTMLInputElement> eachField).value.length > 0 && lookupBtn && cancelLookupBtn) {
      lookupBtn.classList.remove('disabled');
      if (markersLength > 0) {
        showCancelButton(cancelLookupBtn);
      } else {
        hideCancelButton(cancelLookupBtn);
      }

      ready = true;
    }
  });

  if (!ready && lookupBtn && cancelLookupBtn) {
    if (markersLength > 0) {
      lookupBtn.classList.remove('disabled');
      showCancelButton(cancelLookupBtn);
    } else {
      lookupBtn.classList.add('disabled');
      hideCancelButton(cancelLookupBtn);
    }
  }
};

export const getAttachedShopLocation = (): LatLng | null => {
  let latElement: HTMLInputElement | null = document.querySelector('#locationLatitude');
  let lngElement: HTMLInputElement | null = document.querySelector('#locationLongitude');

  if (latElement && lngElement && latElement.value && lngElement.value) {
    let coordinates: LatLng = new L.LatLng(parseFloat(latElement.value), parseFloat(lngElement.value));
    updatePreviousCoordinates(coordinates);
    return (coordinates);
  }

  return null;
};

export const eventListenerOnAddressFields = (): void => {
  let addressFields: NodeListOf<Element> = document.querySelectorAll(".address-field");

  addressFields.forEach((field) => {
    field.addEventListener('change', () => {
      enableNewAddressLookupButton(0);
    });
  });
};

export const initProjectShopsFromMap = (translationForAll: LocaleMessage): void => {
  let mapShopsList: Element | null = document.querySelector("#select-from-map-shops-list");
  let mapShopsListLength: NodeListOf<Element> | null = document.querySelectorAll(".select-from-map-shops-length");
  let mapShopsListHidden: HTMLInputElement | null = document.querySelector("#select-from-map-shops-list-hidden"); // input

  if (mapShopsList && mapShopsListLength && mapShopsListHidden) {
    mapShopsListLength.forEach((mapShopListLength, index) => {
      mapShopListLength.innerHTML = "0";
    });

    mapShopsListHidden.value = "";

    let mapShopsSelects: Element | null = document.querySelector("#select-from-map-shops-selects");
    if (mapShopsSelects) {
      mapShopsSelects.innerHTML = `<option value="-1" selected>${translationForAll}</option>`;
    }
  }
};

export const getMapChainSelects = (): HTMLSelectElement => {
  return <HTMLSelectElement> document.querySelector('#select-from-map-chains-selects');
};

export const getChainsFromSelectFromMapChainSelects = (): number[] => {
  const chainSelects: HTMLSelectElement | null = getMapChainSelects();
  let chainIds: number[] = [];
  if (chainSelects) {
    for (let i = 1; i < chainSelects.options.length; i++) {
      chainIds.push(parseInt(chainSelects.options[i].value));
    }
  }
  return chainIds;
};

export const passShopsToHTML = (validatedMakers: MapMarker[], translationForAll: LocaleMessage): void => {
  let mapShopsListHidden: HTMLInputElement | null = document.querySelector('#select-from-map-shops-list-hidden');
  if (mapShopsListHidden) {
    mapShopsListHidden.value = JSON.stringify(validatedMakers);
  }

  let mapShopsLength: NodeListOf<Element> | null = document.querySelectorAll('.select-from-map-shops-length');
  if (mapShopsLength) {
    mapShopsLength.forEach((mapShopLength, index) => {
      mapShopLength.innerHTML = `${validatedMakers.length}`;
    });
  }

  let mapShopsSelects: Element | null = document.querySelector('#select-from-map-shops-selects');
  if (mapShopsSelects) {
    mapShopsSelects.innerHTML = "";

    let list = `<option value="-1" selected>${translationForAll}</option>`;
    validatedMakers.forEach((marker: MapMarker) => {
      if (marker.html) {
        list += `<option value="${marker.html.item.id}">${marker.html.item.name}</option>`;
      }
    });

    mapShopsSelects.insertAdjacentHTML("afterbegin", list);
  }
};

export const setEachAddressField = (addressResult: MapAddressResultByCoordinates[]): MapAddress => {
  let newAddress: MapAddress = new MapAddress();

  addressResult.forEach((address: MapAddressResultByCoordinates) => {
    if (address && address.types) {
      if (address.types.indexOf("postal_code") > -1) {
        newAddress.postalCode += `${address.long_name}`;
      } else if (address.types.indexOf("street_number") > -1 || address.types.indexOf("route") > -1 ||
        address.types.indexOf("postal_town") > -1 || address.types.indexOf("premise") > -1) {
        newAddress.street += `${address.long_name} `;
      } else if (address.types.indexOf("administrative_area_level_3") > -1 ||
        address.types.indexOf("administrative_area_level_2") > -1 || address.types.indexOf("locality") > -1) {
        newAddress.city += `${address.long_name}`;
      } else if (address.types.indexOf("administrative_area_level_1") > -1) {
        newAddress.region += `${address.long_name}`;
      } else if (address.types.indexOf("country") > -1) {
        newAddress.country = `${address.short_name}`;
      }
    }
  });

  return newAddress;
};

export const initProjectShopsFromEntity = (): void => {
  let chains: HTMLSelectElement | null = document.querySelector("#chain");
  let shops: HTMLSelectElement | null = document.querySelector("#shop");

  if (chains && shops) {
    chains.value = chains.options[0].value;
    shops.value = shops.options[0].value;
  }

  initProjectShopsFromCalloutText();
};

export const initProjectShopsFromCalloutText = (): void => {
  let errorMessasge: HTMLSelectElement | null = document.querySelector("#select-from-map-error-message");
  let successMessage: HTMLSelectElement | null = document.querySelector("#select-from-map-success-message");

  if (errorMessasge && successMessage) {
    errorMessasge.style.display = "none";
    successMessage.style.display = "none";

    let errorText: HTMLSelectElement | null = errorMessasge.querySelector(".select-from-map-error-text");
    let successText: HTMLSelectElement | null = successMessage.querySelector(".select-from-map-success-text");

    if (errorText && successText) {
      errorText.innerText = "";
      successText.innerText = "";
    }
  }
};

const getOffsetHeight = (shopRow: HTMLElement, mapHeight: number): number => {
  let offset = shopRow.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
  return (offset - (mapHeight + 100));
};

const getShopsRows = (): NodeListOf<HTMLElement> => {
  return <NodeListOf<HTMLElement>> document.querySelectorAll('tbody tr');
};

const highlightShopRowInTable = (shid: number, mapHeight: number, scroll: boolean): void => {
  if (shid) {
    let shopRows: NodeListOf<HTMLElement> | null = getShopsRows();
    shopRows.forEach((shopRow: HTMLElement, index: number) => {
      let shidFromData: string | undefined = shopRow.dataset['shid'];
      if (shidFromData) {
        try {
          if (shid === parseInt(shidFromData)) {
            shopRow.classList.add('highlighted');
            if (scroll) {
              window.scrollTo(0, getOffsetHeight(shopRow, mapHeight));
            }
            return;
          } else {
            shopRow.classList.remove('highlighted');
          }
        } catch (e) {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Couldn't highlight the table row", e);
          }
          shopRow.classList.remove('highlighted');
        }
      } else {
        shopRow.classList.remove('highlighted');
      }
    });
  }
};

const getCategoriesFromFilter = (): MapCategory[] => {
  let categories: MapCategory[] = [];
  let categoriesSelects: NodeListOf<HTMLSelectElement> | null = document.querySelectorAll('select[name=categories]');

  categoriesSelects.forEach((select) => {
    for (let i = 0; i < select.options.length; i++) { // selects has no -1 value
      if (i === 0 || (categories[categories.length - 1].id !== parseInt(select.options[i].value))) {
        let currentCategory = new MapCategory();
        currentCategory.id = parseInt(select.options[i].value);
        currentCategory.color = select.options[i].dataset.color;
        currentCategory.name = (select.options[i].innerText);

        if (select.options[i].dataset.parent) {
          let parentCategory = new MapCategory();
          parentCategory.name = select.options[i].dataset.parent;
          currentCategory.parentCategory = parentCategory;
        }

        categories.push(currentCategory);
      }
    }
  });

  return categories;
};

export default {
  getLookupBtn,
  getCancelLookupBtn,
  getSubmitEntityBtn,
  getSelectFromMapBtn,
  getEditShopForm,
  getValidatedAddress,
  getPreviousCoordinates,
  getCurrentCoordinates,
  updatePreviousCoordinates,
  updateNewCoordinates,
  showGeolocationSpinner,
  hideGeolocationSpinner,
  hideFormSpinner,
  preventFormSubmission,
  setAddressInHTML,
  getNameFieldFocus,
  enableNewAddressLookupButton,
  getAttachedShopLocation,
  submitShopForm,
  eventListenerOnAddressFields,
  initProjectShopsFromMap,
  initProjectShopsFromEntity,
  getChainsFromSelectFromMapChainSelects,
  passShopsToHTML,
  setEachAddressField,
  highlightShopRowInTable,
  getCategoriesFromFilter
};
