import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLink } from "./NavigationLink";

export enum NavigationLinkType {
  WEB = "web",
  PAGE_SELF = "self",
  PAGE_NEXT = "next",
  PAGE_PREV = "prev",
  PRIVATE = "private",
  ASSUME_USER = "assume_user",
  UNASSUME_USER = "unassume_user"
}

@JsonObject('NavigationLinks')
export class NavigationLinks {

  @JsonProperty('links', [NavigationLink], true)
  links: NavigationLink[] = [];

  addLink(link: NavigationLink): void {
    if (this.links === undefined) {
      this.links = [];
    }

    if (link) {
      this.links.push(link);
    }
  }

  static getLinkValueFromNavigation(type: string, fallback: string, nav?: NavigationLinks): string {
    let link = NavigationLinks.getLinkFromNavigation(type, nav);
    if (link) {
      return link.href;
    }

    return fallback;
  }

  static getLinkFromNavigation(type: string, nav?: NavigationLinks): NavigationLink | undefined {
    if (nav) {
      return this.getLinkFromLinks(type, nav.links);
    }
  }

  static getLinkFromLinks(type: string, links?: NavigationLink[]): NavigationLink | undefined {
    if (links) {
      return links.filter(link => {
        return NavigationLink.hasType(type, link);
      })[0];
    }
  }

  getLink(type: string): NavigationLink | undefined {
    return NavigationLinks.getLinkFromLinks(type, this.links);
  }

  webLink(): string {
    let link = this.getLink(NavigationLinkType.WEB);
    if (link) {
      return link.href;
    }

    return '#';
  }

}
