import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { Metadata } from "@/main/webapp/vue/model/api/Metadata";
import { DateTime } from "@/main/webapp/vue/model/api/DateTime";
import { Organization } from "@/main/webapp/vue/model/api/Organization";
import { User } from "@/main/webapp/vue/model/api/User";
import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
import { Project } from "@/main/webapp/vue/model/api/Project";
import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

@JsonObject('MapSubmissionDetails')
export class MapSubmissionDetails extends Metadata {
    @JsonProperty('id', Number, true)
    id?: Number = undefined;

    @JsonProperty('nav', NavigationLinks, true)
    nav?: NavigationLinks = undefined;

    @JsonProperty('created', DateTime, true)
    created?: DateTime = undefined;

    @JsonProperty('organization', Organization, true)
    organization?: Organization = undefined;

    @JsonProperty('author', User, true)
    author?: User = undefined;

    @JsonProperty('project', Project, true)
    project?: Project = undefined;

    @JsonProperty('description', String, true)
    description?: string = undefined;

    @JsonProperty('contents', [ItemContent], true)
    contents?: ItemContent[] = [];

    @JsonProperty('itemMetadata', [HierarchicalEntity], true)
    itemMetadata?: HierarchicalEntity[] = [];

    @JsonProperty('comments', Number, true)
    comments?: Number = 0;

    @JsonProperty('likes', Number, true)
    likes?: Number = 0;
}
