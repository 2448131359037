import { JsonObject, JsonProperty } from "json2typescript";
import { Course } from "@/main/webapp/vue/model/api/learning/Course";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('OrganizationData')
export class OrganizationData {

  @JsonProperty('organization', String, true)
  organization?: string = undefined;

  @JsonProperty('siteLinkUrl', String, true)
  siteLinkUrl?: string = undefined;

  @JsonProperty('siteLinkLabel', String, true)
  siteLinkLabel?: string = undefined;

}
