import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { DateTime } from "@/main/webapp/vue/model/api/DateTime";

@JsonObject('Logo')
export class Logo extends NavigationLinks {

  @JsonProperty('type', String, true)
  type?: string = undefined;

  @JsonProperty('itemId', Number, true)
  itemId?: number = undefined;

  @JsonProperty('contentMimeType', String, true)
  contentMimeType?: string = undefined;

  @JsonProperty('lastUpdated', DateTime, true)
  lastUpdated?: DateTime = undefined;

}
