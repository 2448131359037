import { JsonObject, JsonProperty } from "json2typescript";


export enum COURSE_STATUS_TYPE {
  ACTIVE = 'active',
  IN_ACTIVE = 'inactive'
}

@JsonObject('CourseStatus')
export class CourseStatus {

  @JsonProperty('value', String, false)
  value?: string | undefined = undefined;

}
