
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import TextBlockWidgetModel from
  '@/main/webapp/vue/components/learning/widget/widgets/text-block/model/TextBlockWidgetModel';
  import TextStyleWidgetProperties from "@/main/webapp/vue/model/learning/TextStyleWidgetProperties";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'TextBlockWidget',
    props: {
      model: {
        type: Object as PropType<TextBlockWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
        default: false
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        pageReady: false as Boolean,
        editingText: false as Boolean,
        textarea: null as HTMLTextAreaElement | null
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): TextStyleWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedTextBlockStyle(): Object {
        return computedStyleUtil.getStyles(
          ["color", "alignment", 'size'],
          this.model.properties.textStyle,
          this.computedDefaultStyle.textStyle);
      }
    },
    methods: {
      setCursorToEnd(): void {
        if (this.textarea && this.model.data.paragraph && this.model.data.paragraph.length > 0) {
          this.textarea.value += " ";
        }
      },
      startEditing(): void {
        if (this.editable) {
          if (!this.editing) {
            this.$emit('edit', this.model);
          }

          if (!this.editingText) {
            this.editingText = true;
            if (this.textarea !== null) {
              this.textarea.focus();
            }
          }
          this.resizeTextareaHeight();
        }
      },
      stopEditing(): void {
        this.editingText = false;
      },
      resizeTextareaHeight(): void {
        if (this.textarea) {
          this.textarea.style.height = 'auto';
          if (this.textarea.scrollHeight > 0) {
            this.textarea.style.height = `${this.textarea.scrollHeight}px`;
          }
        }
      }
    },
    watch: {
      model: {
        handler(): void {
          this.resizeTextareaHeight();
          this.$emit('edited', this.model, true);
        },
        deep: true
      },
      visible(newVisible: boolean, oldVisible: boolean): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Show collapsible body: ", newVisible);
        }
        if (newVisible) {
          this.resizeTextareaHeight(); // With visible value ie. collapsible widget
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.textarea = (this.$refs.paragraph as HTMLTextAreaElement);

        if (process.env.NODE_ENV !== 'production') {
          console.log("Show collapsible body on mounted: ", this.visible);
        }
        if (this.visible) {
          this.resizeTextareaHeight();
        }
      });
    },
    i18n: {
      messages: messages
    }
  });
