
  import Vue from 'vue';
  import textSpan from "@/main/webapp/vue/components/ui/text/text-span/index.vue";
  import textLocalized from "@/main/webapp/vue/components/ui/text/text-localized/index.vue";

  export default Vue.extend({
    props: {
      title: {
        type: String,
        default: null
      },
      count: {
        type: Number,
        default: null
      }
    },
    components: {
      textSpan,
      textLocalized
    }
  });
