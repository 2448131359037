import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('MarkerColor')
export class MarkerColor {
    @JsonProperty('name', String, true)
    name?: string = undefined;

    @JsonProperty('code', String, true)
    code?: string = undefined;
}
