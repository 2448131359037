
  import Vue, { PropType } from 'vue';

  import draggableContainer from '@/main/webapp/vue/components/ui/draggable/container/index.vue';
  import widgetComponent from '@/main/webapp/vue/components/learning/widget/component/WidgetComponent.vue';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import WidgetModel from '@/main/webapp/vue/model/learning/WidgetModel';
  import LayoutColumnsWidgetModel, {
    ColumnType, LayoutColumnsProperties,
    WidgetColumnMapping
  } from '@/main/webapp/vue/components/learning/widget/widgets/layout-columns/model/LayoutColumnsWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'LayoutColumnsWidget',
    components: {
      draggableContainer,
      widgetComponent
    },
    props: {
      model: {
        type: Object as PropType<LayoutColumnsWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editing: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: false
      },
      editingWidget: {
        type: Object as PropType<WidgetModel | undefined>,
        default: undefined
      },
      widgetFromSideBar: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        leftColumn: ColumnType.LEFT_COLUMN,
        rightColumn: ColumnType.RIGHT_COLUMN,
        selectingWidgetCompatibleSection: null as ColumnType | null
      };
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): LayoutColumnsProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedColumWrapper(): Object { // Only apply to under 768px
        return {
          '--flexFlow': (this.model.properties.columnStyle.responsive ? 'column' : 'row'),
          '--flexWrap': (this.model.properties.columnStyle.responsive ? 'wrap' : 'nowrap')
        };
      },
      computedLeftColumnStyle(): Object {
        return {
          ...computedStyleUtil.getStyles(
            ['columnRatio'],
            this.model.properties,
            this.computedDefaultStyle),
          ...computedStyleUtil.getStyles(
            ['backgroundColor', 'foregroundColor', 'padding'],
            this.model.properties.leftColumnSectionStyle,
            this.computedDefaultStyle.leftColumnSectionStyle),
          '--width': this.model.properties.columnStyle.responsive ? '100%' : 'auto'
        };
      },
      computedRightColumnStyle(): Object {
        const leftColumnRatio: number = computedStyleUtil.getValue(
          'columnRatio',
          this.model.properties,
          this.computedDefaultStyle);

        return {
          '--columnRatio': `${100 - leftColumnRatio}%`,
          ...computedStyleUtil.getStyles(
            ['backgroundColor', 'foregroundColor', 'padding'],
            this.model.properties.rightColumnSectionStyle,
            this.computedDefaultStyle.rightColumnSectionStyle),
          '--width': this.model.properties.columnStyle.responsive ? '100%' : 'auto'
        };
      }
    },
    methods: {
      widgetInColumn(widget: WidgetModel, column: ColumnType): boolean {
        return this.model.data.widgetColumnMapping.find((mapping) =>
          mapping.widgetId === widget.metadata.originalId && (mapping.column === column || !mapping.column)
        ) !== undefined;
      },
      onWidgetCreated(widget: WidgetModel, index: number, column: ColumnType) {
        if (widget) {
          this.model.data.widgetColumnMapping =
            this.model.data.widgetColumnMapping.concat(new WidgetColumnMapping(widget.metadata.originalId, column));

          this.$emit('create', widget, index, this.model);

          // Need to enforce updates on the parent to store the column where the widget is placed
          this.$emit('edited', this.model, index, true);
        }
      },
      onWidgetSelected(widget: WidgetModel, columnType: ColumnType): void {
        this.selectingWidgetCompatibleSection = columnType;
      },
      onWidgetEnded(widget: WidgetModel, index: number) {
        if (widget) {
          this.$emit('moved', widget, index, this.model);
        }

        this.selectingWidgetCompatibleSection = null;
      },
      delete(nestedContents: WidgetModel[], widget: WidgetModel) {
        return nestedContents.filter((e: any) => {
          if (e.nestedContents && e.nestedContents.length > 0) {
            e.nestedContents = this.delete(e.nestedContents, widget);
          }

          return e.id !== widget.id && e.id !== widget.metadata.originalId;
        });
      },
      deleteNestedContents(widget: WidgetModel) {
        if (this.model.nestedContents && this.model.nestedContents.length > 0) {
          this.model.nestedContents = this.delete(this.model.nestedContents, widget);
        }

        this.model.data.widgetColumnMapping = this.model.data.widgetColumnMapping.filter(
          (e) => e.widgetId !== widget.metadata.originalId
        );
      },
      onDelete(widget: WidgetModel) {
        this.$emit('delete', widget, this.deleteNestedContents);
      }
    }
  });
