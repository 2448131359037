
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import entityButton from "@/main/webapp/vue/components/ui/entity-button/index.vue";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { ItemComment } from "@/main/webapp/vue/model/api/ItemComment";
  import { Item } from "@/main/webapp/vue/model/api/Item";

  export default Vue.extend({
    components: {
      entityButton
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      styleClass: {
        type: String,
        default: "mb-3"
      }
    },
    i18n: {
      messages: messages
    },
    data() {
      return {
        text: "" as string
      };
    },
    watch: {
      text(newText: string, oldText: string) {
        if (newText && newText.length > 0) {
          this.$emit('edited');
        }
      }
    },
    methods: {
      submitComment() {
        if (this.text.length > 0) {
          let comment = new ItemComment();
          comment.text = this.text;
          this.postComment(comment);
        }
      },
      postComment(comment: ItemComment) {
        BackendIntegrationService.postComment(this.submission, comment)
          .then((response: ItemComment) => {
            this.$emit('comment-submitted', response);
            this.text = '';
          }).catch((error: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Add comments failed", error);
            }
          });
      }
    }
  });
