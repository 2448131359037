import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

@JsonDiscriminator(StyleModel, 'icon')
export default class IconStyleModel extends StyleModel {
  constructor(@JsonField('icon', String, true)
              public icon?: string,
              @JsonField('color', String, true)
              public color?: string,
              @JsonField('size', Number, true)
              public size?: number,
              @JsonField('secondColor', String, true)
              public secondColor?: string) {
    super();
  }

  default(): IconStyleModel {
    return new IconStyleModel('info', '#868686', 20);
  }
}
