import { JsonObject, JsonProperty } from "json2typescript";
import { WebSocketPayload } from "@/main/webapp/vue/model/api/report/WebSocketPayload";

@JsonObject('WebSocketReportProgressPayload')
export class WebSocketReportProgressPayload extends WebSocketPayload {
  @JsonProperty('value', Number)
  value: number | undefined = undefined;

  @JsonProperty('max', Number)
  max: number | undefined = undefined;
}
