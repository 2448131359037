
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: {
        type: Boolean,
        default: true
      },
      label: {
        type: String,
        default: null
      },
      subLabel: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "md"
      }
    },
    data() {
      return {
        status: this.value as Boolean
      };
    },
    watch: {
      value(newValue: boolean, oldValue: boolean) {
        this.status = newValue;
      }
    },
    methods: {
      update(value: boolean) {
        this.$emit('input', value); // Updates the prop value used as v-model
        this.$emit('updated', value); // For custom handling
      }
    }
  });
