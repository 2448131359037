import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

export enum ALIGNMENT_VERTICAL { // bootstrap-vue css
  TOP = 'align-items-start',
  CENTER = 'align-items-center',
  BOTTOM = 'align-items-end'
}

export enum ALIGNMENT_HORIZONTAL {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}

@JsonDiscriminator(StyleModel, 'alignment')
export default class AlignmentStyleModel extends StyleModel {
  constructor(@JsonField('vertical', String, true)
              public vertical: ALIGNMENT_VERTICAL = ALIGNMENT_VERTICAL.TOP,
              @JsonField('horizontal', String, true)
              public horizontal: ALIGNMENT_HORIZONTAL = ALIGNMENT_HORIZONTAL.LEFT) {
    super();
  }

  default(): AlignmentStyleModel {
    return new AlignmentStyleModel();
  }
}
