import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

export enum TEXT_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}

export enum TEXT_SIZE {
  TITLE = 30 as number,
  CONTENT = 15 as number,
  ICON = 30 as number
}

@JsonDiscriminator(StyleModel, 'text')
export default class TextStyleModel extends StyleModel {
  constructor(@JsonField('size', Number, true)
              public size?: TEXT_SIZE,
              @JsonField('color', String, true)
              public color?: string,
              @JsonField('alignment', String, true)
              public alignment?: TEXT_ALIGNMENT) {
    super();
  }

  default(): TextStyleModel {
    return new TextStyleModel(TEXT_SIZE.CONTENT, '#000000', TEXT_ALIGNMENT.LEFT);
  }
}
