
  import Vue, { PropType } from 'vue';
  import { oc as Optional } from "ts-optchain";
  import { mapState } from 'vuex';
  import messages from './messages.json';

  import featuredSubmission from "@/main/webapp/vue/components/featured-submission/index.vue";
  import feedHeader from '@/main/webapp/vue/components/feed/feed-header/index.vue';
  import dateText from '@/main/webapp/vue/components/ui/text/text-date/index.vue';
  import feedArticle from '@/main/webapp/vue/components/feed/feed-article/index.vue';
  import textCallout from "@/main/webapp/vue/components/ui/text/text-callout/index.vue";
  import loadingNoResultLastArticle
    from "@/main/webapp/vue/views/submissions/ui/loading-noresult-lastarticle/index.vue";
  import scrollToTop from '@/main/webapp/vue/components/ui/scroll/scroll-to-top/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import TimeUtil from '@/main/webapp/vue/util/timeUtil';
  import { DateTime } from "@/main/webapp/vue/model/api/DateTime";
  import { Feed } from "@/main/webapp/vue/model/api/Feed";
  import { FeedArticle } from "@/main/webapp/vue/model/api/FeedArticle";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
  import { SubmissionContainer } from "@/main/webapp/vue/model/api/SubmissionContainer";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

  export default Vue.extend({
    components: {
      featuredSubmission,
      feedHeader,
      dateText,
      feedArticle,
      loadingNoResultLastArticle,
      textCallout,
      scrollToTop
    },
    props: {
      searchCriteria: {
        type: Object as PropType<SearchCriteria>,
        default: () => null
      },
      showFeaturedSubmission: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        today: TimeUtil.getCurrentDateTime() as DateTime,
        articles: [] as FeedArticle[],
        filteredArticles: [] as Item[],
        nextPage: undefined as NavigationLink | undefined,
        loadMore: false as boolean,
        isLoading: false as boolean,
        loadFailureCount: 0 as number,
        isLastArticle: false as boolean,
        filledHeightByItem: false as boolean
      };
    },
    computed: {
      ...mapState([
        'properties'
      ]),
      computedArticles(): FeedArticle[] | Item[] {
        if (this.searchCriteria) {
          return this.filteredArticles;
        }
        return this.articles;
      }
    },
    watch: {
      loadMore(newValue: boolean, oldValue: boolean): void {
        if (newValue) {
          if (this.searchCriteria) {
            this.loadFilterArticles();
          } else {
            this.loadFeedArticles();
          }
        }
      },
      searchCriteria(newSearch: SearchCriteria, oldSearch: SearchCriteria): void {
        this.clearFeed();
        if (newSearch) {
          this.setFilterArticles(newSearch.results);
        } else {
          this.loadFeedArticles(true);
        }
      }
    },
    methods: {
      clearFeed(): void {
        this.articles = [];
        this.filteredArticles = [];
        this.nextPage = undefined;
        this.updateToday();
        this.isLastArticle = false;
      },
      refreshFilterArticles(): void {
        this.isLoading = true;
        setTimeout(() => { // Too quick to render, delay on purpose
          this.setFilterArticles(this.searchCriteria.results);
          this.isLoading = false;
        }, 1000);
      },
      getNewFeed(): void {
        this.clearFeed();

        if (this.searchCriteria) {
          this.refreshFilterArticles();
        } else {
          this.loadMore = true;
        }

        this.loadFailureCount = 0;
      },
      setNextLink(response: Feed | SubmissionContainer): void {
        let nav: NavigationLinks | undefined = Optional(response).nav();

        if (nav) {
          let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
          if (nextLink) {
            this.nextPage = nextLink;
            this.checkScroll();
          } else {
            this.loadNoMore();
          }
        } else {
          this.loadNoMore();
        }
      },
      setArticles(response: Feed): void {
        let articles: FeedArticle[] | undefined = Optional(response).articles();
        if (articles) {
          if (articles.length !== 0) {
            this.articles.push.apply(this.articles, articles);
            this.setNextLink(response);
          } else {
            this.loadNoMore();

            if (this.articles.length === 0) { // No data & no loaded data
              this.enforceToUseFilterData(true);
            }
          }
        } else {
          this.loadNoMore();
        }
      },
      enforceToUseFilterData(enforce: boolean = false): void {
        // When there is no data from item/articles, then switch to filter data
        this.$emit("enforce-to-use-filter-data", enforce);
      },
      setFilterArticles(response: SubmissionContainer | undefined) {
        if (response) {
          let articles: Item[] | undefined = Optional(response).list();
          if (articles) {
            if (articles.length !== 0) {
              this.filteredArticles.push.apply(this.filteredArticles, articles);
              this.setNextLink(response);
            } else {
              this.loadNoMore();
            }
          } else {
            this.loadNoMore();
          }
        }
      },
      handleError(error: BackendError): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Feed loading failed', error);
        }
        this.loadFailureCount++;
        notification.fail(this.$t('error.loading.failed'));
        if (this.loadFailureCount >= (this as any).$properties.feed.threshold.failure.load) {
          this.loadNoMore();
        }
      },
      loadFeedArticles(enforce: boolean = false): void {
        this.loadMore = false;
        if (!this.isLoading && !this.isLastArticle) {
          this.isLoading = true;
          BackendIntegrationService.fetchFeed(enforce ? undefined : this.nextPage)
            .then((response: Feed) => {
              this.isLoading = false;
              this.setArticles(response);
            }).catch((error: BackendError) => {
              this.isLoading = false;
              this.handleError(error);
            });
        }
      },
      loadFilterArticles(): void {
        this.loadMore = false;

        if (!this.isLoading && !this.isLastArticle) {
          this.isLoading = true;

          BackendIntegrationService.fetchSubmissionList(this.nextPage)
            .then((submissionContainer: SubmissionContainer) => {
              this.isLoading = false;
              this.setFilterArticles(submissionContainer);
            }).catch((error: BackendError) => {
              this.isLoading = false;
              this.handleError(error);
            });
        }
      },
      checkScroll(): void {
        if (this.$route.fullPath.includes(RoutePath.TIMELINE) || this.$route.fullPath.includes(RoutePath.SUBMISSIONS_FEED)) {
          const documentElement: HTMLElement = document.documentElement;
          const currentScrollPosition: number = documentElement.scrollTop + documentElement.offsetHeight;
          const threshold: number = documentElement.scrollHeight * 0.7;

          if (!this.loadMore && !this.isLastArticle) {
            if (!this.filledHeightByItem) {
              if (documentElement.scrollHeight <= documentElement.clientHeight) { // For long/wide screen
                this.loadMore = true;
              } else {
                this.filledHeightByItem = true;
              }
            } else if (currentScrollPosition >= threshold) {
              this.loadMore = true;
            }
          }
        }
      },
      getPreviousArticle(index: number): FeedArticle | Item {
        const articles: FeedArticle[] | Item[] = this.searchCriteria ? this.filteredArticles : this.articles;
        return articles[index - 1];

      },
      matchesPreviousDate(article: FeedArticle, index: number): boolean {
        if (index === 0 && article.created) {
          let created = TimeUtil.convertDateTimeToLocalTimezone(article.created);
          if (created) {
            return TimeUtil.datesEqual(created, this.$data.today);
          }
        }

        let previousArticle: FeedArticle = this.getPreviousArticle(index);
        if (article.created && previousArticle.created) {
          return TimeUtil.datesEqual(article.created, previousArticle.created);
        }
        return false;
      },
      matchesPreviousTime(article: FeedArticle, index: number): boolean {
        if (index === 0) {
          return false;
        }

        let previousArticle: FeedArticle = this.getPreviousArticle(index);
        if (article.created && previousArticle.created) {
          return TimeUtil.timestampsEqual(article.created, previousArticle.created);
        }
        return false;
      },
      timeUnderOneHour(dateTime: DateTime | undefined): boolean {
        if (dateTime) {
          return TimeUtil.getTimeDifference(dateTime, "hour") === 0;
        }
        return false;
      },
      updateToday(): void {
        this.today = TimeUtil.getCurrentDateTime();
      },
      loadNoMore(): void {
        this.loadMore = false;
        this.isLastArticle = true;
        this.nextPage = undefined;
        this.filledHeightByItem = false;
      },
      registerScrollEvent(): void {
        window.addEventListener('scroll', this.checkScroll);
      },
      unregisterScrollEvent(): void {
        window.removeEventListener('scroll', this.checkScroll);
      }
    },
    mounted(): void {
      this.registerScrollEvent();

      if (this.searchCriteria) {
        this.setFilterArticles(this.searchCriteria.results);
      } else {
        this.loadMore = true;
      }
    },
    beforeDestroy(): void {
      this.unregisterScrollEvent();
    },
    i18n: {
      messages: messages
    }
  });
