import { JsonObject, JsonProperty } from "json2typescript";
import { Entity } from "@/main/webapp/vue/model/api/Entity";

@JsonObject('EntityRegistryClientFilter')
export class EntityRegistryClientFilter extends Entity {

  @JsonProperty('field', String, false)
  field: string = "";

  @JsonProperty('value', String, false)
  value: string = "";

  @JsonProperty('active', Boolean, false)
  active: boolean = false;

}
