
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import iconInput from '@/main/webapp/vue/components/ui/style/icon/container/icon-input/index.vue';
  import textStyle from '@/main/webapp/vue/components/ui/style/text/container/index.vue';
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import IconMaterialDesignWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/icon-material-design/model/IconMaterialDesignWidgetModel';

  export default Vue.extend({
    name: 'IconMaterialDesignWidgetProperties',
    props: {
      model: {
        type: Object as PropType<IconMaterialDesignWidgetModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      textStyle,
      genericStyle,
      iconInput
    },
    i18n: {
      messages: messages
    }
  });
