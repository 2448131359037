
  import Vue from 'vue';
  import { Map } from 'leaflet';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';

  import mapHtmlConfiguration from "@/main/webapp/vue/components/map/mapHtmlConfiguration";

  export default Vue.extend({
    components: {
      baseMap
    },
    data() {
      return {
        mapObject: undefined as Map | undefined
      };
    },
    methods: {
      geoLocate(): void {
        if (this.mapObject) {
          this.mapObject.locate({ setView: true }).on('locationfound', (e: any) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Location found', e.latlng);
            }
            mapHtmlConfiguration.updateNewCoordinates(e.latlng);
          }).on('locationerror', (e: any) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Location found error', e);
            }
          });
        }
      },
      initMap(map: Map): void {
        this.mapObject = map;
        this.geoLocate();
      }
    }
  });
