import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";
import { ProjectProgress } from "./ProjectProgress";

@JsonObject('ProjectProgressContainer')
export class ProjectProgressContainer {

  @JsonProperty('list', [ProjectProgress], false)
  list: ProjectProgress[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
