
  import Vue from 'vue';
  import { mapState } from "vuex";

  import messages from './messages.json';

  export default Vue.extend({
    props: {
      btnVariant: {
        type: String,
        default: 'light'
      }
    },
    computed: {
      ...mapState([
        'searchString'
      ])
    },
    methods: {
      handleInput(value: string) {
        this.$store.commit('updateSearchString', value);
      }
    },
    mounted() {
      let searchString: string = this.$route.query.q as string;
      if (searchString) {
        this.handleInput(searchString);
      }
    },
    i18n: {
      messages: messages
    }
  });
