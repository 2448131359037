
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import messages from "./messages.json";
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import inputField from "@/main/webapp/vue/components/ui/input/index.vue";
  import datetimePicker from "@/main/webapp/vue/components/ui/datetime-picker/index.vue";
  import filterListToggle from "@/main/webapp/vue/components/ui/filter/filter-list-toggle/index.vue";
  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';
  import filterListCollapsible from "@/main/webapp/vue/components/ui/filter/filter-list-collapsible/index.vue";
  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";
  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
  import { SubmissionContainer } from "@/main/webapp/vue/model/api/SubmissionContainer";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { SearchDefinition, SearchDefinitionType } from "@/main/webapp/vue/model/api/SearchDefinition";
  import { SearchCriterion } from "@/main/webapp/vue/model/api/SearchCriterion";
  import { FilterAttributes } from "@/main/webapp/vue/views/submissions/ui/submissions-switcher/index.vue";
  import { CriterionSelector } from "@/main/webapp/vue/components/ui/filter/filter-criteria-selector/index.vue";

  export class NewFilterParams {
    params: string;
    filters: number;
  }

  export default Vue.extend({
    props: {
      criterionToRemove: {
        type: Object as PropType<CriterionSelector>,
        default: null
      }
    },
    components: {
      collapsibleContainer,
      inputField,
      datetimePicker,
      filterListToggle,
      formSwitch,
      filterListCollapsible,
      loadingIcon,
      glyphiconSymbol
    },
    computed: {
      ...mapState([
        'userDetails',
        'backendLinks'
      ])
    },
    data() {
      return {
        processing: false as boolean,
        inCountBadge: 0 as number,
        isPredefined: false as boolean,
        filterChanged: false as boolean,
        error: false as boolean,
        searchCriteria: {
          criteria: [] as SearchCriterion,
          results: {} as SubmissionContainer,
          hits: 0 as Number
        } as SearchCriteria,
        searchDefinitionTypes: {
          text: SearchDefinitionType.INPUT_TEXT,
          timestamp: SearchDefinitionType.TIMESTAMP,
          listToggle: SearchDefinitionType.LIST_TOGGLE,
          listMulti: SearchDefinitionType.LIST_MULTI,
          listSingle: SearchDefinitionType.LIST_SINGLE,
          switch: SearchDefinitionType.SWITCH
        },
        filter: {
        } as any,
        lastFetchedParams: '' as string
      };
    },
    watch: {
      criterionToRemove(newCriterion: CriterionSelector, oldCriterion: CriterionSelector): void {
        this.removeCriterion(newCriterion);
      }
    },
    methods: {
      isListType(definitions: SearchCriterion): boolean {
        if (definitions && definitions.definition) {
          return (definitions.definition.type === this.searchDefinitionTypes.listMulti ||
            definitions.definition.type === this.searchDefinitionTypes.listSingle);
        }
        return false;
      },
      badgeCount(definitions: SearchCriterion): number {
        if (definitions && definitions.selectedDataValues) {
          if (definitions.selectedDataValues[0]) {
            return definitions.selectedDataValues.length;
          }
        }

        return 0;
      },
      closeFilter(hide: Function): void {
        if (this.filterChanged) {
          this.checkBackendLink();
        } else if (hide) {
          hide();
        }
      },
      clearFilter(): void {
        Object.keys(this.filter).forEach((key: string) => {
          if (Array.isArray(this.filter[key])) { // string[]
            this.filter[key] = [];
          } else {
            this.filter[key] = "";
          }
        });

        this.checkBackendLink();
      },
      clearSearchCriteria(): void {
        this.searchCriteria.criteria = [];
        this.searchCriteria.results = {};
        this.searchCriteria.hits = 0;
      },
      findMatchedCriterion(key: string | undefined): SearchCriterion | undefined {
        if (key && this.searchCriteria && this.searchCriteria.criteria) {
          return this.searchCriteria.criteria.find((searchCriterion: SearchCriterion) =>
            (searchCriterion.definition && searchCriterion.definition.key && searchCriterion.definition.key === key));
        }
        return undefined;
      },
      isArrayType(criterion: CriterionSelector): boolean {
        if (criterion && criterion.definition) {
          const type: SearchDefinitionType | undefined = criterion.definition.type;
          if (type) {
            return (type === SearchDefinitionType.LIST_SINGLE || type === SearchDefinitionType.LIST_MULTI);
          }
        }
        return false;
      },
      removeCriterion(criterion: CriterionSelector | null): void {
        if (criterion) {
          const key: string | undefined = criterion.definition?.key;
          if (key && this.isArrayType(criterion)) {
            let itemIds: string[] = criterion.parentIds;
            const itemId: string | undefined = criterion.data?.id;
            if (itemId) {
              itemIds.push(itemId);
            }

            const searchCriterion: SearchCriterion | undefined = this.findMatchedCriterion(key);
            if (searchCriterion && searchCriterion.selectedDataValues) {
              this.filter[key] = searchCriterion.selectedDataValues.filter((id: any) => !(itemIds.some((itemId: string) => id === itemId)));
            } else {
              this.filter[key] = [];
            }
          } else if (key) {
            this.filter[key] = "";
          }
          this.checkBackendLink();
        } else {
          this.clearFilter();
        }
      },
      onSwitchChanged(key: string, switchOn: boolean): void {
        if (switchOn) {
          this.addNewSearchParams(key, switchOn.toString());
        } else {
          this.removeNewSearchParams(key);
        }
      },
      onListCollapsibleChange(key: string, value: string[]): void {
        this.addNewSearchParams(key, value);
      },
      onListToggleChange(key: string, value: string): void {
        // Clear if user set dates
        this.filter['from'] = "";
        this.filter['to'] = "";

        this.addNewSearchParams(key, value);
      },
      onDateTimeChange(key: string, value: string): void {
        // Clear if user set date_preset
        this.filter['date_preset'] = "";
        this.addNewSearchParams(key, value);
      },
      onSearchChange(key: string, value: string): void {
        this.addNewSearchParams(key, value, false);
      },
      onSearchSubmit(): void {
        this.checkBackendLink();
      },
      onSearchClear(key: string): void {
        this.removeNewSearchParams(key);
      },
      addNewSearchParams(key: string, value: string | string[], fetchToBackend: boolean = true): void {
        if (key) {
          this.filter[key] = value;
          this.filterChanged = true;
        }

        if (fetchToBackend) {
          this.checkBackendLink();
        }
      },
      removeNewSearchParams(key: string): void {
        if (key) {
          this.filter[key] = null;
          this.filterChanged = true;
        }

        this.checkBackendLink();
      },
      getTitle(definition: SearchDefinition, enforce: boolean = false): string {
        if (definition && (definition.type !== this.searchDefinitionTypes.switch || enforce)) {
          if (definition.labelKey) {
            return this.$tc(`text.${definition.labelKey}`, 0, { fallback: definition.label }).toString();
          } else if (definition.label) {
            return definition.label;
          }
        }
        return '';
      },
      getSubTitle(definition: SearchDefinition): string {
        if (definition) {
          return this.getTitle(definition, true);
        }

        return '';
      },
      isRegistered(definitionType: SearchDefinitionType): boolean {
        return Object.values(this.searchDefinitionTypes).includes(definitionType);
      },
      isValidCriteria(criterion: SearchCriterion): boolean {
        return criterion && (criterion.definition.dataRemoteSearch || !criterion.definition.withData || (criterion.data && criterion.data.length > 0));
      },
      emitProcessing(processing: boolean): void {
        this.processing = processing;
        this.$emit('processing', this.processing);
      },
      checkInCountBadge(key: string): boolean {
        if (this.searchCriteria && this.searchCriteria.criteria && this.searchCriteria.criteria.length > 0) {
          const matchedDefinition: SearchCriterion | undefined = this.searchCriteria.criteria.find(
            (definitions: SearchCriterion) => definitions.definition && definitions.definition.key === key);
          if (matchedDefinition) {
            return matchedDefinition?.definition?.inCountBadge;
          }
        }

        return false;
      },
      convertArrayValueToString(itemIds: string[]): string {
        return itemIds.join(",");
      },
      generateFilterParams(): NewFilterParams {
        let newFilterParams: string = "";
        let values: string = "";
        let numberOfHasValueFilter: number = 0;

        Object.keys(this.filter).forEach((key) => {
          if (this.filter[key] && this.filter[key].length > 0) {
            if (newFilterParams.indexOf("?") > -1) {
              newFilterParams += "&";
            } else {
              newFilterParams += "?";
            }

            if (Array.isArray(this.filter[key])) { // string[]
              values = this.convertArrayValueToString(this.filter[key]);
            } else { // string
              values = this.filter[key];
            }

            newFilterParams += `${key}=${values}`;

            if (this.checkInCountBadge(key)) {
              numberOfHasValueFilter += 1;
            }
          }
        });

        let newFilterParamsResult: NewFilterParams = new NewFilterParams();
        newFilterParamsResult.params = newFilterParams;
        newFilterParamsResult.filters = numberOfHasValueFilter;

        return newFilterParamsResult;
      },
      saveLastViewInStore(parameters: string = ""): void {
        const filterAttributes: FilterAttributes = new FilterAttributes(null, parameters);
        this.$store.dispatch('updateFilterAttributes', filterAttributes);
      },
      fetchSearchCriteria(link: NavigationLink | undefined): void {
        if (this.processing) {
          return;
        }

        this.error = false;
        this.emitProcessing(true);

        let filterParams: string = this.prepareFilterParams();

        BackendIntegrationService.fetchSearchCriteria(link, filterParams)
          .then((filteredSubmissions: SearchCriteria) => {
            this.searchCriteria.criteria = filteredSubmissions.criteria;
            this.searchCriteria.hits = filteredSubmissions.hits;
            this.searchCriteria.results = filteredSubmissions.results;

            this.$emit('criteria-change', filteredSubmissions);
          }).catch((error: BackendError) => {
            console.log("Error fetch Search criteria: ", error);
            this.clearSearchCriteria();
            this.error = true;
          }).finally(() => {
            this.emitProcessing(false);
            this.afterFetchSearchCriteria(filterParams);
          });
      },
      afterFetchSearchCriteria(filterParams: string = ""): void {
        this.filterChanged = false;
        if (this.isPredefined) {
          this.prepareFilterParams(true);
          this.isPredefined = false;
        }

        if (this.searchCriteria && this.searchCriteria.hits && this.searchCriteria.hits > 0) { // Only when the search has results
          this.saveLastViewInStore(filterParams);
        }
      },
      prepareFilterParams(onlyInCount: boolean = false): string {
        const newFilterParams: NewFilterParams = this.generateFilterParams();
        this.inCountBadge = newFilterParams.filters;

        let params: string = '';
        if (!onlyInCount) {
          this.refreshUrlWithParams(newFilterParams.params);
          if (newFilterParams.params.length > 0) {
            params = newFilterParams.params;
          }

          this.$emit('has-filter-params', params.length > 0);
        }

        return params;
      },
      getItemFilterLink(): NavigationLink | undefined {
        // TODO if we migrate JSP to Vue, and login call has the backendLinks,
        // TODO the type conversion
        // this.backendLinks.getLink(itemFilter);

        const itemFilter: string = "item_filter";
        let link: NavigationLink | undefined;
        if (this.backendLinks && this.backendLinks.links.length > 0) {
          link = this.backendLinks.links.find((link: NavigationLink) => link.rel === itemFilter);
        }
        return link;
      },
      checkForStatusUpdate(): void {
        if (!this.processing) {
          // TODO if we migrate JSP to Vue, and login call has the backendLinks,
          // we can update statusUpdate and use the backend link
          // for now, use the hardcoded one in BackendIntegrationService
          this.fetchSearchCriteria(undefined);
        }
      },
      refreshUrlWithParams(params: string): void {
        if (!this.isPredefined && (this.lastFetchedParams !== params)) {
          this.$router.push(params); // For submissions map
          this.lastFetchedParams = params;
        } else if (!this.lastFetchedParams) {
          this.lastFetchedParams = params;
        }
      },
      setPredefinedParamsFromURL(): void {
        console.log("Set predefined params!");

        Object.keys(this.$route.query).forEach((key: string) => {
          if (this.$route.query[key]) {
            if (key !== "sort") {
              this.filter[key] = this.$route.query[key].split(",");
            }
          }
        });

        // For the bulk actions
        this.isPredefined = true;
      },
      checkBackendLink(setPredefined: boolean = false): void {
        if (setPredefined) {
          this.setPredefinedParamsFromURL();
        }

        const itemFilterLink: NavigationLink | undefined = this.getItemFilterLink();
        if (itemFilterLink) {
          this.fetchSearchCriteria(itemFilterLink);
        } else {
          this.checkForStatusUpdate();
        }
      }
    },
    mounted(): void {
      // This is for the view tags to apply latest params
      this.$nextTick(() => {
        this.checkBackendLink(true);
      });
    },
    i18n: {
      messages: messages,
      sharedMessages: sharedMessages
    }
  });
