import { ReportDestination } from "@/main/webapp/vue/model/api/report/ReportDestination";

export class DownloadType {
  titleText: string;
  confirmText: string;
  generatingText: string;
  uploadingText: string;
  readyText: string;
  destination: string;

  constructor(titleText: string, confirmText: string, generatingText: string, uploadingText: string, readyText: string,
              destination: string) {
    this.titleText = titleText;
    this.confirmText = confirmText;
    this.generatingText = generatingText;
    this.uploadingText = uploadingText;
    this.readyText = readyText;
    this.destination = destination;
  }
}

export default class DownloadTypeFactory {
  CONTENT_PACKAGE(): DownloadType {
    return new DownloadType('dropdown.download.zip', 'modal.content.confirm.content-package',
      'details.generating', 'details.uploading', 'details.ready',
      ReportDestination.SUBSCRIBE_TO_CONTENT_PACKAGE);
  }

  CONTENT_REPORT(): DownloadType {
    return new DownloadType('dropdown.reports.content', 'modal.content.confirm.content-report',
      'details.generating', 'details.uploading', 'details.ready',
      ReportDestination.SUBSCRIBE_TO_CONTENT_REPORT);
  }

  RAW_DATA_REPORT(): DownloadType {
    return new DownloadType('dropdown.reports.raw-data', 'modal.content.confirm.raw-data-report',
      'details.generating', 'details.uploading', 'details.ready',
      ReportDestination.SUBSCRIBE_TO_RAW_DATA_REPORT);
  }

  CHECK_IN_REPORT(): DownloadType {
    return new DownloadType('dropdown.reports.check-in', 'modal.content.confirm.check-in-report',
        'details.generating', 'details.uploading', 'details.ready',
        ReportDestination.SUBSCRIBE_TO_CHECK_IN_REPORT);
  }

  FRAUD_DATA_REPORT(): DownloadType {
    return new DownloadType('dropdown.reports.fraud-data', 'modal.content.confirm.fraud-data-report',
      'details.generating', 'details.uploading', 'details.ready',
      ReportDestination.SUBSCRIBE_TO_FRAUD_DATA_REPORT);
  }

  SHOP_EXPORT(): DownloadType {
    return new DownloadType('dropdown.reports.shop-export', 'modal.content.confirm.shop-export',
        'details.generating', 'details.uploading', 'details.ready',
        ReportDestination.SUBSCRIBE_TO_SHOP_EXPORT);
  }
}
