import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";
import { ProjectContainer } from "./ProjectContainer";
import { Distance } from "./Distance";
import { GeoLocation } from "./GeoLocation";

@JsonObject('Shop')
export class Shop extends NamedEntity {

  @JsonProperty('externalId', String, true)
  externalId?: string = undefined;

  @JsonProperty('annotation', String, true)
  annotation?: string = undefined;

  @JsonProperty('location', GeoLocation, true)
  location?: GeoLocation = undefined;

  @JsonProperty('distance', Distance, true)
  distance?: Distance = undefined;

  @JsonProperty('projects', ProjectContainer, true)
  projects?: ProjectContainer = undefined;

}
