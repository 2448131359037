import { JsonField } from '@znapio/znapson';

export default class IframeAttributes {
  constructor(
    @JsonField('allowfullscreen', Boolean, true)
    public allowfullscreen?: Boolean,
    @JsonField('allowpaymentrequest', Boolean, true)
    public allowpaymentrequest?: Boolean,
    @JsonField('loading', String, true)
    public loading?: String,
    @JsonField('name', String, true)
    public name?: String,
    @JsonField('referrerpolicy', String, true)
    public referrerpolicy?: String,
    @JsonField('sandbox', String, true)
    public sandbox?: String,
    @JsonField('height', Number, true)
    public height?: Number,
    @JsonField('width', Number, true)
    public width?: Number) {}
}
