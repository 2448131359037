
  import Vue, { PropType } from 'vue';

  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';

  import { ReportStatus } from "@/main/webapp/vue/model/api/report/ReportStatus";
  import { DownloadType } from "@/main/webapp/vue/model/downloadType";

  import messages from './messages.json';

  const Status = {
    IDLE: ReportStatus.IDLE,
    SUBSCRIBING: ReportStatus.SUBSCRIBING,
    GENERATING: ReportStatus.GENERATING,
    FINISHED: ReportStatus.FINISHED
  };

  export default Vue.extend({
    components: {
      loadingIcon
    },
    props: {
      listViewSelectedSubmissionIds: {
        type: Array as PropType<Number[]>,
        required: false
      },
      componentDownloadType: {
        type: Object as PropType<DownloadType>,
        required: true
      },
      reportStatus: {
        type: String as PropType<ReportStatus>,
        required: true
      },
      itemCount: {
        type: Number,
        default: 0
      },
      maxItemCondition: {
        type: Number,
        default: null
      },
      parentGenerating: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        Status
      };
    },
    methods: {
      confirm(): void {
        if ((this.listViewSelectedSubmissionIds && this.listViewSelectedSubmissionIds.length === 0) &&
            this.maxItemCondition !== null && this.itemCount > this.maxItemCondition) {
          let translation: string = this.$tc(this.componentDownloadType.confirmText, this.maxItemCondition).toString();
          this.displayModalWithText(translation);
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Reporting-container-report component - Emitting report request");
          }

          this.emitReportRequest();
        }
      },
      displayModalWithText(text: string) {
        this.$bvModal.msgBoxOk(text);
      },
      emitReportRequest() {
        this.$emit('request-report');
      }
    },
    i18n: {
      messages: messages
    }
  });
