import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('GeoLocation')
export class GeoLocation {

  @JsonProperty('latitude', Number, true)
  latitude?: number = undefined;

  @JsonProperty('longitude', Number, true)
  longitude?: number = undefined;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}
