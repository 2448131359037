import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('HierarchicalEntityMetadata')
export class HierarchicalEntityMetadata {

  @JsonProperty('ignoreRoot', Boolean, false)
  ignoreRoot: boolean;

  constructor(ignoreRoot: boolean) {
    this.ignoreRoot = ignoreRoot;
  }

}
