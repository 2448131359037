
  import Vue from 'vue';

  import dataTableLazy from '@/main/webapp/vue/components/ui/table/lazy/index.vue';
  import { FieldType, SortDirection } from '@/main/webapp/vue/components/ui/table/data/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";

  export enum FieldKey {
    CHAIN_NAME = 'chainHierarchy',
    SHOP_COUNT = 'shopCount'
  }

  export default Vue.extend({
    components: {
      dataTableLazy
    },
    data() {
      return {
        sortBy: FieldKey.CHAIN_NAME as FieldKey,
        sortDesc: false as boolean,
        fields: [
          {
            key: FieldKey.CHAIN_NAME,
            label: FieldKey.CHAIN_NAME,
            type: FieldType.TEXT,
            sortable: false,
            sortDirection: SortDirection.ASC,
            sortKey: FieldKey.CHAIN_NAME
          },
          {
            key: FieldKey.SHOP_COUNT,
            label: FieldKey.SHOP_COUNT,
            type: FieldType.TEXT,
            sortable: false,
            sortDirection: SortDirection.ASC,
            sortKey: FieldKey.SHOP_COUNT
          }
        ],
        totalChainElement: {
          chainHierarchy: "Total",
          shopCount: 0
        },
        totalChainElementAdded: false
      };
    },
    computed: {
      chainsNavigationLink(): NavigationLink {
        return NavigationLink.weblink(BackendIntegrationService.CHAINS_WITH_SHOP_COUNTS);
      }
    },
    methods: {
      loadedChains(chains: any[]): void {
        chains.forEach(chain => { this.totalChainElement.shopCount += chain.shopCount; });
        if (!this.totalChainElementAdded) {
          chains.unshift(this.totalChainElement);
          this.totalChainElementAdded = true;
        }
      }
    }
  });
