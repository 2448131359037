import VueI18n, { Locale, Path } from 'vue-i18n';
import store from '../../store';
import Vue from "vue";

let translated:boolean = true;

// TODO: Set the default from backend
const createI18n = (messages: {}) => {
  return new VueI18n({
    locale: store.state.i18n.locale ? store.state.i18n.locale : 'en',
    fallbackLocale: { // Localazy uses some aliases, that we need to map here:
      'no': ['nb'],
      'zh': ['zh-Hant'],
      'default': ['en']
    },
    silentFallbackWarn: true, // can generate hundreds of warnings in certain cases considerably slowing down the application locally
    messages: messages,
    missing: (locale: Locale, key: Path, vm: Vue, values: object[]) => { // Fallback message with {'fallback': 'message'}
      const fallbackKey: string = "fallback";

      if (values && values[fallbackKey]) {
        return values[fallbackKey];
      } else if (values[0] && values[0][fallbackKey]) {
        return values[0][fallbackKey];
      }

      // return key; // if not return, return key as default;
    }
  });
};

const setI18nLanguage = (i18n: VueI18n, translated: boolean, next: any) => {
  store.commit('updateLocale', i18n);

  translated = true;
  updateTranslated(translated, next);
};

const updateTranslated = (translated: boolean, next: any) => {
  store.commit('updateTranslated', translated);
  if (translated) {
    next();
  }
};

const initializeLanguage = (i18n: VueI18n, lang: any, next: any) => {
  if (lang && lang.length > 0) {
    i18n.locale = lang;
    setI18nLanguage(i18n, translated, next);
  }

  const localLocale = store.getters.getLocale;
  if (localLocale) {
    i18n.locale = localLocale.locale;
    next();
  } else {
    translated = false;
    updateTranslated(translated, next);
  }
};

export default {
  createI18n,
  initializeLanguage
};
