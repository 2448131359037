import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('Distance')
export class Distance {

  @JsonProperty('value', Number, true)
  value: number | undefined = undefined;

  @JsonProperty('unit', String, true)
  unit: string | undefined = undefined;

  @JsonProperty('label', String, true)
  label: string | undefined = undefined;

}
