
  import Vue from 'vue';

  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

  export default Vue.extend({
    mounted() {
      if (this.$router.currentRoute.path !== RoutePath.LEARNING) {
        this.$router.push({ path: RoutePath.LEARNING });
      }
    }
  });
