
  import Vue, { PropType } from 'vue';
  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";
  import { ItemContent, ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";

  export default Vue.extend({
    props: {
      content: {
        type: Object as PropType<ItemContent>,
        required: true
      },
      type: {
        type: String as PropType<ItemContentType>,
        default: ItemContentType.THUMBNAIL
      },
      itemIndex: {
        type: Number
      },
      load: {
        type: Boolean,
        default: true
      }
    },
    components: {
      loadingIcon
    },
    data() {
      return {
        mainProps: {
          center: true,
          fluidGrow: true,
          blank: true,
          blankColor: 'white'
        },
        loading: true as boolean,
        pageReady: false as boolean,
        types: {
          thumb: ItemContentType.THUMBNAIL,
          standard: ItemContentType.STANDARD
        }
      };
    },
    computed: {
      imgUrl(): string | null {
        if (this.content && this.content.nav) {
          let link = this.content.nav.getLink(ItemContentType.THUMBNAIL);
          if (link) {
            return link.href;
          }
        }
        return null;
      },
      computedWebLink(): string | undefined {
        if (this.content && this.content.nav) {
          const webLink: string = this.content.nav.webLink();
          let params: string = "";

          if (this.itemIndex !== null && this.itemIndex !== undefined) {
            params = this.prepareParams();
          }

          return `${webLink}${params}`;
        }
        return undefined;
      }
    },
    watch: {
      load(newLoad: boolean, oldLoad: boolean) {
        if (newLoad && !this.pageReady) {
          this.pageReady = true;
        }
      }
    },
    methods: {
      prepareParams(): string {
        var parameters = "";
        for (const urlSearchParam of new URLSearchParams(window.location.search)) {
          parameters += `&${urlSearchParam[0]}=${urlSearchParam[1]}`;
        }

        return `&iidx=${this.itemIndex}${parameters}`;
      }
    },
    mounted(): void {
      if (this.load) {
        this.pageReady = true;
      }
    }
  });
