import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";
import { Item } from "./Item";

@JsonObject('SubmissionContainer')
export class SubmissionContainer {

  @JsonProperty('list', [Item], true)
  list?: Item[] = [];

  @JsonProperty('total', Number, true)
  total?: number = 0;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = new NavigationLinks();

}
