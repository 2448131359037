import { JsonObject, JsonProperty } from "json2typescript";
import { Item } from "./Item";
import { Entity } from "./Entity";
import { HierarchicalEntity } from "./HierarchicalEntity";

@JsonObject('FeedArticleEntity')
export class FeedArticleEntity extends Entity {
  @JsonProperty('submission', Item, true)
  submission?: Item = undefined;

  @JsonProperty('children', [HierarchicalEntity], true)
  children?: HierarchicalEntity[] = undefined;
}
