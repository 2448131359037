
  import Vue from 'vue';

  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      icon: {
        type: String,
        default: 'remove-circle'
      },
      iconSize: {
        type: String,
        default: 'small'
      },
      color: {
        type: String,
        default: 'white'
      },
      text: {
        type: String
      },
      additionalClass: {
        type: String
      }
    },
    data() {
      return {
      };
    },
    methods: {

    }
  });
