import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('SearchCriteriaSelectedDataValue')
export class SearchCriteriaSelectedDataValue {

  @JsonProperty('definition', String)
  definition: string | undefined = undefined;

  @JsonProperty('selectedDataValues', Array)
  selectedDataValues: string[] | undefined = undefined;

}


