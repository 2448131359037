var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"two-columns",class:_vm.computedWidgetClass,style:(_vm.computedWidgetStyle)},[_c('div',{staticClass:"w-100 layout-column-wrapper",class:_vm.model.properties.columnStyle.alignment.vertical,style:(_vm.computedColumWrapper)},[_c('div',{staticClass:"left-column",class:{ 'section-compatible' : _vm.selectingWidgetCompatibleSection === _vm.leftColumn },style:(_vm.computedLeftColumnStyle)},[_c('div',{staticClass:"w-100 clearfix"},[_c('draggable-container',{attrs:{"container-id":_vm.leftColumn,"disabled":!_vm.editable,"show-placeholder":_vm.editable && (_vm.model.nestedContents.filter(
                                 (widget) => _vm.widgetInColumn(widget, _vm.leftColumn))).length === 0,"group":{
                                name: `${_vm.leftColumn} widgets`,
                                pull: true,
                                put() { // Only allow new widget or order change in same section
                                  return _vm.selectingWidgetCompatibleSection === _vm.leftColumn ||
                                         (_vm.selectingWidgetCompatibleSection === null && _vm.widgetFromSideBar);
                                }
                             }},on:{"widget-created":_vm.onWidgetCreated,"widget-selected":(widget) => _vm.onWidgetSelected(widget, _vm.leftColumn),"widget-ended":_vm.onWidgetEnded},model:{value:(_vm.model.nestedContents),callback:function ($$v) {_vm.$set(_vm.model, "nestedContents", $$v)},expression:"model.nestedContents"}},_vm._l((_vm.model.nestedContents),function(widget){return _c('div',{key:widget.id},[(_vm.widgetInColumn(widget, _vm.leftColumn))?_c('widget-component',_vm._g({attrs:{"widget":widget,"course":_vm.course,"activity":_vm.activity,"editing":_vm.editingWidget !== undefined && widget.id === _vm.editingWidget.id,"editing-widget":_vm.editingWidget,"editable":_vm.editable,"widget-from-side-bar":_vm.widgetFromSideBar,"is-child":true},on:{"delete":_vm.onDelete}},{..._vm.$listeners, delete: () => ''})):_vm._e()],1)}),0)],1)]),_c('div',{staticClass:"right-column",class:{ 'section-compatible' : _vm.selectingWidgetCompatibleSection === _vm.rightColumn },style:(_vm.computedRightColumnStyle)},[_c('div',{staticClass:"w-100 clearfix"},[_c('draggable-container',{attrs:{"container-id":_vm.rightColumn,"disabled":!_vm.editable,"show-placeholder":_vm.editable && (_vm.model.nestedContents.filter(
                                 (widget) => _vm.widgetInColumn(widget, _vm.rightColumn))).length === 0,"group":{
                                name: `${_vm.rightColumn} widgets`,
                                pull: true,
                                put() { // Only allow new widget or order change in same section
                                  return _vm.selectingWidgetCompatibleSection === _vm.rightColumn ||
                                         (_vm.selectingWidgetCompatibleSection === null && _vm.widgetFromSideBar);
                                }
                             }},on:{"widget-created":_vm.onWidgetCreated,"widget-selected":(widget) => _vm.onWidgetSelected(widget, _vm.rightColumn),"widget-ended":_vm.onWidgetEnded},model:{value:(_vm.model.nestedContents),callback:function ($$v) {_vm.$set(_vm.model, "nestedContents", $$v)},expression:"model.nestedContents"}},_vm._l((_vm.model.nestedContents),function(widget){return _c('div',{key:widget.id},[(_vm.widgetInColumn(widget, _vm.rightColumn))?_c('widget-component',_vm._g({attrs:{"widget":widget,"course":_vm.course,"activity":_vm.activity,"editing":_vm.editingWidget !== undefined && widget.id === _vm.editingWidget.id,"editing-widget":_vm.editingWidget,"editable":_vm.editable,"widget-from-side-bar":_vm.widgetFromSideBar,"is-child":true},on:{"delete":_vm.onDelete}},{..._vm.$listeners, delete: () => ''})):_vm._e()],1)}),0)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }