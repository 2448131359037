
  import Vue, { PropType } from 'vue';

  import { DateTime } from "@/main/webapp/vue/model/api/DateTime";

  import textSpan from '../../text/text-span/index.vue';
  import textDate from '../../text/text-date/index.vue';
  import messages from './messages.json';
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

  export default Vue.extend({
    components: {
      textSpan,
      textDate
    },
    props: {
      text: {
        type: String
      },
      date: {
        type: Object as PropType<DateTime>
      },
      author: {
        type: String
      },
      showMetadata: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      commentAuthor: function(): string {
        if (this.author !== null) {
          return this.author;
        } else {
          return this.$t('generic.unregistered-user').toString();
        }
      }
    },
    i18n: {
      messages: messages,
      sharedMessages: sharedMessages
    }
  });
