
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      floatRight: {
        type: Boolean,
        default: true
      },
      isChild: {
        type: Boolean,
        default: false
      }
    }
  });
