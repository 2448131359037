
  import Vue, { PropType } from 'vue';
  import { Chrome } from 'vue-color';
  import messages from '../messages.json';

  import formCard from '@/main/webapp/vue/components/ui/form/card/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';

  import TextStyleModel, {
    TEXT_ALIGNMENT,
    TEXT_SIZE
  } from '@/main/webapp/vue/components/ui/style/text/model/TextStyleModel';

  export default Vue.extend({
    name: 'TextStyle',
    props: {
      textStyle: {
        type: Object as PropType<TextStyleModel>,
        default: () => new TextStyleModel(undefined, undefined, undefined)
      },
      defaultStyle: { // Widget should set default style
        type: Object as PropType<TextStyleModel>, // default value is only for branding setting
        default: () => new TextStyleModel().default()
      },
      title: {
        type: String
      },
      enableTextSize: {
        type: Boolean,
        default: true
      },
      enableTextAlignment: {
        type: Boolean,
        default: true
      },
      enableTextColor: {
        type: Boolean,
        default: true
      },
      useCollapsibleContainer: {
        type: Boolean,
        default: true
      },
      alignContentsVertically: {
        type: Boolean,
        default: true
      },
      busy: {
        type: Boolean,
        default: false
      }
    },
    components: {
      formCard,
      collapsibleContainer,
      'chrome-picker': Chrome
    },
    computed: {
      component() {
        if (this.useCollapsibleContainer) {
          return 'collapsible-container';
        }
        return 'form-card';
      }
    },
    data() {
      return {
        alignmentType: {
          left: TEXT_ALIGNMENT.LEFT as TEXT_ALIGNMENT,
          center: TEXT_ALIGNMENT.CENTER as TEXT_ALIGNMENT,
          right: TEXT_ALIGNMENT.RIGHT as TEXT_ALIGNMENT,
          justify: TEXT_ALIGNMENT.JUSTIFY as TEXT_ALIGNMENT
        }
      };
    },
    methods: {
      pressed(alignmentType: TEXT_ALIGNMENT): boolean {
        if (this.textStyle.alignment) {
          return this.textStyle.alignment === alignmentType;
        }

        return this.defaultStyle.alignment === alignmentType;
      }
    },
    i18n: {
      messages: messages
    }
  });
