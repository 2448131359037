
  import Vue from 'vue';
  import { mapState } from "vuex";

  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import federatedLoginRegistration
    from '@/main/webapp/vue/components/admin/user-configurator/federated-login-management/federated-login-registration/index.vue';
  import { UserRegistryClients } from "@/main/webapp/vue/model/api/UserRegistryClients";
  import { UserRegistryClient } from "@/main/webapp/vue/model/api/UserRegistryClient";

  export default Vue.extend({
    components: {
      federatedLoginRegistration
    },
    data() {
      return {
        clients: [] as UserRegistryClient[]
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      loadClients(): void {
        BackendIntegrationService.fetchUserRegistryClients(this.userDetails.organization).then((clients: UserRegistryClients) => {
          this.clients = clients.list;
        }).catch((error: Error) => {
          notification.fail("Could not fetch user registry clients");
        });
      }
    },
    mounted(): void {
      this.loadClients();
    }
  });
