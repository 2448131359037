import { Any, JsonObject, JsonProperty } from "json2typescript";

import { Entity } from "@/main/webapp/vue/model/api/Entity";
import { ContentUnit } from "@/main/webapp/vue/model/api/learning/ContentUnit";
import { CourseBrandingStyle } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
import WidgetModel from "@/main/webapp/vue/model/learning/WidgetModel";
import { OrganizationData } from "@/main/webapp/vue/model/api/learning/OrganizationData";
import ActivityProperties from "@/main/webapp/vue/model/learning/ActivityProperties";
import CourseProperties from "@/main/webapp/vue/model/api/learning/CourseProperties";
import ActivityPublicModel from "@/main/webapp/vue/model/learning/ActivityPublicModel";
import Znapson from '@znapio/znapson';

@JsonObject('ActivityPublic')
export class ActivityPublic extends Entity {

  @JsonProperty('name', String, false)
  name: string = "";

  @JsonProperty('urlPath', String, true)
  urlPath?: string = undefined;

  @JsonProperty('contents', [ContentUnit], true)
  contents: ContentUnit[] = [];

  @JsonProperty('organizationData', OrganizationData, true)
  organizationData?: OrganizationData = undefined;

  @JsonProperty('defaultStyles', [CourseBrandingStyle], false)
  defaultStyles?: CourseBrandingStyle[] = [];

  @JsonProperty('properties', Any, true)
  properties?: any = undefined;

  @JsonProperty('courseProperties', Any, true)
  courseProperties?: any = undefined;

  @JsonProperty('baseUrl', String, true)
  baseUrl?: string = undefined;

  public static toActivityModel(activity: ActivityPublic): ActivityPublicModel {
    return new ActivityPublicModel(activity.id, activity.name, 0, "", activity.urlPath, true,
        Znapson.deserializeOptional(activity.properties, ActivityProperties),
        Znapson.deserializeOptional(activity.courseProperties, CourseProperties), [],
        Znapson.deserialize<WidgetModel, [WidgetModel]>(activity.contents, [WidgetModel]),
        activity.baseUrl);
  }
}
