import { JsonObject, JsonProperty } from "json2typescript";
import { Entity } from "./Entity";

@JsonObject('ColoredLabel')
export class ColoredLabel {
  @JsonProperty('text', String)
  text: string;

  @JsonProperty('color', String)
  color: string;

  constructor(text: string, color: string) {
    this.text = text;
    this.color = color;
  }
}
