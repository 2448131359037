import { JsonObject, JsonProperty } from "json2typescript";
import { User } from "./User";
import { UserSession } from "./UserSession";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('AuthenticationResult')
export class AuthenticationResult {

  @JsonProperty('user', User, false)
  user?: User = undefined;

  @JsonProperty('session', UserSession, false)
  session?: UserSession = undefined;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
