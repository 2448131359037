
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import YoutubeVideoWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/youtube-video/model/YoutubeVideoWidgetModel';

  export default Vue.extend({
    name: 'YoutubeVideoWidgetProperties',
    components: {
      textTitle,
      collapsibleContainer,
      genericStyle
    },
    props: {
      model: {
        type: Object as PropType<YoutubeVideoWidgetModel>,
        required: true
      }
    },
    i18n: {
      messages: messages
    }
  });
