
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      label: {
        type: String,
        required: true
      },
      href: {
        type: String,
        required: false
      },
      variant: {
        type: String,
        required: false
      },
      size: {
        type: String,
        required: false
      },
      block: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        isSubmitting: false as boolean
      };
    },
    methods: {
      onClick() {
        this.isSubmitting = true;
        this.$emit('clicked');

        setTimeout(() => {
          this.isSubmitting = false;
        }, 1000);
      }
    }
  });
