import SectionStyleModel from "@/main/webapp/vue/components/ui/style/section/model/SectionStyleModel";
import ButtonStyleModel from "@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel";
import TextStyleModel from "@/main/webapp/vue/components/ui/style/text/model/TextStyleModel";
import { CourseBrandingStyle, CourseBrandingStyleType } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
import CollapsibleStyleModel
  from "@/main/webapp/vue/components/ui/style/collapsible-button/model/CollapsibleStyleModel";

export class CourseBrandingStyles {

  constructor() {
  }

  defaultSectionStyle(): CourseBrandingStyle {
    let sectionBrandingStyle = new CourseBrandingStyle();
    sectionBrandingStyle.data = new SectionStyleModel().default();
    sectionBrandingStyle.updated = new Date().toUTCString();
    sectionBrandingStyle.type = CourseBrandingStyleType.SECTION;

    return sectionBrandingStyle;
  }

  defaultButtonStyle(): CourseBrandingStyle {
    let buttonBrandingStyle = new CourseBrandingStyle();
    buttonBrandingStyle.data = new ButtonStyleModel().default();
    buttonBrandingStyle.updated = new Date().toUTCString();
    buttonBrandingStyle.type = CourseBrandingStyleType.BUTTON;

    return buttonBrandingStyle;
  }

  defaultCollapsibleButtonStyle(): CourseBrandingStyle {
    let buttonBrandingStyle = new CourseBrandingStyle();
    buttonBrandingStyle.data = new CollapsibleStyleModel().default();
    buttonBrandingStyle.updated = new Date().toUTCString();
    buttonBrandingStyle.type = CourseBrandingStyleType.COLLAPSIBLE;

    return buttonBrandingStyle;
  }

  defaultTextStyle(): CourseBrandingStyle {
    let textBrandingStyle = new CourseBrandingStyle();
    textBrandingStyle.data = new TextStyleModel().default();
    textBrandingStyle.updated = new Date().toUTCString();
    textBrandingStyle.type = CourseBrandingStyleType.TEXT;

    return textBrandingStyle;
  }

  default(): CourseBrandingStyle[] {
    let courseBrandingStyles: CourseBrandingStyle[] = [];
    courseBrandingStyles.push(this.defaultSectionStyle());
    courseBrandingStyles.push(this.defaultButtonStyle());
    courseBrandingStyles.push(this.defaultCollapsibleButtonStyle());
    courseBrandingStyles.push(this.defaultTextStyle());

    return courseBrandingStyles;
  }
}
