
  import Vue from "vue";
  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";

  export default Vue.extend({
    props: {
      placeholder: {
        type: String,
        default: ""
      },
      autocomplete: {
        type: String,
        default: "off"
      },
      storedSearchTerm: {
        type: String,
        default: null
      },
      focus: {
        type: Boolean,
        default: false
      },
      enableClear: {
        type: Boolean,
        default: false
      },
      clear: {
        type: Boolean,
        default: false
      }
    },
    components: {
      glyphiconSymbol
    },
    data() {
      return {
        searchTerm: this.storedSearchTerm as string
      };
    },
    watch: {
      focus(newFocus: boolean, oldFocus: boolean): void {
        this.focusSearchField();
      },
      clear(newClear: boolean, oldClear): void {
        if (newClear) {
          this.searchTerm = '';
        }
      }
    },
    methods: {
      onSubmit(e: SubmitEvent): void {
        e.preventDefault();
        this.focusSearchField(false);
        this.$emit('submit', this.searchTerm);
      },
      onBlur(): void {
        this.$emit('blur', this.searchTerm);
      },
      onUpdate(): void {
        this.$emit('update', this.searchTerm);
      },
      onClear(): void {
        this.$emit('clear');
      },
      clearSearchTerm(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Clear search terms");
        }
        this.searchTerm = "";
        if (this.enableClear) {
          this.onClear();
        } else {
          this.onUpdate();
        }

        this.focusSearchField();
      },
      focusSearchField(focus: boolean = true): void {
        let searchField: Vue | Element | (Vue | Element)[] | undefined = this.$refs.searchField;
        if (searchField) {
          setTimeout(() => {
            if (focus) {
              (searchField as HTMLInputElement).focus();
            } else {
              (searchField as HTMLInputElement).blur();
            }
          }, 100);
        }
      }
    },
    created(): void {
      if (this.focus) {
        this.focusSearchField();
      }
    }
  });
