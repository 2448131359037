
  import Vue, { PropType } from 'vue';
  import { SearchData } from "@/main/webapp/vue/model/api/SearchData";

  export default Vue.extend({
    name: "filter-list-collapsible-element",
    props: {
      item: {
        type: Object as PropType<SearchData>,
        default: null
      },
      selectedItemIds: {
        type: Array as PropType<String[]>,
        default: () => []
      },
      root: {
        type: Boolean,
        default: false
      },
      showIndicator: {
        type: Boolean,
        default: false
      }
    },
    components: {
      filterListCollapsible: () => import("@/main/webapp/vue/components/ui/filter/filter-list-collapsible/index.vue")
    },
    data() {
      return {
        selected: false as boolean,
        indeterminate: false as boolean,
        showSubItems: false as boolean
      };
    },
    watch: {
      selectedItemIds(newIds: string[], oldIds: string[]): void {
        this.checkStatus();
      }
    },
    methods: {
      isSelected(itemId: string): boolean {
        return this.selectedItemIds.some((selectedItemId: string) => selectedItemId === itemId);
      },
      checkStatus(): void {
        let selected = false;

        // Check selected
        if (this.item && this.item.id) {
          selected = this.isSelected(this.item.id);
        }

        // Check intemediate with subData
        if (selected) {
          if (this.item && this.item.data && this.item.data.length > 0) {
            const selectedSubItems: SearchData[] = this.item.data.filter((subItem: SearchData) => subItem.id && this.isSelected(subItem.id));
            this.indeterminate = selectedSubItems.length > 0;
          }
        } else {
          this.indeterminate = false;
        }

        // Somehow need delay for intemediate & selected
        setTimeout(() => {
          this.selected = selected;
        }, 300);
      },
      onChange(selected: boolean, item: SearchData): void {
        if (selected) {
          this.$emit('item-selected', item);
        } else {
          this.$emit('item-unselected', item);
        }
      },
      onItemChange(selected: boolean): void {
        this.onChange(selected, this.item);
      },
      itemSelected(item: SearchData): void {
        this.selected = !this.selected;
        this.onChange(this.selected, item);
      },
      subItemsSelected(subItem: SearchData): void {
        this.onChange(true, subItem);
      },
      subItemsUnselected(subItem: SearchData): void {
        this.onChange(false, subItem);
      },
      onItemClicked(item: SearchData): void {
        if (item.data && item.data.length > 0) {
          this.showSubItems = !this.showSubItems;
        } else {
          this.itemSelected(item);
        }
      }
    },
    created(): void {
      this.checkStatus();
      if (this.root && this.item && this.item.data && this.item.data.length > 0) {
        this.$emit('need-indicator');
      }
    }
  });
