export enum ReportDestination {
  REPORT_REQUEST = "/znapio/report",

  // queue is a required reserved keyword
  SUBSCRIBE_TO_CONTENT_PACKAGE = "/user/queue/content-package",
  SUBSCRIBE_TO_CONTENT_REPORT = "/user/queue/content-report",
  SUBSCRIBE_TO_RAW_DATA_REPORT = "/user/queue/raw-data-report",
  SUBSCRIBE_TO_CHECK_IN_REPORT = "/user/queue/check-in-report",
  SUBSCRIBE_TO_FRAUD_DATA_REPORT = "/user/queue/fraud-data-report",
  SUBSCRIBE_TO_SHOP_EXPORT = "/user/queue/shop-export"
}
