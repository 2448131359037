
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import contentFileUpload from '@/main/webapp/vue/components/ui/content/file-upload/index.vue';
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';
  import iconInput from '@/main/webapp/vue/components/ui/style/icon/container/icon-input/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import FileAttachmentWidgetModel, { AttachedFile } from '@/main/webapp/vue/components/learning/widget/widgets/file-attachment/model/FileAttachmentWidgetModel';
  import { Entity } from "@/main/webapp/vue/model/api/Entity";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";

  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'AttachmentFileWidgetProperties',
    components: {
      contentFileUpload,
      textTitle,
      collapsibleContainer,
      genericStyle,
      iconInput
    },
    props: {
      model: {
        type: Object as PropType<FileAttachmentWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<Course>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      }
    },
    data: function() {
      return {
        holderEntity: {} as Entity,
        itemContentEntity: {} as ItemContentEntity
      };
    },
    computed: {
      persisted(): boolean {
        return this.model.isPersisted() as boolean;
      }
    },
    watch: {
      persisted(newValue: boolean, oldValue: boolean) {
        if (newValue && newValue !== oldValue) {
          this.loadFileContentDefinition();
        }
      }
    },
    methods: {
      loadFileContentDefinition(): void {
        this.holderEntity.id = this.model.id as number;
        LearningIntegrationService.fetchCourseActivityContentUnitAttachments(this.course, this.activity.id as number, this.model.id as number)
          .then((response: ItemContentEntityContainer) => {
            if (response.list && response.list.length > 0) {
              this.itemContentEntity = response.list[0];
              this.model.data.entityType = this.itemContentEntity.type;
              this.model.data.entityItemId = this.itemContentEntity.itemId;

              let link: NavigationLink | undefined =
                NavigationLinks.getLinkFromLinks(NavigationLinkType.PAGE_SELF, this.itemContentEntity.links);

              if (link && this.model.data.file && link.href !== this.model.data.file.src) {
                this.model.data.file.src = link.href;
              }

              this.$emit('edited', this.model, true);
            } else {
              this.model.data.file = undefined;
            }
          }).catch((e: Error) => {
            notification.fail(this.$t('text.error'));
          });
      },
      contentUpdated(backendResponse: ItemContentEntity, file: File): void {
        let contentLink: NavigationLink | undefined =
          NavigationLinks.getLinkFromNavigation(NavigationLinkType.PAGE_SELF, backendResponse);
        if (contentLink) {
          let attachementFile: AttachedFile = new AttachedFile();
          attachementFile.src = contentLink.href;
          attachementFile.lastUpdated = new Date().getTime();
          attachementFile.name = file.name;
          attachementFile.originalName = file.name;
          attachementFile.size = file.size;

          this.model.data.file = attachementFile;
        }

        this.$emit('edited', this.model, true);
      }
    },
    mounted() {
      this.holderEntity = new Entity();
      this.holderEntity.id = this.model.id as number;

      if (this.model.data.entityType) {
        this.itemContentEntity = new ItemContentEntity();
        this.itemContentEntity.type = this.model.data.entityType;
        this.itemContentEntity.itemId = this.model.data.entityItemId;
      }

      if (this.model.isPersisted() && !this.model.data.file) {
        this.loadFileContentDefinition();
      }
    },
    i18n: {
      messages: messages
    }
  });
