
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';
  import AlignmentStyleModel, { ALIGNMENT_HORIZONTAL, ALIGNMENT_VERTICAL } from "@/main/webapp/vue/components/ui/style/alignment/model/AlignmentStyleModel";

  export default Vue.extend({
    name: 'AlignmentStyle',
    props: {
      alignmentStyle: {
        type: Object as PropType<AlignmentStyleModel>,
        default: () => new AlignmentStyleModel()
      },
      defaultStyle: { // Widget should set default style
        type: Object as PropType<AlignmentStyleModel>, // default value is only for branding setting
        default: () => new AlignmentStyleModel()
      },
      enableVerticalAlignment: {
        type: Boolean,
        default: true
      },
      enableHorizontalAlignment: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        alignmentType: {
          vertical: {
            top: ALIGNMENT_VERTICAL.TOP as ALIGNMENT_VERTICAL,
            center: ALIGNMENT_VERTICAL.CENTER as ALIGNMENT_VERTICAL,
            bottom: ALIGNMENT_VERTICAL.BOTTOM as ALIGNMENT_VERTICAL
          },
          horizontal: {
            left: ALIGNMENT_HORIZONTAL.LEFT as ALIGNMENT_HORIZONTAL,
            center: ALIGNMENT_HORIZONTAL.CENTER as ALIGNMENT_HORIZONTAL,
            right: ALIGNMENT_HORIZONTAL.RIGHT as ALIGNMENT_HORIZONTAL,
            justify: ALIGNMENT_HORIZONTAL.JUSTIFY as ALIGNMENT_HORIZONTAL
          }
        }
      };
    },
    methods: {
      checkVerticalPressed(alignmentType: ALIGNMENT_VERTICAL): Boolean {
        if (this.alignmentStyle.vertical) {
          return this.alignmentStyle.vertical === alignmentType;
        }

        return this.defaultStyle.vertical === alignmentType;
      },
      setVerticalAlignment(alignmentType: ALIGNMENT_VERTICAL): void {
        this.alignmentStyle.vertical = alignmentType;
      },
      checkHorizontalPressed(alignmentType: ALIGNMENT_HORIZONTAL): Boolean {
        if (this.alignmentStyle.horizontal) {
          return this.alignmentStyle.horizontal === alignmentType;
        }

        return this.defaultStyle.horizontal === alignmentType;
      },
      setHorizontalAlignment(alignmentType: ALIGNMENT_HORIZONTAL): void {
        this.alignmentStyle.horizontal = alignmentType;
      }
    },
    i18n: {
      messages: messages
    }
  });
