
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';
  import formCard from '@/main/webapp/vue/components/ui/form/card/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";

  export default Vue.extend({
    name: 'GenericStyle',
    props: {
      genericStyle: {
        type: Object as PropType<GenericStyleWidgetProperties>,
        required: true
      },
      defaultStyle: {
        type: Object as PropType<GenericStyleWidgetProperties>,
        required: true
      },
      title: {
        type: String
      },
      useCollapsibleContainer: {
        type: Boolean,
        default: true
      }
    },
    components: {
      formCard,
      collapsibleContainer
    },
    computed: {
      component() {
        if (this.useCollapsibleContainer) {
          return 'collapsible-container';
        }
        return 'form-card';
      }
    },
    i18n: {
      messages: messages
    }
  });
