import { JsonObject, JsonProperty } from "json2typescript";

import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

import { CourseBrandingCss } from "@/main/webapp/vue/model/api/learning/CourseBrandingCss";
import { CourseBrandingStyle } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
import Znapson from '@znapio/znapson';

@JsonObject('CourseBranding')
export class CourseBranding {

  @JsonProperty('cssStyle', CourseBrandingCss, true)
  cssStyle?: CourseBrandingCss = undefined;

  @JsonProperty('defaultStyles', [CourseBrandingStyle], false)
  defaultStyles?: CourseBrandingStyle[] = [];

  @JsonProperty('images', ItemContentEntityContainer, true)
  images?: ItemContentEntityContainer = undefined;

  static deserializeDefaultStyle(defaultStyles: CourseBrandingStyle[]): CourseBrandingStyle[] {
    return defaultStyles.map(defaultStyle => {
      if (defaultStyle.updated) {
        defaultStyle.data = Znapson.deserialize({ type: defaultStyle.type, ...defaultStyle.data }, StyleModel);
      }

      return defaultStyle;
    });
  }
}
