
  import Vue, { PropType } from 'vue';
  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";
  import { AttributeEntity, AttributeEntityValue } from "@/main/webapp/vue/model/api/AttributeEntity";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
  import { ContentEntryAttributeEntity } from "@/main/webapp/vue/model/api/ContentEntryAttributeEntity";
  import {
    StringRepresentableAttributeEntity,
    StringRepresentableAttributeEntityValue
  } from "@/main/webapp/vue/model/api/StringRepresentableAttributeEntity";

  export default Vue.extend({
    name: 'submissionMetadataAttribute',
    components: {
      glyphiconSymbol
    },
    props: {
      attribute: {
        type: Object as PropType<AttributeEntity<AttributeEntityValue>>,
        required: true
      }
    },
    methods: {
      isContentEntryAttribute(attribute: AttributeEntity<AttributeEntityValue>): boolean {
        return attribute instanceof ContentEntryAttributeEntity;
      },
      handlePictureClick(event: Event, value: AttributeEntityValue): void {
        if (value instanceof ItemContent) {
          if (event && event.target) {
            event.target.dispatchEvent(new CustomEvent('js-picture-click', {
              detail: value,
              bubbles: true
            }));
          }
          this.$emit('picture-click', value);
        }
      },
      defaultAttributeValue(value: AttributeEntityValue): string {
        if (this.attribute instanceof StringRepresentableAttributeEntity &&
          value instanceof StringRepresentableAttributeEntityValue) {
          return value.valueString;
        }
        return '';
      }
    }
  });
