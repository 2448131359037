
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import LinkExternalWidgetModel, { LinkExternalProperties } from
  '@/main/webapp/vue/components/learning/widget/widgets/link-external/model/LinkExternalWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'LinkExternalWidget',
    props: {
      model: {
        type: Object as PropType<LinkExternalWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      },
      draggable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        editingText: false as boolean,
        temporaryText: this.model.data.text as string
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): LinkExternalProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedLinkStyle(): Object {
        return computedStyleUtil.getStyles(
          ['color', 'alignment', 'size'],
          this.model.properties.activeState,
          this.computedDefaultStyle.activeState);
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      startEditingText(): void {
        if (!this.editingText) {
          this.editingText = true;
          (this.$refs.linkInput as HTMLInputElement).focus();
        }

        this.temporaryText = this.model.data.text;
        this.$emit('edit', this.model);
      },
      cancelEditingText(): void {
        if (this.editingText) {
          this.editingText = false;
        }

        this.model.data.text = this.temporaryText;
      },
      stopEditingText(): void {
        if (this.editingText) {
          this.editingText = false;
        }

        this.temporaryText = this.model.data.text;
      }
    },
    i18n: {
      messages: messages
    }
  });
