
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WidgetThumbnail',
    props: {
      icon: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    }
  });
