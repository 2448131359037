import { JsonObject, JsonProperty } from "json2typescript";
import { Entity } from "@/main/webapp/vue/model/api/Entity";

@JsonObject('EntityRegistryClientMapping')
export class EntityRegistryClientMapping extends Entity {

  @JsonProperty('field', String, false)
  field: string = "";

  @JsonProperty('label', String, false)
  label: string = "";

  @JsonProperty('generic', Boolean, false)
  generic: boolean = false;

}
