import { JsonObject, JsonProperty } from "json2typescript";
import { DateTime } from "./DateTime";
import { FeedText } from "./FeedText";
import { Metadata } from "./Metadata";
import { FeedArticleEntity } from "./FeedArticleEntity";

@JsonObject('FeedArticle')
export class FeedArticle extends Metadata {
  @JsonProperty('created', DateTime)
  created?: DateTime = undefined;

  @JsonProperty('entity', FeedArticleEntity)
  entity?: FeedArticleEntity = undefined;

  @JsonProperty('textPlaceholders', FeedText)
  textPlaceholders?: FeedText = undefined;
}
