
  import Vue, { PropType } from 'vue';
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';
  import textCancel from "@/main/webapp/vue/components/ui/text/text-cancel/index.vue";

  import TimeUtil from "@/main/webapp/vue/util/timeUtil";
  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
  import { SearchCriterion } from "@/main/webapp/vue/model/api/SearchCriterion";
  import { SearchData } from "@/main/webapp/vue/model/api/SearchData";
  import { SearchDefinition } from "@/main/webapp/vue/model/api/SearchDefinition";

  export class CriterionSelector {
    definition: SearchDefinition;
    data: SearchData;
    parentIds: string[];

    constructor(definition: SearchDefinition, value: SearchData, parentIds: string[] = []) {
      this.definition = definition;
      this.data = value;
      this.parentIds = parentIds;
    }
  }

  export default Vue.extend({
    props: {
      searchCriteria: {
        type: Object as PropType<SearchCriteria>,
        default: () => null
      }
    },
    components: {
      textCancel
    },
    watch: {
      searchCriteria(newSearch: SearchCriteria, oldSearch: SearchCriteria) {
        this.getSelectedSearchData(newSearch);
      }
    },
    data() {
      return {
        show: false as boolean,
        criterionSelectors: [] as CriterionSelector[],
        exceptionKeys: ['pic', 'f', 'last'] as string[]
      };
    },
    methods: {
      emitSelectedToRemove(criterion: CriterionSelector | null = null): void {
        if (criterion) {
          this.$emit('remove-criterion', criterion);
        } else {
          this.$emit('remove-criterion', null);
        }
      },
      clearSelectedSearchData(): void {
        this.criterionSelectors = [];
      },
      addSelectedSearchData(definition: SearchDefinition, searchData: SearchData, parentIds: string[] = []): void {
        this.criterionSelectors.push(new CriterionSelector(definition, searchData, parentIds));
      },
      handlingHierarchicalEntities(definition: SearchDefinition, searchData: SearchData, selectedValues: string[] = [], parentsIds: string[] = []): void {
        selectedValues.forEach((selectedValue: string) => {
          let matchedSearchData: SearchData | undefined = this.findSelectedData(searchData.data, selectedValue);
          if (matchedSearchData) {
            parentsIds.push(searchData.id ? searchData.id : "-1");
            if (this.hasLeafItems(matchedSearchData.data)) {
              this.handlingHierarchicalEntities(definition, matchedSearchData, selectedValues, parentsIds);
            } else {
              this.addSelectedSearchData(definition, matchedSearchData, parentsIds);
            }
          }
        });
      },
      findSelectedData(searchData: SearchData[] = [], id: string): SearchData | undefined {
        return searchData.find((searchData: SearchData) => searchData.id === id);
      },
      findSelectedValue(definition: SearchDefinition, selectedValue: string): SearchData | undefined {
        let searchData: SearchData = new SearchData();
        let label: string | undefined = this.exceptionKeys.some((key: string) => key === definition.key) ? definition.label : selectedValue;
        if (definition.key === "from" || definition.key === "to") {
          label = TimeUtil.convertUTCToUserTimeZone(selectedValue);
        }

        if (definition.key === 'uid' && selectedValue === label) { // TODO Need to be removed when legacy uid removed
          return undefined; // If no label from backend, ignore
        }

        searchData.id = selectedValue;
        searchData.label = label;
        return searchData;
      },
      isValid(definition?: SearchDefinition, selectedDataValues?: string[]): boolean {
        if (definition && selectedDataValues) {
          return Boolean(definition.inCountBadge &&
            selectedDataValues && selectedDataValues.length > 0 &&
            selectedDataValues[0].length > 0 && selectedDataValues[0] !== 'false');
        }
        return false;
      },
      hasLeafItems(searchData?: SearchData[]): boolean {
        return Boolean(searchData && searchData.length > 0);
      },
      getSelectedSearchData(searchCriteria: SearchCriteria): void {
        this.clearSelectedSearchData();

        if (searchCriteria && searchCriteria.criteria) {
          searchCriteria.criteria.forEach((criterion: SearchCriterion) => {
            const definition: SearchDefinition | undefined = criterion.definition;
            const selectedDataValues: string[] | undefined = criterion.selectedDataValues;

            if (definition && selectedDataValues && this.isValid(definition, selectedDataValues)) {
              if (definition && definition.key === 'oid') {
                return;
              }

              selectedDataValues.forEach((selectedValue) => {
                if (this.hasLeafItems(criterion.data)) {
                  const selectedData: SearchData | undefined = this.findSelectedData(criterion.data, selectedValue);
                  if (selectedData) {
                    if (this.hasLeafItems(selectedData.data)) {
                      this.handlingHierarchicalEntities(definition, selectedData, selectedDataValues);
                    } else {
                      this.addSelectedSearchData(definition, selectedData);
                    }
                  }
                } else {
                  const selectedData: SearchData | undefined = this.findSelectedValue(definition, selectedValue);
                  if (selectedData) {
                    this.addSelectedSearchData(definition, selectedData);
                  }
                }
              });
            }
          });
        }
      }
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
