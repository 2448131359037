
  import Vue from 'vue';
  import { mapState } from "vuex";

  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";
  import { ShopRegistryClient } from "@/main/webapp/vue/model/api/ShopRegistryClient";

  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';
  import collapsibleCard from '@/main/webapp/vue/components/ui/collapsible/card/index.vue';
  import providerTradesolution from '@/main/webapp/vue/components/admin/shop-configurator/data-sources/data-source/provider/tradesolution/index.vue';
  import filtersTable from '@/main/webapp/vue/components/admin/entity-registry/filters-table/index.vue';
  import mappingsTable from '@/main/webapp/vue/components/admin/entity-registry/mappings-table/index.vue';
  import eventLog from '@/main/webapp/vue/components/admin/entity-registry/event-log/index.vue';
  import dateLocalized from '@/main/webapp/vue/components/ui/text/text-date/localized/index.vue';

  export default Vue.extend({
    components: {
      formSwitch,
      collapsibleCard,
      providerTradesolution,
      filtersTable,
      mappingsTable,
      eventLog,
      dateLocalized
    },
    props: {
      client: {
        type: ShopRegistryClient,
        required: true
      }
    },
    data() {
      return {
        linkedClient: this.client as ShopRegistryClient,
        refreshEventLog: false as Boolean
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      statusChanged(newStatus: boolean): void {
        this.refreshEventLog = false;
        this.linkedClient.active = newStatus;
        if (newStatus) {
          if (this.linkedClient.id === undefined) {
            this.createClient();
            return;
          }
        }

        this.updateClient();
      },
      createClient(): void {
        BackendIntegrationService.createShopRegistryClient(this.userDetails.organization, this.linkedClient.provider.name)
          .then((client: ShopRegistryClient) => {
            this.linkedClient.id = client.id;
            this.refreshEventLog = true;
            notification.success("Data source client created!");
          }).catch((error: Error) => {
            this.linkedClient.active = false;
            notification.fail("Could not create data source client");
          });
      },
      updateClient(): void {
        this.linkedClient.provider.data = undefined; // Avoid submitting provider data, it should be updated by the provider itself
        BackendIntegrationService.updateEntityRegistryClient(this.userDetails.organization, this.linkedClient)
          .then((client: EntityRegistryClient) => {
            this.linkedClient = client as ShopRegistryClient;
            this.refreshEventLog = true;
            notification.success("Data source client updated!");
          }).catch((error: Error) => {
            notification.fail("Could not update data source client");
          });
      }
    }
  });
