import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('GalleryContent')
export class GalleryContent {

  @JsonProperty('itemId', Number, false)
  itemId?: Number = undefined;

  @JsonProperty('image', Boolean, false)
  image: boolean = true;

  @JsonProperty('amount', Number, false)
  amount: Number = 0;

  @JsonProperty('nav', NavigationLinks, false)
  nav: NavigationLinks = new NavigationLinks();

}
