import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('MapMarkerData')
export class MapMarkerData {
    @JsonProperty('color', String, true)
    color?: string = undefined;

    @JsonProperty('colorCode', String, true)
    colorCode?: string = undefined;

    @JsonProperty('category', Number, true)
    category?: number = undefined;

    @JsonProperty('icon', String, true)
    icon?: string = undefined;

    @JsonProperty('name', String, true)
    name?: string = undefined;
}
