import { JsonObject, JsonProperty } from "json2typescript";
import { GeoLocation } from '@/main/webapp/vue/model/api/GeoLocation';
import { MarkerColor } from '@/main/webapp/vue/model/api/Map/MarkerColor';
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('MapSubmission')
export class MapSubmission {
    @JsonProperty('nav', NavigationLinks, true)
    nav?: NavigationLinks = undefined;

    @JsonProperty('markerColor', MarkerColor, true)
    markerColor?: MarkerColor = undefined;

    @JsonProperty('location', GeoLocation, true)
    location?: GeoLocation = undefined;
}
