import { JsonObject, JsonProperty } from "json2typescript";
import { MapDefaultEntity } from "@/main/webapp/vue/model/api/Map/MapDefaultEntity";
import { GeoLocation } from "@/main/webapp/vue/model/api/GeoLocation";

@JsonObject('MapShop')
export class MapShop extends MapDefaultEntity {
    @JsonProperty('description', String, true)
    description?: String = undefined;

    @JsonProperty('location', GeoLocation, true)
    location?: GeoLocation = undefined;
}
