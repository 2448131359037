import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";
import { ProjectProgressForDepartment } from "@/main/webapp/vue/model/api/ProjectProgressForDepartment";

@JsonObject('ProjectProgressForDepartmentContainer')
export class ProjectProgressForDepartmentContainer {

  @JsonProperty('list', [ProjectProgressForDepartment], false)
  list: ProjectProgressForDepartment[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
