import { JsonObject, JsonProperty } from "json2typescript";
import { Item } from "./Item";
import { User } from "./User";
import { Organization } from "./Organization";
import { DateTime } from "./DateTime";
import { Entity } from "./Entity";

@JsonObject('ItemLike')
export class ItemLike extends Entity {
  @JsonProperty('item', Item, true)
  item?: Item = undefined;

  @JsonProperty('user', User)
  user?: User = undefined;

  @JsonProperty('organization', Organization, true)
  organization?: Organization = undefined;

  @JsonProperty('created', DateTime)
  created?: DateTime = undefined;
}
