
  import Vue from 'vue';
  import { Map, Marker } from 'leaflet';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';
  import markers from '@/main/webapp/vue/components/map/marker/index.vue';
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import markerConfig from "@/main/webapp/vue/components/map/markerConfig";

  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
  import { IconTypes } from "@/main/webapp/vue/model/api/Map/IconTypes";

  export default Vue.extend({
    components: {
      baseMap,
      markers,
      loadingIcon
    },
    props: {
      userLat: {
        type: String
      },
      userLng: {
        type: String
      },
      shopLat: {
        type: String
      },
      shopLng: {
        type: String
      }
    },
    data() {
      return {
        locations: [] as MapMarker[],
        mapHeight: "275px" as string, // item page map size
        mapObject: undefined as Map | undefined,
        iconTypes: mapConfiguration.config.iconTypes as IconTypes,
        loading: false as boolean
      };
    },
    methods: {
      fitBounds(): void {
        if (this.mapObject) {
          mapConfiguration.fitBounds(this.mapObject, this.locations);
        }
      },
      markerClicked(data: any, marker: Marker): void {
        if (this.mapObject) {
          this.mapObject.setView(marker.getLatLng(), mapConfiguration.config.defaultCloseZoom);
        }
      },
      updateItems(): void {
        this.loading = true;

        if (this.userLat && this.userLng && this.iconTypes.user) {
          let userText: string = this.$t(`text.${this.iconTypes.user.name}-location`).toString();
          let userMarker: MapMarker = markerConfig.transformToMarker(
            this.userLat, this.userLng, this.iconTypes.user, userText);
          this.locations.push(userMarker);
        }

        if (this.shopLat && this.shopLng && this.iconTypes.shop) {
          let shopText: string = this.$t(`text.${this.iconTypes.shop.name}-location`).toString();
          let shopMarker: MapMarker = markerConfig.transformToMarker(
            this.shopLat, this.shopLng, this.iconTypes.shop, shopText);
          this.locations.push(shopMarker);
        }

        this.loading = false;
        this.fitBounds();
      },
      initMap(map: Map): void {
        this.mapObject = map;
        this.updateItems();
      }
    },
    i18n: {
      messages: messages
    }
  });
