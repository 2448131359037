
  import Vue, { PropType } from 'vue';
  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";

  export default Vue.extend({
    name: 'markerItemMetadata',
    props: {
      metadata: {
        type: Object as PropType<HierarchicalEntity>,
        required: true
      },
      firstChild: {
        type: Boolean,
        default: false
      }
    }

  });
