import { JsonObject, JsonProperty } from "json2typescript";
import { MapDefaultEntity } from "@/main/webapp/vue/model/api/Map/MapDefaultEntity";

@JsonObject('MapCategory')
export class MapCategory extends MapDefaultEntity {
    @JsonProperty('color', String, true)
    color?: string = undefined;

    @JsonProperty('colorCode', String, true)
    colorCode?: string = undefined;

    @JsonProperty('parentCategory', MapCategory, true)
    parentCategory?: MapCategory = undefined;
}
