
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import TableWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/table/model/TableWidgetModel';

  export default Vue.extend({
    name: 'TableWidgetProperties',
    components: {
      textTitle,
      genericStyle
    },
    props: {
      model: {
        type: Object as PropType<TableWidgetModel>,
        required: true
      }
    },
    i18n: {
      messages: messages
    }
  });
