import { JsonObject, JsonProperty } from "json2typescript";
import { ItemLike } from "./ItemLike";

@JsonObject('ItemLikes')
export class ItemLikes {
  @JsonProperty('likedByUser', Boolean)
  likedByUser?: boolean = undefined;

  @JsonProperty('likes', [ItemLike], true)
  likes?: ItemLike[] = undefined;
}
