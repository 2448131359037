
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import floatingButton from '@/main/webapp/vue/components/ui/float/float-button/index.vue';
  import floatingButtonGroup from '@/main/webapp/vue/components/ui/float/float-button-group/index.vue';
  import materialIcon from "@/main/webapp/vue/components/ui/icon/material-icon/index.vue";

  import WidgetModel from '@/main/webapp/vue/model/learning/WidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { getWidgetRegistration } from '@/main/webapp/vue/components/learning/widget/model/Widgets';

  export default Vue.extend({
    name: 'WidgetComponent',
    components: {
      floatingButton,
      floatingButtonGroup,
      materialIcon
    },
    props: {
      widget: {
        type: Object as PropType<WidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: false
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: false
      },
      editing: {
        type: Boolean,
        default: false
      },
      editingWidget: {
        type: Object as PropType<WidgetModel | undefined>,
        default: undefined
      },
      editable: {
        type: Boolean,
        default: false
      },
      dragging: {
        type: Boolean,
        default: false
      },
      widgetFromSideBar: {
        type: Boolean,
        default: false
      },
      isChild: {
        type: Boolean,
        default: false
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      component() {
        const widgetRegistration = getWidgetRegistration(this.widget);
        if (widgetRegistration) {
          return widgetRegistration.widgetComponent;
        }
        return undefined;
      }
    },
    data() {
      return {
        edited: false as boolean
      };
    },
    i18n: {
      messages: messages
    }
  });
