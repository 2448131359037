import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

@JsonDiscriminator(StyleModel, 'section')
export default class SectionStyleModel extends StyleModel {
  constructor(@JsonField('backgroundColor', String, true)
              public backgroundColor?: string,
              @JsonField('foregroundColor', String, true)
              public foregroundColor?: string,
              @JsonField('padding', String, true)
              public padding?: string) { // top right bottom left
    super();
  }

  default(): SectionStyleModel {
    return new SectionStyleModel('#ffffff', '#000000', '5 5 5 5');
  }
}
