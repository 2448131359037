import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import iframeWidget from '@/main/webapp/vue/components/learning/widget/widgets/iframe/widget/index.vue';
import iframeWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/iframe/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import IframeAttributes from "@/main/webapp/vue/components/learning/widget/widgets/iframe/model/IframeAttributes";
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

const DEFAULT_PREFERENCES: string =
  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;";
const DEFAULT_ATTRIBUTES: IframeAttributes = new IframeAttributes();


export class IframeWidgetData {
  constructor(@JsonField('url', String)
              public url: string = "",
              @JsonField('preferences', String)
              public preferences: string = DEFAULT_PREFERENCES,
              @JsonField('attributes', IframeAttributes)
              public attributes: IframeAttributes = DEFAULT_ATTRIBUTES) {}
}

export class IframeWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'Iframe')
export default class IframeWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection: DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', IframeWidgetData)
              public data = new IframeWidgetData(),
              @JsonField('properties', IframeWidgetProperties)
              public properties = new IframeWidgetProperties(),
              @JsonField('defaultProperties', IframeWidgetProperties, true)
              public defaultProperties = new IframeWidgetProperties(
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.ADVANCED,
  IframeWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'web_asset',
  iframeWidget,
  iframeWidgetProperties,
  (id: WidgetIdType) => new IframeWidgetModel(id, DraggableSection.BODY, new WidgetMetadata())
);
