import { Any, JsonObject, JsonProperty } from "json2typescript";

export enum CourseBrandingStyleType {
  LOGO = 'logo',
  SECTION = 'section',
  BUTTON = 'button',
  COLLAPSIBLE = 'collapsible',
  TEXT = 'text',
  CSS = "css"
}

@JsonObject('CourseBrandingStyle')
export class CourseBrandingStyle {

  @JsonProperty('type', String, false)
  type?: CourseBrandingStyleType = undefined;

  @JsonProperty('data', Any, true)
  data?: any = undefined;

  @JsonProperty('updated', String, true)
  updated?: String = undefined;

  static find(type: CourseBrandingStyleType, defaultStyles: CourseBrandingStyle[]) {
    return defaultStyles.filter((defaultStyle: CourseBrandingStyle) => defaultStyle.type === type)[0];
  }
}
