import { JsonProperty } from "json2typescript";
import { Entity } from "@/main/webapp/vue/model/api/Entity";

export class VisitedActivitySession extends Entity {

  @JsonProperty('activityId', Number, true)
  activityId?: number;

  @JsonProperty('visited', Boolean, true)
  visited?: boolean = false;

  constructor(activityId: number, visited: boolean) {
    super();

    this.activityId = activityId;
    this.visited = visited;
  }

}
