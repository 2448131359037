
  import Vue from 'vue';
  import { mapState } from "vuex";

  import LoadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';

  import messages from "./messages.json";

  export default Vue.extend({
    components: {
      LoadingIcon
    },
    props: {
      title: {
        type: String,
        default: null
      },
      busy: {
        type: Boolean,
        default: false
      },
      buttonDisabled: {
        type: Boolean,
        default: false
      },
      showFooter: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      update() {
        this.$emit('updated');
      }
    },
    i18n: {
      messages: messages
    }
  });
