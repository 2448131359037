export enum RoutePath {
  // Learning page
  LEARNING = '/learning',
  COURSE_MANAGEMENT = '/learning/course-management',
  COURSE_SETTINGS = '/learning/course-management/course-settings',
  COURSE_ACTIVITIES = '/learning/course-management/course-activities',
  COURSE_ACTIVITY_PREVIEW = '/learning/course-management/course-activities/preview',
  COURSE_BRANDING = '/learning/course-management/course-branding',
  // Submissions page
  SUBMISSIONS = '/submissions',
  SUBMISSIONS_FEED = '/submissions/feed',
  SUBMISSIONS_THUMB = '/submissions/thumbnail',
  SUBMISSIONS_LIST = '/submissions/list',
  SUBMISSIONS_MAP = '/submissions/map',
  SUBMISSIONS_DASHBOARD = '/submissions/dashboard',
  // Feed page
  TIMELINE = "/timeline.html"
}
