
  import Vue from 'vue';
  import { mapState } from "vuex";

  import dropDown from "@/main/webapp/vue/components/ui/drop-down/index.vue";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import { User } from "@/main/webapp/vue/model/api/User";
  import { DomainOrganization } from "@/main/webapp/vue/model/api/DomainOrganization";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { AuthenticationResult } from "@/main/webapp/vue/model/api/AuthenticationResult";
  import { LogoContainer } from "@/main/webapp/vue/model/api/LogoContainer";
  import { Logo } from "@/main/webapp/vue/model/api/Logo";
  import { URL_PATH } from "@/main/webapp/vue/urlPath";

  export default Vue.extend({
    components: {
      dropDown
    },
    props: {
      colorStyle: {
        type: String,
        default: 'dark'
      }
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      filteredDomainOrganizations(): DomainOrganization[] {
        return this.domainOrganizations.filter((domainOrganization: DomainOrganization) => {
          if (domainOrganization && domainOrganization.name) {
            return domainOrganization.name.toLowerCase().includes(this.searchTerm.toLowerCase());
          }
        });
      }
    },
    data() {
      return {
        domainOrganizations: [] as DomainOrganization[],
        searchTerm: "" as string,
        searchThreshold: 10 as number
      };
    },
    watch: {
      userDetails(newUserDetails, oldUserDetails) {
        if (newUserDetails && newUserDetails.domainOrganizations?.list?.length > 0) {
          this.domainOrganizations = newUserDetails.domainOrganizations.list;
        }
      }
    },
    methods: {
      isCurrentOrganization(domainOrganizationId: number | undefined): boolean {
        if (domainOrganizationId && this.userDetails && this.userDetails.organization) {
          return this.userDetails.organization.id === domainOrganizationId;
        }
        return false;
      },
      setSearchTerm(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.$store.commit('updateSwitchOrganizationSearchString', this.searchTerm);
      },
      getIconLink(domainOrganization: DomainOrganization | null = null): string {
        const logoType: string = "organization-icon"; // TODO enum
        const linkType: string = "self"; // TODO enum
        const noIconImage: string = require('@/main/webapp/img/icons/no-logo.png');

        let logoLink: NavigationLink = new NavigationLink();
        logoLink.href = noIconImage;

        if (domainOrganization && domainOrganization.logos && domainOrganization.logos.list) {
          let filteredLogo: Logo = LogoContainer.getLogoWithType(logoType, domainOrganization.logos.list);
          if (filteredLogo) {
            let filteredLink: NavigationLink | undefined = NavigationLinks.getLinkFromLinks(linkType, filteredLogo.links);
            if (filteredLink) {
              logoLink = filteredLink;
            }
          }
        }

        return logoLink.href;
      },
      fetchUserDetails(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Fetch user details");
        }

        if (!this.userDetails) {
          BackendIntegrationService.fetchUserDetails()
              .then((user: User) => {
                this.$store.dispatch('updateUserDetails', user);

                this.domainOrganizations = [];

                if (user && user.domainOrganizations) {
                  this.domainOrganizations = user.domainOrganizations.list;
                }
              }).catch((e: Error) => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log("Fetch user details failed", e);
                }
              });
        } else {
          this.domainOrganizations = this.userDetails.domainOrganizations.list;
        }
      },
      refreshPage(): void {
        if (this.$route.query && this.$route.query.oid) {
          this.$router.push({ path: this.$route.path, query: { oid: this.userDetails.organization.id } });
        }

        switch (this.$route.path) {
          case URL_PATH.ITEM: { // throws an exception without parameters
            this.$router.replace(URL_PATH.THUMBS);
            break;
          }
          case URL_PATH.SHOPS: {
            this.$router.replace(URL_PATH.CHAINS);
            break;
          }
          default: {
            this.$router.replace(this.$route.path);
            break;
          }
        }

        this.$router.go(0);
      },
      assumeOrganizations(selectedOrganization: DomainOrganization): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Assume organization '${selectedOrganization.id}' - '${selectedOrganization.name}'`);
        }

        const linkType: string = "assume_organization";
        if (selectedOrganization && selectedOrganization.nav) {
          let assumeOrganizationLink: NavigationLink | undefined = NavigationLinks.getLinkFromLinks(linkType, selectedOrganization.nav.links);
          if (assumeOrganizationLink) {
            BackendIntegrationService.assumeOrganization(assumeOrganizationLink)
              .then((result: AuthenticationResult) => {
                this.$store.dispatch("updateAuthResult", result);
              }).finally(() => {
                this.refreshPage();
              });
          }
        }
      }
    },
    created(): void {
      this.fetchUserDetails();
    }
  });
