
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DraggableContainerElement',
    props: {
      isDragging: {
        type: Boolean,
        default: false
      }
    }
  });
