
  import Vue, { PropType } from 'vue';
  import { oc as Optional } from "ts-optchain";
  import messages from './messages.json';

  import textBadge from '@/main/webapp/vue/components/ui/text/text-badge/index.vue';
  import commentForm from '@/main/webapp/vue/components/ui/comment/comment-form/index.vue';
  import commentSummary from '@/main/webapp/vue/components/ui/comment/comment-summary/index.vue';
  import collapsibleContent from '@/main/webapp/vue/components/ui/collapsible/collapsible-content/index.vue';
  import basic from "@/main/webapp/vue/components/ui/modal/basic/index.vue";
  import Confirmation from "@/main/webapp/vue/components/ui/modal/confirmation/index.vue";
  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { ItemComments } from "@/main/webapp/vue/model/api/ItemComments";
  import { ItemComment } from "@/main/webapp/vue/model/api/ItemComment";
  import { Item } from "@/main/webapp/vue/model/api/Item";

  export enum CommentDisplayType {
    Popover = "popover",
    Modal = "modal"
  }

  export default Vue.extend({
    components: {
      textBadge,
      commentForm,
      commentSummary,
      collapsibleContent,
      basic,
      loadingIcon
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      type: {
        type: String as PropType<CommentDisplayType>,
        default: CommentDisplayType.Popover
      },
      showText: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        comments: [] as ItemComment[],
        displayCommentsForm: false,
        maxDisplayComments: (this as any).$properties.comment.container.display.max,
        types: {
          popover: CommentDisplayType.Popover,
          modal: CommentDisplayType.Modal
        },
        commentEdited: false as boolean,
        commentFetched: false as boolean,
        loading: false as boolean
      };
    },
    methods: {
      attemptedClosing(): void {
        let confirmation: Vue = new Confirmation({
          propsData: {
            title: this.$t('comment.confirm-leave')
          }
        }).$mount();

        confirmation.$on('user-confirm', (confirm: boolean) => {
          if (confirm) {
            this.closeCommentsModal();
          }
        });
      },
      onClick(): void {
        if (!this.commentFetched) {
          this.fetchComments();
        }

        if (this.type === this.types.popover) {
          this.toggleCommentForm();
        } else if (this.type === this.types.modal) {
          this.showCommentsModal();
        }
      },
      showCommentsModal(): void {
        if (this.submission) {
          this.$bvModal.show(`bv-modal-comments-${this.submission.id}`);
        }
      },
      closeCommentsModal(): void {
        this.commentEdited = false;

        setTimeout(() => {
          this.$bvModal.hide(`bv-modal-comments-${this.submission.id}`);
        }, 100);
      },
      toggleCommentForm(): void {
        this.displayCommentsForm = !this.displayCommentsForm;
      },
      fetchComments(): void {
        if (this.loading) {
          return;
        }

        if (this.submission.comments > 0) {
          this.loading = true;
          BackendIntegrationService.fetchComments(this.submission)
            .then((response: ItemComments) => {
              let comments: ItemComment[] | undefined = Optional(response).comments();
              if (comments && comments.length > 0) {
                this.addComments(comments);
              }
            }).finally(() => {
              this.loading = false;
            });
        }

        if (!this.commentFetched) {
          this.commentFetched = true;
        }
      },
      commentSubmitted(comment: ItemComment) {
        this.displayCommentsForm = false;
        this.commentEdited = false;

        // display submitted comment as the topmost comment
        this.comments.unshift(comment);

        if (this.type === CommentDisplayType.Modal) {
          this.closeCommentsModal();
        }
      },
      addComments(comments: ItemComment[]) {
        this.comments.push.apply(this.comments, comments);
      }
    },
    i18n: {
      messages: messages
    }
  });
