import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('MapAddress')
export class MapAddress {
    @JsonProperty('city', String, true)
    city?: string = '';

    @JsonProperty('country', String, true)
    country?: string = '';

    @JsonProperty('postalCode', String, true)
    postalCode?: string = '';

    @JsonProperty('province', String, true)
    province?: string = '';

    @JsonProperty('street', String, true)
    street?: string = '';

    @JsonProperty('region', String, true)
    region?: string = '';
}
