
  import Vue, { PropType } from 'vue';

  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";

  export const enum CourseLogoType {
    ICON = 'course-icon',
    LOGO = 'course-logo'
  }

  export const enum CourseLogoSize {
    ICON = 50,
    LOGO = 150
  }

  export default Vue.extend({
    props: {
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      type: {
        type: String as PropType<CourseLogoType>,
        default: CourseLogoType.ICON
      },
      emitError: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Boolean,
        default: false
      },
      showDefault: {
        type: Boolean,
        default: true
      },
      maxWidth: {
        type: String,
        default: null
      },
      maxHeight: {
        type: String,
        default: null
      },
      linkEnabled: {
        type: Boolean,
        default: false
      },
      linkTargetBlank: {
        type: Boolean,
        default: false
      },
      altText: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        defaultCourseLogo: require('@/main/webapp/img/no-logo.png') as string,
        logoImageSrc: null as string | null
      };
    },
    computed: {
      computedHeight(): string {
        if (this.maxHeight) {
          return `${this.maxHeight}px`;
        }

        return 'auto';
      },
      computedWidth(): string {
        if (this.maxWidth) {
          return `${this.maxWidth}px`;
        }

        return 'auto';
      }
    },
    methods: {
      setDefaultLogo(): void {
        if (this.showDefault) {
          this.logoImageSrc = this.defaultCourseLogo;
        }
      },
      fetchLogo(): void {
        const course: Course = Course.from(this.course);
        LearningIntegrationService.listCourseBrandingLogos(course)
          .then((item: ItemContentEntityContainer) => {
            item.list.forEach((itemContentEntity: ItemContentEntity) => {
              if (itemContentEntity.type === this.type) {
                let link: NavigationLink | undefined = itemContentEntity.getLink('self');
                if (link) {
                  this.logoImageSrc = `${link.href}`;
                  return;
                }
                this.setDefaultLogo();
              }
            });
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`Loading logo failed from courseId: ${this.course.id}, `, e);
            }
            if (this.emitError) {
              this.$emit('loading-failed');
            }

            this.setDefaultLogo();
          });
      }
    },
    watch: {
      refresh(): void {
        this.fetchLogo();
      }
    },
    mounted(): void {
      this.fetchLogo();
    }
  });
