import { Any, JsonObject, JsonProperty } from "json2typescript";

import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
import { CourseStatus } from "@/main/webapp/vue/model/api/learning/CourseStatus";
import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
import { CourseBranding } from "@/main/webapp/vue/model/api/learning/CourseBranding";
import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";

@JsonObject('Course')
export class Course extends NamedEntity {
  public static MAIN_ACTIVITY_LINK: string = "main_activity";

  @JsonProperty('shortName', String, true)
  shortName?: string = undefined;

  @JsonProperty('status', CourseStatus, false)
  status?: CourseStatus = undefined;

  @JsonProperty('urlPath', String, true)
  urlPath?: string = undefined;

  @JsonProperty('urlPathFragment', String, true)
  urlPathFragment?: string = undefined;

  @JsonProperty('properties', Any, true)
  properties?: any = undefined;

  @JsonProperty('activityCount', Number, true)
  activityCount?: number = undefined;

  @JsonProperty('mainActivity', Activity, true)
  mainActivity?: Activity = undefined;

  @JsonProperty('branding', CourseBranding, true)
  branding?: CourseBranding = undefined;

  // FIXME the date from the backend does not map to DateTime
  @JsonProperty('created', String, false)
  created?: string | undefined = undefined;

  public static from(courseModel: CourseModel): Course {
    let course = new Course();
    course.id = courseModel.id;
    course.name = courseModel.name;
    course.status = courseModel.status;
    course.urlPath = courseModel.urlPath;
    course.urlPathFragment = courseModel.urlPathFragment;
    course.properties = courseModel.properties;
    course.activityCount = courseModel.activityCount;
    course.mainActivity = courseModel.mainActivity;
    course.branding = courseModel.branding;
    course.nav = courseModel.nav;
    course.created = courseModel.created;
    return course;
  }
}
