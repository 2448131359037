
  import Vue from 'vue';

  import glyphiconSymbol from '../../glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      message: {
        type: String,
        required: true
      }
    }
  });
