
  import Vue, { PropType } from 'vue';

  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

  export default Vue.extend({
    props: {
      content: {
        type: Object as PropType<ItemContent>,
        required: true
      }
    }
  });
