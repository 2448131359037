
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import feedHeaderThumb from "@/main/webapp/vue/components/feed/feed-header-thumb/index.vue";
  import carousel from "@/main/webapp/vue/components/ui/content/carousel/index.vue";
  import collapsiblePreview from "@/main/webapp/vue/components/ui/collapsible/collapsible-preview/index.vue";
  import submissionMetadata from '@/main/webapp/vue/components/ui/submission/submission-metadata/index.vue';
  import likeContainer from "@/main/webapp/vue/components/ui/like/like-container/index.vue";
  import commentContainer from "@/main/webapp/vue/components/ui/comment/comment-container/index.vue";
  import shareContainer from "@/main/webapp/vue/components/ui/share/share-container/index.vue";

  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

  export default Vue.extend({
    components: {
      feedHeaderThumb,
      carousel,
      collapsiblePreview,
      submissionMetadata,
      likeContainer,
      commentContainer,
      shareContainer
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      selectedSubmissionIds: {
        type: Array as PropType<number[]>,
        default: () => []
      },
      itemIndex: {
        type: Number
      }
    },
    data() {
      return {
        showMetadata: false as boolean,
        showMore: false as boolean,
        selected: false as boolean,
        busy: false as boolean,
        slideIndex: 0,
        slideIndexChangeToggle: false as boolean
      };
    },
    computed: {
      navLink(): string | undefined {
        return NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, this.submission.nav)?.href;
      }
    },
    watch: {
      selectedSubmissionIds(newIds: number[], oldIds: number[]): void {
        this.checkIfSelected(newIds);
      }
    },
    methods: {
      checkIfSelected(newIds: number[] = []): void {
        if (this.submission) {
          this.selected = newIds.some((newId: number) => newId === this.submission.id);
        }
      },
      emitChanges(): void {
        if (this.submission && !this.busy) {
          this.busy = true;

          setTimeout(() => { // The moment of click, this.selected not changed yet
            if (this.selected) {
              this.$emit('item-selected', this.submission.id);
            } else {
              this.$emit('item-unselected', this.submission.id);
            }

            this.busy = false;
          }, 100);
        }
      },
      onItemShared(item: Item): void {
        this.selected = true;
        this.emitChanges();
      },
      showCardMetadata() {
        this.showMetadata = true;
      },
      hideCardMetadata() {
        this.showMetadata = false;
      },
      handlePictureClick(itemContent: ItemContent) {
        if (this.submission.contents && itemContent.token) {
          const newIndex = this.submission.contents
            .findIndex((value) => value.token === itemContent.token);
          if (newIndex >= 0) {
            this.slideIndex = newIndex;
            this.slideIndexChangeToggle = !this.slideIndexChangeToggle;
          }
        }
      }
    },
    mounted() {
      this.checkIfSelected(this.selectedSubmissionIds);
    },
    i18n: {
      messages: messages
    }
  });
