
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import HeadingWidgetModel from
  '@/main/webapp/vue/components/learning/widget/widgets/heading/model/HeadingWidgetModel';
  import TextStyleWidgetProperties from "@/main/webapp/vue/model/learning/TextStyleWidgetProperties";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'HeadingWidget',
    props: {
      model: {
        type: Object as PropType<HeadingWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        editingText: false as boolean,
        temporaryText: this.model.data.text as string
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): TextStyleWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedHeadingStyle(): Object {
        return computedStyleUtil.getStyles(
          ['color', 'alignment', 'size'],
          this.model.properties.textStyle,
          this.computedDefaultStyle.textStyle);
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      startEditingText(): void {
        if (this.editable) {
          if (!this.editing) {
            this.$emit('edit', this.model);
          }
          this.temporaryText = this.model.data.text;
          this.editingText = true;
          (this.$refs.headingInput as HTMLInputElement).focus();
        }
      },
      removeFocus(): void {
        (this.$refs.headingInput as HTMLInputElement).blur();
      },
      cancelEditingText(): void {
        if (this.editingText) {
          this.editingText = false;
        }

        this.model.data.text = this.temporaryText;
        this.removeFocus();
      },
      stopEditingText(): void {
        if (this.editingText) {
          this.editingText = false;

          this.temporaryText = this.model.data.text;
          this.removeFocus();
          setTimeout(() => {
            this.$emit('stop-editing');
          }, 500);
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
