import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('OrganizationModuleFeature')
export class OrganizationModuleFeature {

  @JsonProperty('attributeType', String, true)
  attributeType?: string = undefined;

  @JsonProperty('text', String, true)
  text?: string = undefined;

  @JsonProperty('name', String, true)
  name?: string = undefined;

  @JsonProperty('value', String, true)
  value?: string = undefined;

}
