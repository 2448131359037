
  import Vue from 'vue';

  import glyphiconSymbol from '../glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      icon: {
        type: String,
        required: true
      },
      color: {
        type: String,
        default: "black"
      },
      size: {
        type: String,
        default: "medium"
      },
      titleText: {
        type: String,
        default: ""
      }
    },
    methods: {
      onClick(event: any): void {
        this.$emit('click', event);
      },
      onMouseOver(event: any): void {
        this.$emit('hover', event);
      },
      onMouseLeave(event: any): void {
        this.$emit('blur', event);
      }
    }
  });
