
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import messages from './messages.json';

  import textBadge from '@/main/webapp/vue/components/ui/text/text-badge/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { generateShareHTMLService } from "@/main/webapp/vue/services/GenerateShareHTMLService";

  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { ShareItem } from "@/main/webapp/vue/model/api/ShareItem";

  export default Vue.extend({
    components: {
      textBadge
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      showText: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        shareLink: undefined as NavigationLink | undefined
      };
    },
    methods: {
      checkImageContent(contents: ItemContent[] | undefined): boolean {
        if (contents && contents.length > 0) {
          return Boolean(contents.find((content: ItemContent) => content.image));
        }
        return false;
      },
      checkShareLink(): void {
        const shareLinkKey: string = "share";
        if (this.submission && this.submission.nav && this.checkImageContent(this.submission.contents)) {
          this.shareLink = this.submission.nav.getLink(shareLinkKey);
        }
      },
      prepareShareOutbox(item: ShareItem): void {
        generateShareHTMLService.prepareShareOutbox(item);
      },
      shareContent(): void {
        if (this.submission && this.submission.id) {
          BackendIntegrationService.postShareItem(this.submission.id, this.shareLink)
            .then((item: ShareItem) => {
              if (item) {
                this.prepareShareOutbox(item);
                this.$emit("item-shared", item);
              }
            }).catch((e: Error) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('Share current view failed', e);
              }
              notification.fail(this.$t('error.share'));
            });
        }
      }
    },
    created(): void {
      this.checkShareLink();
    },
    i18n: {
      messages: messages
    }
  });
