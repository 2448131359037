import { JsonObject, JsonProperty } from "json2typescript";

import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('Activities')
export class Activities {

  @JsonProperty('list', [Activity], false)
  list: Activity[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
