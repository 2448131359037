
  import Vue, { PropType } from "vue";
  import { ItemContent, ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";

  export default Vue.extend({
    name: 'standardImage',
    props: {
      content: {
        type: Object as PropType<ItemContent>,
        required: true
      }
    },
    computed: {
      imgUrl(): string | undefined {
        return this.content.nav?.getLink(ItemContentType.STANDARD)?.href;
      },
      webLink(): string | undefined {
        return this.content.nav?.webLink();
      }
    }
  });
