import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('ListViewSelectedField')
export class ListViewSelectedField {

  @JsonProperty('entityType', String)
  entityType: string | undefined = undefined;

  @JsonProperty('entityId', String)
  entityId: string | undefined = undefined;

  @JsonProperty('priority', Number)
  priority: number | undefined = undefined;

  @JsonProperty('active', Boolean)
  active: boolean | undefined = undefined;

}


