
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import { ProjectProgress } from "@/main/webapp/vue/model/api/ProjectProgress";
  import LoadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";

  export default Vue.extend({
    components: {
      LoadingIcon
    },
    props: {
      progress: {
        type: Object as PropType<ProjectProgress>,
        required: true
      },
      loading: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    i18n: {
      messages: messages
    }
  });
