
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';

  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { nameToUrlSafe } from "@/main/webapp/vue/util/stringUtil";

  export default Vue.extend({
    name: 'ActivitySideBar',
    components: {
      textTitle,
      collapsibleContainer,
      formSwitch
    },
    props: {
      model: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      isRoot: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isCustomPath(): boolean {
        return this.model.urlPathFragment !== nameToUrlSafe(this.model.name);
      }
    },
    methods: {
      handleNameInput(value: string): void {
        if (!this.isRoot && !this.isCustomPath) {
          this.model.urlPathFragment = nameToUrlSafe(value);
        }
        this.model.name = value;
      },
      resetPath(): void {
        if (this.isRoot) {
          this.model.urlPathFragment = "/";
        } else {
          this.model.urlPathFragment = nameToUrlSafe(this.model.name);
        }

        this.$emit('edited', this.model);
      }
    },
    i18n: {
      messages: messages
    }
  });
