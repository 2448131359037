
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import contentUpload from '@/main/webapp/vue/components/ui/content/content-upload/index.vue';
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';
  import alignmentStyle from '@/main/webapp/vue/components/ui/style/alignment/container/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import ImageWidgetModel, { ImageSourceType } from '@/main/webapp/vue/components/learning/widget/widgets/image/model/ImageWidgetModel';
  import { Entity } from "@/main/webapp/vue/model/api/Entity";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";

  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'ImageWidgetProperties',
    components: {
      contentUpload,
      textTitle,
      collapsibleContainer,
      genericStyle,
      alignmentStyle
    },
    props: {
      model: {
        type: Object as PropType<ImageWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      }
    },
    data: function() {
      return {
        holderEntity: {} as Entity,
        itemContentEntity: {} as ItemContentEntity,
        imageSourceType: {
          file: ImageSourceType.FILE as ImageSourceType,
          url: ImageSourceType.URL as ImageSourceType
        },
        pageReady: false as boolean
      };
    },
    computed: {
      persisted(): boolean {
        return this.model.isPersisted() as boolean;
      }
    },
    watch: {
      persisted(newValue: boolean, oldValue: boolean): void {
        if (newValue && newValue !== oldValue) {
          this.loadImageContentDefinition();
        }
      }
    },
    methods: {
      isSameSourceType(imageSourceType: ImageSourceType): boolean {
        if (!this.pageReady) {
          return true;
        }
        return this.model.data.imageSourceType === imageSourceType;
      },
      imageSourceSelected(imageSourceType: ImageSourceType): void {
        this.model.data.imageSourceType = imageSourceType;
      },
      externalLinkChanged(externalLink: string): void {
        this.model.data.externalLink = externalLink;
      },
      externalImageSrcChanged(externalSrc: string): void {
        this.model.data.externalImageSrc = externalSrc;
      },
      altTextChanged(altText: string): void {
        this.model.data.altText = altText;
      },
      loadImageContentDefinition(): void {
        const course: Course = Course.from(this.course);
        this.holderEntity.id = this.model.id as number;
        LearningIntegrationService.fetchCourseActivityContentUnitAttachments(course, this.activity.id as number, this.model.id as number)
          .then((response: ItemContentEntityContainer) => {
            if (response.list && response.list.length > 0) {
              this.itemContentEntity = response.list[0];
              this.model.data.entityType = this.itemContentEntity.type;
              this.model.data.entityItemId = this.itemContentEntity.itemId;

              let link: NavigationLink | undefined =
                NavigationLinks.getLinkFromLinks(NavigationLinkType.PAGE_SELF, this.itemContentEntity.links);
              if (link && link.href !== this.model.data.src) {
                this.model.data.src = link.href;
              }

              this.$emit('edited', this.model, true);
            } else {
              this.model.data.src = undefined;
            }
          }).catch((e: Error) => {
            notification.fail(this.$t('text.error'));
          });
      },
      imageUpdated(backendResponse: ItemContentEntity): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('image - imageUpdated()');
        }
        let imageLink: NavigationLink | undefined =
          NavigationLinks.getLinkFromNavigation(NavigationLinkType.PAGE_SELF, backendResponse);
        if (imageLink) {
          this.model.data.src = imageLink.href;
          this.model.data.lastUpdated = new Date().getTime();
        }

        this.$emit('edited', this.model, true);
      }
    },
    mounted() {
      this.holderEntity = new Entity();
      this.holderEntity.id = this.model.id as number;

      if (this.model.data.entityType) {
        this.itemContentEntity = new ItemContentEntity();
        this.itemContentEntity.type = this.model.data.entityType;
        this.itemContentEntity.itemId = this.model.data.entityItemId;
      }

      if (this.model.isPersisted() && !this.model.data.src) {
        this.loadImageContentDefinition();
      }

      this.$nextTick(() => { // This is to load file-uploader
        this.pageReady = true;
      });
    },
    i18n: {
      messages: messages
    }
  });
