
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import alignmentStyle from "@/main/webapp/vue/components/ui/style/alignment/container/index.vue";
  import sectionStyle from "@/main/webapp/vue/components/ui/style/section/container/index.vue";
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import LayoutColumnsWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/layout-columns/model/LayoutColumnsWidgetModel';

  export default Vue.extend({
    name: 'LayoutColumnsWidgetProperties',
    props: {
      model: {
        type: Object as PropType<LayoutColumnsWidgetModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      sectionStyle,
      genericStyle,
      alignmentStyle
    },
    data() {
      return {
        minRatio: 10 as number,
        maxRatio: 90 as number
      };
    },
    methods: {
      decreaseRatio(): void {
        if (this.model.properties.columnRatio > 0) { // To avoid string addition
          this.model.properties.columnRatio = (this.model.properties.columnRatio * 1) - 1;
        }
      },
      increaseRatio(): void {
        if (this.model.properties.columnRatio < 100) { // To avoid string addition
          this.model.properties.columnRatio = (this.model.properties.columnRatio * 1) + 1;
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
