import { JsonObject, JsonProperty } from "json2typescript";
import { SearchDefinition } from "@/main/webapp/vue/model/api/SearchDefinition";
import { SearchData } from "@/main/webapp/vue/model/api/SearchData";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('SearchCriterion')
export class SearchCriterion {

  @JsonProperty('definition', SearchDefinition, true)
  definition?: SearchDefinition = undefined;

  @JsonProperty('selectedDataValues', [String], true)
  selectedDataValues?: string[] = [];

  @JsonProperty('data', [SearchData], true)
  data?: SearchData[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;
}
