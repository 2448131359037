import { WidgetIdType } from "@/main/webapp/vue/model/learning/WidgetModel";
import { JsonField } from '@znapio/znapson';

export default class NamedModel {

  constructor(@JsonField('id', Number)
              public id: WidgetIdType,
              @JsonField('name', String)
              public name: string) {
  }

}
