// @ts-ignore
import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import activityButtonWidget from '@/main/webapp/vue/components/learning/widget/widgets/activity-button/widget/index.vue';
import activityButtonWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/activity-button/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import ButtonStyleWidgetProperties from "@/main/webapp/vue/model/learning/ButtonStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import ButtonStyleModel from '@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export enum DefaultValues {
  DESTINATION = '#'
}

export enum IconAlignment {
  LEFT = 'initial',
  RIGHT = 'row-reverse',
  TOP = 'column',
  BOTTOM = 'column-reverse'
}

export class ActivityButtonWidgetData {
  constructor(@JsonField('label', String)
              public label: string = '',
              @JsonField('icon', String, true)
              public icon: string | null = null,
              @JsonField('iconAlignment', String, true)
              public iconAlignment: IconAlignment = IconAlignment.LEFT,
              @JsonField('destination', String)
              public destination: string = DefaultValues.DESTINATION,
              @JsonField('block', Boolean, true)
              public block: boolean = true,
              @JsonField('id', Number, true)
              public id?: number) {}
}

@JsonDiscriminator(WidgetModel, 'activityButton')
export default class ActivityButtonWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.ALL,
              metadata = new WidgetMetadata(),
              @JsonField('data', ActivityButtonWidgetData)
              public data = new ActivityButtonWidgetData(),
              @JsonField('properties', ButtonStyleWidgetProperties)
              public properties = new ButtonStyleWidgetProperties(),
              @JsonField('defaultProperties', ButtonStyleWidgetProperties, true)
              public defaultProperties =
                new ButtonStyleWidgetProperties(
                  new ButtonStyleModel('#17a2b8', '#ffffff', 5)),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.NAVIGATION,
  ActivityButtonWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'smart_button',
  activityButtonWidget,
  activityButtonWidgetProperties
);
