
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      title: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'h3'
      },
      borderTop: {
        type: Boolean,
        default: false
      },
      borderBottom: {
        type: Boolean,
        default: false
      }
    }
  });
