import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
import { MapMarkerContainer } from "@/main/webapp/vue/model/api/Map/MapMarkerContainer";
import { IconType } from "@/main/webapp/vue/model/api/Map/IconType";
import { MapMarkerData } from "@/main/webapp/vue/model/api/Map/MapMarkerData";
import { MapMarkerHtml } from "@/main/webapp/vue/model/api/Map/MapMarkerHtml";

export const transformToMarkers = (items: any[], markerColors: string[]): MapMarkerContainer => {
  let markers: MapMarker[] = [];
  let mapMarkerContainer: MapMarkerContainer = new MapMarkerContainer();

  items.forEach((item: any, index: number) => { // TODO: consistent item type
    let lat: number | undefined;
    let lng: number | undefined;
    let color: string = 'white'; // Default (Otherwise Red from Leaflet)
    let colorCode: string = 'white'; // Default (Otherwise Red from Leaflet)
    let category: number = 0;

    if (item.location && item.location.latitude && item.location.longitude) {
      lat = parseFloat(item.location.latitude);
      lng = parseFloat(item.location.longitude);

      if (item.markerColor && item.markerColor.name && item.markerColor.code) {
        color = item.markerColor.name;
        colorCode = item.markerColor.code;

        if (markerColors.indexOf(item.markerColor.code) === -1) {
          markerColors.push(item.markerColor.code);
        }

        category = markerColors.indexOf(item.markerColor.code);
      }

      markers.push({
        lat: lat,
        lng: lng,
        markerData: {
          color: color,
          colorCode: colorCode,
          category: category
        },
        html: {
          item: item
        }
      });
    }
  });

  mapMarkerContainer.markers = markers;
  mapMarkerContainer.markerColors = markerColors;

  return mapMarkerContainer;
};

export const transformToMarker = (lat: string, lng: string, iconType: IconType, text: string): MapMarker => {
  let marker: MapMarker = new MapMarker();
  marker.lat = parseFloat(lat);
  marker.lng = parseFloat(lng);

  marker.markerData = new MapMarkerData();
  marker.markerData.name = iconType.name;
  marker.markerData.color = iconType.color;
  marker.markerData.icon = iconType.icon;

  marker.html = new MapMarkerHtml();
  marker.html.text = text;

  return marker;
};

export default {
  transformToMarkers,
  transformToMarker
};
