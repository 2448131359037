import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
import { ProjectProgress } from "@/main/webapp/vue/model/api/ProjectProgress";
import { ProjectProgressForDepartment } from "@/main/webapp/vue/model/api/ProjectProgressForDepartment";

@JsonObject('ProjectProgressForUser')
export class ProjectProgressForUser extends ProjectProgressForDepartment {

  @JsonProperty('user', NamedEntity, false)
  user?: NamedEntity = undefined;

}
