import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";

@JsonObject('UserContact')
export class UserContact extends NamedEntity {

  @JsonProperty('email', String, true)
  email?: string = undefined;

  @JsonProperty('phone', String, true)
  phone?: string = undefined;

}
