import { JsonObject, JsonProperty } from "json2typescript";
import { UserSettings } from "@/main/webapp/vue/model/api/UserSettings";
import { Department } from "@/main/webapp/vue/model/api/Department";
import { Language } from "@/main/webapp/vue/model/api/Language";

@JsonObject('UpdateUserSettingsMetadata')
export class UpdateUserSettingsMetadata {
  @JsonProperty('userSettings', UserSettings)
  userSettings: UserSettings = new UserSettings();

  @JsonProperty('departments', [Department])
  departments: Department[] = [];

  @JsonProperty('locales', [Language])
  locales: Language[] = [];

  @JsonProperty('timeZones', [String])
  timeZones: string[] = [];

  @JsonProperty('afterLoginPages', [String])
  afterLoginPages: string[] = [];

  @JsonProperty('notificationSubscriptionConfigurations', [String])
  notificationSubscriptionConfigurations: string[] = [];

  @JsonProperty('minimumPasswordLength', Number)
  minimumPasswordLength: number = 1;

  @JsonProperty('enforceSecurePassword', Boolean)
  enforceSecurePassword: boolean = false;
}
