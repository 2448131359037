import store from "@/main/webapp/vue/store";
import configuration from "@/main/webapp/vue/config/application/configuration";
import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
import { ShareItem } from "@/main/webapp/vue/model/api/ShareItem";

export class GenerateShareHTMLService {

  protected shareClearButtonKey: string = "#share-clear-button";
  protected shareButtonKey: string = "#share-button";
  protected shareContinueKey: string = "#share-continue";
  protected shareBasicDataKey: string = "#share-basic-data";
  protected shareNotificationButtonKey: string = "#share-notification-button";
  protected shareCounterKey: string = "#share-counter";
  protected shareCountKey: string = "#share-count";
  protected thumbnailsKey: string = ".thumbnails";
  protected removeSelectedItemKey: string = "#remove-thumbnail";
  protected pageInit: boolean = false;

  public getHTMLElement(element: HTMLElement | null, key: string): HTMLElement | null {
    if (element) {
      return element.querySelector(key);
    }
    return document.querySelector(key);
  }

  public openShareOutbox(): void {
    // @ts-ignore
    $('#share-modal').modal(); // from bootstrap & snapshop.js
  }

  public thumbnailHTML(contentEntry: ItemContent): string {
    const thumbWidth: number = configuration.properties.content.image.size.thumbnail.width;
    const thumbHeight: number = configuration.properties.content.image.size.thumbnail.height;
    const thumbnailLink: NavigationLink | undefined = contentEntry.nav?.getLink("thumbnail");

    let innerHTML: string = ``;
    innerHTML += `
          <li class="thumbnail" id="share-thumb-${contentEntry.token}">
            <button type="button" class="remove" onclick="removeFromShare(${contentEntry.id}, '${contentEntry.token}', 
                    $(this).parent())"
                    data-id="${contentEntry.id}">&times;</button>
            <a class="image" href="/item.html?iid=${contentEntry.id}">
              <div class="img-wrapper" style="width:${thumbWidth}px;line-height:${thumbHeight}px;height:${thumbHeight}px;text-align:center;">`;

    if (thumbnailLink) {
      innerHTML += `<img src=""
                          data-src="${thumbnailLink?.href}"
                          width="${thumbWidth}" height="${thumbHeight}" alt=""  />`;
    } else {
      innerHTML += `<span class="filetypes filetypes-${contentEntry.mimetype} filetypes-unknown glyphicons-big"></span>`;
    }

    innerHTML += `
              </div>
            </a>
          </li>`;

    return innerHTML;
  }

  public setDisplayByContentsLength(key: string, shareModal: HTMLElement, item: ShareItem): void {
    let element: HTMLElement | null = this.getHTMLElement(shareModal, key);
    let display: string = "none";
    if (element) {
      if (item && item.contents && item.contents.length > 0) {
        display = "block";
      }

      element.style.display = display;
    }
  }

  public prepareShareButtons(shareModal: HTMLElement, item: ShareItem): void {
    this.setDisplayByContentsLength(this.shareClearButtonKey, shareModal, item);
    this.setDisplayByContentsLength(this.shareButtonKey, shareModal, item);
  }

  public prepareShareBasicData(shareModal: HTMLElement, item: ShareItem): void {
    let shareBasicData: HTMLElement | null = this.getHTMLElement(shareModal, this.shareBasicDataKey);
    let display: string = "none";
    if (shareBasicData) {
      if (item && item.contents && item.contents.length > 0) {
        display = "block";
      }

      shareBasicData.style.display = display;
    }
  }

  public prepareThumbnails(shareModal: HTMLElement, item: ShareItem): void {
    let thumbnails: HTMLElement | null = this.getHTMLElement(shareModal, this.thumbnailsKey);
    if (thumbnails) {
      thumbnails.innerHTML = "";

      let thumbnailsHTML: string = "";
      if (item && item.contents) {
        item.contents.forEach((content: ItemContent): void => { // itemId should be from backend
          thumbnailsHTML += this.thumbnailHTML(content);
        });
      }

      thumbnails.innerHTML = thumbnailsHTML;
    }
  }

  public prepareShareAlert(shareModal: HTMLElement, item: ShareItem): void {
    let shareAlert: HTMLElement | null = this.getHTMLElement(shareModal, "#share-alert");
    let display: string = "none";

    if (shareAlert) {
      if (!item.contents || item.contents.length === 0) {
        display = "block";
      }

      shareAlert.style.display = display;
    }
  }

  public updateToClearOrRemoveInStore(id: number | null = null): void {
    if (id) {
      store.dispatch('updateRemoveSelectedSubmission', id);
    } else {
      store.dispatch('updateClearSubmissions');
    }
  }

  public setCountValue(target: HTMLElement | null, value: number = 0, toggleDisplay: boolean = false): void {
    if (target) {
      target.innerHTML = `${value}`;

      if (toggleDisplay) {
        if (value > 0) {
          target.style.display = "block";
        } else {
          target.style.display = "none";
        }
      }
    }
  }

  public prepareShareCounter(shareModal: HTMLElement, item: ShareItem): void {
    const count: number = item && item.contents ? item.contents.length : 0;
    // shareCounter in outbox
    this.setCountValue(this.getHTMLElement(shareModal, this.shareCounterKey), count);
    // shareCounter in page
    this.setCountValue(this.getHTMLElement(null, this.shareCountKey), count, true);
  }

  public listeningButtons(shareModal: HTMLElement): void {
    if (!this.pageInit) { // To avoid multiple listener
      this.pageInit = true;

      const shareClearButton: HTMLElement | null = this.getHTMLElement(shareModal, this.shareClearButtonKey);
      if (shareClearButton) {
        shareClearButton.addEventListener('click', () => {
          this.updateToClearOrRemoveInStore(null);
        });
      }

      const removeButton: HTMLElement | null = this.getHTMLElement(null, this.removeSelectedItemKey);
      if (removeButton) {
        removeButton.addEventListener('click', () => {
          const id: string | null = removeButton.getAttribute('data-id');
          if (id) {
           this.updateToClearOrRemoveInStore(parseInt(id));
          }
        });
      }

      const shareNotificationButton: HTMLElement | null = this.getHTMLElement(shareModal, this.shareNotificationButtonKey);
      if (shareNotificationButton) {
        shareNotificationButton.addEventListener('click', () => {
          this.updateToClearOrRemoveInStore(null);
        });
      }
    }
  }

  public prepareShareOutbox(item: ShareItem): void {
    const shareModal: HTMLElement | null = this.getHTMLElement(null, "#share-modal");
    if (shareModal) {
      this.prepareShareCounter(shareModal, item);
      this.prepareShareAlert(shareModal, item);
      this.prepareThumbnails(shareModal, item);
      this.prepareShareBasicData(shareModal, item);
      this.prepareShareButtons(shareModal, item);
      this.openShareOutbox();

      this.listeningButtons(shareModal);
    }
  }
}

export const generateShareHTMLService: GenerateShareHTMLService = new GenerateShareHTMLService();
