import { JsonObject, JsonProperty } from "json2typescript";
import { User } from "./User";
import { DateTime } from "./DateTime";
import { Item } from "./Item";

@JsonObject('ItemComment')
export class ItemComment {
  @JsonProperty('text', String)
  text?: string = undefined;

  @JsonProperty('item', Item)
  item?: Item = undefined;

  @JsonProperty('user', User)
  user?: User = undefined;

  @JsonProperty('created', DateTime)
  created?: DateTime = undefined;
}
