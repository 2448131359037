
  import Vue, { PropType } from 'vue';
  // FIXME: This stopped working: import sanitizeHtml from 'sanitize-html';
  import messages from '../messages.json';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import ContentEditorWidgetModel, { ContentEditorWidgetProperties } from "@/main/webapp/vue/components/learning/widget/widgets/content-editor/model/ContentEditorWidgetModel";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'ContentEditorWidget',
    props: {
      model: {
        type: Object as PropType<ContentEditorWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        mounted: false as boolean,
        tempHtml: this.model.data.html as string
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): ContentEditorWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedEditingStatus(): boolean {
        if (this.mounted) {
          return this.editing;
        }

        return true;
      },
      sanitizedHtml(): string { // double sanitized
        return this.model.data.html; /* FIXME: This stopped working: sanitizeHtml(this.model.data.html, {
          allowedClasses: { // allow all css to have quill css
            '*': [ '*' ]
          }
        }); */
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      sanitizedHtmlAndSave() { // So the data to be saved as sanitized html
        this.model.data.html = this.tempHtml; /* FIXME: This stopped working: sanitizeHtml(this.tempHtml, {
          allowedClasses: { // allow all css to have quill css
            '*': [ '*' ]
          }
        }); */
      },
      startEditing(): void {
        if (this.editable) {
          this.$emit('edit', this.model);
        }
      }
    },
    mounted() {
      this.$nextTick(() => { // To have quil css loaded
        this.mounted = true;
      });
    },
    i18n: {
      messages: messages
    }
  });
