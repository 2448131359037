
  import Vue from 'vue';
  import { mapState } from "vuex";

  import ContentUpload from '@/main/webapp/vue/components/ui/content/content-upload/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";

  import messages from "./messages.json";

  export default Vue.extend({
    components: {
      ContentUpload
    },
    data: function() {
      return {
        container: {} as ItemContentEntityContainer
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      fetchLogos(): void {
        BackendIntegrationService.listLogos(this.userDetails.organization).then((response: ItemContentEntityContainer) => {
          this.container = response;
        });
      },
      refreshLogo(newLogo: ItemContentEntity): void {
        setTimeout(() => {
          location.reload(); // Ugly hack to show the actual logo updated in the header
        }, 2000);
      }
    },
    mounted(): void {
      this.fetchLogos();
    },
    i18n: {
      messages: messages
    }
  });
