import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";
import { NavigationLink } from "./NavigationLink";
import { ProjectStatusLabel } from "./ProjectStatusLabel";

@JsonObject('Project')
export class Project extends NamedEntity {

  @JsonProperty('status', ProjectStatusLabel, true)
  status: ProjectStatusLabel = new ProjectStatusLabel();

  @JsonProperty('annotation', String, true)
  annotation: string | undefined = undefined;

  // TODO: Backend should wrap under nav
  @JsonProperty('links', [NavigationLink], true)
  links: NavigationLink[] = [];

}
