
  import Vue, { PropType } from 'vue';
  import feed from "@/main/webapp/vue/views/feed/feed.vue";
  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";

  export default Vue.extend({
    props: {
      searchCriteria: {
        type: Object as PropType<SearchCriteria>,
        default: null
      },
      showSearchSubmissions: {
        type: Boolean,
        default: false
      },
      showFeaturedSubmission: {
        type: Boolean,
        default: false
      }
    },
    components: {
      feed
    },
    computed: {
      computedSearchCriteria(): SearchCriteria | null {
        if (this.showSearchSubmissions || this.enforce) {
          return this.searchCriteria;
        }
        return null;
      }
    },
    data() {
      return {
        enforce: false as boolean
      };
    },
    methods: {
      enforceFilterData(enforce: boolean): void {
        this.enforce = enforce;
      }
    }
  });
