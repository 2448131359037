import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('UserSession')
export class UserSession {

  @JsonProperty('headerName', String, false)
  headerName: string = 'Cookie';

  @JsonProperty('tokenType', String, false)
  tokenType: string = 'JSESSIONID';

  @JsonProperty('token', String, false)
  token?: string = undefined;

  @JsonProperty('separator', String, false)
  tokenSeparator: string = '=';

  public static isValid(other: UserSession): boolean {
    return <boolean>(other && other.token && other.token.length > 0);
  }

  /*
   * Formats the header to be sent back to the backend, depending on the data given by the backend itself
   * For example:
   * JSESSIONID=DKASMDAMDAKSDMASASADASDDAASAS
   * Bearer asdmskasmdaksdmkasdmaksdmaksd
   * Basic kfdlkfdsf
   */
  public static formatSessionHeader(other: UserSession): string {
    if (!UserSession.isValid(other)) {
      throw new Error("Not valid token");
    }

    let sessionHeader = '';
    if (other.tokenType && other.tokenType.length > 0) {
      sessionHeader = other.tokenType + other.tokenSeparator;
    }

    sessionHeader += other.token;

    return sessionHeader;
  }
}
