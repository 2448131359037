
  import Vue from "vue";
  import { mapState } from "vuex";
  import messages from './messages.json';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { AuthenticationResult } from "@/main/webapp/vue/model/api/AuthenticationResult";
  import { NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";

  import notification from "@/main/webapp/vue/notification";
  import materialIconText from "@/main/webapp/vue/components/ui/icon/material-icon-text/index.vue";

  export default Vue.extend({
    components: {
      materialIconText
    },
    props: {
      loginName: {
        type: String,
        default: null // Not required, if not passed, then we use the link to assume the user
      },
      destination: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      asIcon: {
        type: Boolean,
        default: true
      },
      colorStyle: {
        type: String,
        default: "light"
      }
    },
    data() {
      return {
        loading: false
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      redirectToDestination(): void {
        if (this.destination !== null) {
          window.location.href = this.destination;
        } else {
          location.reload();
        }
      },
      assume(): void {
        let assumeLink = this.userDetails.nav.getLink(NavigationLinkType.ASSUME_USER);
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Assume user with link '${assumeLink}'`);
        }

        if (assumeLink) {
          this.loading = true;
          BackendIntegrationService.assume(this.loginName, assumeLink)
            .then((response: AuthenticationResult) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(response);
              }

              this.redirectToDestination();
            }).catch((error: BackendError) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`Assume user '${this.loginName}' failed`, error);
              }

              let message: string = error.message;
              if (message === 'system.error.assume-user.not-found') {
                notification.warn(this.$t(message));
              } else {
                notification.fail(this.$t(message, String(error.exception)));
              }
            }).finally(() => {
              setTimeout(() => {
                this.loading = false; // Let's keep the loading a bit longer
              }, 2000);
            });
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Assume not allowed for logged in user");
          }
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
