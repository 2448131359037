import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('ThemableComponent')
export class ThemableComponent {

  @JsonProperty('foregroundColor', String, true)
  foregroundColor?: string = undefined;

  @JsonProperty('backgroundColor', String, true)
  backgroundColor?: string = undefined;

  @JsonProperty('padding', String, true)
  padding?: string = undefined;

  @JsonProperty('height', Number, true)
  height?: number = undefined;

  @JsonProperty('name', String, true)
  name?: string = undefined;

  fromDefaults(defaults: any): void {
    this.foregroundColor = defaults.foregroundColor;
    this.backgroundColor = defaults.backgroundColor;
    this.padding = defaults.padding;
    this.height = defaults.height;
  }

  copy(other: ThemableComponent): void {
    this.name = other.name;
    this.foregroundColor = other.foregroundColor;
    this.backgroundColor = other.backgroundColor;
    this.padding = other.padding;
    this.height = other.height;
  }

}
