
  import Vue, { PropType } from 'vue';
  import { Chrome } from 'vue-color';
  import messages from '../messages.json';

  import formCard from '@/main/webapp/vue/components/ui/form/card/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';

  import CollapsibleStyleModel
    from "@/main/webapp/vue/components/ui/style/collapsible-button/model/CollapsibleStyleModel";

  export default Vue.extend({
    name: 'CollapsibleButtonStyle',
    props: {
      collapsibleButtonStyle: {
        type: Object as PropType<CollapsibleStyleModel>,
        default: () => new CollapsibleStyleModel()
      },
      defaultCollapsibleButtonStyle: { // Widget should set default style
        type: Object as PropType<CollapsibleStyleModel>, // default value is only for branding setting
        default: () => new CollapsibleStyleModel().default()
      },
      title: {
        type: String
      },
      enableBackgroundColor: {
        type: Boolean,
        default: true
      },
      enableTextColor: {
        type: Boolean,
        default: true
      },
      enableBorderRadius: {
        type: Boolean,
        default: true
      },
      useCollapsibleContainer: {
        type: Boolean,
        default: true
      },
      alignContentsVertically: {
        type: Boolean,
        default: true
      },
      busy: {
        type: Boolean,
        default: false
      },
      showHeaderStyle: {
        type: Boolean,
        default: true
      },
      showBodyStyle: {
        type: Boolean,
        default: true
      }
    },
    components: {
      formCard,
      collapsibleContainer,
      'chrome-picker': Chrome
    },
    computed: {
      component() {
        if (this.useCollapsibleContainer) {
          return 'collapsible-container';
        }
        return 'form-card';
      },
      computedShowTitle(): boolean {
        if (this.component === 'collapsible-container') {
          return false;
        }

        return true;
      }
    },
    i18n: {
      messages: messages
    }
  });
