
  import Vue from 'vue';
  import messages from './messages.json';

  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";
  import textCallout from "@/main/webapp/vue/components/ui/text/text-callout/index.vue";
  import materialIconText from "@/main/webapp/vue/components/ui/icon/material-icon-text/index.vue";

  export default Vue.extend({
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      noResult: {
        type: Boolean,
        default: false
      },
      lastArticle: {
        type: Boolean,
        default: false
      }
    },
    components: {
      materialIconText,
      textCallout,
      loadingIcon
    },
    i18n: {
      messages: messages
    }
  });
