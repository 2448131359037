import { JsonObject, JsonProperty } from "json2typescript";
import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
import { LatLngTuple } from "leaflet";

@JsonObject('MapMarkerContainer')
export class MapMarkerContainer {
  @JsonProperty('markers', [MapMarker], true)
  markers?: MapMarker[] = undefined;

  @JsonProperty('markerColors', [String], true)
  markerColors?: string[] = undefined;
}
