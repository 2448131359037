import { JsonObject, JsonProperty } from "json2typescript";
import { Entity } from "./Entity";
import { ColoredLabel } from "./ColoredLabel";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('NamedEntity')
export class NamedEntity extends Entity {

  @JsonProperty('name', String, true)
  name?: string = undefined;

  @JsonProperty('label', ColoredLabel, true)
  label?: ColoredLabel = undefined;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
