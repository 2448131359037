
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import messages from "./messages.json";

  import contentUpload from '@/main/webapp/vue/components/ui/content/content-upload/index.vue';
  import buttonStyle from "@/main/webapp/vue/components/ui/style/button/container/index.vue";
  import collapsibleButtonStyle from "@/main/webapp/vue/components/ui/style/collapsible-button/container/index.vue";
  import textStyle from '@/main/webapp/vue/components/ui/style/text/container/index.vue';
  import sectionStyle from '@/main/webapp/vue/components/ui/style/section/container/index.vue';
  import formCard from '@/main/webapp/vue/components/ui/form/card/index.vue';

  import notification from "@/main/webapp/vue/notification";

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";

  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { CourseBranding } from "@/main/webapp/vue/model/api/learning/CourseBranding";
  import { CourseBrandingStyle, CourseBrandingStyleType } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
  import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
  import { CourseBrandingCss } from "@/main/webapp/vue/model/api/learning/CourseBrandingCss";

  export default Vue.extend({
    components: {
      contentUpload,
      sectionStyle,
      buttonStyle,
      collapsibleButtonStyle,
      textStyle,
      formCard
    },
    props: {
      course: {
        type: Object as PropType<CourseModel>,
        required: false
      }
    },
    data: function() {
      return {
        logoContainer: {} as ItemContentEntityContainer,
        cssStyle: {} as CourseBrandingCss,
        brandingStylesType: {
          logo: CourseBrandingStyleType.LOGO,
          button: CourseBrandingStyleType.BUTTON,
          collapsible: CourseBrandingStyleType.COLLAPSIBLE,
          text: CourseBrandingStyleType.TEXT,
          section: CourseBrandingStyleType.SECTION,
          css: CourseBrandingStyleType.CSS
        },
        updatingStyleType: null as null | CourseBrandingStyleType
      };
    },
    computed: {
      ...mapState([
        'userDetails',
        'courseDefaultStyles'
      ])
    },
    methods: {
      updateLogos(): void {
        this.$emit('course-branding-updated', CourseBrandingStyleType.LOGO);
      },
      updateCss(type: CourseBrandingStyleType): void {
        this.updatingStyleType = type;

        const course: Course = Course.from(this.course);
        LearningIntegrationService.updateCourseBrandingCss(course, this.cssStyle)
          .catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(e);
            }
            notification.fail(this.$t('text.error.update-css'));
          }).finally(() => {
            this.updatingStyleType = null;
            this.$emit('course-branding-updated', CourseBrandingStyleType.CSS, this.cssStyle);
          });
      },
      updateStyles(type: CourseBrandingStyleType, newStyle: CourseBrandingStyle): void {
        this.updatingStyleType = type;
        const course: Course = Course.from(this.course);
        const style: CourseBrandingStyle = CourseBrandingStyle.find(type, this.courseDefaultStyles);
        style.data = newStyle;

        LearningIntegrationService.updateCourseBrandingStyle(course, style)
          .then((updatedStyle: CourseBrandingStyle) => {
            this.$store.commit('updateCourseDefaultStyle', updatedStyle);
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(e);
            }
            notification.fail(this.$t('text.error.update-styles'));
          }).finally(() => {
            this.updatingStyleType = null;
            this.$emit('course-branding-updated', type, newStyle);
          });
      }
    },
    mounted(): void {
      const course: Course = Course.from(this.course);
      LearningIntegrationService.fetchCourseBranding(course).then((branding: CourseBranding) => {
        if (branding.images) {
          this.logoContainer = branding.images;
        }

        if (branding.cssStyle) {
          this.cssStyle = branding.cssStyle;
        }

        if (branding && branding.defaultStyles) { // Deserialize & update store
          this.$store.dispatch('updateCourseDefaultStyles',
                               CourseBranding.deserializeDefaultStyle(branding.defaultStyles));
        }
      }).catch((e: Error) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(e);
        }
        notification.fail(this.$t('text.error.branding'));
      });
    },
    i18n: {
      messages: messages
    }
  });
