
  import Vue, { PropType } from 'vue';
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import projectPopup from '@/main/webapp/vue/components/map/marker/marker-popup/project-popup/index.vue';

  import { MapProjectDetails } from "@/main/webapp/vue/model/api/Map/MapProjectDetails";

  export default Vue.extend({
    props: {
      item: {
        type: Object as PropType<MapProjectDetails>
      },
      chid: {
        type: Number
      },
      editShop: {
        type: Boolean,
        default: false
      },
      editLocationText: {
        type: String,
        default: ''
      }
    },
    components: {
      projectPopup
    },
    data() {
      return {};
    },
    i18n: {
      messages: messages
    }
  });
