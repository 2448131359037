
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import textStyle from "@/main/webapp/vue/components/ui/style/text/container/index.vue";
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import LinkExternalWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/link-external/model/LinkExternalWidgetModel';

  export default Vue.extend({
    name: 'LinkExternalWidgetProperties',
    props: {
      model: {
        type: Object as PropType<LinkExternalWidgetModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      textStyle,
      genericStyle
    },
    computed: {
      destinationState(): boolean {
        if (this.model.data.destination !== '' && this.model.data.destination !== '#') {
          return this.validateURL(this.model.data.destination);
        }

        return true;
      }
    },
    methods: {
      validateURL(urlToValidate: string): boolean {
        // Source https://cran.r-project.org/web/packages/rex/vignettes/url_parsing.html
        const validationRegularExpression = '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$';
        return urlToValidate.match(validationRegularExpression) !== null;
      }
    },
    i18n: {
      messages: messages
    }
  });
