
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      target: {
        type: HTMLElement,
        required: true
      },
      resize: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showScroll: true as boolean,
        targetElement: this.target as HTMLElement | null,
        scrollCloneContainer: null as HTMLElement | null
      };
    },
    watch: {
      resize(): void {
        this.setScrollWidth();
      }
    },
    methods: {
      handleScroll(scrollLeft: number = 0, fromClone: boolean = false): void {
        const targetElement: HTMLElement | null = fromClone ? this.targetElement : this.scrollCloneContainer;
        if (targetElement) {
          targetElement.scrollTo(scrollLeft, this.targetElement ? this.targetElement.scrollTop : 0);
        }
      },
      registerScrollEvent(): void {
        if (this.scrollCloneContainer) {
          this.scrollCloneContainer.addEventListener('scroll', () => {
            this.handleScroll(this.scrollCloneContainer?.scrollLeft, true);
          });
        }

        if (this.targetElement) {
          this.targetElement.addEventListener('scroll', () => {
            this.handleScroll(this.targetElement?.scrollLeft, false);
          });
        }

        window.addEventListener('resize', this.setScrollWidth);
      },
      unregisterScrollEvent(): void {
        if (this.scrollCloneContainer) {
          this.scrollCloneContainer.removeEventListener('scroll', () => {});
        }

        if (this.targetElement) {
          this.targetElement.removeEventListener('scroll', () => {});
        }

        window.removeEventListener('resize', this.setScrollWidth);
      },
      setScrollWidth(): void {
        if (this.targetElement) {
          this.scrollCloneContainer = this.$refs["horizontal-scroll-clone-container"] as HTMLElement | null;
          const scrollCloneElement = this.$refs["horizontal-scroll-clone"] as HTMLElement | null;
          if (scrollCloneElement) {
            scrollCloneElement.style.width = `${this.targetElement.scrollWidth}px`;

            const cloneWidth: number = this.scrollCloneContainer ? this.scrollCloneContainer.scrollWidth : 0;
            this.showScroll = !(cloneWidth > this.targetElement.scrollWidth);
          }
        }
      },
      init(): void {
        this.setScrollWidth();
        this.registerScrollEvent();
      }
    },
    mounted(): void {
      // Just call init() is not synced with table loading etc. So use this two
      window.onload = () => { // Switched from navigation bar
        this.init();
      };

      if (document.readyState === "complete") { // Switched from submissions-switcher
        this.init();
      }
    },
    beforeDestroy(): void {
      this.unregisterScrollEvent();
    }
  });
