import { JsonObject, JsonProperty } from "json2typescript";
import { EntityViewMetadata } from "./EntityViewMetadata";

@JsonObject('Metadata')
export class Metadata {

  @JsonProperty('metadata', EntityViewMetadata, true)
  metadata?: EntityViewMetadata = undefined;

}
