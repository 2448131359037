
  import Vue from 'vue';
  import { v1 as uuidv1 } from 'uuid';

  export default Vue.extend({
    name: 'CollapsibleContainer',
    props: {
      title: {
        type: String,
        default: ''
      },
      badge: {
        type: Number,
        default: 0
      },
      visibleByDefault: {
        type: Boolean,
        default: false
      },
      customTitleWidth: {
        type: Number,
        default: 100
      },
      enableCollapsible: {
        type: Boolean,
        default: true
      },
      bodyPadding: {
        type: String,
        default: '15px 0'
      },
      styleClass: {
        type: String,
        default: 'mt-3 mb-3'
      }
    },
    data() {
      return {
        visible: this.visibleByDefault as boolean,
        collapsibleId: `collapsible-${uuidv1()}`
      };
    },
    methods: {
      toggleVisible(): void {
        if (this.enableCollapsible) {
          this.visible = !this.visible;
        }
      }
    }
  });
