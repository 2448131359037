import { JsonObject, JsonProperty } from "json2typescript";
import { ShopRegistryDataSourceProvider } from "@/main/webapp/vue/model/api/ShopRegistryDataSourceProvider";
import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";

@JsonObject('ShopRegistryDataSource')
export class ShopRegistryClient extends EntityRegistryClient {

  @JsonProperty('provider', ShopRegistryDataSourceProvider, false)
  provider: ShopRegistryDataSourceProvider = new ShopRegistryDataSourceProvider();

  @JsonProperty('lastExecuted', String, true)
  lastExecuted?: string = undefined;

}
