export function grouped<T>(array: T[], size: number) {
  const result: T[][] = [];
  array.forEach((item, index) => {
    const resultIndex = Math.floor(index / size);
    if (!result[resultIndex]) {
      result[resultIndex] = [];
    }
    result[resultIndex].push(item);
  });
  return result;
}

/*
 * Converts a JSOM MAP into a string in the format:
 *   Key0: Value0
 *   Key1: Value1
 *   ...
 *   KeyN: ValueN
 *
 * In order to get this nicely rendered in HTML,
 * apply the following CSS:
 *   white-space: pre-wrap;
 */
export function jsonMapToKeyValueString(jsonMap: string, formatter?: Function): string {
  let result = '';
  if (jsonMap != null) {
    let map = new Map<string, string>(Object.entries(jsonMap));
    map.forEach((k: string, v: string) => {
      result += (formatter ? formatter(v, k) : `${v}: ${k}\n`);
    });
  }
  return result;
}
