import { WidgetRegistration } from '@/main/webapp/vue/components/learning/widget/model/WidgetRegistration';

export default class WidgetFamily {
  widgets: WidgetRegistration[];

  private constructor(
    readonly name: string,
    readonly visibleByDefault: boolean
  ) {
    this.widgets = [];
  }

  addWidget(widget: WidgetRegistration) {
    const index = this.widgets.findIndex(({ name }) => name.localeCompare(widget.name) > 0);
    if (index < 0) {
      this.widgets.push(widget);
    } else {
      this.widgets.splice(index, 0, widget);
    }
  }

  static FAMILIES: WidgetFamily[] = [];

  static of(name: string, visibleByDefault: boolean) {
    const family = new WidgetFamily(name, visibleByDefault);
    WidgetFamily.FAMILIES.push(family);
    return family;
  }

  static BASIC = WidgetFamily.of('basic', true);

  static LAYOUT = WidgetFamily.of('layout', false);

  static MEDIA = WidgetFamily.of('media', false);

  static NAVIGATION = WidgetFamily.of('navigation', false);

  static SHARE = WidgetFamily.of('share', false);

  static ADVANCED = WidgetFamily.of('advanced', false);
}
