
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";

  import { oc as Optional } from "ts-optchain";

  import reportingReport from "@/main/webapp/vue/components/reporting/reporting-report/index.vue";
  import reportingProgress from "@/main/webapp/vue/components/reporting/reporting-progress/index.vue";

  import { ReportStatus } from "@/main/webapp/vue/model/api/report/ReportStatus";
  import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
  import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";
  import DownloadTypeFactory, { DownloadType } from "@/main/webapp/vue/model/downloadType";
  import { ListViewSelectedField } from "@/main/webapp/vue/model/api/ListViewSelectedField";

  import messages from './messages.json';

  const Status = {
    IDLE: ReportStatus.IDLE,
    SUBSCRIBING: ReportStatus.SUBSCRIBING,
    GENERATING: ReportStatus.GENERATING,
    FINISHED: ReportStatus.FINISHED
  };

  export default Vue.extend({
    components: {
      reportingReport,
      reportingProgress
    },
    props: {
      isListView: {
        type: Boolean,
        required: false
      },
      searchCriteriaSelectedDataValues: {
        type: Array as PropType<SearchCriteriaSelectedDataValue[]>,
        required: false
      },
      listViewSelectedSubmissionIds: {
        type: Array as PropType<Number[]>,
        required: false
      },
      listViewSelectedFields: {
        type: Array as PropType<ListViewSelectedField[]>,
        required: false
      },
      contentPackageEnabled: {
        type: Boolean,
        default: false
      },
      contentReportEnabled: {
        type: Boolean,
        default: false
      },
      rawDataReportEnabled: {
        type: Boolean,
        default: false
      },
      checkInReportEnabled: {
        type: Boolean,
        default: false
      },
      fraudDataReportEnabled: {
        type: Boolean,
        default: false
      },
      shopExportEnabled: {
        type: Boolean,
        default: false
      },
      itemCount: {
        type: Number,
        default: 0
      }
    },
    computed: {
      ...mapState([
        'reportStatuses'
      ]),
      generating(): boolean {
        return this.contentPackageStatus === ReportStatus.GENERATING || this.contentReportStatus === ReportStatus.GENERATING || this.rawDataReportStatus === ReportStatus.GENERATING || this.checkInReportStatus === ReportStatus.GENERATING || this.fraudDataReportStatus === ReportStatus.GENERATING || this.shopExportStatus === ReportStatus.GENERATING;
      },
      showToast(): String {
        const show: boolean = this.contentPackageStatus !== ReportStatus.IDLE || this.contentReportStatus !== ReportStatus.IDLE || this.rawDataReportStatus !== ReportStatus.IDLE || this.checkInReportStatus !== ReportStatus.IDLE || this.fraudDataReportStatus !== ReportStatus.IDLE || this.shopExportStatus !== ReportStatus.IDLE;
        return show ? '' : 'd-none';
      }
    },
    created() {
      this.checkReportStatuses();
    },
    data() {
      return {
        contentPackage: DownloadTypeFactory.prototype.CONTENT_PACKAGE() as DownloadType,
        contentReport: DownloadTypeFactory.prototype.CONTENT_REPORT() as DownloadType,
        rawDataReport: DownloadTypeFactory.prototype.RAW_DATA_REPORT() as DownloadType,
        checkInReport: DownloadTypeFactory.prototype.CHECK_IN_REPORT() as DownloadType,
        fraudDataReport: DownloadTypeFactory.prototype.FRAUD_DATA_REPORT() as DownloadType,
        shopExport: DownloadTypeFactory.prototype.SHOP_EXPORT() as DownloadType,
        contentPackageStatus: ReportStatus.IDLE as ReportStatus,
        contentReportStatus: ReportStatus.IDLE as ReportStatus,
        rawDataReportStatus: ReportStatus.IDLE as ReportStatus,
        checkInReportStatus: ReportStatus.IDLE as ReportStatus,
        fraudDataReportStatus: ReportStatus.IDLE as ReportStatus,
        shopExportStatus: ReportStatus.IDLE as ReportStatus,
        Status
      };
    },
    watch: {
      contentPackageStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        this.contentPackageStatus = newValue;

        const payload = {
          key: 'contentPackage',
          value: newValue
        };

        this.updateReportStatus(payload);
      },
      contentReportStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        this.contentReportStatus = newValue;

        const payload = {
          key: 'contentReport',
          value: newValue
        };

        this.updateReportStatus(payload);
      },
      rawDataReportStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        this.rawDataReportStatus = newValue;

        const payload = {
          key: 'rawDataReport',
          value: newValue
        };

        this.updateReportStatus(payload);
      },
      checkInReportStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        this.checkInReportStatus = newValue;

        const payload = {
          key: 'checkInReport',
          value: newValue
        };

        this.updateReportStatus(payload);
      },
      fraudDataReportStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        this.fraudDataReportStatus = newValue;

        const payload = {
          key: 'fraudDataReport',
          value: newValue
        };

        this.updateReportStatus(payload);
      },
      shopExportStatus: function(newValue: ReportStatus, oldValue: ReportStatus) {
        this.shopExportStatus = newValue;

        const payload = {
          key: 'shopExport',
          value: newValue
        };

        this.updateReportStatus(payload);
      }
    },
    methods: {
      checkReportStatuses(): void {
        this.refreshReportStatuses();

        if (this.generating) {
          if (process.env.NODE_ENV !== 'production') {
            console.log("One or more reports already being generated on startup");
          }
        }
      },
      refreshReportStatuses(): void {
        this.contentPackageStatus = Optional(this.reportStatuses).contentPackage(ReportStatus.IDLE);
        this.contentReportStatus = Optional(this.reportStatuses).contentReport(ReportStatus.IDLE);
        this.rawDataReportStatus = Optional(this.reportStatuses).rawDataReport(ReportStatus.IDLE);
        this.checkInReportStatus = Optional(this.reportStatuses).checkInReport(ReportStatus.IDLE);
        this.fraudDataReportStatus = Optional(this.reportStatuses).fraudDataReport(ReportStatus.IDLE);
        this.shopExportStatus = Optional(this.reportStatuses).shopExport(ReportStatus.IDLE);
      },
      updateReportStatus(payload: any): void {
        this.$store.commit('updateReportStatus', payload);
      }
    },
    i18n: {
      messages: messages
    }
  });
