import WidgetModel from './WidgetModel';

import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
import ActivityProperties from "@/main/webapp/vue/model/learning/ActivityProperties";
import Znapson from '@znapio/znapson';

export type ActivityIdType = string | number | undefined;

export default class ActivityModel {

  name: string;
  sequence: number;
  urlPathFragment: string;
  urlPath: string;
  active: boolean;
  properties: ActivityProperties;
  subactivities: ActivityModel[];
  hasSubactivities: boolean;
  widgets: WidgetModel[];
  baseUrl: string;

  constructor(public id: ActivityIdType,
              name: string,
              sequence: number = 0,
              urlPathFragment: string = "/",
              urlPath: string = '',
              active: boolean = true,
              properties: ActivityProperties = new ActivityProperties(),
              subactivities: ActivityModel[] = [],
              widgets: WidgetModel[] = [],
              baseUrl: string = "",
              hasSubactivities: boolean = false) {
    this.name = name;
    this.sequence = sequence;
    this.urlPathFragment = urlPathFragment;
    this.urlPath = urlPath;
    this.active = active;
    this.properties = properties;
    this.subactivities = subactivities;
    this.widgets = widgets;
    this.baseUrl = baseUrl;
    this.hasSubactivities = hasSubactivities;
  }

  public updateId(id: number): void {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`Update activity ID from ${this.id} to ${id}`);
    }
    this.id = id;
  }

  public from(activityModel: ActivityModel): void {
    this.id = activityModel.id;
    this.name = activityModel.name;
    this.sequence = activityModel.sequence;
    this.urlPathFragment = activityModel.urlPathFragment;
    this.urlPath = activityModel.urlPath;
    this.active = activityModel.active;
    this.properties = activityModel.properties;
    this.subactivities = activityModel.subactivities;
    this.widgets = activityModel.widgets;
    this.baseUrl = activityModel.baseUrl;
    this.hasSubactivities = activityModel.hasSubactivities;
  }

  public static from(activity: Activity): ActivityModel {
    return new ActivityModel(activity.id,
                             activity.name || "",
                             activity.sequence || 0,
                             activity.urlPathFragment,
                             activity.urlPath,
                             activity.active,
                             Znapson.deserializeOptional(activity.properties, ActivityProperties),
                             this.fromArray(activity.subactivities),
                             Znapson.deserialize<WidgetModel, [WidgetModel]>(activity.contents, [WidgetModel]),
                             activity.baseUrl,
                             activity.hasSubactivities);
  }

  public static fromArray(activities: Activity[]): ActivityModel[] {
    return (activities || []).map((activity: Activity) => {
      return this.from(activity);
    });
  }

}
