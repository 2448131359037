import { JsonField } from '@znapio/znapson';
import TextStyleModel from "@/main/webapp/vue/components/ui/style/text/model/TextStyleModel";
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";

export default class TextStyleWidgetProperties {
  constructor(@JsonField('textStyle', TextStyleModel, true)
              public textStyle = new TextStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}
