
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";
  import dateText from '@/main/webapp/vue/components/ui/text/text-date/index.vue';

  import { Item } from "@/main/webapp/vue/model/api/Item";

  export default Vue.extend({
    components: {
      glyphiconSymbol,
      dateText
    },
    props: {
      article: {
        type: Object as PropType<Item>,
        required: true
      }
    },
    i18n: {
      messages: messages
    }
  });
