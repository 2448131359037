import { JsonObject, JsonProperty } from "json2typescript";
import { ProjectStatus } from "./ProjectStatus";
import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";

@JsonObject('ProjectProgress')
export class ProjectProgress {

  @JsonProperty('id', Number, true)
  projectId?: number = undefined;

  @JsonProperty('name', String, true)
  project?: string = undefined;

  @JsonProperty('statuses', [ProjectStatus], false)
  statuses: ProjectStatus[] = [];

  @JsonProperty('completed', Number, true)
  completed: number = 0;

  @JsonProperty('target', Number, false)
  target: number = 0;

}
