import Vue from "vue";
import { TranslateResult } from "vue-i18n";

enum NotificationTypes {
  SUCCESS = 'success',
  INFO = 'info',
  FAIL = 'fail',
  WARN = 'warn'
}

const showNotification = (type: string, text: string | TranslateResult) => {
  if (process.env.NODE_ENV !== 'test') {
    return Vue.notify({
      group: 'message',
      title: 'Message',
      type: type,
      text: String(text)
    });
  }
};

const success = (text: string | TranslateResult) => {
  showNotification(NotificationTypes.SUCCESS, text);
};

const info = (text: string | TranslateResult) => {
  showNotification(NotificationTypes.INFO, text);
};

const warn = (text: string | TranslateResult) => {
  showNotification(NotificationTypes.WARN, text);
};

const fail = (text: string | TranslateResult) => {
  showNotification(NotificationTypes.FAIL, text);
};

export default {
  success,
  info,
  warn,
  fail
};
