
  import Vue from 'vue';
  import { Prop } from "vue/types/options";
  import { mapState } from "vuex";
  import messages from "./messages.json";

  import FormCard from '@/main/webapp/vue/components/ui/form/card/index.vue';

  import { Entity } from "@/main/webapp/vue/model/api/Entity";
  import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import notification from "@/main/webapp/vue/notification";

  export default Vue.extend({
    components: {
      FormCard
    },
    props: {
      title: {
        type: String,
        default: null
      },
      holderEntity: {
        type: Object as Prop<Entity>
      },
      itemContentEntity: {
        type: Object as Prop<ItemContentEntity>
      }
    },
    data: function() {
      return {
        file: null as File | null,
        updating: false as Boolean,
        changed: false as Boolean
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      addFile(file: File) {
        if (file) {
          this.file = file;
          this.changed = true;
        }
      },
      removePlusIcon(e: any) {
        e.currentTarget.classList.add('bg-gray-100');
        e.currentTarget.classList.remove('bg-green-300');
      },
      addPlusIcon(e: any) {
        if (!e.currentTarget.classList.contains('bg-green-300')) {
          e.currentTarget.classList.remove('bg-gray-100');
          e.currentTarget.classList.add('bg-green-300');
        }
      },
      onChange(e: any): void {
        const file: File = e.target.files[0];
        this.addFile(file);
      },
      dragover(e: any): void {
        e.preventDefault();
        this.addPlusIcon(e);
      },
      dragleave(e: any): void {
        this.removePlusIcon(e);
      },
      drop(e: any): void {
        e.preventDefault();
        this.removePlusIcon(e);

        const file: File = e.dataTransfer.files[0];
        this.addFile(file);
      },
      removeFile(): void {
        this.file = null;
      },
      update(): void {
        if (this.file) {
          this.uploadContent(this.file);
        }
      },
      uploadContent(file: File): void {
        this.updating = true;
        BackendIntegrationService.postItemContentEntity(this.holderEntity, this.itemContentEntity, file)
          .then((response: ItemContentEntity) => {
            this.$emit('content-updated', response, file);
            this.file = null;
            this.changed = false;
          }).catch(() => {
            notification.fail(this.$t('text.error.post'));
          }).finally(() => {
            this.updating = false;
          });
      }
    },
    i18n: {
      messages: messages
    }
  });
