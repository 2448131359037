import { Any, JsonObject, JsonProperty } from "json2typescript";

import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
import { ContentUnit } from "@/main/webapp/vue/model/api/learning/ContentUnit";
import { OrganizationData } from "@/main/webapp/vue/model/api/learning/OrganizationData";

@JsonObject('Activity')
export class Activity extends NamedEntity {

  @JsonProperty('sequence', Number, true)
  sequence?: number = undefined;

  @JsonProperty('active', Boolean, true)
  active?: boolean = undefined;

  @JsonProperty('properties', Any, true)
  properties?: any = undefined;

  @JsonProperty('created', String, true)
  created?: string = undefined;

  @JsonProperty('urlPathFragment', String, true)
  urlPathFragment?: string = undefined;

  @JsonProperty('urlPath', String, true)
  urlPath?: string = undefined;

  @JsonProperty('organizationData', OrganizationData, true)
  organizationData?: OrganizationData = undefined;

  @JsonProperty('subactivities', [Activity], true)
  subactivities: Activity[] = [];

  @JsonProperty('hasSubactivities', Boolean, true)
  hasSubactivities: boolean = false;

  @JsonProperty('contents', [ContentUnit], true)
  contents: ContentUnit[] = [];

  @JsonProperty('baseUrl', String, true)
  baseUrl: string = '';

  public static from(activityModel: ActivityModel): Activity {
    let activity = new Activity();
    activity.id = activityModel.id ? +activityModel.id : -1; // TODO: Handle better!
    activity.name = activityModel.name;
    activity.sequence = activityModel.sequence;
    activity.active = activityModel.active;
    activity.properties = activityModel.properties;
    activity.urlPathFragment = activityModel.urlPathFragment;
    activity.urlPath = activityModel.urlPath;
    activity.baseUrl = activityModel.baseUrl;
    activity.hasSubactivities = activityModel.hasSubactivities;
    return activity;
  }

}
