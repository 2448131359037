
  import Vue from 'vue';

  import messages from '../messages.json';

  export default Vue.extend({
    name: 'SettingsInputGroup',
    props: {
      name: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        default: false
      },
      state: {
        type: Boolean,
        default: true
      },
      explicitState: {
        type: Boolean,
        default: false
      },
      validFeedback: {
        type: String,
        default: undefined
      },
      invalidFeedback: {
        type: String,
        default: undefined
      },
      value: {
        type: String,
        required: false
      },
      type: {
        type: String,
        default: 'text'
      },
      labelArguments: {
        type: Array,
        default: undefined
      },
      placeholder: {
        type: String,
        default: undefined
      },
      autocomplete: {
        type: String,
        default: undefined
      }
    },
    computed: {
      invalidFeedbackText() {
        if (this.required && !this.value) {
          return this.$t('text.requiredField');
        }
        return this.invalidFeedback;
      },
      displayedState() {
        if (!this.state) {
          return false;
        }
        return this.explicitState && this.value ? true : null;
      }
    },
    i18n: {
      messages
    }
  });
