
  import Vue from 'vue';
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';
  import keyValueInputTable from "@/main/webapp/vue/components/ui/table/key-value-input-table/index.vue";

  export default Vue.extend({
    props: {
      rawData: {
        type: String,
        required: true
      }
    },
    components: {
      keyValueInputTable
    },
    data() {
      return {
        customValidationBeforeSave: (item: any): boolean => Boolean(item.label),
        displayKeyCustomFunctions: {
          validateState: (item: any): boolean => Boolean(item.label),
          validationErrorMessage: (item: any): string => {
            return `${this.$t('generic.required')}`;
          }
        }
      };
    },
    methods: {
      update(newData: any): void {
        let metadataInput: HTMLInputElement | null = document.querySelector('#shop-metadata');
        if (metadataInput) {
          metadataInput.value = JSON.stringify(newData);
        }
      }
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
