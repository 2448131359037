
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import draggableContainer from '@/main/webapp/vue/components/ui/draggable/container/index.vue';
  import widgetComponent from '@/main/webapp/vue/components/learning/widget/component/WidgetComponent.vue';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import WidgetModel from '@/main/webapp/vue/model/learning/WidgetModel';
  import CardWidgetModel, { CardWidgetProperties } from
  '@/main/webapp/vue/components/learning/widget/widgets/card/model/CardWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'CardWidget',
    components: {
      draggableContainer,
      widgetComponent
    },
    props: {
      model: {
        type: Object as PropType<CardWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editing: {
        type: Boolean,
        default: false
      },
      editable: {
        type: Boolean,
        default: false
      },
      editingWidget: {
        type: Object as PropType<WidgetModel | undefined>,
        default: undefined
      },
      widgetFromSideBar: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showContent: true as Boolean
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return {
          ...computedStyleUtil.getStyles(
            ['margin', 'padding'],
            this.model.properties.genericStyle,
            this.computedDefaultStyle.genericStyle),
          ...computedStyleUtil.getStyles(
            ['backgroundColor'],
            this.model.properties.sectionStyle,
            this.computedDefaultStyle.sectionStyle)
        };
      },
      computedCloseButtonStyle(): Object {
        return computedStyleUtil.getStyles(
          ['backgroundColor', 'foregroundColor'],
          this.model.properties.sectionStyle,
          this.computedDefaultStyle.sectionStyle);
      },
      computedDefaultStyle(): CardWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      }
    },
    methods: {
      onCreate(widget: WidgetModel, index: number): void {
        this.$emit('create', widget, index, this.model);
      },
      onEnd(widget: WidgetModel, index: number): void {
        this.$emit('moved', widget, index, this.model);
      },
      delete(nestedContents: WidgetModel[], widget: WidgetModel): WidgetModel[] {
        return nestedContents.filter((e: any) => {
          if (e.nestedContents && e.nestedContents.length > 0) {
            e.nestedContents = this.delete(e.nestedContents, widget);
          }

          return e.id !== widget.id && e.id !== widget.metadata.originalId;
        });
      },
      deleteNestedContents(widget: WidgetModel): void {
        if (this.model.nestedContents && widget) {
          this.model.nestedContents = this.delete(this.model.nestedContents, widget);
        }
      },
      onDelete(widget: WidgetModel): void {
        this.$emit('delete', widget, this.deleteNestedContents);
      },
      closeCard(): void {
        this.showContent = false;
      }
    },
    i18n: {
      messages: messages
    }
  });
