import axios, { AxiosError, AxiosResponse } from "axios";
import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript";
import configuration from '../config/application/configuration';
import { AuthenticationResult } from "../model/api/AuthenticationResult";
import { BackendError } from "@/main/webapp/vue/model/BackendError";

import DomUtil from "@/main/webapp/vue/util/domUtil";

enum ResponseStatuses {
  SUCCESS = 'SUCCESS',
  GENERAL_FAILURE = 'GENERAL',
  VALIDATION_FAILURE = 'VALIDATION'
}

enum StatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND= 404,
  TIMEOUT = 408,
  SESSION_EXPIRED = 419,
  ACCOUNT_EXPIRED = 423,
  SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503
}

export enum ApiError {
  VOID = "ignore",
  NO_CONNECTION = "no-connection",
  BAD_REQUEST = "bad-request",
  BACKEND_ERROR = "backend-error",
  BACKEND_ERROR_GENERAL = "backend-error-general",
  BACKEND_ERROR_VALIDATION = "backend-error-validation",
  UNPARSABLE_RESPONSE = "response-parse-error",
  UNAUTHORIZED = "not-allowed",
  SESSION_EXPIRED = "session-expired",
  ACCOUNT_EXPIRED = "account-expired",
  TIMEOUT = "timeout",
  UNKNOWN = "unknown-error"
}

export class ApiService {
  public static readonly RETRY_THRESHOLD: number = 3;

  public static readonly HEADER_SERVICE: string = 'X-Service';
  public static readonly HEADER_PLATFORM: string = 'X-Platform';
  public static readonly HEADER_DEVICE: string = 'X-Device';
  public static readonly HEADER_VERSION: string = 'X-App-Version';

  private static readonly EXTRA_DEBUG: boolean = false;

  constructor() {
    axios.defaults.baseURL = configuration.properties.api.contextPath;
    axios.defaults.timeout = configuration.properties.api.timeout;

    const CSRF_HEADER_META_TAG_NAME: string = '_csrf_header';
    const CSRF_TOKEN_META_TAG_NAME: string = '_csrf';

    const csrfHeaderName = DomUtil.getMetaTagContent(CSRF_HEADER_META_TAG_NAME);
    const csrfToken = DomUtil.getMetaTagContent(CSRF_TOKEN_META_TAG_NAME);
    if (csrfHeaderName !== null && csrfToken !== null) {
      axios.defaults.headers.post[csrfHeaderName] = csrfToken;
      axios.defaults.headers.put[csrfHeaderName] = csrfToken;
      axios.defaults.headers.delete[csrfHeaderName] = csrfToken;
    }

    if (process.env.NODE_ENV === 'development') {
      axios.interceptors.request.use(request => {
        return request;
      });

      axios.interceptors.response.use(response => {
        return response;
      });
    }
  }

  public get<T>(type: { new(): T }, path: string, params?: any): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: GET ${path}`);
    }
    return this.request(type, 'get', path, null, null, params);
  }

  public post<T>(type: { new(): T }, path: string, data?: any, params?: any, unbox?: boolean): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: POST ${path}`);
    }
    return this.request(type, 'post', path, null, data, params, unbox, ApiService.RETRY_THRESHOLD);
  }

  public put<T>(type: { new(): T }, path: string, data: any, params?: any, unbox?: boolean): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: PUT ${path}`);
    }
    return this.request(type, 'put', path, null, data, params, unbox, ApiService.RETRY_THRESHOLD);
  }

  public patch<T>(type: { new(): T }, path: string, data?: any, params?: any, unbox?: boolean): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: PATCH ${path}`);
    }
    return this.request(type, 'patch', path, null, data, params, unbox, ApiService.RETRY_THRESHOLD);
  }

  public options<T>(type: { new(): T }, path: string, data?: any, params?: any, unbox?: boolean): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: OPTIONS ${path}`);
    }
    return this.request(type, 'options', path, null, data, params, unbox, ApiService.RETRY_THRESHOLD);
  }

  public delete<T>(type: { new(): T }, path: string): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: DELETE ${path}`);
    }
    return this.request(type, 'delete', path);
  }

  public multipart<T>(type: { new(): T }, path: string, data: FormData, unbox?: boolean): Promise<T> {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API call: POST MULTIPART ${path}`);
    }
    return this.request(type, 'post', path, {
      'Content-Type': 'multipart/form-data'
    }, data, null, unbox, ApiService.RETRY_THRESHOLD); // We want to avoid several retries when submitting contents, also a longer timout
  }

  public auth(path: string, username: string, password: string): Promise<AuthenticationResult> {
    return this.request(AuthenticationResult, 'post', path, null, {
      username: username,
      password: password
    });
  }

  public img(path: string): Promise<Blob> {
    return axios.request({
      method: 'get',
      url: path,
      responseType: 'blob'
    }).then((response: AxiosResponse) => {
      if (response.headers['content-type'] === "application/json") {
        throw new BackendError(ApiError.UNPARSABLE_RESPONSE);
      }
      return response.data;
    }).catch((error: AxiosError) => this.handleAxiosError(error, path));
  }

  public request<T>(type: { new(): T }, method: any, path: string, headers?: any, data?: any, params?: any, unbox: boolean = true,
                    retry: number = 0): Promise<T | any> {
    return axios.request({
      method: method,
      url: path,
      headers: headers,
      timeout: configuration.properties.api.timeout + (retry * 1000),
      params: params,
      data: data
    }).then((response: AxiosResponse) => {
      if (response && response.data) {
        if (response.status === 200 || response.data.status === ResponseStatuses.SUCCESS) {
          return Promise.resolve(this.parseResponseData(type, response, unbox));
        } else {
          return Promise.reject(this.handleResponseFailureWithData(response.data.error));
        }
      } else { // This will go away once the API always returns data
        if (response.status === 204) {
          if (process.env.NODE_ENV === 'development') {
            console.log("API: Backend returned no content");
          }
          return Promise.resolve(null);
        }

        return this.handleResponseWithoutData(response.status);
      }
    }).catch((error: AxiosError) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`API SERVICE: error when calling: ${path}`, error);
      }

      if (error && error.response && error.response.status < 500) { // Only retry server errors
        if (process.env.NODE_ENV === 'development') {
          console.log("API SERVICE: Will not retry failed API call for status code", error.response.status);
        }
        retry = ApiService.RETRY_THRESHOLD;
      }

      if (retry < ApiService.RETRY_THRESHOLD) {
        if (process.env.NODE_ENV === 'development') {
          console.log("API SERVICE: Retry silently", retry);
        }

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.request(type, method, path, headers, data, params, unbox, retry + 1));
          }, retry * 1000); // Give some slack to the backend
        });
      }

      this.handleAxiosError(error, path);
    });
  }

  private handleAxiosError(error: AxiosError, path: string): void {
    if (process.env.NODE_ENV === 'development') {
      console.log(`API error when calling: ${path}`);
      console.log(error);
    }

    if (!error.isAxiosError) {
      throw error;
    }

    if (!error.response) {
      throw new BackendError(ApiError.UNKNOWN);
    }
    const apiError = this.mapStatusCodeToApiError(error.response.status);
    if (!error.response.data || !error.response.data.error) {
      throw new BackendError(apiError);
    }
    const errorResponse = error.response.data.error;
    throw new BackendError(apiError, errorResponse.message, errorResponse.exception, errorResponse.details);
  }

  private parseResponseData<T>(type: { new(): T }, response: AxiosResponse, unbox: boolean = true): T {
    let jsonConvert: JsonConvert = new JsonConvert();

    if (process.env.NODE_ENV === 'development') {
      jsonConvert.operationMode = OperationMode.ENABLE; // OperationMode.LOGGING;
    }

    jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

    let targetJsonObject: any = unbox ? response.data.payload.data : response.data;

    try {
      // Notice! In order to deserialization to work, the type you pass has to have all attributes initialized
      let jsonConvertedObject: T = jsonConvert.deserializeObject(targetJsonObject, type);
      if (process.env.NODE_ENV === 'development' && ApiService.EXTRA_DEBUG) {
        console.log("API:", targetJsonObject);
        console.log("API:", jsonConvertedObject);
      }
      return jsonConvertedObject;
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log("API: Deserialization failed", e);
        console.log("API:", targetJsonObject);
      }

      throw new BackendError(ApiError.UNPARSABLE_RESPONSE);
    }
  }

  private handleResponseFailureWithData(error: any): void {
    if (!error || !error.type) {
      if (process.env.NODE_ENV === 'development') {
        console.log("API: Expected error data but nothing was included");
      }
      throw new BackendError(ApiError.UNKNOWN);
    }

    switch (error.type) {
      case ResponseStatuses.GENERAL_FAILURE:
        if (process.env.NODE_ENV === 'development') {
          console.log(`API: General error from backend: ${error.message}`);
        }
        throw new BackendError(ApiError.BACKEND_ERROR_GENERAL);
      case ResponseStatuses.VALIDATION_FAILURE:
        if (process.env.NODE_ENV === 'development') {
          console.log(`API: Validation error from backend: ${error.message}`);
        }
        throw new BackendError(ApiError.BACKEND_ERROR_VALIDATION);
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`API: Backend error type does not match registered types: ${error.type}`);
    }
    throw new BackendError(error.type);
  }

  private handleResponseWithoutData<T>(status: number): Promise<T> {
    if (status === null) {
      if (process.env.NODE_ENV === 'development') {
        console.log("API: Null status code received");
      }
      throw new BackendError(ApiError.NO_CONNECTION);
    } else if (status === 200 || status >= 500) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`API: Backend error with status: ${status}`);
      }
      throw new BackendError(ApiError.BACKEND_ERROR);
    } else if (status >= 400) {
      if (process.env.NODE_ENV === 'development') {
        console.log(`API: Bad request error with status: ${status}`);
      }
      throw new BackendError(ApiError.BAD_REQUEST);
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`API: Unknown error with status: ${status}`);
    }

    throw new BackendError(ApiError.UNKNOWN);
  }

  public mapStatusCodeToApiError(status: number | null): ApiError {
    let result: ApiError;

    switch (status) {
      case null:
        result = ApiError.NO_CONNECTION;
        break;
      case StatusCode.BAD_REQUEST:
        result = ApiError.BAD_REQUEST;
        break;
      case StatusCode.UNAUTHORIZED:
      case StatusCode.FORBIDDEN:
        result = ApiError.UNAUTHORIZED;
        break;
      case StatusCode.NOT_FOUND:
        result = ApiError.BAD_REQUEST;
        break;
      case StatusCode.TIMEOUT:
        result = ApiError.TIMEOUT;
        break;
      case StatusCode.SESSION_EXPIRED:
        result = ApiError.SESSION_EXPIRED;
        break;
      case StatusCode.ACCOUNT_EXPIRED:
        result = ApiError.ACCOUNT_EXPIRED;
        break;
      case StatusCode.SERVER_ERROR:
      case StatusCode.BAD_GATEWAY:
      case StatusCode.NOT_IMPLEMENTED:
        result = ApiError.BACKEND_ERROR_GENERAL;
        break;
      case StatusCode.SERVICE_UNAVAILABLE:
        result = ApiError.BACKEND_ERROR;
        break;
      default:
        result = ApiError.UNKNOWN;
    }

    if (process.env.NODE_ENV === 'development') {
      console.log(`API: Mapped error status code: ${status} to API error: ${result}`);
    }

    return result;
  }

}

export const apiService = new ApiService();
