import { VisitedActivitySession } from "@/main/webapp/vue/model/learning/VisitedActivitySession";


export default class LearningActivityStatusSessionUtil {

  private static readonly znapioVisitedActivityKey: string = "znapio.learning.activity.status";

  public static isVisited(activityId: any): Boolean {
    let visited: boolean = false;

    try {
      let visitedActivities: VisitedActivitySession[] = this.getSessions();
      let matchedIndex: number = this.findMatchedIndex(visitedActivities, activityId);
      if (matchedIndex !== -1) {
        visited = true;
      }
    } catch (e: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("Check visited activity failed", e);
      }
    }

    return visited;
  }

  public static markVisited(activityId: any): void {
    try {
      let visitedActivities: VisitedActivitySession[] = this.getSessions();
      let matchedIndex: number = this.findMatchedIndex(visitedActivities, activityId);
      if (matchedIndex !== -1) {
        visitedActivities[matchedIndex].visited = true;
      } else {
        let newlyVisitedActivity: VisitedActivitySession = new VisitedActivitySession(activityId, true);
        visitedActivities.push(newlyVisitedActivity);
      }

      this.overrideSession(visitedActivities);
    } catch (e: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("Saving visited activity failed", e);
      }
    }
  }

  private static getSessions(): VisitedActivitySession[] {
    const rawSessions = localStorage.getItem(this.znapioVisitedActivityKey);
    if (rawSessions) {
      return JSON.parse(rawSessions);
    }

    return [];
  }

  private static overrideSession(sessions: VisitedActivitySession[]): void {
    localStorage.setItem(this.znapioVisitedActivityKey, JSON.stringify(sessions));
  }

  private static findMatchedIndex(sessions: VisitedActivitySession[], activityId: number): number {
    let matchedIndex: number = -1;
    if (sessions && sessions.length > 0) {
      sessions.forEach((visitedActivity: VisitedActivitySession, index: number) => {
        if (visitedActivity.activityId === activityId && visitedActivity.visited) {
          matchedIndex = index;
        }
      });
    }

    return matchedIndex;
  }

}
