
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import activityListItem from
  '@/main/webapp/vue/components/learning/learning-home/course-management/course-activities/activity-list-item/index.vue';

  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import notification from "@/main/webapp/vue/notification";

  export default Vue.extend({
    name: 'ActivityList',
    components: {
      textTitle,
      activityListItem
    },
    props: {
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activeActivity: {
        type: Object as PropType<ActivityModel | undefined>,
        default: undefined
      }
    },
    methods: {
      addActivity(activityModel: ActivityModel, parent: ActivityModel): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Add activity ${activityModel.name} with parent ${parent.id}`);
        }

        const course: Course = Course.from(this.course);
        let activity: Activity = new Activity();
        activity.name = activityModel.name;
        activity.urlPathFragment = activityModel.urlPathFragment;

        if (parent && parent.id !== undefined) {
          LearningIntegrationService.createCourseSubactivity(course, parent.id as number, activity)
            .then((persistedActivity: Activity) => {
              activityModel.updateId(persistedActivity.id as number);
              this.$emit('activate-activity', activityModel, false);
            }).catch((error: BackendError) => {
              notification.fail(this.$t('text.error.create-subactivity'));
            });
        } else {
          LearningIntegrationService.createCourseActivity(course, activity)
            .then((persistedActivity: Activity) => {
              activityModel.updateId(persistedActivity.id as number);
              this.$emit('activate-activity', activityModel, false);
            }).catch((error: BackendError) => {
              notification.fail(this.$t('text.error.create-activity'));
            });
        }
      },
      duplicateActivity(activityModel: ActivityModel, parent: ActivityModel): void {
        if (activityModel) {
          const course: Course = Course.from(this.course);
          const activity: Activity = Activity.from(activityModel);

          LearningIntegrationService.duplicateActivity(course, activity)
            .then((duplicatedActivity: Activity) => {
              let duplicatedActivityModel: ActivityModel = ActivityModel.from(duplicatedActivity);
              parent.subactivities.push(duplicatedActivityModel); // FIXME: Instead of adding at the end, add at the next index...
              this.$emit('activate-activity', duplicatedActivityModel, true);
            }).catch((e: Error) => {
              notification.fail(this.$t('text.error.duplicate-activity'));
            });
        }
      },
      unlinkActivityContents(activityModel: ActivityModel): void {
        if (activityModel) {
          const course: Course = Course.from(this.course);
          const activity: Activity = Activity.from(activityModel);

          LearningIntegrationService.unlinkActivityContents(course, activity)
            .then((unlinkedActivity: Activity) => {
              let unlinkedActivityModel: ActivityModel = ActivityModel.from(unlinkedActivity);
              this.$emit('activate-activity', unlinkedActivityModel, true);
            }).catch((e: Error) => {
              notification.fail(this.$t('text.error.unlink-activity-contents'));
            });
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
