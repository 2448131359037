import { JsonObject, JsonProperty } from "json2typescript";
import { ProjectStatusLabel } from "./ProjectStatusLabel";

@JsonObject('ProjectStatus')
export class ProjectStatus {

  @JsonProperty('label', ProjectStatusLabel, false)
  label: ProjectStatusLabel = new ProjectStatusLabel();

  @JsonProperty('value', Number, false)
  value: number = 0;

  static calculatePercentage(value: number, total: number) {
    if (total === 0) {
      return 0;
    }

    return (value * 100) / total;
  }

  static calculatePercentageRounded(value: number, total: number): number {
    return Math.round(ProjectStatus.calculatePercentage(value, total));
  }

  calculatePercentage(total: number): number {
    return ProjectStatus.calculatePercentage(this.value, total);
  }

  calculatePercentageRounded(total: number): number {
    return ProjectStatus.calculatePercentageRounded(this.value, total);
  }

  static create(value: number, label: string, bar: string): ProjectStatus {
    let status = new ProjectStatus();
    status.value = value;
    status.label = new ProjectStatusLabel();
    status.label.text = label;
    status.label.style = bar;
    return status;
  }
}
