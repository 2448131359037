
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import buttonStyle from "@/main/webapp/vue/components/ui/style/button/container/index.vue";
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';

  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import NavigationMenuWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/navigation-menu/model/NavigationMenuWidgetModel';
  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";

  export default Vue.extend({
    name: 'NavigationMenuWidgetProperties',
    props: {
      model: {
        type: Object as PropType<NavigationMenuWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      buttonStyle,
      genericStyle
    },
    data() {
      return {
        activities: [] as ActivityModel[],
        selectedActivities: [] as ActivityModel[]
      };
    },
    watch: {
      selectedActivities(newValue: boolean, oldValue: boolean) {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Watching selectedActivities");
        }

        let selectedActivityIds: number[] = [];
        for (let activity of this.selectedActivities) {
          selectedActivityIds.push(activity.id as number);
        }
        this.model.data.selectedActivityIds = selectedActivityIds;
      }
    },
    mounted() {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`Navigation menu widget properties mounted: ${this.course.mainActivity}`);
      }
      if (this.course.mainActivity) {
        const course: Course = Course.from(this.course);
        LearningIntegrationService.fetchActiveCourseActivityWithSubactivitiesOnline(course, this.course.mainActivity)
          .then((response: Activity) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(response);
            }
            this.generateDisplayedActivities([ActivityModel.from(response)]);
          }).catch((error: BackendError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Failed to fetch active course activities, using fallback instead");
            }
            if (this.course.mainActivity) {
              this.generateDisplayedActivities([ActivityModel.from(this.course.mainActivity)]);
            }
          }).finally(() => {
            this.updateSelectedActivities();
          });
      }
    },
    methods: {
      generateDisplayedActivities(activities: ActivityModel[]) {
        this.activities = this.getActiveActivities([], activities);
      },
      getActiveActivities(activeActivities: ActivityModel[], subactivities: ActivityModel[]) {
        for (let activity of subactivities) {
          if (activity.active) {
            activeActivities.push(activity);
            if (activity.subactivities && activity.subactivities.length > 0) {
              activeActivities = this.getActiveActivities(activeActivities, activity.subactivities);
            }
          }
        }
        return activeActivities;
      },
      updateSelectedActivities(): void {
        let selectedActivities: ActivityModel[] = [];
        for (let activity of this.activities) {
          if (this.model.data.selectedActivityIds.length === 0 ||
            this.model.data.selectedActivityIds.indexOf(activity.id as number) > -1) {
            selectedActivities.push(activity);
          }
        }
        this.selectedActivities = selectedActivities;
      }
    },
    i18n: {
      messages: messages
    }
  });
