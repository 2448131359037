import Vue from "vue";
import VueGtag from "vue-gtag";
import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

export default class GoogleAnalyticsUtil {

  vueInstance: any;
  measurementId: string;

  constructor(id: string) {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Create new Google analytics util", id);
    }

    this.measurementId = id;
    this.vueInstance = Vue.use(VueGtag, {
      config: { id: id }
    });
  }

  pageView(activity: ActivityModel): void {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Send activity to track", activity.name, activity.urlPath);
    }

    this.vueInstance.$gtag.pageview({
      page_title: activity.name,
      page_path: activity.urlPath
    });
  }

}
