import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
import { LogoContainer } from "@/main/webapp/vue/model/api/LogoContainer";

@JsonObject('DomainOrganization')
export class DomainOrganization extends NamedEntity {

  @JsonProperty('logos', LogoContainer, true)
  logos?: LogoContainer = undefined

}
