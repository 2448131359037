import { JsonObject, JsonProperty } from "json2typescript";
import { Category } from "./Category";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('CategoryContainer')
export class CategoryContainer {

  @JsonProperty('categories', [Category], false)
  categories: Category[] = [];

  @JsonProperty('nav', NavigationLinks, false)
  nav: NavigationLinks = new NavigationLinks();

}
