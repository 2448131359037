
  import Vue from 'vue';
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { User } from "@/main/webapp/vue/model/api/User";

  import assume from "@/main/webapp/vue/components/admin/assume-user/assume/index.vue";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { mapState } from "vuex";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";

  export default Vue.extend({
    components: {
      assume
    },
    props: {
      showAssumeUser: {
        type: Boolean,
        default: true
      },
      colorStyle: {
        type: String,
        default: "light"
      }
    },
    data() {
      return {
        userLoaded: false
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      showAssumeAssociatedUser(): boolean {
        let link: NavigationLink | undefined =
            NavigationLinks.getLinkFromNavigation(NavigationLinkType.ASSUME_USER, this.userDetails.nav);
        return link !== undefined && link.href.indexOf("username") !== -1;
      }
    },
    mounted(): void {
      BackendIntegrationService.fetchUserDetails()
        .then((user: User) => {
          if (user) {
            this.$store.dispatch('updateUserDetails', user);
            this.userLoaded = true;
          }
        });
    }
  });
