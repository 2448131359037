
  import Vue, { PropType } from 'vue';

  import fullscreenOverlay from "@/main/webapp/vue/components/ui/fullscreen-overlay/index.vue";
  import learningOnline from "@/main/webapp/vue/components/learning/learning-online/index.vue";

  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";


  export default Vue.extend({
    components: {
      fullscreenOverlay,
      learningOnline
    },
    props: {
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      }
    },
    methods: {
      closePreview() {
        this.$router.back();
      }
    }
  });
