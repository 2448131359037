import { Any, JsonObject, JsonProperty } from "json2typescript";

import { ShopDetails } from "./ShopDetails";

@JsonObject('ShopDetailsWithMetadata')
export class ShopDetailsWithMetadata extends ShopDetails {

  @JsonProperty('shopKey', String, true)
  shopKey?: string = undefined;

  @JsonProperty('rawMetadata', Any, true)
  rawMetadata?: any = undefined;

}
