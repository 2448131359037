import SockJS from 'sockjs-client';
import { Client, frameCallbackType, IPublishParams, messageCallbackType, StompSubscription } from "@stomp/stompjs";

import { ReportDestination } from "@/main/webapp/vue/model/api/report/ReportDestination";
import { WebSocketReportRequest } from "@/main/webapp/vue/model/api/report/WebSocketReportRequest";

import applicationConfiguration from '@/main/webapp/vue/config/application/configuration';
import DomUtil from "@/main/webapp/vue/util/domUtil";
import store from "@/main/webapp/vue/store";

const CSRF_HEADER_META_TAG_NAME: string = '_csrf_header';
const CSRF_TOKEN_META_TAG_NAME: string = '_csrf';

let client: Client;
const subscriptions: Map<ReportDestination, StompSubscription> = new Map();
const stompConnectUrl: string = "/connect";
const sockJSConnectUrl: string = "/connect";

const initialize = (onConnectCallback: frameCallbackType, onDisconnectCallback: frameCallbackType, onStompErrorCallback: frameCallbackType): void => {
  if (process.env.NODE_ENV !== 'production') {
    console.log("Creating new WebSocket client");
  }

  const csrfHeaders: { [key: string]: string } = {};

  const csrfHeaderName: string | null = DomUtil.getMetaTagContent(CSRF_HEADER_META_TAG_NAME);
  const csrfToken = DomUtil.getMetaTagContent(CSRF_TOKEN_META_TAG_NAME);

  if (csrfHeaderName !== null && csrfToken !== null) {
    csrfHeaders[csrfHeaderName] = csrfToken;
  }

  client = new Client({
    brokerURL: prepareBrokerURL(stompConnectUrl),
    debug: function(text: string) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(text);
      }
    },
    connectHeaders: csrfHeaders,
    splitLargeFrames: applicationConfiguration.properties.websocket.splitLargeFrames,
    reconnectDelay: applicationConfiguration.properties.websocket.reconnectDelay,
    heartbeatIncoming: applicationConfiguration.properties.websocket.heartbeatIncoming,
    heartbeatOutgoing: applicationConfiguration.properties.websocket.heartbeatOutgoing
  });

  // Fallback code for browsers not supporting WebSockets
  if (typeof WebSocket !== 'function') {
    // @ts-ignore
    client.webSocketFactory = function() {
      return new SockJS(
        sockJSConnectUrl,
        { transports: ['websocket'] },
        { sessionId: () => { return store.getters.getUserDetails.id; } });
    };
  }

  client.onConnect = onConnectCallback;
  client.onDisconnect = onDisconnectCallback;
  client.onStompError = onStompErrorCallback;
  client.onWebSocketError = function(error: Event) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(error);
    }
  };

  if (process.env.NODE_ENV !== 'production') {
    console.log("Establishing a connection through the WebSocket client");
  }

  client.activate();
};

const prepareBrokerURL = (path: string): string => {
  // Create a relative http(s) URL relative to current page
  const url = new URL(path, window.location.href);
  // Convert protocol http -> ws and https -> wss
  url.protocol = url.protocol.replace('http', 'ws');

  return url.href;
};

const active = () : boolean => {
  if (client === undefined) {
    if (process.env.NODE_ENV !== 'production') {
      console.log("The WebSocket client does not exist");
    }

    return false;
  } else {
    const active = client.active;

    if (process.env.NODE_ENV !== 'production') {
      console.log(`The WebSocket connection is '${active}'`);
    }

    return active;
  }
};

const connect = (onConnectCallback: frameCallbackType, onDisconnectCallback: frameCallbackType, onStompErrorCallback: frameCallbackType): void => {
  initialize(onConnectCallback, onDisconnectCallback, onStompErrorCallback);
};

const subscribe = (reportDestination: ReportDestination, onSubscribeCallback: messageCallbackType): void => {
  const subscription: StompSubscription = client.subscribe(reportDestination, onSubscribeCallback);
  subscriptions.set(reportDestination, subscription);
};

const request = (reportRequest: WebSocketReportRequest): void => {
  console.log("REQUEST BEFORE");
  console.log(reportRequest);
  const publishParameters: IPublishParams = {
    destination: ReportDestination.REPORT_REQUEST,
    body: JSON.stringify(reportRequest)
  };

  console.log("BODY");
  console.log(publishParameters.body);

  client.publish(publishParameters);
};

const unsubscribe = (reportDestination: ReportDestination): void => {
  if (client !== undefined && client.active) {
    const stompSubscription: StompSubscription | undefined = subscriptions.get(reportDestination);
    if (stompSubscription !== undefined) {
      stompSubscription.unsubscribe();
      subscriptions.delete(reportDestination);
    }

    if (subscriptions.size === 0) {
      disconnect();
    }
  } else {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Failed to unsubscribe - WebSocket connection already closed");
    }
  }
};

const disconnect = (): void => {
  if (client !== undefined && client.active) {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Closed the WebSocket connection manually");
    }

    client.deactivate();
  } else {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Failed to close the WebSocket connection - it was already closed");
    }
  }
};


export default {
  active,
  connect,
  subscribe,
  request,
  unsubscribe
};
