import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";
import { AttributeEntity, AttributeEntityValue } from "./AttributeEntity";
import { oc as Optional } from "ts-optchain";
import { EntityViewMetadata } from "@/main/webapp/vue/model/api/EntityViewMetadata";
import { Json2TypescriptAdapter } from "@znapio/znapson/json2typescriptAdapter";

@JsonObject('HierarchicalEntity')
export class HierarchicalEntity extends NamedEntity {

  @JsonProperty('children', [HierarchicalEntity], true)
  children?: HierarchicalEntity[] = undefined;

  @JsonProperty('attributes', Json2TypescriptAdapter([AttributeEntity<AttributeEntityValue>], true), true)
  attributes?: AttributeEntity<AttributeEntityValue>[] = undefined;

  @JsonProperty('priority', Number, true)
  priority: number = 0;

  getLeafEntity(hierarchicalEntity: HierarchicalEntity): HierarchicalEntity {
    let children: HierarchicalEntity[] | undefined = Optional(hierarchicalEntity).children();
    if (children) {
      for (let child of children) {
        return this.getLeafEntity(child);
      }
    }

    return hierarchicalEntity;
  }

  getChildrenWithMetadata(): HierarchicalEntity[] {
    let children = Optional(this).children([]);
    for (let child of children) {
      child.metadata = this.metadata;
      child.metadata.ignoreRoot = false;
    }

    return children;
  }
}
