
  import Vue from 'vue';
  import messages from './messages.json';

  import glyphiconTitle from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-title/index.vue';
  import commentSummary from '@/main/webapp/vue/components/ui/comment/comment-summary/index.vue';
  import submissionSummary from '@/main/webapp/vue/components/ui/submission/submission-summary/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { FeaturedSubmission } from "@/main/webapp/vue/model/api/FeaturedSubmission";

  export default Vue.extend({
    components: {
      glyphiconTitle,
      commentSummary,
      submissionSummary
    },
    data() {
      return {
        featuredSubmission: null
      };
    },
    methods: {
      getLastFeatured(): void {
        BackendIntegrationService.fetchLastFeaturedSubmission()
          .then((featuredSubmission: FeaturedSubmission) => {
            this.$data.featuredSubmission = featuredSubmission;
          });
      }
    },
    created(): void {
      this.getLastFeatured();
    },
    i18n: {
      messages: messages
    }
  });
