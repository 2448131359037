
  import Vue, { PropType } from 'vue';
  import { mapState } from 'vuex';

  import { DateTime } from "@/main/webapp/vue/model/api/DateTime";

  import TimeUtil from '@/main/webapp/vue/util/timeUtil';

  export default Vue.extend({
    props: {
      date: {
        type: Object as PropType<DateTime>,
        required: true
      },
      displayField: {
        type: String,
        default: "timestamp"
      },
      relative: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        relativeDateMaxHours: -12 as number
      };
    },
    computed: {
      ...mapState([
        'properties'
      ]),
      computedDate(): string {
        return this.convertToLocalTime(this.date);
      }
    },
    methods: {
      convertToLocalTime(date: DateTime): string {
        let timestampFormat = (this as any).$properties.timestampFormat;

        let diffHours: number = TimeUtil.getTimeDifference(date, 'hours');
        if (this.relative && (diffHours > this.relativeDateMaxHours)) {
          return TimeUtil.convertToRelativeTime(date);
        } else {
          return TimeUtil.formatToDateString(date, timestampFormat.display[this.displayField]);
        }
      }
    }
  });
