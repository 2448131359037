import scriptjs from "scriptjs";
import configuration from "@/main/webapp/vue/config/application/configuration";
import { LatLng } from "leaflet";
import { MapAddress } from "@/main/webapp/vue/model/api/Map/MapAddress";
import mapHtmlConfiguration from "@/main/webapp/vue/components/map/mapHtmlConfiguration";

export class GoogleMapIntegrationService {

  GEO_CODER: any = null;
  timeout: number = configuration.properties.api.timeout; // sync with api timeout

  constructor() {
    scriptjs(`https://maps.googleapis.com/maps/api/js?key=${configuration.properties.apiKeys.google}`, () => {
      // @ts-ignore
      this.GEO_CODER = new google.maps.Geocoder();
    });
  }

  public fromAddressToCoordinates(address: string): Promise<LatLng> {
    return new Promise((resolve, reject) => {
      this.GEO_CODER.geocode({ 'address': address }, (results: any, status: string) => {
        if (status === "OK") { // google.maps.GeocoderStatus.OK
          resolve(
            new LatLng(
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng()
            )
          );
        } else {
          reject(status);
        }
      });

      setTimeout(() => { // When timeout or invalid key
        reject(new Error("Timeout or invalid key"));
      }, this.timeout);
    });
  }

  public fromCoordinatesToAddress(coordinates: LatLng): Promise<MapAddress> {
    return new Promise((resolve, reject) => {
      this.GEO_CODER.geocode({ 'location': coordinates }, (results: any, status: string) => {
        if (status === "OK") { // google.maps.GeocoderStatus.OK
          resolve(mapHtmlConfiguration.setEachAddressField(results[0].address_components));
        } else {
          reject(status);
        }
      });

      setTimeout(() => { // When api key is not valid
        reject(new Error("Timeout Timeout or invalid key"));
      }, this.timeout);
    });
  }
}
