
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import notification from "@/main/webapp/vue/notification";
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';
  import formInput from '@/main/webapp/vue/components/ui/form/input/index.vue';

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { COURSE_STATUS_TYPE } from "@/main/webapp/vue/model/api/learning/CourseStatus";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import CourseProperties from "@/main/webapp/vue/model/api/learning/CourseProperties";

  export default Vue.extend({
    components: {
      formInput,
      collapsibleContainer,
      formSwitch
    },
    props: {
      course: {
        type: Object as PropType<CourseModel>,
        required: false
      },
      newCourse: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        courseInfo: this.course as CourseModel,
        courseActive: this.course?.status?.value === 'active',
        expand: false as boolean,
        componentName: 'course-settings'
      };
    },
    methods: {
      nameChanged(newName: string): void {
        this.courseInfo.name = newName;
        this.saveCourseDetails({ name: newName });
      },
      statusChanged(newStatus: boolean): void {
        if (this.courseInfo && this.courseInfo.status) {
          let newValue: string = COURSE_STATUS_TYPE.IN_ACTIVE;
          if (newStatus) {
            newValue = COURSE_STATUS_TYPE.ACTIVE;
          }
          this.saveCourseDetails({ status: newValue });
        }
      },
      shortNameChanged(newShortName: string): void {
        this.saveCourseDetails({ shortName: newShortName });
      },
      urlChanged(newUrl: string): void {
        this.saveCourseDetails({ urlPathFragment: newUrl });
      },
      googleAnalyticsChanged(newMeasurementId: string): void {
        let properties: CourseProperties = new CourseProperties();
        if (!this.courseInfo.properties) {
          this.courseInfo.properties = properties;
        }

        this.courseInfo.properties.googleAnalytics.measurementId = newMeasurementId;
        properties = this.courseInfo.properties;

        this.saveCourseDetails({ properties: properties });
      },
      saveCourseDetails(newCourseInfo: any): void {
        if (this.course && this.course.id) {
          const course: Course = Course.from(this.course);
          LearningIntegrationService.updateCourseDetails(course, newCourseInfo)
            .then((updatedCourse: Course) => {
              this.courseInfo.urlPath = updatedCourse.urlPath;
              this.courseInfo.urlPathFragment = updatedCourse.urlPathFragment;
              this.$emit('course-settings-updated', updatedCourse);
            })
            .catch((e: BackendError) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('Save course details failed', e);
              }

              let exception: string = e.exception!;
              if (exception === 'course-url-already-in-use') {
                notification.fail(this.$t('text.error.course-url-already-in-use'));
              } else {
                notification.fail(this.$t('text.error.default'));
              }
            });
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
