import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
import { ProjectProgress } from "@/main/webapp/vue/model/api/ProjectProgress";

@JsonObject('ProjectProgressForDepartment')
export class ProjectProgressForDepartment extends ProjectProgress {

  @JsonProperty('department', NamedEntity, true)
  department: NamedEntity | undefined = undefined;

}
