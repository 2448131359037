
  import Vue from 'vue';

  import materialIcon from '../material-icon/index.vue';

  export default Vue.extend({
    components: {
      materialIcon
    },
    props: {
      icon: {
        type: String
      },
      text: {
        type: String
      },
      color: {
        type: String,
        default: ""
      },
      colorStyle: {
        type: String,
        default: "light"
      },
      textSize: {
        type: String,
        default: "small"
      },
      iconSize: {
        type: String,
        default: "medium"
      },
      iconRotation: {
        type: Boolean,
        default: false
      },
      direction: {
        type: String,
        default: "horizontal"
      },
      tooltipText: {
        type: Boolean,
        default: false
      }
    }
  });
