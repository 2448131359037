
  import Vue from 'vue';
  import { mapState } from "vuex";
  import { FilterAttributes } from "@/main/webapp/vue/views/submissions/ui/submissions-switcher/index.vue";

  export default Vue.extend({
    data() {
      return {
        pageReady: false as boolean
      };
    },
    computed: {
      ...mapState([
        'lastFilterAttributes'
      ])
    },
    watch: {
      lastFilterAttributes: {
        handler(newFilter: FilterAttributes, oldFilter: FilterAttributes): void {
          this.initPage(newFilter.parameters);
        },
        deep: true
      }
    },
    methods: {
      prepareInit(): void {
        this.pageReady = true;
        this.initPage(this.lastFilterAttributes.parameters);
      },
      initPage(filterParams: string = ''): void {
        console.log("Reload dashboard page");
        if (this.pageReady) {
          initDashboardPage(filterParams); // This is from snapshop.js
        }
      }
    },
    mounted(): void {
      window.onload = () => { // Switched from navigation bar
        this.prepareInit();
      };

      if (document.readyState === "complete") { // Switched from submissions-switcher
        this.prepareInit();
      }
    }
  });
