
  import Vue, { PropType } from 'vue';

  import { oc as Optional } from "ts-optchain";

  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

  import responsiveImage from '@/main/webapp/vue/components/ui/content/responsive-image/index.vue';
  import filetypeIcon from '@/main/webapp/vue/components/ui/content/filetype-icon/index.vue';

  export default Vue.extend({
    components: {
      responsiveImage,
      filetypeIcon
    },
    props: {
      contents: {
        type: Array as PropType<ItemContent[]>,
        required: false
      },
      defaultLink: {
        type: String,
        required: false,
        default: "#"
      },
      autoPlayInterval: {
        type: Number,
        default: 0 // disable as default
      },
      itemIndex: {
        type: Number
      },
      slideIndex: {
        type: Number,
        default: 0
      },
      slideIndexChangeToggle: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      parsedContents: function(): ItemContent[] {
        let contentLength: number = Optional(this.contents).length(0);
        if (contentLength > 0) {
          return this.contents;
        } else {
          return [
            ItemContent.prototype.generateNoImageContent(
              true,
              (this as any).$properties.content.image.placeholder.noImage,
              this.$props.defaultLink)
          ];
        }
      }
    },
    data() {
      return {
        currentSlideIndex: this.slideIndex
      };
    },
    watch: {
      slideIndexChangeToggle: function() {
        this.currentSlide = this.slideIndex;
      }
    }
  });
