
  import Vue, { PropType } from 'vue';
  import { v1 as uuidv1 } from 'uuid';
  import messages from './messages.json';

  import draggableContainer, { DraggableGroup } from "@/main/webapp/vue/components/ui/draggable/container/index.vue";

  import { nameToUrlSafe, nextOrdinalString } from "@/main/webapp/vue/util/stringUtil";
  import Confirmation from "@/main/webapp/vue/components/ui/modal/confirmation/index.vue";

  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import VueI18n from "vue-i18n";
  import TranslateResult = VueI18n.TranslateResult;

  export default Vue.extend({
    name: 'ActivityListItem',
    components: {
      draggableContainer
    },
    props: {
      model: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      isRoot: {
        type: Boolean,
        default: false
      },
      isChildActive: {
        type: Boolean,
        default: false
      },
      activeActivity: {
        type: Object as PropType<ActivityModel | undefined>,
        default: undefined
      }
    },
    data() {
      return {
        activeRoot: undefined as ActivityModel | undefined,
        showSubactivities: this.isRoot as Boolean
      };
    },
    computed: {
      isActive(): boolean {
        return this.activeActivity !== undefined && this.model.id === this.activeActivity.id;
      },
      draggableGroup(): DraggableGroup {
        return {
          name: `activity-list-${this.model.id}`
        };
      }
    },
    methods: {
      activateActivity(): void {
        this.$emit('activate-activity', this.model, true);
      },
      addActivity(): void {
        const nameBase = this.isRoot ? `${this.$t('text.activity')} ` : `${this.model.name}.`;
        const name = nextOrdinalString(nameBase,
                                       this.model.subactivities
                                         .map((subactivity) => subactivity.name));

        let activity: ActivityModel =
          new ActivityModel(uuidv1(), name, this.model.subactivities.length + 1, nameToUrlSafe(name));
        this.model.subactivities.push(activity);
        this.addSubactivity(activity, this.model);
      },
      addSubactivity(activity: ActivityModel, parent: ActivityModel): void {
        this.activeRoot = activity;
        this.$emit('activity-created', activity, parent);
      },
      duplicateSubActivity(activity: ActivityModel, parent: ActivityModel | null = null): void {
        if (!parent) {
          parent = this.model;
        }

        this.$emit('activity-duplicate', activity, parent);
      },
      hasLinkedContents(): boolean {
        return (this.model.widgets || []).find(widget => (widget.activities || []).length > 1) !== undefined;
      },
      unlinkSubActivityContents(activity: ActivityModel): void {
        this.$emit('activity-unlink-contents', activity);
      },
      deleteSubActivity(activity: ActivityModel): void {
        this.$emit('delete', activity);
      },
      deleteActivity(): void {
        if (this.isActive) {
          const title = this.$t('text.confirmation.delete-activity', { activityName: this.model.name });
          this.confirmUser(title, () => {
            this.$emit('delete', this.model);
          });
        }
      },
      confirmUser(title: TranslateResult, callback: Function): void {
        let confirmation: Vue = new Confirmation({
          propsData: {
            title: title
          }
        }).$mount();

        confirmation.$on('user-confirm', (confirm: boolean) => {
          if (confirm) {
            callback();
          }
        });
      },
      handleActivate(activity: ActivityModel, fetchDetails: boolean): void {
        this.activeRoot = activity;
        this.$emit('activate-activity', activity, fetchDetails);
      },
      handleMove(activity: ActivityModel, index: number): void {
        activity.sequence = index;
        this.$emit('edited', activity, this.model);
      }
    },
    i18n: {
      messages: messages
    }
  });
