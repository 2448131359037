import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from '@/main/webapp/vue/model/api/NavigationLinks';
import { MapSubmission } from "@/main/webapp/vue/model/api/Map/MapSubmission";

@JsonObject('MapSubmissionContainer')
export class MapSubmissionContainer {
    @JsonProperty('nav', NavigationLinks, true)
    nav?: NavigationLinks = undefined;

    @JsonProperty('list', [MapSubmission], true)
    list?: MapSubmission[] = undefined;

    @JsonProperty('total', Number, true)
    total?: number = undefined;
}
