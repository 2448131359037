
  import Vue from 'vue';
  import { mapState } from "vuex";

  import applicationConfiguration from '@/main/webapp/vue/config/application/configuration';
  import settingsInputGroup from '@/main/webapp/vue/components/settings/settings-input-group/index.vue';
  import settingsSwitchGroup from '@/main/webapp/vue/components/settings/settings-switch-group/index.vue';
  import settingsSelectGroup, { SelectOption } from "@/main/webapp/vue/components/settings/settings-select-group/index.vue";
  import assumeUser from "@/main/webapp/vue/components/admin/assume-user/assume/index.vue";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { UserSettings } from "@/main/webapp/vue/model/api/UserSettings";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

  import messages from './messages.json';
  import notification from "@/main/webapp/vue/notification";
  import UriUtil from "@/main/webapp/vue/util/uriUtil";

  enum PasswordValidationResult {
    VALID = 'VALID',
    TOO_SHORT = 'TOO_SHORT',
    NO_LOWERCASE = 'NO_LOWERCASE',
    NO_UPPERCASE = 'NO_UPPERCASE',
    NO_DIGITS = 'NO_DIGITS',
    NO_SPECIAL_CHARACTERS = 'NO_SPECIAL_CHARACTERS'
  }

  export default Vue.extend({
    name: 'Settings',
    components: {
      settingsSelectGroup,
      settingsInputGroup,
      settingsSwitchGroup,
      assumeUser
    },
    data() {
      return {
        settings: new UserSettings(),
        currentEmail: undefined as string | undefined,
        currentLocale: undefined as string | undefined,
        departments: [] as SelectOption[],
        locales: [] as SelectOption[],
        timeZones: [] as SelectOption[],
        afterLoginPages: [] as SelectOption[],
        notificationSubscriptionConfigurations: [] as string[],
        minimumPasswordLength: 1,
        enforceSecurePassword: false
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      passwordValidation(): PasswordValidationResult {
        if (!this.settings.userPassword || this.settings.userPassword.length < this.minimumPasswordLength) {
          return PasswordValidationResult.TOO_SHORT;
        }
        if (this.enforceSecurePassword) {
          if (this.settings.userPassword.toLowerCase() === this.settings.userPassword) {
            return PasswordValidationResult.NO_UPPERCASE;
          }
          if (this.settings.userPassword.toUpperCase() === this.settings.userPassword) {
            return PasswordValidationResult.NO_LOWERCASE;
          }
          if (!this.settings.userPassword.match(applicationConfiguration.properties.security.password.validateDigits)) {
            return PasswordValidationResult.NO_DIGITS;
          }

          let password = this.settings.userPassword;
          if (password) {
            var specialCharacters =
              Array.from(applicationConfiguration.properties.security.password.validateSpecialCharacters);
            if (!(specialCharacters.some((value) => { return password.indexOf(value) >= 0; }))) {
              return PasswordValidationResult.NO_SPECIAL_CHARACTERS;
            }
          }
        }
        return PasswordValidationResult.VALID;
      },
      sensitiveFieldChanged(): boolean {
        return Boolean(this.settings.userPassword) || this.settings.userEmail !== this.currentEmail;
      },
      state(): {
        userName: boolean,
        userPassword: boolean,
        userPasswordAgain: boolean,
        currentPassword: boolean
      } {
        return {
          userName: Boolean(this.settings.userName),
          userPassword: !this.settings.userPassword ||
            this.passwordValidation === PasswordValidationResult.VALID,
          userPasswordAgain: !this.settings.userPassword ||
            this.settings.userPassword === this.settings.userPasswordAgain,
          currentPassword: Boolean(this.settings.currentPassword) ||
            !this.sensitiveFieldChanged
        };
      },
      formState(): boolean {
        return Object.values(this.state)
          .every(value => value);
      },
      showAssumeAssociatedUser(): boolean {
        return NavigationLinks.getLinkFromNavigation(NavigationLinkType.ASSUME_USER, this.userDetails.nav) !== undefined;
      }
    },
    mounted() {
      BackendIntegrationService.getUpdateUserSettingsMetadata()
        .then(metadata => {
          this.settings = metadata.userSettings;
          this.currentEmail = metadata.userSettings.userEmail;
          this.currentLocale = metadata.userSettings.userLocale;
          this.departments = [
            new SelectOption('', ''),
            ...metadata.departments
              .map(department => new SelectOption(String(department.id), department.name))
          ];
          this.locales = metadata.locales
            .map(locale => new SelectOption(locale.code, locale.name));
          this.timeZones = metadata.timeZones
            .map(SelectOption.of);
          this.afterLoginPages = metadata.afterLoginPages
            .map(afterLoginPage => new SelectOption(
              afterLoginPage,
              String(this.$t(`text.settings.afterLoginPages.${afterLoginPage}`)))
            );
          this.notificationSubscriptionConfigurations = metadata.notificationSubscriptionConfigurations;
          this.minimumPasswordLength = metadata.minimumPasswordLength;
          this.enforceSecurePassword = metadata.enforceSecurePassword;
        })
        .catch(error => {
          if (error.message === 'system.error.general.authentication.failed') {
            notification.fail(this.$t('text.error.authentication-failed'));
          }
          notification.fail(this.$t('text.error.internal-error'));
        });
    },
    methods: {
      onSubmit(e: Event) {
        e.preventDefault();
        if (!this.formState) {
          return;
        }
        BackendIntegrationService.putAuthenticatedUserSettings(this.settings)
          .then(settings => {
            const previousLocale = this.currentLocale;
            this.settings = settings;
            this.currentEmail = settings.userEmail;
            this.currentLocale = settings.userLocale;

            if (previousLocale !== settings.userLocale) {
              // Refresh page on locale change
              window.location.href = UriUtil.setQueryParameters(window.location.href, { 'lc': settings.userLocale, 'ok': 0 });
            }
            notification.success(this.$t(`text.success`));
          }).catch(error => {
            if (error.message === 'system.error.general.authentication.failed') {
              notification.fail(this.$t('text.error.authentication-failed'));
            } else if (error.message === 'system.error.general.user.not-allowed') {
              notification.fail(this.$t('text.error.not-allowed'));
            } else {
              notification.fail(this.$t(`text.error.${error.exception || 'internal-error'}`));
            }
          });
      }
    },
    i18n: {
      messages
    }
  });
