import { JsonObject, JsonProperty } from "json2typescript";
import { Logo } from "@/main/webapp/vue/model/api/Logo";

@JsonObject('LogoContainer')
export class LogoContainer {

  @JsonProperty('list', [Logo], false)
  list: Logo[] = [];

  public static getLogoWithType(type: string, list: Logo[]): Logo {
    return list.filter((logo: Logo) => {
      return logo.type === type;
    })[0];
  }
}
