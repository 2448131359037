import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";
import { ProjectProgress } from "./ProjectProgress";
import { ProjectProgressForUser } from "@/main/webapp/vue/model/api/ProjectProgressForUser";

@JsonObject('ProjectProgressForUserContainer')
export class ProjectProgressForUserContainer {

  @JsonProperty('list', [ProjectProgressForUser], false)
  list: ProjectProgressForUser[] = [];

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
