import { JsonField } from '@znapio/znapson';
import ButtonStyleModel from "@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel";
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";

export default class ButtonStyleWidgetProperties {
  constructor(@JsonField('buttonStyle', ButtonStyleModel, true)
              public buttonStyle = new ButtonStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}
