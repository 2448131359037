import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import imageWidget from '@/main/webapp/vue/components/learning/widget/widgets/image/widget/index.vue';
import imageWidgetProperties from '@/main/webapp/vue/components/learning/widget/widgets/image/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import AlignmentStyleModel from "@/main/webapp/vue/components/ui/style/alignment/model/AlignmentStyleModel";
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export enum ImageSourceType {
  FILE = "file",
  URL = "url"
}

export class ImageWidgetSize {
  constructor(
    @JsonField('width', Number, true)
    public width?: number) {}
}

export class ImageStyle {
  constructor(@JsonField('size', ImageWidgetSize, true)
              public size: ImageWidgetSize = new ImageWidgetSize(),
              @JsonField('alignment', AlignmentStyleModel, true)
              public alignment: AlignmentStyleModel = new AlignmentStyleModel()) {}
}

export class ImageWidgetData {
  constructor(@JsonField('src', String, true)
              public src?: string,
              @JsonField('lastUpdated', Number, true)
              public lastUpdated?: number,
              @JsonField('entityType', String, true)
              public entityType?: string,
              @JsonField('entityItemId', Number, true)
              public entityItemId?: number,
              @JsonField('externalImageSrc', String, true)
              public externalImageSrc: string = '',
              @JsonField('imageSourceType', String, true)
              public imageSourceType: ImageSourceType = ImageSourceType.FILE,
              @JsonField('altText', String, true)
              public altText: string = '',
              @JsonField('externalLink', String, true)
              public externalLink: string = '',
              @JsonField('fullscreen', Boolean, true)
              public fullscreen: boolean = false) {}
}

export class ImageWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties(),
              @JsonField('imageStyle', ImageStyle, true)
              public imageStyle = new ImageStyle()) {}
}

@JsonDiscriminator(WidgetModel, 'image')
export default class ImageWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.ALL,
              metadata = new WidgetMetadata(),
              @JsonField('data', ImageWidgetData)
              public data = new ImageWidgetData(),
              @JsonField('properties', ImageWidgetProperties)
              public properties = new ImageWidgetProperties(),
              @JsonField('defaultProperties', ImageWidgetProperties, true)
              public defaultProperties = new ImageWidgetProperties(),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.MEDIA,
  ImageWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'image',
  imageWidget,
  imageWidgetProperties
);
