import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import wistiaVideoWidget from '@/main/webapp/vue/components/learning/widget/widgets/wistia-video/widget/index.vue';
import wistiaVideoWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/wistia-video/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

const DEFAULT_URL_PREFIX: string = '//fast.wistia.net/embed/iframe/';
const DEFAULT_PREFERENCES: string = ''; // Override original video preferences

export class WistiaVideoWidgetData {
  constructor(@JsonField('src', String)
              public src: string = DEFAULT_URL_PREFIX,
              @JsonField('videoId', String)
              public videoId: string = '',
              @JsonField('preferences', String)
              public preferences: string = DEFAULT_PREFERENCES) {}
}

export class WistiaVideoWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'WistiaVideo')
export default class WistiaVideoWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection: DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', WistiaVideoWidgetData)
              public data = new WistiaVideoWidgetData(),
              @JsonField('properties', WistiaVideoWidgetProperties)
              public properties = new WistiaVideoWidgetProperties(),
              @JsonField('defaultProperties', WistiaVideoWidgetProperties, true)
              public defaultProperties = new WistiaVideoWidgetProperties(
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.MEDIA,
  WistiaVideoWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'ondemand_video',
  wistiaVideoWidget,
  wistiaVideoWidgetProperties,
  (id: WidgetIdType) => new WistiaVideoWidgetModel(id, DraggableSection.BODY, new WidgetMetadata())
);
