
  import Vue from 'vue';
  import { mapState } from "vuex";
  import textSpan from '@/main/webapp/vue/components/ui/text/text-span/index.vue';

  export default Vue.extend({
    components: {
      textSpan
    },
    props: {
      text: {
        type: String,
        default: null
      }
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      computedText(): string {
        return (this.text).toLocaleString(this.$i18n.locale);
      }
    }
  });
