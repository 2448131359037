
  import Vue, { PropType } from 'vue';

  import { OrganizationData } from "@/main/webapp/vue/model/api/learning/OrganizationData";

  export default Vue.extend({
    props: {
      data: {
        type: Object as PropType<OrganizationData>,
        required: false
      }
    }
  });
