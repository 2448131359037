import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import fileAttchmentWidget from '@/main/webapp/vue/components/learning/widget/widgets/file-attachment/widget/index.vue';
import fileAttachmentFileWidgetProperties from '@/main/webapp/vue/components/learning/widget/widgets/file-attachment/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class AttachedFile {
  constructor(@JsonField('src', String, true)
              public src?: string,
              @JsonField('lastUpdated', Number, true)
              public lastUpdated?: number,
              @JsonField('name', String, true)
              public name?: string,
              @JsonField('originalName', String, true)
              public originalName?: string,
              @JsonField('icon', String, true)
              public icon: string = "attach_file",
              @JsonField('size', Number, true)
              public size?: number,
              @JsonField('includeSize', Boolean, true)
              public includeSize: boolean = false) {}
}

export class FileAttachmentWidgetData {
  constructor(@JsonField('entityType', String, true)
              public entityType?: string,
              @JsonField('entityItemId', Number, true)
              public entityItemId?: number,
              @JsonField('file', AttachedFile, true)
              public file?: AttachedFile) {}
}

export class FileAttachmentWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'fileAttachment')
export default class FileAttachmentWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', FileAttachmentWidgetData)
              public data = new FileAttachmentWidgetData(),
              @JsonField('properties', FileAttachmentWidgetProperties)
              public properties = new FileAttachmentWidgetProperties(),
              @JsonField('defaultProperties', FileAttachmentWidgetProperties, true)
              public defaultProperties = new FileAttachmentWidgetProperties(),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.MEDIA,
  FileAttachmentWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'attach_file',
  fileAttchmentWidget,
  fileAttachmentFileWidgetProperties
);
