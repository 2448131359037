
  import Vue from 'vue';
  import messages from './messages.json';

  import { v4 as uuidv4 } from 'uuid';

  export default Vue.extend({
    props: {
      inputValue: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: 'Enter a value'
      },
      size: {
        type: String,
        default: 'medium'
      },
      autoFocus: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      masked: {
        type: Boolean,
        default: false
      },
      validator: {
        type: Function,
        default: null
      }
    },
    data() {
      return {
        inputUUID: "" as string,
        editable: false as boolean,
        hover: false as boolean,
        tempInput: this.inputValue as string
      };
    },
    computed: {
      computedTempInput(): string {
        if (!this.tempInput) {
          return this.placeholder;
        }

        return this.tempInput;
      }
    },
    watch: {
      inputValue(newValue: string, oldValue: string): void {
        if (newValue !== oldValue) {
          this.tempInput = newValue;
        }
      }
    },
    methods: {
      addFocus(): void {
        this.editable = true;
        this.hover = false;
        setTimeout(() => {
          (this.$refs.formInput as HTMLInputElement).focus();
        }, 100);
      },
      removeFocus(): void {
        this.editable = false;
        this.hover = false;
        (this.$refs.formInput as HTMLInputElement).blur();
      },
      saveAndRemoveFocus(save: boolean): void {
        if (!save) {
          this.tempInput = this.inputValue;
        }

        this.removeFocus();
      },
      onFocus(): void {
        if (this.masked) {
          this.tempInput = '';
        }
      },
      onBlur(): void {
        if (this.editable) {
          this.removeFocus();
        }

        if (this.tempInput === this.inputValue) {
          return;
        }

        if (this.validator && !this.validator(this.tempInput)) {
          this.$emit('validation-error', this.tempInput);
          this.tempInput = this.inputValue;
          return;
        }

        this.$emit('input-changed', this.tempInput);
      }
    },
    mounted(): void {
      this.inputUUID = uuidv4();
      if (this.autoFocus) {
        this.addFocus();
      }
    },
    i18n: {
      messages: messages
    }
  });
