
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import inputField from "@/main/webapp/vue/components/ui/input/index.vue";
  import filterListCollapsibleElement
    from "@/main/webapp/vue/components/ui/filter/filter-list-collapsible/filter-list-collapsible-element/index.vue";
  import materialIconText from "@/main/webapp/vue/components/ui/icon/material-icon-text/index.vue";
  import loadingIcon from "@/main/webapp/vue/components/ui/icon/loading/index.vue";
  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import { SearchData } from "@/main/webapp/vue/model/api/SearchData";
  import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { SearchCriterion } from "@/main/webapp/vue/model/api/SearchCriterion";

  export default Vue.extend({
    name: "filter-list-collapsible",
    props: {
      items: {
        type: Array as PropType<SearchData[]>,
        default: () => []
      },
      selectedItemIds: {
        type: Array as PropType<String[]>,
        default: () => []
      },
      root: {
        type: Boolean,
        default: false
      },
      allowRemoteSearch: {
        type: Boolean,
        default: false
      },
      nav: {
        type: Object as PropType<NavigationLinks>,
        default: null
      },
      showIndicator: {
        type: Boolean,
        default: false
      },
      focusOnOpen: {
        type: Boolean,
        default: false
      }
    },
    components: {
      inputField,
      filterListCollapsibleElement,
      materialIconText,
      loadingIcon
    },
    data() {
      return {
        temps: {
          selectedItemIds: this.selectedItemIds.concat() as string[],
          showIndicator: this.showIndicator as boolean,
          remoteSearchData: [] as SearchData[]
        },
        searchKeywords: "" as string,
        processing: false as boolean
      };
    },
    computed: {
      computedItems(): SearchData[] {
        if (this.allowRemoteSearch && this.searchKeywords.length > 0) { // Prioritize remoteSearch than localSearch
          return this.temps.remoteSearchData;
        }

        if (this.searchKeywords.length > 0) {
          return this.items.filter((searchData: SearchData) =>
            searchData && searchData.label && searchData.label.toLowerCase().includes(this.searchKeywords.toLowerCase()));
        }

        return this.items;
      }
    },
    watch: {
      selectedItemIds(newIds: string[], oldIds: string[]): void {
        this.temps.selectedItemIds = newIds.concat();
      },
      clear(newClear, oldClear): void {
        if (newClear) {
          this.temps.selectedItemIds = [];
        }
      }
    },
    methods: {
      fetchRemoteSearch(link: NavigationLink, searchTerm: string): void {
        if (this.processing) {
          return;
        }

        this.processing = true;

        BackendIntegrationService.fetchRemoteSearchCriteria(link, searchTerm)
          .then((results: SearchCriterion) => {
            if (results) {
              this.temps.remoteSearchData = results.data ? results.data : [];
            }
          }).catch((e: Error) => {
            notification.fail(this.$t('text.error.remote-search'));
          }).finally(() => {
            this.processing = false;
          });
      },
      onSearchUpdate(newSearch: string): void {
        if (newSearch) {
          if (!this.allowRemoteSearch) { // on the fly
            this.searchKeywords = newSearch;
          } else {
            if (newSearch.length >= 3) { // if more than 3, fetch for remote search
              this.onSearchSubmit(newSearch);
            }
          }
        } else { // Clear
          this.searchKeywords = newSearch;
        }
      },
      onSearchSubmit(newSearch: string): void {
        if (this.allowRemoteSearch) {
          const remoteSearchKey: string = "filter_data_search";
          let link: NavigationLink | undefined = this.nav.getLink(remoteSearchKey);
          if (link && link.href) {
            this.searchKeywords = newSearch;
            this.fetchRemoteSearch(link, this.searchKeywords);
          }
        }
      },
      isSelected(itemId: string): boolean {
        return Boolean(this.temps.selectedItemIds.find((selectedItemId: string) => selectedItemId === itemId));
      },
      emitChange(): void {
        this.$emit('item-change', this.temps.selectedItemIds);
      },
      removeSelected(item: SearchData): void {
        const index: number = this.temps.selectedItemIds.findIndex((selectedItemId: string) => selectedItemId === item.id);
        if (index >= 0) {
          this.temps.selectedItemIds.splice(index, 1);
        }

        if (item.data && item.data.length > 0) {
          item.data.forEach((subItem: SearchData) => {
            this.removeSelected(subItem);
          });
        }
      },
      addSelected(item: SearchData): void {
        if (item && item.id) {
          this.temps.selectedItemIds.push(item.id);
        }

        if (item.data && item.data.length > 0) {
          item.data.forEach((subItem: SearchData) => {
            this.addSelected(subItem);
          });
        }
      },
      itemSelected(item: SearchData): void {
        if (item) {
          if (!this.root) {
            this.$emit('sub-items-selected', item);
          } else if (item.id && !this.isSelected(item.id)) {
            this.addSelected(item);
            this.emitChange();
          }
        }
      },
      checkIfNeedToRemoveRootItem(item: SearchData): boolean {
        let numberOfSubItemsSelected: number = 0;
        if (item && item.data && item.data.length > 0) {
          item.data.forEach((subItem: SearchData) => {
            if (subItem && subItem.id && this.isSelected(subItem.id)) {
              numberOfSubItemsSelected += 1;
            }
          });
        }

        return (numberOfSubItemsSelected <= 1);
      },
      itemUnselected(item: SearchData, rootItem: SearchData): void {
        let itemToRemove: SearchData = item;
        if (rootItem && this.checkIfNeedToRemoveRootItem(rootItem)) {
          itemToRemove = rootItem;
        }

        if (itemToRemove) {
          if (!this.root) {
            this.$emit('sub-items-unselected', itemToRemove);
          } else if (itemToRemove.id && this.isSelected(itemToRemove.id)) {
            this.removeSelected(itemToRemove);
            this.emitChange();
          }
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
