import { JsonObject, JsonProperty } from "json2typescript";
import { MapPropertiesItem } from "@/main/webapp/vue/model/api/Map/MapPropertiesItem";

@JsonObject('MapProperties')
export class MapProperties {
    @JsonProperty('path', String, true)
    path?: string = undefined;

    @JsonProperty('item', MapPropertiesItem, true)
    item?: MapPropertiesItem = undefined;
}
