
  import Vue, { PropType } from 'vue';
  import { isArray } from "lodash";
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

  import dataTable, { ActionType, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';
  import { EntityRegistryClientMapping } from "@/main/webapp/vue/model/api/EntityRegistryClientMapping";

  export default Vue.extend({
    props: {
      rawData: {
        type: String,
        required: true
      },
      displayKey: {
        type: String,
        default: 'label'
      },
      keyType: {
        type: String as PropType<FieldType>,
        default: FieldType.TEXT
      },
      keyLabel: {
        type: String,
        default: null
      },
      displayKeyCustomFunctions: {
        type: Object,
        default: () => {}
      },
      displayValue: {
        type: String,
        default: 'field'
      },
      valueType: {
        type: String as PropType<FieldType>,
        default: FieldType.TEXT
      },
      valueLabel: {
        type: String,
        default: null
      },
      displayValueCustomFunctions: {
        type: Object,
        default: () => {}
      },
      uniqueId: {
        type: String,
        default: null
      },
      overwriteValue: {
        type: String,
        default: null
      },
      selectedItems: {
        type: Array,
        default: null
      },
      enabledAdd: {
        type: Boolean,
        default: true
      },
      noDataField: {
        type: String,
        default: 'data'
      }
    },
    components: {
      dataTable
    },
    data() {
      return {
        data: [] as EntityRegistryClientMapping[],
        originalData: [] as EntityRegistryClientMapping[],
        newRowDataType: () => new EntityRegistryClientMapping(),
        fields: [
          {
            key: "label",
            label: this.keyLabel ? this.keyLabel : this.$t('generic.field'),
            type: this.keyType,
            list: () => this.originalData,
            ...this.displayKeyCustomFunctions
          },
          {
            key: "field",
            label: this.valueLabel ? this.valueLabel : this.$t('generic.value'),
            type: this.valueType,
            ...this.displayValueCustomFunctions
          },
          {
            key: "actions",
            label: "",
            type: FieldType.ACTIONS,
            autosave: true,
            actions: [
              {
                type: ActionType.INLINE_EDIT,
                icon: {
                  title: "Edit mapping"
                }
              },
              {
                type: ActionType.REMOVE,
                icon: {
                  title: "Remove mapping"
                },
                confirmation: (mapping: EntityRegistryClientMapping): String => {
                  return `Delete mapping '${mapping.label}'?`;
                },
                hideIf: (mapping: EntityRegistryClientMapping): boolean => {
                  return mapping.generic;
                }
              }
            ]
          }
        ],
        isArray: false as boolean,
        selectedItemIds: null as number[] | null,
        tempEnableAdd: this.enabledAdd
      };
    },
    methods: {
      update(mapping: EntityRegistryClientMapping, data: EntityRegistryClientMapping[]): void {
        let newData = JSON.parse(JSON.stringify(data));
        let newMetadata: any;
        if (this.isArray) {
          newMetadata = this.generateArrayFormat(newData);
        } else {
          newMetadata = this.generateObjectFormat(newData);
        }

        this.$emit('update', newMetadata);
        this.setSelectedData(newData);
      },
      generateArrayFormat(newData: EntityRegistryClientMapping[]) {
        const tempMetadata: any[] = newData.map((mapping: EntityRegistryClientMapping) => {
          if (mapping && mapping.metadata) { // When only change field value
            mapping.metadata[this.displayValue] = mapping.field;
          }
          return mapping.metadata;
        });
        return tempMetadata;
      },
      generateObjectFormat(newData: EntityRegistryClientMapping[]) {
        let tempMetadata: any = {};
        newData.forEach((mapping: EntityRegistryClientMapping) => {
          if (mapping.label || mapping.field) {
            let label: string = "-";
            let field: string = "-";
            // Prevent null
            if (mapping.label) {
              label = mapping.label;
            }
            if (mapping.field) {
              field = mapping.field;
            }
            tempMetadata[label] = field;
          }
        });
        return tempMetadata;
      },
      convertArrayToEntity(parsedData: any[] = []) {
        const newData: EntityRegistryClientMapping[] = parsedData.map((data: any) => {
          let newEntity: EntityRegistryClientMapping = new EntityRegistryClientMapping();
          newEntity.label = data[this.displayKey];
          newEntity.field = data[this.displayValue];
          newEntity.metadata = data;
          if (this.uniqueId) {
            newEntity.id = data[this.uniqueId];
          }
          return newEntity;
        });

        this.setData(newData);
      },
      convertObjectToEntity(parsedData: any = {}) {
        const newData: EntityRegistryClientMapping[] = Object.keys(parsedData).map((key: string, index: number) => {
          let newEntity: EntityRegistryClientMapping = new EntityRegistryClientMapping();
          newEntity.label = key;
          newEntity.field = parsedData[key];
          newEntity.id = index;
          return newEntity;
        });

        this.setData(newData);
      },
      setSelectedData(data: EntityRegistryClientMapping[] = []) {
        if (this.selectedItemIds && this.uniqueId) {
          this.selectedItemIds = data.map((item: any) => {
            if (item && item.metadata) {
              return item.metadata[this.uniqueId];
            }
          });

          this.checkEnableAdd();
        }
      },
      checkEnableAdd(): void { // If all the data selected, disable add
        if (this.selectedItemIds) {
          this.tempEnableAdd = !(this.originalData.length > 0 &&
            this.selectedItemIds.length === this.originalData.length);
        }
      },
      isSelected(item: any): boolean {
        if (this.selectedItems && this.uniqueId && item && item.metadata) {
          let matchedItem: any = this.selectedItems.find((selectedItem: any) =>
            item.metadata[this.uniqueId] === selectedItem[this.uniqueId]);
          if (matchedItem) {
            if (this.overwriteValue &&
              (matchedItem[this.overwriteValue] !== undefined && matchedItem[this.overwriteValue] !== null)) {
              item.field = matchedItem[this.overwriteValue];
            }
            return true;
          }
        }
        return false;
      },
      filteredData(data: EntityRegistryClientMapping[]): EntityRegistryClientMapping[] {
        if (this.selectedItemIds) {
          return data.filter((item: any) => this.isSelected(item));
        }
        return data;
      },
      setData(data: EntityRegistryClientMapping[] = []): void {
        this.originalData = JSON.parse(JSON.stringify(data));
        this.data = this.filteredData(data);
      },
      initSelectedItemIds(): void {
        if (this.selectedItems && this.uniqueId) {
          this.selectedItemIds = this.selectedItems.map((selectedItem: any) => selectedItem[this.uniqueId]);
        }
      },
      transformToItems(): void {
        if (this.rawData) {
          const parsedData: any = JSON.parse(this.rawData);
          if (isArray(parsedData)) {
            this.isArray = true;
            this.convertArrayToEntity(parsedData);
          } else {
            this.convertObjectToEntity(parsedData);
          }
        }
      }
    },
    created(): void {
      this.initSelectedItemIds();
      this.transformToItems();
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
