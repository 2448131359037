
  import Vue, { PropType } from 'vue';
  import { Chrome } from 'vue-color';
  import messages from '../messages.json';

  import formCard from '@/main/webapp/vue/components/ui/form/card/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import iconInput from '@/main/webapp/vue/components/ui/style/icon/container/icon-input/index.vue';

  import IconStyleModel from "@/main/webapp/vue/components/ui/style/icon/model/IconStyleModel";

  export default Vue.extend({
    name: 'IconStyle',
    props: {
      iconStyle: {
        type: Object as PropType<IconStyleModel>,
        default: () => new IconStyleModel(undefined, undefined, undefined)
      },
      defaultStyle: { // Widget should set default style
        type: Object as PropType<IconStyleModel>, // default value is only for branding setting
        default: () => new IconStyleModel().default()
      },
      title: {
        type: String
      },
      enableColor: {
        type: Boolean,
        default: true
      },
      enableSecondColor: {
        type: Boolean,
        default: false
      },
      enableSize: {
        type: Boolean,
        default: true
      },
      colorTitle: {
        type: String,
        default: null
      },
      secondColorTitle: {
        type: String,
        default: null
      },
      sizeTitle: {
        type: String,
        default: null
      },
      useCollapsibleContainer: {
        type: Boolean,
        default: true
      },
      visibleByDefault: {
        type: Boolean,
        default: false
      },
      alignContentsVertically: {
        type: Boolean,
        default: true
      },
      busy: {
        type: Boolean,
        default: false
      }
    },
    components: {
      formCard,
      iconInput,
      collapsibleContainer,
      'chrome-picker': Chrome
    },
    computed: {
      component() {
        if (this.useCollapsibleContainer) {
          return 'collapsible-container';
        }
        return 'form-card';
      }
    },
    i18n: {
      messages: messages
    }
  });
