import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import StyleModel from "@/main/webapp/vue/model/learning/StyleModel";

export class CollapsibleHeaderModel {
  constructor(@JsonField('backgroundColor', String, true)
              public backgroundColor?: string,
              @JsonField('textColor', String, true)
              public textColor?: string,
              @JsonField('borderRadius', Number, true)
              public borderRadius?: number) {}
}

export class CollapsibleBodyModel {
  constructor(@JsonField('backgroundColor', String, true)
              public backgroundColor?: string,
              @JsonField('borderRadius', Number, true)
              public borderRadius?: number) {}
}

@JsonDiscriminator(StyleModel, 'collapsible')
export default class CollapsibleStyleModel extends StyleModel {
  constructor(@JsonField('headerStyle', CollapsibleHeaderModel, true)
              public headerStyle: CollapsibleHeaderModel = new CollapsibleHeaderModel(),
              @JsonField('bodyBrand', CollapsibleBodyModel, true)
              public bodyBrand: CollapsibleBodyModel = new CollapsibleBodyModel()) {
    super();
  }

  default(): CollapsibleStyleModel {
    return new CollapsibleStyleModel(
      new CollapsibleHeaderModel('#007BFF', '#ffffff', 5),
      new CollapsibleBodyModel('#007BFF', 5));
  }
}
