import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject('MapMarkerHtml')
export class MapMarkerHtml {
    @JsonProperty('item', Any, true)
    item?: any = undefined;

    @JsonProperty('text', String, true)
    text?: string = undefined;
}
