
  import Vue, { PropType } from 'vue';

  import { ColoredLabel } from "@/main/webapp/vue/model/api/ColoredLabel";

  export default Vue.extend({
    props: {
      label: {
        type: Object as PropType<ColoredLabel>,
        required: true
      }
    }
  });
