import { JsonObject, JsonProperty } from "json2typescript";
import { Project } from "./Project";
import { ProjectStatusLabel } from "./ProjectStatusLabel";

@JsonObject('ProjectStatusDefinition')
export class ProjectStatusDefinition {

  @JsonProperty('priority', Number, true)
  priority?: number = 0;

  @JsonProperty('name', String, false)
  name: string = "";

  @JsonProperty('alias', String, true)
  alias?: string = "";

  @JsonProperty('label', String, true)
  label?: string = "";

  @JsonProperty('color', String, true)
  color?: string = "";

  @JsonProperty('colorCode', String, true)
  colorCode?: string = "";

  @JsonProperty('barStyle', String, true)
  barStyle?: string = "";

  @JsonProperty('assignable', Boolean, false)
  assignable: boolean = false;

  @JsonProperty('showable', Boolean, true)
  showable?: boolean = false;

  @JsonProperty('selected', Boolean, true)
  selected?: boolean = false;

  @JsonProperty('initial', Boolean, true)
  initial?: boolean = false;

  @JsonProperty('category', Number, true)
  category?: number = -1;

  static getForProject(statuses: ProjectStatusDefinition[], project: Project): ProjectStatusDefinition {
    return this.get(statuses, project.status);
  }

  static get(statuses: ProjectStatusDefinition[], label: ProjectStatusLabel): ProjectStatusDefinition {
    return statuses.filter(status => {
      return status !== null && status.name === label.name;
    })[0];
  }

  static getSelected(statuses: ProjectStatusDefinition[]): ProjectStatusDefinition {
    return statuses.filter(status => {
      return status !== null && status.selected;
    })[0];
  }

}
