import { JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";
import { ProjectProgress } from "./ProjectProgress";
import { ProjectDocumentation } from "./ProjectDocumentation";
import { LastSubmission } from "./LastSubmission";

@JsonObject('ProjectDetails')
export class ProjectDetails extends NamedEntity {

  // TODO: validity

  @JsonProperty('progress', ProjectProgress, true)
  progress: ProjectProgress | undefined = undefined;

  @JsonProperty('documentation', ProjectDocumentation, true)
  documentation: ProjectDocumentation | undefined = undefined;

  @JsonProperty('lastSubmission', LastSubmission, true)
  lastSubmission?: LastSubmission = undefined;

}
