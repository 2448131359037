
  import Vue from "vue";
  import { mapState } from "vuex";

  import messages from './messages.json';

  import materialIconText from "@/main/webapp/vue/components/ui/icon/material-icon-text/index.vue";
  import assumeUser from "@/main/webapp/vue/components/admin/assume-user/assume/index.vue";
  import { URL_PATH } from "@/main/webapp/vue/urlPath";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import notification from "@/main/webapp/vue/notification";

  export default Vue.extend({
    components: {
      materialIconText,
      assumeUser
    },
    props: {
      loginName: {
        type: String,
        default: null
      },
      userIdString: {
        type: String,
        default: null
      },
      assumeUserString: {
        type: String,
        default: null
      },
      resetPasswordString: {
        type: String,
        default: null
      },
      editUserString: {
        type: String,
        default: null
      },
      deleteUserString: {
        type: String,
        default: null
      }
    },
    computed: { // JSON.parse() is the recommended way to convert string to boolean in TS
      userId(): number {
        return Number(this.userIdString);
      },
      assumeUser(): boolean {
        return JSON.parse(this.assumeUserString);
      },
      resetPassword(): boolean {
        return JSON.parse(this.resetPasswordString);
      },
      editUser(): boolean {
        return JSON.parse(this.editUserString);
      },
      deleteUser(): boolean {
        return JSON.parse(this.deleteUserString);
      },
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      handleResetPassword(): void {
        this.$bvModal.msgBoxConfirm(this.$t('text.modal.reset-password', [this.loginName]).toString(), {
          centered: true
        }).then(value => {
          if (value === true) {
            BackendIntegrationService.resetPassword(this.loginName).then(response => {
              notification.success(this.$t('text.notification.success.reset-password'));
            }).catch(error => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(error);
              }
              notification.fail(this.$t('text.notification.failure.reset-password'));
            });
          }
        }).catch(error => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(error);
          }
        });
      },
      handleEditUser(): void {
        window.location.href = `${URL_PATH.USERS}?uid=${this.userId}`;
      },
      handleDeleteUser(): void {
        this.$bvModal.msgBoxConfirm(this.$t('text.modal.delete-user', [this.loginName]).toString(), {
          centered: true
        }).then(value => {
          if (value === true) {
            window.location.href = `delete-user.html?uid=${this.userId}`;
          }
        }).catch(error => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(error);
          }
        });
      }
    },
    i18n: {
      messages: messages
    }
  });
