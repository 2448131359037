import { JsonObject, JsonProperty } from "json2typescript";
import { Metadata } from "@/main/webapp/vue/model/api/Metadata";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('Entity')
export class Entity extends Metadata {

  @JsonProperty('id', Number, true)
  id: number | undefined = undefined;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
