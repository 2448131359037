import { JsonObject, JsonProperty } from "json2typescript";
import { Entity } from "./Entity";
import { DateTime } from "./DateTime";
import { Organization } from "@/main/webapp/vue/model/api/Organization";
import { User } from "./User";
import { ItemContent } from "./ItemContent";
import { HierarchicalEntity } from "./HierarchicalEntity";
import { FeedText } from "@/main/webapp/vue/model/api/FeedText";

@JsonObject('Item')
export class Item extends Entity {

  @JsonProperty('created', DateTime, true)
  created?: DateTime = undefined;

  @JsonProperty('organization', Organization, true)
  organization?: Organization = undefined;

  @JsonProperty('author', User, true)
  author?: User = undefined;

  @JsonProperty('description', String, true)
  description?: string = undefined;

  @JsonProperty('contents', [ItemContent], true)
  contents?: ItemContent[] = undefined;

  @JsonProperty('itemMetadata', [HierarchicalEntity], true)
  itemMetadata?: HierarchicalEntity[] = undefined;

  @JsonProperty('comments', Number, false)
  comments: number = 0;

  @JsonProperty('likes', Number, false)
  likes: number = 0;

  @JsonProperty('textPlaceholders', FeedText, true)
  textPlaceholders?: FeedText = undefined;

}
