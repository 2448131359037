import { Any, JsonObject, JsonProperty } from "json2typescript";
import { NamedEntity } from "./NamedEntity";
import { CategoryContainer } from "./CategoryContainer";

@JsonObject('Category')
export class Category extends NamedEntity {

  @JsonProperty('mandatory', Boolean, true)
  mandatory?: boolean = false;

  @JsonProperty('multiselect', Boolean, true)
  multiselect?: boolean = false;

  @JsonProperty('subcategories', Any, true)
  subcategories: CategoryContainer | undefined = undefined;

}

export enum CategorySetType {
  SHOP = 'shop',
  PROJECT = 'project',
  DEFAULT = 'default'
}
