
  import Vue from 'vue';

  export default Vue.extend({
    name: 'FloatingButton',
    props: {
      text: {
        type: String,
        default: ''
      },
      variant: {
        type: String,
        default: 'info'
      },
      hoverText: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        hover: false
      };
    }
  });
