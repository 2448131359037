import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject('EntityRegistryDataSourceEvent')
export class EntityRegistryDataSourceEvent {

  @JsonProperty('uuid', String, false)
  uuid: string = "";

  @JsonProperty('timestamp', String, false)
  timestamp: string = "";

  @JsonProperty('data', Any, true)
  data: any = undefined;

}
