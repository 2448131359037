
  import Vue from 'vue';
  import { mapState } from "vuex";
  import messages from './messages.json';

  import textButton from '@/main/webapp/vue/components/ui/text/text-button/index.vue';

  import CollapsibleControlFactory, { CollapsibleControl } from "@/main/webapp/vue/model/collapsibleControl";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

  export default Vue.extend({
    components: {
      textButton
    },
    data() {
      return {
        defaultButton: CollapsibleControlFactory.prototype.DEFAULT() as CollapsibleControl,
        collapseButton: CollapsibleControlFactory.prototype.COLLAPSE() as CollapsibleControl,
        expandButton: CollapsibleControlFactory.prototype.EXPAND() as CollapsibleControl
      };
    },
    methods: {
      update(collapsibleType: CollapsibleControl): void {
        const urlPath: string = RoutePath.TIMELINE;
        this.$store.commit('updateCollapsibleTypes', { urlPath, collapsibleType });
      }
    },
    computed: {
      ...mapState([
        'collapsedTypes'
      ]),
      computedCollapsedType(): CollapsibleControl {
        return this.collapsedTypes?.paths[RoutePath.TIMELINE];
      }
    },
    i18n: {
      messages: messages
    }
  });
