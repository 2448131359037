import { JsonObject, JsonProperty } from "json2typescript";
import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
import { ListViewSelectedField } from "@/main/webapp/vue/model/api/ListViewSelectedField";

@JsonObject('ReportFilter')
export class ReportFilter {

  @JsonProperty('selectedCriteria', Array)
  selectedCriteria: SearchCriteriaSelectedDataValue[] | undefined = undefined

  @JsonProperty('selectedSubmissionIds', Array)
  selectedSubmissionIds: Number[] | undefined = undefined

  @JsonProperty('selectedFields', Array)
  selectedFields: ListViewSelectedField[] | undefined = undefined
}
