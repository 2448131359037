
  import Vue from 'vue';
  import textSpan from '../text-span/index.vue';

  export default Vue.extend({
    components: {
      textSpan
    },
    props: {
      localization: {
        type: Object,
        required: true
      },
      partialTranslationKey: {
        type: String,
        required: true
      },
      messageParameters: {
        type: Object
      },
      extendedTranslation: {
        type: Boolean
      }
    }
  });
