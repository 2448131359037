
  import Vue from 'vue';
  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    props: {
      target: { // Target element should have its height like 100vh etc
        type: HTMLElement,
        default: null
      }
    },
    components: {
      glyphiconSymbol
    },
    data() {
      return {
        show: false,
        displayThreshold: 300,
        targetElement: null as HTMLElement | Window | null
      };
    },
    methods: {
      scrollToTop(): void {
        let target: HTMLElement | Window = this.getTargetElement();
        target.scrollTo({ top: 0, behavior: "smooth" });
      },
      getTargetElement(): HTMLElement | Window {
        if (this.target) {
          return this.target;
        }

        console.log("Target element not found for scrollToTop, set default window");
        return window;
      },
      handleScroll(): void {
        if (this.targetElement) {
          const target = this.target ? this.targetElement : document.documentElement;
          this.show = (target.scrollTop > this.displayThreshold);
        }
      },
      registerScrollEvent(): void {
        this.targetElement = this.getTargetElement();
        this.targetElement.addEventListener('scroll', this.handleScroll);
      },
      unregisterScrollEvent(): void {
        if (this.targetElement) {
          this.targetElement.removeEventListener('scroll', this.handleScroll);
        }
      }
    },
    mounted(): void {
      this.registerScrollEvent();
    },
    beforeDestroy(): void {
      this.unregisterScrollEvent();
    }
  });
