
  import Vue from 'vue';
  import L, { LatLng, Map } from 'leaflet';
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';
  import markers from '@/main/webapp/vue/components/map/marker/index.vue';
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import mapHtmlConfiguration from "@/main/webapp/vue/components/map/mapHtmlConfiguration";
  import markerConfig from "@/main/webapp/vue/components/map/markerConfig";

  import { MapAddress } from "@/main/webapp/vue/model/api/Map/MapAddress";
  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
  import { IconTypes } from "@/main/webapp/vue/model/api/Map/IconTypes";
  import { IconType } from "@/main/webapp/vue/model/api/Map/IconType";
  import { GoogleMapIntegrationService } from "@/main/webapp/vue/services/GoogleMapIntegrationService";

  export default Vue.extend({
    components: {
      baseMap,
      markers,
      loadingIcon
    },
    data() {
      return {
        locations: [] as MapMarker[],
        mapHeight: "275px" as string, // item page map size
        mapObject: undefined as Map|undefined,
        iconTypes: mapConfiguration.config.iconTypes as IconTypes,
        googleMapService: null as any, // GoogleMapIntegrationService
        modalActivated: false as boolean,
        loading: false as boolean
      };
    },
    methods: {
      fitBounds(): void {
        setTimeout(() => {
          if (this.mapObject && this.locations && this.locations.length > 0) {
            this.mapObject.fitBounds((this.locations as any));
          }
        }, 100);
      },
      validateScreenSize(): void {
        setTimeout(() => { // need to wait for jquery
          if (this.mapObject) {
            this.mapObject.invalidateSize();
            this.fitBounds();
          }
        }, 1000);
      },
      addNewLocation(newLocation: LatLng, iconType: IconType, message: string): void {
        this.removeLastMarker();

        let newMarker: MapMarker = markerConfig.transformToMarker(
          newLocation.lat.toString(), newLocation.lng.toString(), iconType, message);

        this.locations.push(newMarker);
        this.fitBounds();
      },
      removeLastMarker(): void {
        if (this.locations.length >= 2) {
          this.locations.pop();
        }
      },
      getNewAddressOrLocation(address: string | null, submit: boolean = false): void {
        mapHtmlConfiguration.showGeolocationSpinner();
        let error: boolean = false;

        if (address) {
          this.googleMapService.fromAddressToCoordinates(address).then((latLngByAddress: LatLng) => {
            if (this.iconTypes.shop) {
              let message: string = this.$t(`text.new-location`).toString();
              this.addNewLocation(latLngByAddress, this.iconTypes.shop, message);
              this.updateLookupButton(latLngByAddress);
            }
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('From coordinates to address failed: ', e);
            }

            error = true;
          }).finally(() => {
            mapHtmlConfiguration.hideGeolocationSpinner();
            if (submit && !error) {
              this.submitForm();
            }

            if (error) {
              this.cleanUpAndEnableButton();
            }
          });
        } else {
          let coordinates: LatLng | null = mapHtmlConfiguration.getCurrentCoordinates();
          if (coordinates) {
            this.googleMapService.fromCoordinatesToAddress(coordinates).then((addressByLatLng: MapAddress) => {
              mapHtmlConfiguration.setAddressInHTML(addressByLatLng);
              mapHtmlConfiguration.enableNewAddressLookupButton(this.locations.length);
            }).catch((e: Error) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log('From new address to coordinates failed: ', e);
              }

              error = true;
            }).finally(() => {
              mapHtmlConfiguration.hideGeolocationSpinner();
              if (submit && !error) {
                this.submitForm();
              }

              if (error) {
                this.cleanUpAndEnableButton();
              }
            });
          } else {
            this.cleanUpAndEnableButton();
          }
        }
      },
      cleanUpAndEnableButton(): void {
        notification.fail(this.$t('text.error.lookup'));
        mapHtmlConfiguration.hideGeolocationSpinner();
        mapHtmlConfiguration.hideFormSpinner();
        this.disableButton(null, false); // enable the button => prevent submit => prepare next submit
      },
      listeningToHtmlModalButtons(): void {
        const newAddressLookupButton: HTMLButtonElement | null = mapHtmlConfiguration.getLookupBtn();
        const cancelLookupButton: HTMLElement | null = mapHtmlConfiguration.getCancelLookupBtn();
        const submitButton: HTMLButtonElement | null = mapHtmlConfiguration.getSubmitEntityBtn();

        mapHtmlConfiguration.enableNewAddressLookupButton(this.locations.length);

        if (newAddressLookupButton) {
          newAddressLookupButton.addEventListener('click', () => {
            if (!newAddressLookupButton.classList.contains('disabled')) {
              let currentAddress: string | null = mapHtmlConfiguration.getValidatedAddress();
              this.getNewAddressOrLocation(currentAddress, false);
            }
          });
        }

        if (cancelLookupButton) {
          cancelLookupButton.addEventListener('click', () => {
            this.removeLastMarker();
            mapHtmlConfiguration.updateNewCoordinates(null);

            let previousCoordinates: LatLng | null = mapHtmlConfiguration.getPreviousCoordinates();
            if (previousCoordinates) {
              this.updateLookupButton(previousCoordinates);
            }
          });
        }

        if (submitButton) {
          submitButton.addEventListener('click', (e) => {
            this.disableButton(submitButton, true);
            mapHtmlConfiguration.preventFormSubmission(); // prevent submit form

            // location
            let currentCoordinate: LatLng | null = mapHtmlConfiguration.getCurrentCoordinates();
            let currentAddress: string | null = mapHtmlConfiguration.getValidatedAddress();

            if (currentCoordinate) {
              this.submitForm();
            } else {
              this.getNewAddressOrLocation(currentAddress, true);
            }
          });
        }
      },
      disableButton(submitButton: HTMLButtonElement | null, disable: boolean): void {
        if (!submitButton) {
          submitButton = mapHtmlConfiguration.getSubmitEntityBtn();
        }

        if (submitButton) {
          submitButton.disabled = disable; // if true, prevent form submit
        }
      },
      submitForm(): void {
        const editShopForm: HTMLFormElement | null = mapHtmlConfiguration.getEditShopForm();
        if (editShopForm) {
          editShopForm.submit();
        }
      },
      updateLookupButton(coordinates: LatLng): void {
        mapHtmlConfiguration.updateNewCoordinates(coordinates);
        mapHtmlConfiguration.enableNewAddressLookupButton(this.locations.length);
      },
      mapClickedOrDragEnd(clickedLocation: LatLng): void {
        if (clickedLocation.lat && clickedLocation.lng && this.iconTypes.shop) {
          let message: string = this.$t(`text.new-location`).toString();
          this.addNewLocation(clickedLocation, this.iconTypes.shop, message);
          this.updateLookupButton(clickedLocation);
        }
      },
      checkAttachedShop(): void {
        const attachedShop: LatLng | null = mapHtmlConfiguration.getAttachedShopLocation();
        if (attachedShop && this.iconTypes.shop) {
          let message: string = this.$t(`text.initial-location`).toString();
          this.addNewLocation(attachedShop, this.iconTypes.shop, message);
        }
      },
      updateShopsModalMap(): void {
        this.checkAttachedShop(); // when edit shop
        this.validateScreenSize();
      },
      listeningToMap(): void {
        if (this.mapObject) {
          let thisVue = this;
          this.mapObject.on('click', function(e: any) {
            thisVue.mapClickedOrDragEnd(e.latlng);
          });
        }
      },
      addEventListener() {
        this.listeningToMap(); // listen to map click
        this.listeningToHtmlModalButtons(); // inside modal
        mapHtmlConfiguration.eventListenerOnAddressFields(); // address fields
      },
      initMap(map: Map): void {
        this.mapObject = map;
        this.googleMapService = new GoogleMapIntegrationService();

        this.addEventListener();
        this.updateShopsModalMap();
      }
    },
    mounted(): void {
      this.$nextTick(() => {
        // This will not load the map until modal is activated
        if (this.$route.fullPath.search('&add') !== -1 || this.$route.fullPath.search('eshid=') !== -1) {
          this.modalActivated = true;
        }
      });
    },
    i18n: {
      messages: messages
    }
  });
