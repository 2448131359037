import { JsonObject, JsonProperty } from "json2typescript";
import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
import { OrganizationUserStats } from "@/main/webapp/vue/model/api/OrganizationUserStats";

@JsonObject('OrganizationBilling')
export class OrganizationBilling {

  @JsonProperty('availableStatuses', [String], false)
  availableStatuses: string[] = []

  @JsonProperty('status', String, false)
  status: string = '';

  @JsonProperty('federatedLoginEnabled', Boolean, false)
  federatedLoginEnabled: boolean = false;

  @JsonProperty('passwordLoginEnabled', Boolean, false)
  passwordLoginEnabled: boolean = false;

  @JsonProperty('userStats', OrganizationUserStats, false)
  userStats: OrganizationUserStats = new OrganizationUserStats();

}
