import { JsonObject, JsonProperty } from "json2typescript";
import { OrganizationModuleFeature } from "@/main/webapp/vue/model/api/OrganizationModuleFeature";

@JsonObject('OrganizationModule')
export class OrganizationModule {

  @JsonProperty('type', String, true)
  type?: string = undefined;

  @JsonProperty('name', String, true)
  name?: string = undefined;

  @JsonProperty('enabled', Boolean, true)
  enabled?: boolean = undefined;

  @JsonProperty('features', [OrganizationModuleFeature], true)
  features?: [OrganizationModuleFeature] = undefined;

}
