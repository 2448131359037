
  import Vue from 'vue';

  import messages from '../messages.json';

  export default Vue.extend({
    name: 'SettingsSwitchGroup',
    props: {
      name: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    i18n: {
      messages
    }
  });
