import { CourseStatus } from "@/main/webapp/vue/model/api/learning/CourseStatus";
import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
import { CourseBranding } from "@/main/webapp/vue/model/api/learning/CourseBranding";
import CourseProperties from "@/main/webapp/vue/model/api/learning/CourseProperties";
import { Course } from "@/main/webapp/vue/model/api/learning/Course";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import Znapson from '@znapio/znapson';

export default class CourseModel {

  id?: number;
  name?: string;
  shortName?: string;
  status?: CourseStatus;
  urlPath?: string;
  urlPathFragment?: string;
  properties?: CourseProperties;
  activityCount?: number;
  mainActivity?: Activity;
  branding?: CourseBranding;
  nav?: NavigationLinks;
  created?: string;

  constructor(id?: number,
              name?: string,
              shortName?: string,
              status?: CourseStatus,
              urlPath?: string,
              urlPathFragment?: string,
              properties: CourseProperties = new CourseProperties(),
              activityCount?: number,
              mainActivity?: Activity,
              branding?: CourseBranding,
              nav?: NavigationLinks,
              created?: string) {
    this.id = id;
    this.name = name;
    this.shortName = shortName;
    this.status = status;
    this.urlPath = urlPath;
    this.urlPathFragment = urlPathFragment;
    this.properties = properties;
    this.activityCount = activityCount;
    this.mainActivity = mainActivity;
    this.branding = branding;
    this.nav = nav;
    this.created = created;
  }

  public static from(course: Course): CourseModel {
    return new CourseModel(course.id,
                           course.name,
                           course.shortName,
                           course.status,
                           course.urlPath,
                           course.urlPathFragment,
                           Znapson.deserializeOptional<CourseProperties>(course.properties, CourseProperties),
                           course.activityCount,
                           course.mainActivity,
                           course.branding,
                           course.nav,
                           course.created);
  }

  public static fromArray(courses: Course[]): CourseModel[] {
    return (courses || []).map((course: Course) => {
      return this.from(course);
    });
  }
}
