
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import YoutubeVideoWidgetModel, { YoutubeVideoWidgetProperties } from
  '@/main/webapp/vue/components/learning/widget/widgets/youtube-video/model/YoutubeVideoWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'YoutubeVideoWidget',
    props: {
      model: {
        type: Object as PropType<YoutubeVideoWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): YoutubeVideoWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    i18n: {
      messages: messages
    }
  });
