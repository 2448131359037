import { JsonObject, JsonProperty } from "json2typescript";
import { ProjectStatusDefinition } from "./ProjectStatusDefinition";
import { ContentAccessToken } from "./ContentAccessToken";
import { NavigationLinks } from "./NavigationLinks";
import { ImageSize } from "./ImageSize";

@JsonObject('UploadConfiguration')
export class UploadConfiguration extends NavigationLinks {

  @JsonProperty('shopMandatory', Boolean)
  shopMandatory? : boolean = false;

  @JsonProperty('projectMandatory', Boolean)
  projectMandatory? : boolean = false;

  @JsonProperty('pictureMandatory', Boolean)
  pictureMandatory? : boolean = false;

  @JsonProperty('maxFileSizeBytes', Number)
  maxFileSizeBytes? : number | undefined = undefined;

  @JsonProperty('maxRequestSizeBytes', Number)
  maxRequestSizeBytes? : number | undefined = undefined;

  @JsonProperty('projectStatusValues', [ProjectStatusDefinition], false)
  projectStatusValues : ProjectStatusDefinition[] = [];

  @JsonProperty('contentAccessToken', ContentAccessToken)
  contentAccessToken : ContentAccessToken | undefined = undefined;

  @JsonProperty('imageSizes', [ImageSize], true)
  imageSizes: ImageSize[] = [];

  getImageSize(type: string): ImageSize {
    return this.imageSizes.filter(imageSize => {
      return imageSize !== null && imageSize.name === type;
    })[0];
  }
}
