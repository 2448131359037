import { JsonObject, JsonProperty } from "json2typescript";
import { WebSocketResponseType } from "./WebSocketResponseType";
import { ReportType } from "@/main/webapp/vue/model/api/report/ReportType";
import { WebSocketPayload } from "@/main/webapp/vue/model/api/report/WebSocketPayload";

@JsonObject('WebSocketReportResponse')
export class WebSocketReportResponse {
  @JsonProperty('responseType', WebSocketResponseType)
  responseType: WebSocketResponseType | undefined = undefined;

  @JsonProperty('reportType', ReportType)
  reportType: ReportType | undefined = undefined;

  @JsonProperty('destination', String)
  destination: string | undefined = undefined;

  @JsonProperty('hostName', String)
  hostName: string | undefined = undefined;

  @JsonProperty('payload', WebSocketPayload)
  payload: WebSocketPayload | undefined = undefined;

  @JsonProperty('headers', Object)
  headers: { [key: string]: string } | undefined = undefined;
}
