import { Any, JsonObject, JsonProperty } from "json2typescript";
import { EntityRegistryClientFilter } from "@/main/webapp/vue/model/api/EntityRegistryClientFilter";
import { EntityRegistryClientMapping } from "@/main/webapp/vue/model/api/EntityRegistryClientMapping";
import { Entity } from "@/main/webapp/vue/model/api/Entity";
import { EntityRegistryDataSourceEvent } from "@/main/webapp/vue/model/api/EntityRegistryDataSourceEvent";

@JsonObject('EntityRegistryClient')
export class EntityRegistryClient extends Entity {

  @JsonProperty('active', Boolean, false)
  active: boolean = false;

  @JsonProperty('allowInheritConfiguration', Boolean, true)
  allowInheritConfiguration?: boolean = undefined;

  @JsonProperty('inheritParentConfiguration', Boolean, true)
  inheritParentConfiguration?: boolean = undefined;

  @JsonProperty('lastExecuted', String, true)
  lastExecuted?: string = undefined;

  @JsonProperty('filters', [EntityRegistryClientFilter], true)
  filters: EntityRegistryClientFilter[] = [];

  @JsonProperty('mappings', [EntityRegistryClientMapping], true)
  mappings: EntityRegistryClientMapping[] = [];

  @JsonProperty('events', [EntityRegistryDataSourceEvent], true)
  events: EntityRegistryDataSourceEvent[] = [];

}
