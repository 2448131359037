import { JsonObject, JsonProperty } from "json2typescript";
import { ProjectStatusDefinition } from "./ProjectStatusDefinition";
import { ContentAccessToken } from "./ContentAccessToken";
import { NavigationLinks } from "./NavigationLinks";
import { ImageSize } from "./ImageSize";

@JsonObject('StatusUpdates')
export class StatusUpdates extends NavigationLinks {

  @JsonProperty('imageSizes', [ImageSize], true)
  imageSizes: ImageSize[] = [];

  getImageSize(type: string): ImageSize {
    return this.imageSizes.filter(imageSize => {
      return imageSize !== null && imageSize.name === type;
    })[0];
  }
}
