
  import Vue from 'vue';
  import { mapState } from "vuex";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import dataTableLazy from "@/main/webapp/vue/components/ui/table/lazy/index.vue";

  import { Field, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';
  import { AuditLogEventContainer } from "@/main/webapp/vue/model/api/AuditLogEventContainer";
  import { AuditLogEvent } from "@/main/webapp/vue/model/api/AuditLogEvent";
  import { jsonMapToKeyValueString } from "@/main/webapp/vue/util/arrayUtil";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";

  export default Vue.extend({
    components: {
      dataTableLazy
    },
    props: {
      entity: {
        type: Number,
        default: null
      },
      type: {
        type: String,
        default: 'item'
      }
    },
    data() {
      return {
        navigationLink: null as NavigationLink,
        events: [] as AuditLogEvent[],
        fields: [{
          key: 'timestamp',
          type: FieldType.TIMESTAMP
        },
        {
          key: 'entityType',
          type: FieldType.TEXT,
          active: this.entity == null
        },
        {
          key: 'entityId',
          type: FieldType.NUMBER,
          active: this.entity == null
        },
        {
          key: 'action',
          type: FieldType.TEXT
        },
        {
          key: 'result',
          type: FieldType.TEXT
        },
        {
          key: 'actor',
          type: FieldType.TEXT
        },
        {
          key: 'message',
          type: FieldType.TEXT
        },
        {
          key: 'additionalInfo',
          type: FieldType.POPOVER,
          labelHidden: true,
          popover: {
            content: (value: any, key: any, item: any): string => {
              return jsonMapToKeyValueString(value, (k: string, v: any) => {
                return `${k}: ${v}\n`;
              });
            }
          }
        }] as Field[]
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      loadEvents(): void {
        BackendIntegrationService.fetchAuditLogEvents(this.userDetails.organization, this.type, this.entity)
            .then((container: AuditLogEventContainer) => {
              this.events = container.list;
              this.navigationLink = NavigationLinks.getLinkFromNavigation(NavigationLinkType.PAGE_NEXT, container.nav);
              if (process.env.NODE_ENV !== 'production') {
                console.log("Next page", this.navigationLink);
              }
            }).catch((error: Error) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log("Could not fetch audit log data", error);
              }
            });
      }
    },
    mounted() {
      this.loadEvents();
    }
  });
