import { Any, JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "./NavigationLinks";
import { Shop } from "@/main/webapp/vue/model/api/Shop";
import { ShopDetails } from "@/main/webapp/vue/model/api/ShopDetails";

@JsonObject('EntityContainer')
export class EntityContainer<T> {

  @JsonProperty('list', [Any], true)
  list: T[] = [];

  // TODO remove this when ShopContainer changed to list from shops
  @JsonProperty('shops', [ShopDetails], true)
  shops: ShopDetails[] = [];

  @JsonProperty('total', Number, true)
  total?: number = undefined;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
