
  import Vue from 'vue';
  import { mapState } from "vuex";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";
  import { UserRegistryClient } from "@/main/webapp/vue/model/api/UserRegistryClient";

  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';
  import collapsibleCard from '@/main/webapp/vue/components/ui/collapsible/card/index.vue';
  import filtersTable from '@/main/webapp/vue/components/admin/entity-registry/filters-table/index.vue';
  import mappingsTable from '@/main/webapp/vue/components/admin/entity-registry/mappings-table/index.vue';
  import eventLog from '@/main/webapp/vue/components/admin/entity-registry/event-log/index.vue';

  import notification from "@/main/webapp/vue/notification";

  export default Vue.extend({
    components: {
      formSwitch,
      collapsibleCard,
      filtersTable,
      mappingsTable,
      eventLog
    },
    props: {
      client: {
        type: UserRegistryClient,
        required: true
      }
    },
    data() {
      return {
        linkedClient: this.client as UserRegistryClient,
        refreshEventLog: false as Boolean
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      statusChanged(newStatus: boolean): void {
        this.refreshEventLog = false;
        this.linkedClient.active = newStatus;
        if (newStatus) {
          if (this.linkedClient.id === undefined) {
            this.createClient();
            return;
          }
        }

        this.updateClient();
      },
      createClient(): void {
        BackendIntegrationService.createUserRegistryClient(this.userDetails.organization, this.linkedClient.registration)
          .then((client: UserRegistryClient) => {
            this.linkedClient.id = client.id;
            this.refreshEventLog = true;
            notification.success("Registration created!");
          }).catch((error: Error) => {
            this.linkedClient.active = false;
            notification.fail("Could not create registration");
          });
      },
      updateClient(): void {
        BackendIntegrationService.updateEntityRegistryClient(this.userDetails.organization, this.linkedClient)
          .then((client: EntityRegistryClient) => {
            this.linkedClient = client as UserRegistryClient;
            this.refreshEventLog = true;
            notification.success("Registration updated!");
          }).catch((error: Error) => {
            notification.fail("Could not update registration");
          });
      }
    }
  });
