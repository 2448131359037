
  import Vue from 'vue';
  import { mapState } from "vuex";
  import { BvTableCtxObject } from "bootstrap-vue";
  import { oc as Optional } from "ts-optchain";
  import messages from "@/main/webapp/vue/components/learning/learning-home/course-list/messages.json";

  import headerSection from "@/main/webapp/vue/components/ui/header/section/index.vue";
  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";
  import scrollToTop from "@/main/webapp/vue/components/ui/scroll/scroll-to-top/index.vue";
  import courseLogo from "@/main/webapp/vue/components/learning/ui/content/course-logo/index.vue";
  import Confirmation from "@/main/webapp/vue/components/ui/modal/confirmation/index.vue";
  import materialIconText from "@/main/webapp/vue/components/ui/icon/material-icon-text/index.vue";

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { Courses } from "@/main/webapp/vue/model/api/learning/Courses";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { RouteName } from "@/main/webapp/vue/model/RouteName";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";
  import notification from "@/main/webapp/vue/notification";

  enum FieldKey {
    COURSE_NAME = 'name',
    ACTIVITIES = 'activities',
    STATUS = 'status',
    EDIT = 'edit'
  }

  export default Vue.extend({
    components: {
      headerSection,
      loadingIcon,
      scrollToTop,
      courseLogo,
      materialIconText
    },
    data() {
      return {
        sortBy: FieldKey.COURSE_NAME as FieldKey,
        sortDesc: false as boolean,
        fields: [
          {
            key: FieldKey.COURSE_NAME,
            label: this.$i18n.t('text.table.head.courses'),
            sortable: true
          },
          {
            key: FieldKey.ACTIVITIES,
            label: this.$i18n.t('text.table.head.activities'),
            sortable: false
          },
          {
            key: FieldKey.STATUS,
            label: this.$i18n.t('text.table.head.status'),
            sortable: true
          },
          {
            key: FieldKey.EDIT,
            label: '',
            tdClass: 'edit-column'
          }
        ],
        courses: [] as CourseModel[],
        nextPage: undefined as NavigationLink | undefined,
        isLoading: false as boolean,
        loadMore: false as boolean,
        isLastCourse: false as boolean,
        courseLogoError: false as boolean,
        RouteName,
        RoutePath
      };
    },
    watch: {
      loadMore: function(newValue, oldValue) {
        if (newValue) {
          this.loadMoreCourses(this.nextPage);
        }
      }
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      numberOfCourses(): number {
        return this.courses.length;
      }
    },
    methods: {
      duplicateCourse(course: Course): void {
        LearningIntegrationService.duplicateCourse(course)
          .then((course: Course) => {
            notification.success(this.$t('text.success.duplicate-course'));
            (this as any).$router.push({
              name: RouteName.COURSE_MANAGEMENT,
              params: { courseId: course.id },
              query: { cid: course.id }
            });
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Course duplication failed", e);
            }
            notification.fail(this.$t('text.error.duplicate-course'));
          });
      },
      deleteCourse(course: Course): void {
        const title: string = this.$t('text.confirmation.delete-course', { courseName: course.name }).toString();
        let confirmation: Vue = new Confirmation({
          propsData: {
            title: title
          }
        }).$mount();

        confirmation.$on('user-confirm', (confirm: boolean) => {
          if (confirm) {
            LearningIntegrationService.deleteCourse(course)
              .then((response: Courses) => {
                notification.success(this.$t('text.success.delete-course'));
                this.initCoursePage();
              }).catch((e: Error) => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log("Course deletion failed", e);
                }
                notification.fail(this.$t('text.error.delete-course'));
              });
          }
        });
      },
      editCourse(course: CourseModel, index: number, event: any): void {
        if (!event.target.classList.contains('edit-column') && course && course.id) {
          (this as any).$router.push({
            name: RouteName.COURSE_MANAGEMENT,
            params: { courseId: course.id },
            query: { cid: course.id }
          });
        }
      },
      sortingChanged(sortingContext: BvTableCtxObject): void {
        this.sortBy = sortingContext.sortBy! as FieldKey;
        this.sortDesc = sortingContext.sortDesc!;

        let linkWithSort: NavigationLink =
          new NavigationLink().weblinkWithSort("/learning/courses", this.sortBy, this.sortDesc, true);
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Requesting courses with following navigation link href '${linkWithSort.href}'`);
        }

        this.initCoursePage();
        this.loadMoreCourses(linkWithSort);
      },
      clearTable(): void {
        this.courses = [];
        this.nextPage = undefined;
        this.isLastCourse = false;
      },
      initCoursePage(): void {
        this.clearTable();
        this.loadMore = true;
      },
      loadMoreCourses(navigationLink: NavigationLink | undefined): void {
        this.loadMore = false;
        if (!this.isLoading && !this.isLastCourse) {
          this.isLoading = true;
          LearningIntegrationService.fetchCourses(navigationLink).then((response: Courses) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(response);
            }

            let courses: Course[] | undefined = Optional(response).list();
            if (courses && courses.length !== 0) {
              let courseModels: CourseModel[] = CourseModel.fromArray(courses);
              this.courses.push.apply(this.courses, courseModels);
            }

            let nav: NavigationLinks | undefined = Optional(response).nav();
            if (nav) {
              let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
              if (nextLink) {
                this.nextPage = nextLink;
              } else {
                this.loadNoMore();
              }
            } else {
              this.loadNoMore();
            }
          }).catch((e: Error) => {
            notification.fail(this.$t('text.error.course'));
          }).finally(() => {
            this.isLoading = false;
            if (this.nextPage) {
              this.checkScroll();
            }
          });
        }
      },
      handleScroll(): void {
        window.onscroll = () => {
          this.checkScroll();
        };
      },
      checkScroll(): void {
        const documentElement = document.documentElement;
        let currentScrollPosition = documentElement.scrollTop + documentElement.offsetHeight;
        let threshold = documentElement.scrollHeight * 0.7;
        if (currentScrollPosition >= threshold) {
          this.loadMore = true;
        }
      },
      loadNoMore(): void {
        this.loadMore = false;
        this.isLastCourse = true;
        this.nextPage = undefined;
      },
      loadingCourseLogoFailed(): void {
        if (!this.courseLogoError) {
          this.courseLogoError = true;
        }
      }
    },
    created(): void {
      this.initCoursePage();
    },
    mounted(): void {
      this.handleScroll();
    },
    i18n: {
      messages: messages
    }
  });
