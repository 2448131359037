import { JsonObject, JsonProperty } from "json2typescript";
import { Description } from "./Description";
import { Item } from "./Item";
import { Entity } from "./Entity";

@JsonObject('FeaturedSubmission')
export class FeaturedSubmission extends Entity {
  @JsonProperty('description', Description)
  description?: Description = undefined;

  @JsonProperty('submission', Item)
  submission?: Item = undefined;
}

