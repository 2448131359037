
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import buttonStyle from "@/main/webapp/vue/components/ui/style/button/container/index.vue";
  import genericStyle from '@/main/webapp/vue/components/ui/style/generic/container/index.vue';
  import iconInput from '@/main/webapp/vue/components/ui/style/icon/container/icon-input/index.vue';
  import iconAlignmentInput from '@/main/webapp/vue/components/ui/style/icon/container/icon-alignment-input/index.vue';

  import ActivityModel from '@/main/webapp/vue/model/learning/ActivityModel';
  import ActivityButtonWidgetModel, { DefaultValues }
    from "@/main/webapp/vue/components/learning/widget/widgets/activity-button/model/ActivityButtonWidgetModel";
  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
  import WidgetModel from "@/main/webapp/vue/model/learning/WidgetModel";

  export default Vue.extend({
    name: 'ActivityButtonWidgetProperties',
    props: {
      model: {
        type: Object as PropType<ActivityButtonWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      }
    },
    components: {
      textTitle,
      collapsibleContainer,
      buttonStyle,
      genericStyle,
      iconInput,
      iconAlignmentInput
    },
    data() {
      return {
        activities: [] as ActivityModel[],
        selectedActivity: undefined as ActivityModel | undefined,
        labelEdited: false as boolean
      };
    },
    watch: {
      model: {
        handler(newModel: WidgetModel, oldModel: WidgetModel): void {
          if (newModel.id !== oldModel.id) {
            this.refresh();
          }
        },
        deep: true
      },
      selectedActivity(newSelected: ActivityModel, oldSelected: ActivityModel): void {
        if (newSelected) {
          this.model.data.destination = newSelected.urlPath.split(`${this.course.urlPath}`)[1];

          const newlyCreated: boolean = this.model.data.id === undefined;
          if (newlyCreated || (oldSelected && this.model.data.label === oldSelected.name)) {
            this.model.data.label = newSelected.name;
          }

          if (typeof newSelected.id === 'number') {
            this.model.data.id = newSelected.id;
          }
        }
      }
    },
    methods: {
      refresh(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Refreshing activity button widget");
        }
        if (this.course) {
          const course: Course = Course.from(this.course);
          LearningIntegrationService.fetchActiveCourseActivitiesOnline(course)
            .then((response: Activity) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(response);
              }
              this.generateDisplayedActivities([ActivityModel.from(response)]);
            }).catch((error: BackendError) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log("Failed to fetch active course activities, using fallback instead");
              }
              if (course.mainActivity) {
                this.generateDisplayedActivities([ActivityModel.from(course.mainActivity)]);
              }
            }).finally(() => {
              if (this.model.data.destination !== DefaultValues.DESTINATION) {
                this.handleSelectedActivity();
              } else {
                if (this.activities[0]) {
                  this.selectedActivity = this.activities[0];
                }
              }
            });
        }
      },
      generateDisplayedActivities(activities: ActivityModel[]): void {
        this.activities = this.getActiveActivities([], activities);
      },
      getActiveActivities(activeActivities: ActivityModel[], subactivities: ActivityModel[]) {
        for (let activity of subactivities) {
          if (activity.active) {
            activeActivities.push(activity);
            if (activity.subactivities && activity.subactivities.length > 0) {
              activeActivities = this.getActiveActivities(activeActivities, activity.subactivities);
            }
          }
        }
        return activeActivities;
      },
      handleSelectedActivity(): void {
        if (this.activities) {
          this.selectedActivity = this.activities.find((activity: ActivityModel) =>
            activity.id === this.model.data.id);
        }
      },
      onLabelChanged(newInput: string) {
        this.model.data.label = newInput;
      }
    },
    mounted() {
      if (process.env.NODE_ENV !== 'production') {
        console.log("Activity button widget properties mounted");
      }
      this.refresh();
    },
    i18n: {
      messages: messages
    }
  });
