import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject('FeedText')
export class FeedText {
  @JsonProperty('extendedTranslation', Boolean, true)
  extendedTranslation?: boolean = undefined;

  @JsonProperty('messageParameters', Any, true)
  messageParameters?: Map<String, String> = undefined;
}
