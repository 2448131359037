import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import SectionStyleModel from "@/main/webapp/vue/components/ui/style/section/model/SectionStyleModel";
import layoutColumnsWidget from '@/main/webapp/vue/components/learning/widget/widgets/layout-columns/widget/index.vue';
import layoutColumnsWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/layout-columns/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import AlignmentStyleModel from "@/main/webapp/vue/components/ui/style/alignment/model/AlignmentStyleModel";
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export enum ColumnType {
  LEFT_COLUMN = 'Left Column',
  RIGHT_COLUMN = 'Right Column'
}

export class ColumnStyle {
  constructor(@JsonField('alignment', AlignmentStyleModel, true)
              public alignment: AlignmentStyleModel = new AlignmentStyleModel(),
              @JsonField('responsive', Boolean, true)
              public responsive: boolean = true) {}
}

export class WidgetColumnMapping {
  constructor(@JsonField('widgetId', String)
              public widgetId: WidgetIdType,
              @JsonField('column', String, true)
              public column: ColumnType) {}
}

export class LayoutColumnsData {
  constructor(@JsonField('widgetColumnMapping', [WidgetColumnMapping])
              public widgetColumnMapping: WidgetColumnMapping[] = []) {}
}

export class LayoutColumnsProperties {
  constructor(@JsonField('leftColumnSectionStyle', SectionStyleModel, true)
              public leftColumnSectionStyle = new SectionStyleModel(),
              @JsonField('rightColumnSectionStyle', SectionStyleModel, true)
              public rightColumnSectionStyle = new SectionStyleModel(),
              @JsonField('columnRatio', Number, true)
              public columnRatio = 50, // Default 50%
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties(),
              @JsonField('columnStyle', ColumnStyle, true)
              public columnStyle = new ColumnStyle()) {}
}

@JsonDiscriminator(WidgetModel, 'layoutColumns')
export default class LayoutColumnsWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.ALL,
              metadata = new WidgetMetadata(),
              @JsonField('data', LayoutColumnsData)
              public data = new LayoutColumnsData(),
              @JsonField('properties', LayoutColumnsProperties)
              public properties = new LayoutColumnsProperties(),
              @JsonField('defaultProperties', LayoutColumnsProperties, true)
              public defaultProperties = new LayoutColumnsProperties(
                new SectionStyleModel('transparent', '#000000', '5 5 5 5'),
                new SectionStyleModel('transparent', '#000000', '5 5 5 5'),
                50,
                new GenericStyleWidgetProperties()
              ),
              @JsonField('nestedContents', [WidgetModel], true)
              public nestedContents: WidgetModel[] = [],
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }

  updateInternal(newModel: LayoutColumnsWidgetModel): void {
    if (this.nestedContents && this.nestedContents.length > 0) {
      this.nestedContents = newModel.nestedContents;
    }
  }
}

registerWidget(
  WidgetFamily.LAYOUT,
  LayoutColumnsWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'dashboard',
  layoutColumnsWidget,
  layoutColumnsWidgetProperties
);
