
  import Vue from 'vue';
  import { v1 as uuidv1 } from 'uuid';
  import messages from './messages.json';

  import textTitle from '@/main/webapp/vue/components/ui/text/text-title/index.vue';
  import draggableContainer, { DraggableGroupNames } from '@/main/webapp/vue/components/ui/draggable/container/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import widgetThumbnail from './WidgetThumbnail.vue';

  import widgets from '@/main/webapp/vue/components/learning/widget/model/Widgets';
  import WidgetModel from "@/main/webapp/vue/model/learning/WidgetModel";

  export type WidgetPosition = {
    x: number,
    y: number
  }

  type EndEvent = {
    oldIndex: number;
    originalEvent: WidgetPosition;
  };

  export default Vue.extend({
    name: 'WidgetSideBar',
    components: {
      collapsibleContainer,
      draggableContainer,
      widgetThumbnail,
      textTitle
    },
    data() {
      return {
        widgetFamilies: widgets
          .filter((widgetFamily) => widgetFamily.widgets.length > 0)
          .map((widgetFamily) => ({
            name: widgetFamily.name,
            visibleByDefault: widgetFamily.visibleByDefault,
            widgets: widgetFamily.widgets.map((widgetRegistration) => ({
              name: widgetRegistration.name,
              icon: widgetRegistration.icon,
              model: widgetRegistration.factory(uuidv1())
            }))
          }))
      };
    },
    computed: {
      draggableGroup() {
        return {
          name: DraggableGroupNames.WIDGETS,
          pull: 'clone',
          put: false
        };
      }
    },
    methods: {
      onStart(widgetFamilyIndex: number, e: EndEvent) {
        let currentWidget: WidgetModel = this.widgetFamilies[widgetFamilyIndex].widgets[e.oldIndex].model;
        this.$emit('widget-selected', currentWidget);
      },
      createNewInstance(widgetFamilyIndex: number, e: EndEvent) {
        this.$emit('widget-ended', e.originalEvent);
        this.widgetFamilies[widgetFamilyIndex].widgets = this.widgetFamilies[widgetFamilyIndex]
          .widgets
          .map((widget, index) => {
            if (index !== e.oldIndex) return widget;
            return {
              ...widget,
              model: widgets[widgetFamilyIndex]
                .widgets[e.oldIndex]
                .factory(uuidv1())
            };
          });
      }
    },
    i18n: {
      messages: messages
    }
  });
