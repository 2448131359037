
  import Vue from 'vue';

  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';

  export default Vue.extend({
    name: 'Console',
    components: {
      glyphiconSymbol
    },
    methods: {
      icon(type: string) {
        switch (type) {
          case 'success':
            return 'ok-sign';
          case 'fail':
          case 'warn':
            return 'warning-sign';
          default:
            return undefined;
        }
      }
    }
  });
