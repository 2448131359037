import { JsonObject, JsonProperty } from "json2typescript";
import { ShopRegistryClient } from "@/main/webapp/vue/model/api/ShopRegistryClient";

@JsonObject('ShopRegistryDataSources')
export class ShopRegistryClients {

  @JsonProperty('list', [ShopRegistryClient], false)
  list: ShopRegistryClient[] = [];

}
