export class ImageUtil {

  public static base64ToFile(base64EncodedImage: string, fileName: string): File {
    return new File([ImageUtil.base64ToBlob(base64EncodedImage)], fileName);
  }

  public static blobToFile(blob: Blob, fileName: string = '') {
    return new File([blob], fileName);
  }

  public static base64ToBlob(base64EncodedImage: string): Blob {
    if (base64EncodedImage == null) {
      throw new Error("No image to decode");
    }

    if (base64EncodedImage.indexOf(',') < 0) {
      throw new Error("Wrong format");
    }

    let data = base64EncodedImage.split(',');
    if (data == null || data.length < 2) {
      throw new Error("Wrong format");
    }

    let mime = data[0].match(/:(.*?);/);
    if (mime == null || mime.length < 2) {
      throw new Error("Wrong format");
    }

    let base64Data = atob(data[1]);
    let dataSize = base64Data.length;
    let blobData = new Uint8Array(dataSize);
    while (dataSize--) {
      blobData[dataSize] = base64Data.charCodeAt(dataSize);
    }

    return new Blob([blobData], { type: mime[1] });
  }
}
