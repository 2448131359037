
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import messages from '../messages.json';

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import ActivityModel from '@/main/webapp/vue/model/learning/ActivityModel';
  import ActivityListWidgetModel, { ActivityListProperties } from
  '@/main/webapp/vue/components/learning/widget/widgets/activity-list/model/ActivityListWidgetModel';
  import learningActivityStatusSessionUtil from "@/main/webapp/vue/util/learningActivityStatusSessionUtil";

  export default Vue.extend({
    name: 'ActivityListWidget',
    props: {
      model: {
        type: Object as PropType<ActivityListWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        parentActivity: undefined as ActivityModel | undefined,
        pageReady: true as Boolean
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ]),
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedActivityListButtonStyle(): Object {
        return computedStyleUtil.getStyles(
          ['backgroundColor', 'textColor', 'borderRadius'],
          this.model.properties.buttonStyle,
          this.computedDefaultStyle.buttonStyle);
      },
      computedVisitedIconStyle(): Object {
        return computedStyleUtil.getStyles(['size'], this.model.properties.iconStyle, this.computedDefaultStyle.iconStyle);
      },
      computedDefaultStyle(): ActivityListProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      filterActivities(): ActivityModel[] {
        if (this.parentActivity) {
          return this.filterActivitiesByHidden(this.filterActivitiesByActive(this.parentActivity.subactivities));
        }
        return [];
      }
    },
    methods: {
      filterActivitiesByActive: function(activities: ActivityModel[]) {
        return activities.filter(activity => activity.active);
      },
      filterActivitiesByHidden: function(activities: ActivityModel[]) {
        return activities.filter(activity => activity.properties.showInActivityList);
      },
      checkVisited(subactivity: ActivityModel): Boolean {
        return learningActivityStatusSessionUtil.isVisited(subactivity.id);
      },
      onClick(subactivity: ActivityModel) {
        if (this.editable) {
          this.$emit('activate-activity', subactivity, true);
        }
      }
    },
    mounted() {
      let parentActivityId: number = -1;
      if (this.model.data.parentActivityId === -1 && this.activity.id) {
        if (typeof this.activity.id === 'number') {
          parentActivityId = this.activity.id;
        }
      } else {
        parentActivityId = this.model.data.parentActivityId;
      }

      const course: Course = Course.from(this.course);
      let parentActivity: Activity = new Activity();
      parentActivity.id = parentActivityId;
      LearningIntegrationService.fetchActiveCourseActivityWithSubactivitiesOnline(course, parentActivity)
        .then((response: Activity) => {
          this.parentActivity = ActivityModel.from(response);
          if (response.id) {
            this.model.data.parentActivityId = response.id;
          }
        // eslint-disable-next-line
        }).catch((error: BackendError) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Failed to fetch activity details, using fallback instead");
          }

          this.parentActivity = this.activity;
          if (typeof this.activity.id === 'number') {
            this.model.data.parentActivityId = this.activity.id;
          }
        });

      // On Safari, when page is back, reload latest localStore ie visitedActivity
      const that = this;
      window.onpageshow = function(event) {
        if (event.persisted) {
          that.pageReady = false;
          setTimeout(() => {
            that.pageReady = true;
          }, 100);
        }
      };
    },
    i18n: {
      messages: messages
    }
  });
