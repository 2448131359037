import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { MapShop } from "@/main/webapp/vue/model/api/Map/MapShop";

@JsonObject('MapShopContainer')
export class MapShopContainer {

    @JsonProperty('shops', [MapShop], true)
    list?: MapShop[] = undefined;

    @JsonProperty('nav', NavigationLinks, true)
    nav?: NavigationLinks = undefined;

}
