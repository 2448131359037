import { Any, JsonObject, JsonProperty } from "json2typescript";
import { Entity } from "@/main/webapp/vue/model/api/Entity";

@JsonObject('ShopRegistryDataSourceProvider')
export class ShopRegistryDataSourceProvider extends Entity {

  @JsonProperty('name', String, false)
  name: string = "UNDEFINED";

  @JsonProperty('data', Any, true)
  data: any = undefined;

  @JsonProperty('scheduled', Boolean, false)
  scheduled: boolean = false;

  @JsonProperty('supportsSync', Boolean, false)
  supportsSync: boolean = false;

  @JsonProperty('nextExecution', String, true)
  nextExecution?: string = undefined;

}
