import { ApiError } from "../services/ApiService";

export class BackendError extends Error {

  apiError?: ApiError;

  exception?: string;

  details?: any;

  constructor(apiError?: ApiError, message?: string, exception?: string, details?: any) {
    super(message || (apiError ? apiError.toString() : ''));
    this.apiError = apiError;
    this.name = 'BackendError';
    this.exception = exception;
    this.details = details;
    Object.setPrototypeOf(this, new.target.prototype); // JS BS :F
  }

  static isApiError(message: string) {
    return message in ApiError;
  }
}
