import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('Department')
export class Department {
  @JsonProperty('id', Number)
  id: number = 0;

  @JsonProperty('name', String)
  name: string = '';
}
