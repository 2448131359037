
  import Vue from "vue";
  import { mapState } from "vuex";
  import messages from './messages.json';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { AuthenticationResult } from "@/main/webapp/vue/model/api/AuthenticationResult";
  import notification from "@/main/webapp/vue/notification";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";

  export default Vue.extend({
    props: {
      assumedUserName: {
        type: String,
        required: true
      },
      assumingUserName: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      redirectToUsersPage() {
        window.location.href = "users.html";
      },
      unassume() {
        BackendIntegrationService.unassume()
          .then((response: AuthenticationResult) => {
            this.redirectToUsersPage();
          })
          .catch((error: BackendError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`Unassume user '${(this as any).loginName}' failed`, error);
            }
            let exception: string = error.exception!;
            notification.fail(`Unassume failed: ${exception}`);
          });
      }
    },
    i18n: {
      messages: messages
    }
  });
