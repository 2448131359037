
  import Vue, { PropType } from 'vue';
  import messages from '../messages.json';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import ShareWidgetModel, {
    ShareWidgetProperties
  } from "@/main/webapp/vue/components/learning/widget/widgets/share/common/model/ShareWidgetModel";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'ShareWidget',
    props: {
      model: {
        type: Object as PropType<ShareWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return {
          ...computedStyleUtil.getStyles(
            ["margin"], this.model.properties.genericStyle, this.computedDefaultStyle.genericStyle),
          ...computedStyleUtil.getStyles(
            ["alignment"], this.model.properties.textStyle, this.computedDefaultStyle.textStyle)
        };
      },
      computedDefaultStyle(): ShareWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedWidgetButtonStyle(): Object {
        return computedStyleUtil.getStyles(
          ["backgroundColor", "textColor", "borderRadius"],
          this.model.properties.buttonStyle,
          this.computedDefaultStyle.buttonStyle);
      },
      computedWidgetIconStyle(): Object {
        return {
          ...computedStyleUtil.getStyles(
            ["size"],
            this.model.properties.textStyle,
            this.computedDefaultStyle.textStyle),
          '--padding': this.computedIconPadding
        };
      },
      computedIconPadding(): string {
        const threadhold: number = 0.15;
        let padding: any = computedStyleUtil.getValue(
          'size',
          this.model.properties.textStyle,
          this.computedDefaultStyle.textStyle);

        if (padding) {
          return `${padding * threadhold}px 0px`; // TO make it circle with border radius
        }

        return '5px 0px';
      }
    },
    i18n: {
      messages: messages
    }
  });
