import { JsonObject, JsonProperty } from "json2typescript";
import { DomainOrganization } from "@/main/webapp/vue/model/api/DomainOrganization";

@JsonObject('DomainOrganizations')
export class DomainOrganizations {

  @JsonProperty('list', [DomainOrganization], true)
  list: DomainOrganization[] = [];

}
