import { JsonObject, JsonProperty } from "json2typescript";
import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";

@JsonObject('OrganizationUserStats')
export class OrganizationUserStats {

  @JsonProperty('registered', Number, false)
  registered: number = 0

  @JsonProperty('active', Number, false)
  active: number = 0

  @JsonProperty('locked', Number, false)
  locked: number = 0

  @JsonProperty('expired', Number, false)
  expired: number = 0

  @JsonProperty('inactive', Number, false)
  inactive: number = 0

  @JsonProperty('interactiveThisMonth', Number, false)
  interactiveThisMonth: number = 0

  @JsonProperty('interactiveLast30Days', Number, false)
  interactiveLast30Days: number = 0

  @JsonProperty('interactiveLast90Days', Number, false)
  interactiveLast90Days: number = 0

}
