
  import Vue from 'vue';
  import { mapState } from "vuex";

  import moduleConfiguration from './module-configuration/index.vue';
  import navigationSection from '@/main/webapp/vue/components/ui/navigation/section/index.vue';
  import navigationSectionElement from '@/main/webapp/vue/components/ui/navigation/section/element/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { OrganizationModuleContainer } from "@/main/webapp/vue/model/api/OrganizationModuleContainer";

  import messages from "@/main/webapp/vue/components/admin/module-configurator/messages.json";

  export default Vue.extend({
    components: {
      moduleConfiguration,
      navigationSection,
      navigationSectionElement
    },
    data() {
      return {
        modules: {} as OrganizationModuleContainer
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    mounted(): void {
      BackendIntegrationService.fetchOrganizationModules(this.userDetails.organization).then((response: OrganizationModuleContainer) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(response);
        }
        this.modules = response;
      });
    },
    i18n: {
      messages: messages
    }
  });
