import moment from 'moment';

import { DateTime } from "@/main/webapp/vue/model/api/DateTime";

import store from '../store';
import configuration from '../config/application/configuration';

const fallbackTimeZone: string = "Europe/Helsinki";
const fallbackLocale: string = "en";

export default class TimeUtil {
  private static timestampFormat = configuration.properties.timestampFormat;

  /**
   * Using Moment Timezone to change the time zone on an existing moment
   *
   * @param date moment object to convert to time zone
   * @param timeZone time zone to convert the moment object to
   */
  private static convertMomentToTimezone = (date: moment.Moment, timeZone: string): moment.Moment => {
    return date.tz(timeZone);
  };

  /**
   * Using Moment Timezone to create a moment with a time zone.
   *
   * @param timestamp timestamp to create the moment object from
   * @param timeZone
   */
  private static createMomentWithTimezone = (timestamp: string | null, timeZone: string): moment.Moment => {
    if (timestamp !== null) {
      return moment.tz(timestamp, TimeUtil.timestampFormat.db.timestamp, timeZone);
    } else {
      return moment.tz(timeZone);
    }
  };

  private static convertMomentToLocalTimezone = (date: DateTime): moment.Moment => {
    let utcDate: moment.Moment = TimeUtil.createMomentWithTimezone(date.timestamp!, date.timeZone!);
    return TimeUtil.convertMomentToTimezone(utcDate, store.getters.getUserDetails.timeZone);
  };

  private static getCurrentMoment = (): moment.Moment => {
    return TimeUtil.createMomentWithTimezone(null, store.getters.getUserDetails.timeZone);
  };

  public static getUserTimezone = (): string => {
    return store.state.userDetails ? store.state.userDetails.timeZone : fallbackTimeZone;
  };

  public static getUserLocale = (): string => {
    return store.state.userDetails ? store.state.userDetails.locale : fallbackLocale;
  }

  private static convertMomentToDateTime = (momentObj: moment.Moment): DateTime => {
    let timeZone: string = momentObj.tz() || '';

    return new DateTime(
      timeZone,
      momentObj.format(TimeUtil.timestampFormat.db.date),
      momentObj.format(TimeUtil.timestampFormat.db.time),
      momentObj.format(TimeUtil.timestampFormat.db.timestamp)
    );
  };

  static datesEqual = (date0: DateTime, date1: DateTime): boolean => {
    return date0 !== null && date1 !== null && date0.date === date1.date;
  };

  static timestampsEqual = (date0: DateTime, date1: DateTime): boolean => {
    return date0 !== null && date1 !== null && date0.timestamp === date1.timestamp;
  };

  static getTimeDifference =
    (date: DateTime, unitOfTime: moment.unitOfTime.Base): number => {
    let momentInLocalTimezone: moment.Moment = TimeUtil.convertMomentToLocalTimezone(date);
    return moment(momentInLocalTimezone).diff(TimeUtil.getCurrentMoment(), unitOfTime);
  };

  static convertToRelativeTime = (date: DateTime): string => {
    let userTimezoneDateTime: moment.Moment = TimeUtil.convertMomentToLocalTimezone(date);
    return moment(userTimezoneDateTime, TimeUtil.timestampFormat.db.timestamp).fromNow();
  };

  static formatToDateString = (date: DateTime, format: string): string => {
    let moment: moment.Moment = TimeUtil.convertMomentToLocalTimezone(date);
    return moment.format(format);
  };

  static convertDateTimeToLocalTimezone = (date: DateTime): DateTime => {
    const convertedDate: moment.Moment = TimeUtil.convertMomentToLocalTimezone(date);
    return TimeUtil.convertMomentToDateTime(convertedDate);
  };

  static getCurrentDateTime = (): DateTime => {
    let now: moment.Moment = TimeUtil.createMomentWithTimezone(null, store.getters.getUserDetails.timeZone);
    return TimeUtil.convertMomentToDateTime(now);
  };

  static convertUTCToTimezoneWithLocale = (utcTimestamp: string, timeZone: string, locale: string,
                                           returnFormat: string): string => {
    const utcMomentInstance: moment.Moment = moment.utc(utcTimestamp);
    const timezoneMomentInstance: moment.Moment = utcMomentInstance.clone().tz(timeZone);
    timezoneMomentInstance.locale(locale === 'en' ? 'en-gb' : locale); // TODO: Centralize and apply consistently
    return timezoneMomentInstance.format(returnFormat);
  };

  public static convertUTCToUserTimeZone = (utcTimestamp: string): string => {
    const userTimezone: string = TimeUtil.getUserTimezone();
    const userLocale: string = TimeUtil.getUserLocale();

    const utcMomentInstance: moment.Moment = moment.utc(utcTimestamp);
    const timezoneMomentInstance: moment.Moment = utcMomentInstance.clone().tz(userTimezone);
    timezoneMomentInstance.locale(userLocale === 'en' ? 'en-gb' : userLocale); // TODO: Centralize and apply consistently
    return timezoneMomentInstance.format('L');
  }

};
