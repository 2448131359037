
  import Vue from 'vue';
  import { Prop } from "vue/types/options";
  import { mapState } from "vuex";
  import messages from './messages.json';

  import { ThemableComponent } from '@/main/webapp/vue/model/api/ThemableComponent';
  import LoadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import { Chrome } from 'vue-color';
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  export default Vue.extend({
    components: {
      'chrome-picker': Chrome,
      'loading-icon': LoadingIcon
    },
    props: {
      component: {
        type: Object as Prop<ThemableComponent>,
        required: true
      }
    },
    data() {
      return {
        updatedComponent: {} as ThemableComponent,
        updating: false
      };
    },
    methods: {
      transformBackgroundColor(color: any) {
        this.updatedComponent.backgroundColor = color.hex;
      },
      transformForegroundColor(color: any) {
        this.updatedComponent.foregroundColor = color.hex;
      },
      updateHeight(height: number) {
        this.updatedComponent.height = height;
      },
      updatePadding(padding: any) {
        if (padding !== '') {
          this.updatedComponent.padding = padding;
        }
      },
      update() {
        this.updating = true;
        BackendIntegrationService.postThemeComponent(this.userDetails.organization, this.updatedComponent).then(() => {
          this.$emit("updated", this.updatedComponent);
        }).finally(() => {
          // For now simple error handling automatically showing a notification (see template)
          this.updating = false;
        });
      }
    },
    created(): void {
      this.updatedComponent = new ThemableComponent();
      this.updatedComponent.copy(this.component);
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    i18n: {
      messages: messages
    }
  });
