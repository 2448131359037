import { JsonObject, JsonProperty } from "json2typescript";

import { HierarchicalEntityMetadata } from "./HierarchicalEntityMetadata";

@JsonObject('EntityViewMetadata')
export class EntityViewMetadata {
  @JsonProperty('type', String, true)
  type?: string = undefined;

  @JsonProperty('hierarchical', HierarchicalEntityMetadata, true)
  hierarchical?: HierarchicalEntityMetadata = undefined;

  @JsonProperty('submissionBased', Boolean, true)
  submissionBased?: boolean = undefined;

  @JsonProperty('icon', String, true)
  icon?: string = undefined;

  @JsonProperty('badge', String, true)
  badge?: string = undefined;

  @JsonProperty('label', String, true)
  label?: string = undefined;
}
