
  import Vue from 'vue';

  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";
  import glyphiconLink from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-link/index.vue";

  export default Vue.extend({
    components: {
      glyphiconSymbol,
      glyphiconLink
    },
    props: {
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: null
      },
      subtitleLink: {
        type: String,
        default: null
      },
      actionButtonText: {
        type: String,
        default: null
      },
      actionButtonIcon: {
        type: String,
        default: null
      },
      actionButtonRoute: {
        type: String,
        default: null
      }
    }
  });
