
  import Vue from 'vue';
  import { Map, LatLngBounds, Marker } from 'leaflet';
  import { oc as Optional } from "ts-optchain";
  import { mapState } from "vuex";
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';
  import PruneCluster from '@/main/webapp/vue/components/map/prune-cluster/index.vue';
  import markers from '@/main/webapp/vue/components/map/marker/index.vue';
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import mapHtmlConfiguration from "@/main/webapp/vue/components/map/mapHtmlConfiguration";
  import markerConfig from '@/main/webapp/vue/components/map/markerConfig';
  import ProjectModalPopup from '@/main/webapp/vue/components/map/marker/marker-popup/project-modal-popup/index.vue';

  import { MapProperties } from "@/main/webapp/vue/model/api/Map/MapProperties";
  import { MapShop } from "@/main/webapp/vue/model/api/Map/MapShop";
  import { DrawToolbar } from "@/main/webapp/vue/model/api/Map/DrawToolbar";
  import { Polygon } from '@/main/webapp/vue/model/api/Map/Polygon';
  import { ValidatedMarkers } from "@/main/webapp/vue/model/api/Map/ValidatedMarkers";
  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { MapMarkerContainer } from "@/main/webapp/vue/model/api/Map/MapMarkerContainer";
  import { MapShopContainer } from "@/main/webapp/vue/model/api/Map/MapShopContainer";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { IconTypes } from "@/main/webapp/vue/model/api/Map/IconTypes";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  export default Vue.extend({
    components: {
      baseMap,
      PruneCluster,
      markers,
      loadingIcon
    },
    data() {
      return {
        locations: [] as MapMarker[],
        mapObject: undefined as Map | undefined,
        mapHeight: `${window.innerHeight * 0.7}px` as string, // project modal map page - map size
        mapName: "map" as string,
        markerColors: mapConfiguration.config.markerColors as string[],
        viewInClusters: true as boolean,
        initializedToolbar: null as DrawToolbar | null,
        zoomInThisLocation: 0 as number,
        iconTypes: mapConfiguration.config.iconTypes as IconTypes,
        loading: false as boolean
      };
    },
    computed: {
      ...mapState([
        'mapProperties'
      ])
    },
    methods: {
      initLocations(): void {
        this.locations = [];
      },
      viewWithoutCluster(): void {
        this.viewInClusters = false;
      },
      fitBounds(): void {
        if (this.mapObject) {
          mapConfiguration.fitBounds(this.mapObject, this.locations, 100);
        }
      },
      validateScreenSize(): void {
        setTimeout(() => { // need to wait for jquery
          if (this.mapObject) {
            this.mapObject.invalidateSize();
            this.fitBounds();
          }
        }, 1000);
      },
      addMarkers(transformedMarkers: MapMarkerContainer, fitBounds: Boolean): void {
        if (transformedMarkers.markers) {
          this.locations.push.apply(this.locations, transformedMarkers.markers);
        }

        if (transformedMarkers.markerColors) {
          this.markerColors = transformedMarkers.markerColors;
        }

        if (window.location.search.indexOf('last') > 0) {
          this.viewWithoutCluster();
        }

        if (fitBounds) {
          this.fitBounds();
        }
      },
      passShopsToHTML(validatedMakers: MapMarker[]): void {
        mapHtmlConfiguration.passShopsToHTML(validatedMakers, this.$t('text.all'));
      },
      validateMarkersInPolygon(polygons: Polygon[]): void {
        let validatedMarkers: ValidatedMarkers = mapConfiguration.validateMarkersInPolygon(polygons, this.locations);
        if (validatedMarkers && validatedMarkers.validMarkers && validatedMarkers.markersForCluster) {
          this.locations = validatedMarkers.markersForCluster;
          this.passShopsToHTML(validatedMarkers.validMarkers);
        }
      },
      removeLayers(): void {
        if (this.mapObject) {
          let map: Map = this.mapObject;
          map.eachLayer((layer: any) => {
            if (layer.editing) {
              map.removeLayer(layer);
            }
          });
        }
      },
      showDrawingToolbar(): void {
        if (this.initializedToolbar == null && this.mapObject) {
          let thisVue = this;
          let map: Map = this.mapObject;
          let drawToolbar: DrawToolbar = mapConfiguration.setDrawToolbar(map);

          map.on('draw:created', function(e: any) {
            if (drawToolbar.drawnItems) {
              drawToolbar.drawnItems.addLayer(e.layer);
              thisVue.validateMarkersInPolygon(mapConfiguration.getAllPolygonsFromLayer(drawToolbar.drawnItems));
            }
          });

          map.on('draw:edited', function(e: any) {
            thisVue.validateMarkersInPolygon(mapConfiguration.getAllPolygonsFromLayer(drawToolbar.drawnItems));
          });

          map.on('draw:deleted', function(e: any) {
            thisVue.validateMarkersInPolygon(mapConfiguration.getAllPolygonsFromLayer(drawToolbar.drawnItems));
          });

          if (drawToolbar) {
            this.initializedToolbar = drawToolbar;
          }
        }
      },
      zoomToEditingLayer(): void {
        if (this.mapObject) {
          let map: Map = this.mapObject;

          const selectFromMapButton:HTMLElement | null = mapHtmlConfiguration.getSelectFromMapBtn();
          if (selectFromMapButton) {
            selectFromMapButton.addEventListener('click', () => {
              if (this.initializedToolbar && this.initializedToolbar.drawnItems) {
                let editedLayerBoundary: LatLngBounds | null = mapConfiguration.getAllPolygonsBoundariesFromLayer(this.initializedToolbar.drawnItems);
                if (editedLayerBoundary) {
                  map.fitBounds(editedLayerBoundary);
                }
              }
            });
          }
        }
      },
      hideDrawingToolbar(): void {
        if (this.initializedToolbar) {
          let drawControl = this.initializedToolbar.drawControl;
          if (drawControl) {
            drawControl.remove();
          }
        }

        this.initializedToolbar = null;
        this.removeLayers();
      },
      lazilyLoadMoreShopsByChainId(chainIds: number[], index: number, nextPage: NavigationLink | undefined): void {
        this.loading = true;
        let chid: number = chainIds[index];

        BackendIntegrationService.fetchMapShopsByChain(nextPage, chid).then((response: MapShopContainer) => {
          if (response.list && response.list.length > 0) {
            let transformedMarkers: MapMarkerContainer = markerConfig.transformToMarkers(response.list, this.markerColors);
            if (transformedMarkers) {
              this.addMarkers(transformedMarkers, index === 0);
            }
          }

          let nav: NavigationLinks | undefined = Optional(response).nav();
          if (nav) {
            let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
            if (nextLink) { // TODO fix here
              this.lazilyLoadMoreShopsByChainId(chainIds, index, nextLink);
            } else {
              if (chainIds.length - 1 > index) {
                this.lazilyLoadMoreShopsByChainId(chainIds, index + 1, undefined);
              } else {
                this.loadNoMore();
              }
            }
          } else {
            if (chainIds.length - 1 > index) {
              this.lazilyLoadMoreShopsByChainId(chainIds, index + 1, undefined);
            } else {
              this.loadNoMore();
            }
          }
        }).catch((error: Error) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Get shops by chain failed', error);
          }
          notification.fail(this.$t('text.error.location'));
          this.loadNoMore();
        });
      },
      loadNoMore(): void {
        this.loading = false;
      },
      markerClicked(shop: MapShop, leafletMarker: Marker): void {
        if (!this.viewInClusters && this.mapObject) { // If not viewInCluster, manually zoom
          this.mapObject.setView(leafletMarker.getLatLng(), mapConfiguration.config.defaultCloseZoom);
        }

        if (leafletMarker.getPopup() === undefined) {
          // Trick to use vue component html
          const projectModalPopupElement: string = new ProjectModalPopup({
            propsData: {
              item: shop
            }
          }).$mount().$el.outerHTML;

          leafletMarker.bindPopup(projectModalPopupElement, { minWidth: 200 });
          leafletMarker.openPopup();
        }
      },
      updateProjectModalMap(mapProperties: MapProperties | null): void {
        if (window.location.hash.indexOf(this.mapName) > 0 && mapProperties) {
          this.zoomToEditingLayer();

          if (parseInt(this.$route.query.shid as string) > -1) {
            this.zoomInThisLocation = parseInt(this.$route.query.shid as string);
          } else {
            this.initLocations();
            let chainIds: number[] = [];

            if (mapProperties.item && mapProperties.item.chids) {
              if (mapProperties.item.chids.indexOf(-1) > -1) { // all or init
                this.hideDrawingToolbar(); // to remove layer
                mapHtmlConfiguration.initProjectShopsFromMap(this.$t('text.all'));
                chainIds = mapHtmlConfiguration.getChainsFromSelectFromMapChainSelects();
              } else { // chain filter
                chainIds = mapProperties.item.chids;
              }

              this.lazilyLoadMoreShopsByChainId(chainIds, 0, undefined);
            }

            this.validateScreenSize(); // need to consider
            this.showDrawingToolbar();
          }
        }
      },
      initMap(map: Map): void {
        this.mapObject = map;
        this.updateProjectModalMap(null);
      }
    },
    watch: {
      mapProperties(newValue: MapProperties, oldValue: MapProperties): void {
        if (oldValue.item.chids === undefined && newValue.item.chids !== undefined) {
          this.updateProjectModalMap(newValue);
        } else if (oldValue.item.chids === undefined && newValue.item.pid === undefined) {
          this.updateProjectModalMap(newValue);
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
