import { JsonObject, JsonProperty } from "json2typescript";
import { DateTime } from "./DateTime";
import { User } from "./User";

@JsonObject('Description')
export class Description {
  @JsonProperty('text', String)
  text?: string = undefined;

  @JsonProperty('created', DateTime)
  created?: DateTime = undefined;

  @JsonProperty('user', User)
  user?: User = undefined;
}
