import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('ImageSize')
export class ImageSize {

  @JsonProperty('name', String)
  name?: string = undefined;

  @JsonProperty('maxDimensionPixels', Number)
  maxDimensionPixels?: number = undefined;


  constructor() {
    this.name = 'medium';
  }
}
