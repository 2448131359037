
  import Vue from 'vue';
  import messages from "@/main/webapp/vue/components/learning/learning-home/course-management/messages.json";

  import headerSection from "@/main/webapp/vue/components/ui/header/section/index.vue";
  import glyphiconText from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-text/index.vue';
  import courseLogo from "@/main/webapp/vue/components/learning/ui/content/course-logo/index.vue";
  import notification from "@/main/webapp/vue/notification";

  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";

  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { CourseBranding } from "@/main/webapp/vue/model/api/learning/CourseBranding";
  import { CourseBrandingStyleType } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
  import { CourseBrandingCss } from "@/main/webapp/vue/model/api/learning/CourseBrandingCss";

  export default Vue.extend({
    components: {
      headerSection,
      glyphiconText,
      courseLogo
    },
    props: {
      courseId: {
        type: Number,
        required: false
      }
    },
    data: function() {
      return {
        subtitle: '' as string,
        course: null as null | CourseModel,
        courseCreated: false as Boolean,
        courseBrandingCss: null as null | string,
        courseBrandingUpdated: false as boolean,
        activeActivity: undefined as ActivityModel | undefined,
        RoutePath
      };
    },
    head: { // Part of vue-head package to dynamically inject elements in <head />
      style: function() {
        return [
          // @ts-ignore
          { type: 'text/css', inner: this.courseBrandingCss, id: 'course-css' }
        ];
      }
    },
    methods: {
      backToList(): void {
        this.$router.push({ path: RoutePath.LEARNING });
      },
      onCourseBrandingUpdated(type: CourseBrandingStyleType, style: any) {
        this.courseBrandingUpdated = !this.courseBrandingUpdated;
        if (type === CourseBrandingStyleType.CSS) {
          let cssStyle = style as CourseBrandingCss;
          this.courseBrandingCss = cssStyle.data as string;
          this.$emit('updateHead');
        }
      },
      courseSettingsUpdated(updatedCourse: Course) {
        if (updatedCourse.urlPath && updatedCourse.urlPath !== '') {
          this.updateSubtitle(updatedCourse);
        }
      },
      activityActivated(activity: ActivityModel) {
        this.activeActivity = activity;
        if (activity.urlPath && activity.urlPath !== '') {
          this.updateSubtitle(activity);
        }
      },
      updateSubtitle(urlPathHolder?: ActivityModel | Course): void {
        if (!urlPathHolder) {
          if (this.course && this.course.urlPath !== '' && this.course.urlPath !== undefined) {
            this.subtitle = this.course.urlPath;
          }
        } else {
          if (urlPathHolder && urlPathHolder.urlPath && urlPathHolder.urlPath !== '') {
            this.subtitle = urlPathHolder.urlPath;
          }
        }

        if (process.env.NODE_ENV !== 'production') {
          console.log(`Subtitle URL path updated: '${this.subtitle}'`);
        }
      },
      fetchCourseDetails(courseId: number): void {
        LearningIntegrationService.fetchCourseDetails(this.courseId).then((course: Course) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Loading courses: ', course);
          }

          this.course = CourseModel.from(course);
          this.updateSubtitle();

          if (course.branding) {
            if (course.branding.defaultStyles) {
              this.$store.dispatch('updateCourseDefaultStyles',
                                   CourseBranding.deserializeDefaultStyle(course.branding.defaultStyles));
            }

            if (course.branding.cssStyle) {
              this.courseBrandingCss = course.branding.cssStyle.data;
              this.$emit('updateHead');
            }
          }

          this.$router.push({ path: RoutePath.COURSE_ACTIVITIES });
        }).catch((e: Error) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log('Loading courses failed with id:', this.courseId, e);
          }
          notification.fail(this.$t('text.error.loading-courses'));
          this.$router.back();
        });
      },
      createCourse(): void {
        LearningIntegrationService.createCourse(
          this.$t('text.course.default.name').toString(),
          this.$t('text.course.default.main-activity-name').toString())
          .then((response: Course) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Create new course', response);
            }

            this.course = CourseModel.from(response);
            this.courseCreated = true;
            this.$router.push({ path: RoutePath.COURSE_SETTINGS });
            this.$store.dispatch('updateCourseDefaultStyles', null);
          }).catch((e: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Create new course failed', e);
            }
            notification.fail(this.$t('text.error.create-course'));
            this.$router.back();
          }).finally(() => {
            this.courseCreated = false;
          });
      }
    },
    mounted() {
      if (this.courseId) {
        this.fetchCourseDetails(this.courseId);
      } else {
        this.createCourse();
      }
    },
    i18n: {
      messages: messages
    }
  });
