import 'add-to-homescreen/addtohomescreen';
import 'add-to-homescreen/dist/style/addtohomescreen.css';

import notification from "@/main/webapp/vue/notification";
import { AddToHomeSession } from "@/main/webapp/vue/model/learning/AddToHomeSession";

export default class AddToHomescreenUtil {

  private static readonly addToHomekey: string = "org.cubiq.addtohome";
  private static readonly znapioAddToHomeKey: string = `${AddToHomescreenUtil.addToHomekey}.znapio`;

  private static getSessions(key: string): AddToHomeSession[] {
    const rawSessions = localStorage.getItem(key);
    if (rawSessions) {
      return JSON.parse(rawSessions);
    }

    return [];
  }

  private static getSession(key: string): AddToHomeSession | null {
    const rawSessions = localStorage.getItem(key);
    if (rawSessions) {
      return JSON.parse(rawSessions);
    }

    return null;
  }

  private static findMatchedIndex(sessions: AddToHomeSession[], activityId: number): number {
    let matchedIndex: number = -1;
    if (sessions && sessions.length > 0) {
      sessions.forEach((znapioSession: AddToHomeSession, index: number) => {
        if (znapioSession.id === activityId) {
          matchedIndex = index;
        }
      });
    }

    return matchedIndex;
  }

  private static overrideSession(key: string, sessions: AddToHomeSession | AddToHomeSession[]): void {
    localStorage.setItem(key, JSON.stringify(sessions));
  }

  private static findZnapiosessionAndOverride(activityId: number) {
    let znapioSessions: AddToHomeSession[] = this.getSessions(this.znapioAddToHomeKey);
    let matchedIndex: number = this.findMatchedIndex(znapioSessions, activityId);
    if (matchedIndex !== -1) {
      this.overrideSession(this.addToHomekey, znapioSessions[matchedIndex]);
    }
  }

  public static show(activityId: number, errorMessage: string): void {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Showing add to homescreen on MainActivity");
    }

    try {
      this.findZnapiosessionAndOverride(activityId);

      // @ts-ignore
      addToHomescreen({
        startDelay: 30, // 30 seconds
        lifespan: 30, // 30 seconds
        displayPace: 1440 // default 1440min = 24h
      });
    } catch (e: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("Add to homescreen failed", e);
      }
      notification.fail(errorMessage);
    }
  }

  public static updateSession(activityId: number, errorMessage: string) {
    try {
      let addToHomeSession: AddToHomeSession | null = this.getSession(this.addToHomekey);
      if (addToHomeSession) {
        addToHomeSession.id = activityId;

        let znapioSessions: AddToHomeSession[] = this.getSessions(this.znapioAddToHomeKey);
        let matchedIndex: number = this.findMatchedIndex(znapioSessions, activityId);
        if (matchedIndex !== -1) {
          znapioSessions.splice(matchedIndex, 1, addToHomeSession);
        } else {
          znapioSessions.push(addToHomeSession);
        }

        this.overrideSession(this.znapioAddToHomeKey, znapioSessions);
      }
    } catch (e: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("Add to homescreen failed", e);
      }
      notification.fail(errorMessage);
    }
  }
}
