
  import Vue from 'vue';
  import L, { LatLng } from 'leaflet';
  import { LMap, LTileLayer } from "vue2-leaflet";

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import '@/main/webapp/vue/components/map/map.css';

  export default Vue.extend({
    components: {
      LMap,
      LTileLayer
    },
    props: {
      scrollZoom: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        mapConfig: {
          center: L.latLng(mapConfiguration.config.defaultLat, mapConfiguration.config.defaultLng) as LatLng,
          zoom: mapConfiguration.config.defaultOpenZoom as number,
          minZoom: mapConfiguration.config.defaultMinZoom as number,
          url: mapConfiguration.config.tileLayerUrl as string,
          attribution: mapConfiguration.config.tileLayerAttribution as string
        },
        mapOptions: { // Important for Safari, to make openPopup working
          tap: false as boolean,
          scrollWheelZoom: this.scrollZoom
        }
      };
    },
    mounted() {
      this.$nextTick(() => {
        let map = (this as any).$refs.map.mapObject;
        this.$emit('map-initialized', map);
      });
    }
  });
