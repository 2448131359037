import { JsonObject, JsonProperty } from "json2typescript";
import { ThemableComponent } from "./ThemableComponent";

export enum ThemablePlatform {
  WEB = "web",
  MOBILE = "mobile"
}

@JsonObject('ThemableComponents')
export class ThemableComponents {

  @JsonProperty('components', [ThemableComponent], true)
  components?: [ThemableComponent] = undefined;

}
