
  import Vue, { PropType } from 'vue';

  import { Item } from "@/main/webapp/vue/model/api/Item";

  import contentContainer from '../../content/content-container/index.vue';
  import submissionMetadata from '../submission-metadata/index.vue';
  import commentSummary from '../../comment/comment-summary/index.vue';

  export default Vue.extend({
    components: {
      contentContainer,
      commentSummary,
      submissionMetadata
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      itemIndex: {
        type: Number
      }
    }
  });
