import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('UserSettings')
export class UserSettings {
  @JsonProperty('userName', String)
  userName: string = '';

  @JsonProperty('userPassword', String, true)
  userPassword?: string = undefined;

  @JsonProperty('userPasswordAgain', String, true)
  userPasswordAgain?: string = undefined;

  @JsonProperty('oldPassword', String, true)
  currentPassword?: string = undefined;

  @JsonProperty('userMsisdn', String, true)
  userMsisdn?: string = undefined;

  @JsonProperty('userEmail', String, true)
  userEmail?: string = undefined;

  @JsonProperty('userPosition', String, true)
  userPosition?: string = undefined;

  @JsonProperty('userDepartmentId', Number, true)
  userDepartmentId?: number = undefined;

  @JsonProperty('userTimeZone', String)
  userTimeZone: string = 'Europe/Helsinki';

  @JsonProperty('userLocale', String, true)
  userLocale?: string = undefined;

  @JsonProperty('afterLoginPage', String, true)
  afterLoginPage?: string = undefined;

  @JsonProperty('notificationSubscriptionConfigurations', Object, true)
  notificationSubscriptionConfigurations: { [key: string]: boolean; } = {};
}
