import WidgetModel, {
  WidgetIdType,
  DraggableSection,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import cardWidget from '@/main/webapp/vue/components/learning/widget/widgets/card/widget/index.vue';
import cardWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/card/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import SectionStyleModel from "@/main/webapp/vue/components/ui/style/section/model/SectionStyleModel";
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class CardWidgetData {
  constructor(@JsonField('closable', Boolean, true)
              public closable: boolean = false) {}
}

export class CardWidgetProperties {
  constructor(@JsonField('sectionStyle', SectionStyleModel, true)
              public sectionStyle = new SectionStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'card')
export default class CardWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', CardWidgetData, true)
              public data = new CardWidgetData(),
              @JsonField('properties', CardWidgetProperties)
              public properties = new CardWidgetProperties(),
              @JsonField('defaultProperties', CardWidgetProperties, true)
              public defaultProperties = new CardWidgetProperties(
                new SectionStyleModel('#f8f9fa', '#000000', '10 10 10 10'),
                new GenericStyleWidgetProperties()
              ),
              @JsonField('nestedContents', [WidgetModel], true)
              public nestedContents: WidgetModel[] = [],
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }

  updateInternal(newModel: CardWidgetModel): void {
    if (this.nestedContents && this.nestedContents.length > 0) {
      this.nestedContents = newModel.nestedContents;
    }
  }
}

registerWidget(
  WidgetFamily.LAYOUT,
  CardWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'wysiwyg',
  cardWidget,
  cardWidgetProperties
);
