import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject('CourseBrandingCss')
export class CourseBrandingCss {

  @JsonProperty('data', Any, true)
  data?: any = undefined;

  @JsonProperty('updated', String, true)
  updated?: String = undefined;

}
