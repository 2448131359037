import { apiService } from "./ApiService";

import { Any } from "json2typescript";

import { Courses } from "@/main/webapp/vue/model/api/learning/Courses";
import { Course } from "@/main/webapp/vue/model/api/learning/Course";
import { Activities } from "@/main/webapp/vue/model/api/learning/Activities";
import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";
import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
import { ContentUnit } from "@/main/webapp/vue/model/api/learning/ContentUnit";
import { CourseBranding } from "@/main/webapp/vue/model/api/learning/CourseBranding";
import { CourseBrandingStyle } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
import { CourseBrandingCss } from "@/main/webapp/vue/model/api/learning/CourseBrandingCss";
import { ActivityPublic } from "@/main/webapp/vue/model/api/learning/ActivityPublic";

export class LearningIntegrationService {
  private static readonly BASE_PATH = "/learning/courses";
  private static readonly BASE_PATH_OPEN = "/open/learning/courses";


  public static createCourse(courseName: string, mainActivityName: string, navLink?: NavigationLink): Promise<Course> {
    return apiService.post(Course, navLink ? navLink.href : this.BASE_PATH, {
      name: courseName,
      mainActivityName: mainActivityName
    });
  }

  public static fetchCourses(navLink?: NavigationLink): Promise<Courses> {
    return apiService.get(Courses, navLink ? navLink.href : this.BASE_PATH);
  }

  public static fetchCourseDetails(courseId?: number, navLink?: NavigationLink): Promise<Course> {
    return apiService.get(Course, navLink ? navLink.href : `${this.BASE_PATH}/${courseId}`);
  }

  public static updateCourseDetails(course: Course, courseBasicInfo: Course): Promise<Course> {
    return apiService.put(Course, `${this.BASE_PATH}/${course.id}`, courseBasicInfo);
  }

  public static duplicateCourse(course: Course): Promise<Course> {
    return apiService.patch(Course, `${this.BASE_PATH}/${course.id}`);
  }

  public static deleteCourse(course: Course): Promise<any> {
    return apiService.delete(Any, `${this.BASE_PATH}/${course.id}`);
  }

  public static fetchCourseBranding(course: Course, navLink?: NavigationLink): Promise<CourseBranding> {
    return apiService.get(CourseBranding, navLink ? navLink.href : `${this.BASE_PATH}/${course.id}/branding`);
  }

  public static updateCourseBrandingStyle(course: Course, style: CourseBrandingStyle): Promise<CourseBrandingStyle> {
    return apiService.put(CourseBrandingStyle,
        `${this.BASE_PATH}/${course.id}/branding/style/${style.type}`, style);
  }

  public static updateCourseBrandingCss(course: Course, style: CourseBrandingCss): Promise<CourseBrandingCss> {
    return apiService.put(CourseBrandingCss, `${this.BASE_PATH}/${course.id}/branding/css`, style);
  }

  public static listCourseBrandingLogos(course: Course): Promise<ItemContentEntityContainer> {
    return apiService.get(ItemContentEntityContainer, `${this.BASE_PATH_OPEN}/${course.id}/branding/logos`);
  }

  public static listActiveCourseActivities(course: Course, navLink?: NavigationLink): Promise<Activities> {
    return apiService.get(Activities, navLink ? navLink.href : `${this.BASE_PATH}/${course.id}/activities/active`);
  }

  public static listCourseActivities(course: Course, navLink?: NavigationLink): Promise<Activities> {
    return apiService.get(Activities, navLink ? navLink.href : `${this.BASE_PATH}/${course.id}/activities`);
  }

  public static fetchCourseActivityDetails(course: Course,
                                           activity: Activity,
                                           navLink?: NavigationLink): Promise<Activity> {
    return apiService.get(Activity,
        navLink ? navLink.href : `${this.BASE_PATH}/${course.id}/activities/${activity.id}`);
  }

  public static fetchCourseActivityDetailsOnline(course: Course,
                                                 activity: Activity): Promise<ActivityPublic> {
    return apiService.get(ActivityPublic, `${this.BASE_PATH_OPEN}/${course.id}/activities/${activity.id}`);
  }

  public static fetchActiveCourseActivitiesOnline(course: Course): Promise<Activity> {
    return apiService.get(Activity, `${this.BASE_PATH_OPEN}/${course.id}/activities/active`);
  }

  public static fetchActiveCourseActivityWithSubactivitiesOnline(course: Course, activity: Activity): Promise<Activity> {
    return apiService.get(Activity, `${this.BASE_PATH_OPEN}/${course.id}/activities/${activity.id}/subactivities`);
  }

  public static createCourseActivity(course: Course, activity: Activity): Promise<Activity> {
    return apiService.post(Activity, `${this.BASE_PATH}/${course.id}/activities`, activity);
  }

  public static updateCourseActivity(course: Course, activity: Activity): Promise<Activity> {
    return apiService.put(Activity, `${this.BASE_PATH}/${course.id}/activities/${activity.id}`, activity);
  }

  public static deleteCourseActivity(course: Course, activity: Activity): Promise<any> {
    return apiService.delete(Any, `${this.BASE_PATH}/${course.id}/activities/${activity.id}`);
  }

  public static createCourseSubactivity(course: Course,
                                        parentId: number,
                                        activity: Activity): Promise<Activity> {
    return apiService.post(Activity,
        `${this.BASE_PATH}/${course.id}/activities/${parentId}/subactivities`, activity);
  }

  public static createCourseActivityContentUnit(course: Course,
                                                activity: Activity,
                                                contentUnit: ContentUnit,
                                                parentContentUnitId?: string | number | undefined): Promise<ContentUnit> {
    return apiService.post(ContentUnit,
        `${this.BASE_PATH}/${course.id}/activities/${activity.id}/contents${parentContentUnitId ? `/${parentContentUnitId}` : ''}`, contentUnit);
  }

  public static updateCourseActivityContentUnit(course: Course,
                                                activity: Activity,
                                                contentUnit: ContentUnit): Promise<ContentUnit> {
    return apiService.put(ContentUnit,
        `${this.BASE_PATH}/${course.id}/activities/${activity.id}/contents/${contentUnit.id}`, contentUnit);
  }

  public static deleteCourseActivityContentUnit(course: Course,
                                                activity: Activity,
                                                contentUnit: ContentUnit): Promise<any> {
    return apiService.delete(Any, `${this.BASE_PATH}/${course.id}/activities/${activity.id}/contents/${contentUnit.id}`);
  }

  public static fetchCourseActivityContentUnitAttachments(course: Course, activityId: number, contentUnitId: number, navLink?: NavigationLink): Promise<ItemContentEntityContainer> {
    return apiService.get(ItemContentEntityContainer, navLink ? navLink.href : `${this.BASE_PATH}/${course.id}/activities/${activityId}/contents/${contentUnitId}/attachments`);
  }

  public static duplicateActivity(course: Course, activity: Activity): Promise<Activity> {
    return apiService.patch(Activity, `${this.BASE_PATH}/${course.id}/activities/${activity.id}`);
  }

  public static unlinkActivityContents(course: Course, activity: Activity): Promise<Activity> {
    return apiService.patch(Activity, `${this.BASE_PATH}/${course.id}/activities/${activity.id}/contents`);
  }

  public static unlinkContent(course: Course,
                              activity: Activity,
                              contentUnit: ContentUnit): Promise<ContentUnit> {
    return apiService.patch(ContentUnit, `${this.BASE_PATH}/${course.id}/activities/${activity.id}/contents/${contentUnit.id}`, contentUnit);
  }

}
