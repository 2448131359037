
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  import textBadge from '@/main/webapp/vue/components/ui/text/text-badge/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { generateShareHTMLService } from "@/main/webapp/vue/services/GenerateShareHTMLService";
  import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { ShareItem } from "@/main/webapp/vue/model/api/ShareItem";

  export default Vue.extend({
    components: {
      textBadge
    },
    props: {
      searchCriteriaSelectedDataValues: {
        type: Array as PropType<SearchCriteriaSelectedDataValue[]>,
        required: false
      },
      selectedSubmissionIds: {
        type: Array as PropType<number[]>,
        default: () => []
      }
    },
    data() {
      return {
        busy: false as boolean
      };
    },
    methods: {
      checkToShare(): void {
        if (this.selectedSubmissionIds && this.selectedSubmissionIds.length > 0) {
          this.shareSelectedItems(this.selectedSubmissionIds);
        } else {
          this.shareCurrentView();
        }
      },
      handleError(error: BackendError): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Share current view failed', error);
        }

        let errorMessageKey: string = "error.share-current-view";
        let limit: number = 0;
        let current: number = 0;

        if (error && error.details) {
          if (error.details.current > error.details.limit) {
            errorMessageKey = "error.exceed-limit";
            limit = error.details.limit;
            current = error.details.current;
          }
        }

        notification.fail(this.$t(errorMessageKey, { limit, current }));
      },
      prepareShareOutbox(item: ShareItem) {
        generateShareHTMLService.prepareShareOutbox(item);
      },
      shareSelectedItems(selectedItems: Number[]): void {
        if (this.busy) {
          return;
        }

        this.busy = true;
        BackendIntegrationService.postShareItems(selectedItems)
          .then((item: ShareItem) => {
            console.log("Get shared items from selected items:", item.contents?.length);
            if (item) {
              this.prepareShareOutbox(item);
            }
          }).catch((error: BackendError) => {
            this.handleError(error);
          }).finally(() => {
            this.busy = false;
          });
      },
      shareCurrentView(): void {
        if (this.busy) {
          return;
        }

        this.busy = true;
        BackendIntegrationService.postShareCurrentView(this.searchCriteriaSelectedDataValues)
          .then((item: ShareItem) => {
            console.log("Get share items from current view:", item.contents?.length);
            if (item) {
              this.prepareShareOutbox(item);
            }
          }).catch((error: BackendError) => {
            this.handleError(error);
          }).finally(() => {
            this.busy = false;
          });
      }
    },
    i18n: {
      messages: messages,
      sharedMessages: sharedMessages
    }
  });
