import { JsonObject, JsonProperty } from "json2typescript";
import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";

@JsonObject('ShareItem')
export class ShareItem {

  @JsonProperty('contents', [ItemContent], true)
  contents?: ItemContent[] = undefined;

}



