
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      icon: {
        type: String,
        required: true
      },
      color: {
        type: String,
        default: null
      },
      theme: {
        type: String,
        default: "dark"
      },
      size: {
        type: String,
        default: "medium"
      }
    },
    computed: {
      themeComputed() {
        if (this.color !== null) {
          return '';
        }

        return this.theme;
      },
      styleComputed() {
        if (this.color !== null) {
          return this.color;
        }

        return null;
      }
    }
  });
