import { JsonObject, JsonProperty } from "json2typescript";
import { UserRegistryClient } from "@/main/webapp/vue/model/api/UserRegistryClient";

@JsonObject('UserRegistryClients')
export class UserRegistryClients {

  @JsonProperty('list', [UserRegistryClient], false)
  list: UserRegistryClient[] = [];

}
