
  import Vue from 'vue';

  import glyphiconSymbol from '../glyphicon-symbol/index.vue';

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    props: {
      icon: {
        type: String,
        required: true
      },
      text: {
        type: String,
        default: null
      },
      showText: {
        type: Boolean,
        default: false
      }
    }
  });
