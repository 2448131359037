import L, { FeatureGroup, Layer, Map, LatLng, LatLngBounds } from 'leaflet';
import 'vue2-leaflet-draw-toolbar';
import * as turf from '@turf/turf';

import store from "@/main/webapp/vue/store";
import { URL_PATH } from '@/main/webapp/vue/urlPath';

import { Polygon } from "@/main/webapp/vue/model/api/Map/Polygon";
import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
import { MapProperties } from "@/main/webapp/vue/model/api/Map/MapProperties";
import { MapPropertiesItem } from "@/main/webapp/vue/model/api/Map/MapPropertiesItem";
import { MapMarkerData } from "@/main/webapp/vue/model/api/Map/MapMarkerData";
import { Route } from "vue-router";

export const config = {
  markerColors: ['#ffffff', '#000000'],
  defaultLat: 50, // same lat from apps
  defaultLng: 12, // same lng from apps
  defaultOpenZoom: 3,
  defaultCloseZoom: 17,
  defaultMinZoom: 2,
  tileLayerUrl: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
  tileLayerAttribution: '',
  itemsPerMapAjaxCall: 500,
  pruneClusterSize: 150,
  projectMapMarkerLimit: 750,
  drawToolbarPosition: 'topleft',
  moreShopsByChainRequestDelay: 2000, // milliseconds
  iconTypes: {
    shop: { icon: 'shop', name: 'shop', color: 'blue' },
    user: { icon: 'user', name: 'user', color: 'red' },
    editUser: { icon: 'user', name: 'editUser', color: 'green' }
  }
};

export const updateMapPropertiesToStore = (to: Route): void => {
  setTimeout(() => {
    let mapProperties: MapProperties = new MapProperties();
    mapProperties.item = new MapPropertiesItem();

    if (to.path === URL_PATH.PROJECTS) {
      if (to.query && to.query.pid && to.query.ps) {
        mapProperties.item.pid = parseInt(to.query.pid.toString());
        mapProperties.item.ps = to.query.ps.toString();
      } else if (to.hash) {
        let hashUrlArray: string[] = to.hash.split('&');
        if (to.hash.search("map") > -1) {
          mapProperties.item.chids = hashUrlArray[1].split("=")[1].split(",").map(x => +x);
        } else {
          if (to.hash.search("pid") > -1 && to.hash.search("ps") > -1) {
            mapProperties.item.pid = parseInt(hashUrlArray[0].split("=")[1]);
            mapProperties.item.ps = hashUrlArray[1].split("=")[1];
          }
        }
      }
    } else if (to.path === URL_PATH.SHOPS) {
      if (to.query && to.query.shid) {
        mapProperties.item.shid = parseInt(to.query.shid.toString());
      }
    }

    (store as any).state.mapProperties = mapProperties;
  }, 100);
};

export const validateMarkersInPolygon = (polygons: Polygon[], markers: MapMarker[]) => {
  let validMarkers: MapMarker[] = [];
  let markersForCluster: MapMarker[] = [];

  markers.forEach((marker: MapMarker) => {
    if (marker.lat && marker.lng && marker.markerData) {
      let turfPoint: any = turf.point([marker.lat, marker.lng], undefined);
      marker.markerData.color = "white";

      let turfPolygon: any;
      let isItInside: boolean = false;

      polygons.forEach((polygon: Polygon) => {
        if (polygon.coordinates && marker.markerData && marker.html && marker.html.item) {
          if (polygon.type === "circle" && polygon.radius) { // using turf lib is not correct
            let circleCenterPoint: LatLng = (L as any).circle(polygon.coordinates[0], polygon.radius).getLatLng();
            isItInside = Math.abs((circleCenterPoint as any).distanceTo([marker.lat, marker.lng])) <= polygon.radius;
          } else {
            turfPolygon = turf.polygon([polygon.coordinates]);
            isItInside = turf.booleanPointInPolygon(turfPoint, turfPolygon);
          }

          if (isItInside) {
            marker.markerData.color = "red";
            if (validMarkers.length === 0) {
              validMarkers.push(marker);
            } else {
              let tempMarker: MapMarker = validMarkers[validMarkers.length - 1];
              if (tempMarker && tempMarker.html && tempMarker.html.item.id !== marker.html.item.id) {
                validMarkers.push(marker);
              }
            }
          }
        }
      });

      markersForCluster.push(marker);
    }
  });

  return { validMarkers, markersForCluster };
};

export const setDrawToolbar = (map: Map) => {
  let drawnItems: FeatureGroup = new L.FeatureGroup();
  map.addLayer(drawnItems);

  let drawControl = new (L as any).Control.Draw({
    draw: {
      position: config.drawToolbarPosition,
      polyline: false,
      rectangle: true,
      circle: true,
      circlemarker: false,
      polygon: true,
      marker: false
    },
    edit: {
      featureGroup: drawnItems
    }
  });

  map.addControl(drawControl);

  return { drawnItems, drawControl };
};

export const transformToOnlyNumbers = (polygon: [[LatLng]]) => {
  let tempPoly: number[][] = [];

  polygon.forEach((points: [LatLng]) => {
    points.forEach((point: LatLng) => {
      if (point.lat && point.lng) {
        tempPoly.push([point.lat, point.lng]);
      }
    });
  });

  tempPoly.push(tempPoly[0]); // last point should be equal as first
  return tempPoly;
};

export const getAllPolygonsFromLayer = (drawnItems: any) => {
  let polygons: Polygon[] = [];
  let layers = drawnItems._layers;

  for (let key in layers) {
    if (layers[key].editing.latlngs) { // polygon
      polygons.push({ type: "polygon", coordinates: transformToOnlyNumbers(layers[key].editing.latlngs[0]), radius: 0 });
    } else if (layers[key]._mRadius) { // circle
      polygons.push({ type: "circle", coordinates: [[layers[key]._latlng.lat, layers[key]._latlng.lng]], radius: layers[key]._mRadius });
    } else if (layers[key]._latlngs) { // rectangle
      polygons.push({ type: "rectangle", coordinates: transformToOnlyNumbers(layers[key]._latlngs), radius: 0 });
    }
  }

  return polygons;
};

export const registerCustomClusterMarker = (pruneCluster: any, markerColors: string[]) => {
  pruneCluster.BuildLeafletClusterIcon = (cluster: any) => {
    let e: any = new (L as any).Icon.MarkerCluster();
    e.stats = cluster.stats;
    e.population = cluster.population;
    return e;
  };

  let fullCircle: number = Math.PI * 2;

  (L as any).Icon.MarkerCluster = L.Icon.extend({
    options: {
      iconSize: new L.Point(44, 44),
      className: 'prunecluster leaflet-markercluster-icon'
    },
    createIcon: function() {
      let e = document.createElement('canvas');
      this._setIconStyles(e, 'icon');
      let s = this.options.iconSize;
      e.width = s.x;
      e.height = s.y;
      this.draw(e.getContext('2d'), s.x, s.y);
      return e;
    },
    createShadow: function() {
      return null;
    },
    draw: function(canvas: any, width: number, height: number) {
      let start = 0;
      for (let i = 0, l = markerColors.length; i < l; ++i) {
        let size = this.stats[i] / this.population;
        if (size > 0) {
          canvas.beginPath();
          canvas.moveTo(22, 22);
          canvas.fillStyle = markerColors[i];

          let from: number = start + 0.14;
          let to: number = start + size * fullCircle;
          if (to < from) {
            from = start;
          }

          canvas.arc(22, 22, 22, from, to);
          start = start + size * fullCircle;
          canvas.lineTo(22, 22);
          canvas.fill();
          canvas.closePath();
        }
      }
      canvas.beginPath();
      canvas.fillStyle = 'white';
      canvas.arc(22, 22, 18, 0, fullCircle);
      canvas.fill();
      canvas.closePath();
      canvas.fillStyle = '#555';
      canvas.textAlign = 'center';
      canvas.textBaseline = 'middle';
      canvas.font = 'bold 12px sans-serif';
      canvas.fillText(this.population, 22, 22, 40);
    }
  });

  return new (L as any).Icon.MarkerCluster();
};

export const initPruneClusterMarkerColors = (items: MapMarker[], markerColors: string[]) => {
  let markerColorsInitialized: boolean = !(markerColors.length === 0);

  if (!markerColorsInitialized) {
    let tempMarkerColors: MapMarkerData[] = [];
    items.forEach((item: MapMarker, index: number) => {
      if (item.markerData) {
        let category: number | undefined = item.markerData.category;
        let colorCode: string | undefined = item.markerData.colorCode;

        let tempColor: MapMarkerData = {
          category: category,
          colorCode: colorCode
        };

        if (!markerColorsInitialized) {
          let position = tempMarkerColors.map((element: MapMarkerData) =>
            element.category
          ).indexOf(category);

          if (position === -1 && category) {
            tempMarkerColors.splice(category, 0, tempColor);
          }
        }
      }
    });

    tempMarkerColors.sort(function(a: any, b: any) {
      return a.category - b.category;
    });

    tempMarkerColors.forEach((tmpMarkerColor: MapMarkerData, index: number) => {
      if (tmpMarkerColor.colorCode) {
        markerColors.push(tmpMarkerColor.colorCode);
      }
    });
  }
  return markerColors;
};

export const getAllPolygonsBoundariesFromLayer = (drawnItems: any): LatLngBounds | null => {
  let boundaries: Layer[] = [];
  let layers = drawnItems._layers;

  for (let key in layers) {
    if (layers[key].editing.latlngs || layers[key]._mRadius || layers[key]._latlngs) {
      boundaries.push(layers[key]);
    }
  }

  if (boundaries.length > 0) {
    return new FeatureGroup(boundaries).getBounds();
  }

  return null;
};

export const fitBounds = (mapObject: Map, locations: MapMarker[], padding: number = 50): void => {
  if (mapObject && locations && locations.length > 0) {
    setTimeout(() => {
      mapObject.fitBounds(locations as any, { padding: [padding, padding] });
    }, 100);
  }
};

export default {
  config,
  updateMapPropertiesToStore,
  getAllPolygonsFromLayer,
  setDrawToolbar,
  validateMarkersInPolygon,
  registerCustomClusterMarker,
  initPruneClusterMarkerColors,
  getAllPolygonsBoundariesFromLayer,
  fitBounds
};
