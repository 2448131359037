
  import Vue, { PropType } from 'vue';
  import { Chrome } from 'vue-color';
  import messages from '../messages.json';
  import formCard from '@/main/webapp/vue/components/ui/form/card/index.vue';
  import collapsibleContainer from '@/main/webapp/vue/components/ui/collapsible/collapsible-container/index.vue';
  import ButtonStyleModel from '@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel';

  export default Vue.extend({
    name: 'ButtonStyle',
    props: {
      buttonStyle: {
        type: Object as PropType<ButtonStyleModel>,
        default: () => new ButtonStyleModel(undefined, undefined, undefined)
      },
      defaultStyle: { // Widget should set default style
        type: Object as PropType<ButtonStyleModel>, // default value is only for branding setting
        default: () => new ButtonStyleModel().default()
      },
      title: {
        type: String
      },
      enableBackgroundColor: {
        type: Boolean,
        default: true
      },
      enableTextColor: {
        type: Boolean,
        default: true
      },
      enableBorderColor: {
        type: Boolean,
        default: false
      },
      enableBorderRadius: {
        type: Boolean,
        default: true
      },
      useCollapsibleContainer: {
        type: Boolean,
        default: true
      },
      alignContentsVertically: {
        type: Boolean,
        default: true
      },
      busy: {
        type: Boolean,
        default: false
      }
    },
    components: {
      formCard,
      collapsibleContainer,
      'chrome-picker': Chrome
    },
    computed: {
      component() {
        if (this.useCollapsibleContainer) {
          return 'collapsible-container';
        }
        return 'form-card';
      }
    },
    i18n: {
      messages: messages
    }
  });
