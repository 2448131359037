import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('ContentAccessToken')
export class ContentAccessToken {

  @JsonProperty('access_token', String, false)
  accessToken?: string = undefined;

  @JsonProperty('token_type', String, false)
  tokenType?: string = undefined;

  @JsonProperty('expires_in', Number, false)
  expiresIn?: number = undefined;
}
