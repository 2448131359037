
  import Vue, { PropType } from 'vue';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import IconMaterialDesignWidgetModel
    from '@/main/webapp/vue/components/learning/widget/widgets/icon-material-design/model/IconMaterialDesignWidgetModel';
  import TextStyleWidgetProperties from "@/main/webapp/vue/model/learning/TextStyleWidgetProperties";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'IconMaterialDesignWidget',
    props: {
      model: {
        type: Object as PropType<IconMaterialDesignWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      }
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return {
          ...computedStyleUtil.getStyles(
            ["margin"],
            this.model.properties.genericStyle,
            this.computedDefaultStyle.genericStyle),
          ...computedStyleUtil.getStyles(
            ["color", "alignment", "size"],
            this.model.properties.textStyle,
            this.computedDefaultStyle.textStyle)
        };
      },
      computedDefaultStyle(): TextStyleWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    }
  });
