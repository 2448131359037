import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('DateTime')
export class DateTime {

  @JsonProperty('timeZone', String)
  timeZone?: string = undefined;

  @JsonProperty('date', String)
  date?: string = undefined;

  @JsonProperty('time', String)
  time?: string = undefined;

  @JsonProperty('timestamp', String)
  timestamp?: string = undefined;

  constructor(timeZone: string, date: string, time: string, timestamp: string) {
    this.timeZone = timeZone;
    this.date = date;
    this.time = time;
    this.timestamp = timestamp;
  }

}
