
  import Vue, { PropType } from 'vue';
  import collapsibleContent from '@/main/webapp/vue/components/ui/collapsible/collapsible-content/index.vue';
  import { MapCategory } from "@/main/webapp/vue/model/api/Map/MapCategory";

  export default Vue.extend({
    props: {
      legends: {
        type: Array as PropType<MapCategory[]>,
        default: null
      }
    },
    components: {
      collapsibleContent
    },
    methods: {
      matchParentCategoryName(item: MapCategory, index: number) {
        if (index === 0 && item.parentCategory) {
          return item.parentCategory.name;
        }

        let previousCategory: MapCategory = this.legends[index - 1];

        if (item.parentCategory && previousCategory.parentCategory) {
          if (item.parentCategory.name !== previousCategory.parentCategory.name) {
            return item.parentCategory.name;
          }
        }
      }
    }
  });
