
  import Vue, { PropType } from 'vue';

  import { oc as Optional } from "ts-optchain";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";

  import submissionMetadataElement from '../submission-metadata-element/index.vue';

  export default Vue.extend({
    components: {
      submissionMetadataElement
    },
    props: {
      itemId: {
        type: Number,
        required: false
      },
      metadata: {
        type: Array as PropType<HierarchicalEntity[]>,
        required: false
      },
      disablePictureClickModal: {
        type: Boolean,
        default: false
      }
    },
    data: function() {
      return {
        submission: {} as Item
      };
    },
    computed: {
      processedElements: function() {
        let unprocessedElements: HierarchicalEntity[] = [];

        if (this.metadata) {
          unprocessedElements = [...this.metadata];
        }

        if (this.submission && this.submission.itemMetadata) {
          unprocessedElements = [...this.submission.itemMetadata];
        }

        let processedElements: HierarchicalEntity[] = [];
        (unprocessedElements || []).forEach((element: HierarchicalEntity, index: number) => {
          if (Optional(element).metadata.hierarchical.ignoreRoot()) {
            processedElements.push(...element.getChildrenWithMetadata());
          } else {
            processedElements.push(element);
          }
        });

        return processedElements;
      }
    },
    mounted(): void {
      if (this.itemId) {
        BackendIntegrationService.fetchSubmissionDetails(this.itemId).then((submission: Item) => {
          this.submission = submission;
        });
      }
    }
  });
