import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('Language')
export class Language {
  @JsonProperty('name', String)
  name: string = 'English';

  @JsonProperty('code', String)
  code: string = 'en';
}
