
  import Vue, { PropType } from 'vue';
  import { MapShop } from '@/main/webapp/vue/model/api/Map/MapShop';

  export default Vue.extend({
    props: {
      item: {
        type: Object as PropType<MapShop>
      }
    },
    data() {
      return {
      };
    }
  });
