import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import TextStyleModel, {
  TEXT_ALIGNMENT,
  TEXT_SIZE
} from '@/main/webapp/vue/components/ui/style/text/model/TextStyleModel';
import textBlockWidget from '@/main/webapp/vue/components/learning/widget/widgets/text-block/widget/index.vue';
import textBlockWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/text-block/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import TextStyleWidgetProperties from "@/main/webapp/vue/model/learning/TextStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class TextBlockWidgetData {
  constructor(@JsonField('paragraph', String)
              public paragraph = '') {}
}

@JsonDiscriminator(WidgetModel, 'textBlock')
export default class TextBlockWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', TextBlockWidgetData)
              public data = new TextBlockWidgetData(),
              @JsonField('properties', TextStyleWidgetProperties)
              public properties = new TextStyleWidgetProperties(),
              @JsonField('defaultProperties', TextStyleWidgetProperties, true)
              public defaultProperties =
                new TextStyleWidgetProperties(
                  new TextStyleModel(TEXT_SIZE.CONTENT, '#000000', TEXT_ALIGNMENT.LEFT)),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.BASIC,
  TextBlockWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'subject',
  textBlockWidget,
  textBlockWidgetProperties
);
