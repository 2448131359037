
  import Vue, { PropType } from 'vue';
  import { v1 as uuidv1 } from 'uuid';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import NavigationMenuWidgetModel
    , {
      NavigationMenuProperties
    } from '@/main/webapp/vue/components/learning/widget/widgets/navigation-menu/model/NavigationMenuWidgetModel';
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import { LearningIntegrationService } from "@/main/webapp/vue/services/LearningIntegrationService";
  import { Course } from "@/main/webapp/vue/model/api/learning/Course";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { Activity } from "@/main/webapp/vue/model/api/learning/Activity";

  export default Vue.extend({
    name: 'NavigationMenuWidget',
    props: {
      model: {
        type: Object as PropType<NavigationMenuWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activities: [] as ActivityModel[],
        collapsibleId: `nav-bar-collapsible-${uuidv1()}`
      };
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): NavigationMenuProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedHamburgerMenuStyle(): Object {
        return computedStyleUtil.getStyles(
          ['backgroundColor', 'textColor', 'borderRadius'],
          this.model.properties.menuButtonStyle,
          this.computedDefaultStyle.menuButtonStyle);

      },
      computedNavItemStyle(): Object {
        return computedStyleUtil.getStyles(
          ['backgroundColor', 'textColor', 'borderRadius'],
          this.model.properties.buttonStyle,
          this.computedDefaultStyle.buttonStyle);
      },
      selectedActivities(): ActivityModel[] {
        let selectedActivities: ActivityModel[] = [];
        for (let activity of this.activities) {
          if (this.model.data.selectedActivityIds.length === 0 ||
            this.model.data.selectedActivityIds.indexOf(activity.id as number) > -1) {
            selectedActivities.push(activity);
          }
        }
        return selectedActivities;
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      generateDisplayedActivities(activities: ActivityModel[]) {
        this.activities = this.getActiveActivities([], activities);
      },
      getActiveActivities(activeActivities: ActivityModel[], subactivities: ActivityModel[]) {
        for (let activity of subactivities) {
          if (activity.active) {
            activeActivities.push(activity);
            if (activity.subactivities && activity.subactivities.length > 0) {
              activeActivities = this.getActiveActivities(activeActivities, activity.subactivities);
            }
          }
        }
        return activeActivities;
      }
    },
    mounted() {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`Navigation menu widget mounted: ${this.course ? this.course.name : 'undefined'}`);
      }
      if (this.course) {
        const course: Course = Course.from(this.course);
        LearningIntegrationService.fetchActiveCourseActivitiesOnline(course)
          .then((response: Activity) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(response);
            }
            this.generateDisplayedActivities([ActivityModel.from(response)]);
            // eslint-disable-next-line
          }).catch((error: BackendError) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log("Failed to fetch active course activities, using fallback instead");
            }

            if (course.mainActivity) {
              this.generateDisplayedActivities([ActivityModel.from(course.mainActivity)]);
            }
          });
      }
    }
  });
