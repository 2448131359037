import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('NavigationLink')
export class NavigationLink {

  @JsonProperty('rel', String, false)
  rel: string = "";

  @JsonProperty('href', String, false)
  href: string = "";

  static hasType(type: string, link?: NavigationLink): boolean {
    return link !== null && link !== undefined && link.rel === type;
  }

  hasType(type: string): boolean {
    return NavigationLink.hasType(type, this);
  }

  static weblink(href: string): NavigationLink {
    let navigationLink: NavigationLink = new NavigationLink();
    navigationLink.href = href;
    navigationLink.rel = NavigationLinkType.WEB;
    return navigationLink;
  }

  public weblinkWithSort(href: string, sortField: string, sortDesc: boolean, defaultSort: boolean): NavigationLink {
    this.rel = NavigationLinkType.WEB;

    this.href = href;

    this.href = NavigationLink.appendAmpersandIfQuestionMarkNotPresent(this.href);
    this.href += `sort=${sortField},${(sortDesc ? "desc" : "asc")}`;

    if (defaultSort) {
      this.href = NavigationLink.appendAmpersandIfQuestionMarkNotPresent(this.href);
      this.href += "sort=created,desc";
    }

    return this;
  }

  private static appendAmpersandIfQuestionMarkNotPresent(href: string): string {
    if (href.includes("?")) {
      href += "&";
    } else {
      href += "?";
    }

    return href;
  }
}
