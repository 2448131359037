export default class DomUtil {
  static getMetaTagContent = (name: String) => {
    const metaTag = document.querySelector(`meta[name=${name}]`);
    if (metaTag === null) {
      return null;
    }

    return metaTag.getAttribute('content');
  };
}
