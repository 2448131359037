import { JsonObject, JsonProperty } from "json2typescript";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

@JsonObject('ItemContentEntity')
export class ItemContentEntity extends NavigationLinks {

  @JsonProperty('type', String, true)
  type?: string = undefined;

  @JsonProperty('itemId', Number, true)
  itemId?: number = undefined;

}
