
  import Vue, { PropType } from 'vue';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import ActivityButtonWidgetModel
    from "@/main/webapp/vue/components/learning/widget/widgets/activity-button/model/ActivityButtonWidgetModel";
  import ButtonStyleWidgetProperties from '@/main/webapp/vue/model/learning/ButtonStyleWidgetProperties';
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";

  export default Vue.extend({
    name: 'ActivityButtonWidget',
    props: {
      model: {
        type: Object as PropType<ActivityButtonWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedActivityButtonLinkStyles(): Object {
        const styles: any = computedStyleUtil.getStyles(
          ['backgroundColor', 'textColor', 'borderRadius'],
          this.model.properties.buttonStyle,
          this.computedDefaultStyle.buttonStyle);
        styles['--iconAlignment'] = this.model.data.iconAlignment;
        styles['display'] = 'flex';

        return styles;
      },
      computedDefaultStyle(): ButtonStyleWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedDestination(): string {
        const prefixUrl: string = 'http';
        if (this.model.data.destination.indexOf(prefixUrl) > -1) {
          return this.model.data.destination; // Backward compatibility
        }

        return `${this.activity.baseUrl}${this.model.data.destination}`;
      }
    },
    watch: {
      model: {
        handler(): void {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Activity button widget edited");
          }
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      setDefaultProperties(newDefault: ButtonStyleWidgetProperties): void {
        this.model.defaultProperties = newDefault;
      },
      onClick(): void {
        if (this.editable) {
          let activity: ActivityModel = new ActivityModel(this.model.data.id, this.model.data.label);
          this.$emit('activate-activity', activity, true);
        }
      }
    }
  });
