
  import Vue from 'vue';

  import { v1 as uuidv1 } from "uuid";

  export default Vue.extend({
    props: {
      title: {
        type: String,
        required: true
      },
      visibleByDefault: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        visible: this.visibleByDefault as boolean,
        collapsibleId: `collapsible-${uuidv1()}`
      };
    },
    watch: {
      visibleByDefault(newValue: boolean, oldValue: boolean) {
        this.visible = newValue;
      }
    }
  });
