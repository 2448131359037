import { VueConstructor } from 'vue';
import WidgetModel, { WidgetIdType } from '@/main/webapp/vue/model/learning/WidgetModel';
import { WidgetRegistration, WidgetFactory } from '@/main/webapp/vue/components/learning/widget/model/WidgetRegistration';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import '@/main/webapp/vue/components/learning/widget/model/widget.css';

type WidgetConstructor<W extends WidgetModel> = { new(...args: any[]): W };

type WidgetWithIdConstructor<W extends WidgetModel> = WidgetConstructor<W>
  & { new(id: WidgetIdType): W };

const registeredWidgets = new WeakMap<Function, WidgetRegistration>();

export function getWidgetRegistration(model: WidgetModel) {
  return registeredWidgets.get(model.constructor);
}

export function registerWidget<W extends WidgetModel>(
  family: WidgetFamily,
  model: WidgetWithIdConstructor<W>,
  name: string,
  icon: string,
  widgetComponent: VueConstructor,
  propertiesComponent: VueConstructor,
): WidgetRegistration;

// eslint-disable-next-line no-redeclare
export function registerWidget<W extends WidgetModel>(
  family: WidgetFamily,
  model: WidgetConstructor<W>,
  name: string,
  icon: string,
  widgetComponent: VueConstructor,
  propertiesComponent: VueConstructor,
  factory: WidgetFactory,
): WidgetRegistration;

// eslint-disable-next-line no-redeclare
export function registerWidget<W extends WidgetModel>(
  family: WidgetFamily,
  model: WidgetConstructor<W> | WidgetWithIdConstructor<W>,
  name: string,
  icon: string,
  widgetComponent: VueConstructor,
  propertiesComponent: VueConstructor,
  factory?: WidgetFactory
) {
  const registration: WidgetRegistration = {
    name,
    icon,
    widgetComponent,
    propertiesComponent,
    factory: factory || ((id) => new (model as WidgetWithIdConstructor<W>)(id))
  };
  registeredWidgets.set(model, registration);
  family.addWidget(registration);
  return registration;
}

// Import all .ts files in ./widgets
const context = require.context('../widgets', true, /\.ts$/);
context.keys()
  .forEach((key) => {
    context(key);
  });

const widgets = WidgetFamily.FAMILIES;

export default widgets;
