import { JsonObject, JsonProperty } from "json2typescript";
import { FeedArticle } from "./FeedArticle";
import { Metadata } from "./Metadata";
import { NavigationLinks } from "./NavigationLinks";

@JsonObject('Feed')
export class Feed extends Metadata {

  @JsonProperty('articles', [FeedArticle])
  articles?: FeedArticle[] = undefined;

  @JsonProperty('nav', NavigationLinks, true)
  nav?: NavigationLinks = undefined;

}
