import { JsonObject, JsonProperty } from "json2typescript";
import { MapMarkerData } from "@/main/webapp/vue/model/api/Map/MapMarkerData";
import { MapDefaultEntity } from "@/main/webapp/vue/model/api/Map/MapDefaultEntity";
import { MapMarkerHtml } from "@/main/webapp/vue/model/api/Map/MapMarkerHtml";

@JsonObject('MapMarker')
export class MapMarker extends MapDefaultEntity {
  @JsonProperty('lat', Number, true)
  lat?: number = undefined;

  @JsonProperty('lng', Number, true)
  lng?: number = undefined;

  @JsonProperty('markerData', MapMarkerData, true)
  markerData?: MapMarkerData = undefined;

  @JsonProperty('html', MapMarkerHtml, true)
  html?: MapMarkerHtml = undefined;
}
