import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import ButtonStyleModel from "@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel";
import TextStyleModel, { TEXT_SIZE } from "@/main/webapp/vue/components/ui/style/text/model/TextStyleModel";
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";

import shareWidget from '@/main/webapp/vue/components/learning/widget/widgets/share/common/widget/index.vue';
import shareWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/share/common/properties/index.vue';
import messages from '../../common/messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";
import ShareWidgetModel, {
  ShareWidgetData,
  ShareWidgetProperties
} from "@/main/webapp/vue/components/learning/widget/widgets/share/common/model/ShareWidgetModel";

export class WhatsappWidgetData extends ShareWidgetData {
  constructor(@JsonField('text', String, true)
              public text = '',
              @JsonField('icon', String, true)
              public icon = 'mms') {
    super(text);
  }
}

@JsonDiscriminator(WidgetModel, 'whatsapp')
export default class WhatsappWidgetModel extends ShareWidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', WhatsappWidgetData)
              public data = new WhatsappWidgetData(),
              @JsonField('properties', ShareWidgetProperties)
              public properties = new ShareWidgetProperties(),
              @JsonField('defaultProperties', ShareWidgetProperties, true)
              public defaultProperties = new ShareWidgetProperties(
                new ButtonStyleModel('#ffffff', '#000000', 5, '#ffffff'),
                new TextStyleModel(TEXT_SIZE.TITLE, '#000000'),
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }

  formatUrl(activityUrlPath: string): string {
    return `whatsapp://send?text=${this.data.text} ${activityUrlPath}`;
  }
}

registerWidget(
  WidgetFamily.SHARE,
  WhatsappWidgetModel,
  translationUtil.translate('text.widget-name.whatsapp', messages),
  'mms',
  shareWidget,
  shareWidgetProperties
);
