import { JsonObject, JsonProperty } from "json2typescript";
import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";

@JsonObject('Organization')
export class Organization extends HierarchicalEntity {

  @JsonProperty('logos', ItemContentEntityContainer, true)
  logos: ItemContentEntityContainer | undefined = undefined

}
