import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import activityListWidget from '@/main/webapp/vue/components/learning/widget/widgets/activity-list/widget/index.vue';
import activityListWidgetProperties from
  '@/main/webapp/vue/components/learning/widget/widgets/activity-list/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import ButtonStyleModel from "@/main/webapp/vue/components/ui/style/button/model/ButtonStyleModel";
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import IconStyleModel from "@/main/webapp/vue/components/ui/style/icon/model/IconStyleModel";
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class ActivityListData {
  constructor(@JsonField('parentActivityId', Number)
              public parentActivityId: number = -1,
              @JsonField('showActivityStatus', Boolean, true)
              public showActivityStatus: boolean = false) {}
}

export class ActivityListProperties {
  constructor(@JsonField('iconStyle', IconStyleModel, true)
              public iconStyle = new IconStyleModel("circle", "#FF0F0F", 20, "#00FF00"),
              @JsonField('buttonStyle', ButtonStyleModel, true)
              public buttonStyle = new ButtonStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'activityList')
export default class ActivityListWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', ActivityListData)
              public data = new ActivityListData(),
              @JsonField('properties', ActivityListProperties)
              public properties = new ActivityListProperties(),
              @JsonField('defaultProperties', ActivityListProperties, true)
              public defaultProperties =
                new ActivityListProperties(
                  new IconStyleModel("circle", "#FF0F0F", 20, "#00FF00"),
                  new ButtonStyleModel('#17a2b8', '#ffffff', 5)),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.NAVIGATION,
  ActivityListWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'view_list',
  activityListWidget,
  activityListWidgetProperties
);
