
  import Vue, { PropType } from 'vue';
  import draggable from 'vuedraggable';
  import messages from './messages.json';

  type SortableEvent = {
    oldIndex: number | undefined;
    newIndex: number | undefined;
  };

  export default Vue.extend({
    name: 'DraggableContainerImplementation',
    components: {
      draggable
    },
    props: {
      value: {
        type: Array as PropType<any[]>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      group: {
        type: Object,
        required: true
      },
      containerId: {
        type: String,
        default: null
      },
      padding: {
        type: Boolean,
        required: true
      },
      showPlaceholder: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dragging: undefined as number | undefined
      };
    },
    methods: {
      onAdd(e: SortableEvent): void {
        if (e && e.newIndex !== undefined && e.newIndex >= 0) {
          this.$emit('widget-created', this.value[e.newIndex], e.newIndex, this.containerId, this.group.section);
        }
      },
      onStart(e: SortableEvent): void {
        if (e && e.oldIndex !== undefined && e.oldIndex >= 0) {
          this.dragging = e.oldIndex;
          this.$emit('widget-selected', this.value[e.oldIndex]);
        }
      },
      onEnd(e: SortableEvent): void {
        this.dragging = undefined;
        if (e && e.oldIndex !== undefined && e.oldIndex >= 0 && e.newIndex !== undefined && e.newIndex >= 0) {
          this.$emit('widget-ended', this.value[e.newIndex], e.newIndex);
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
