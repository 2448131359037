import deburr from 'lodash/deburr';
import kebabCase from 'lodash/kebabCase';

export function nameToUrlSafe(name: string) {
  return kebabCase(deburr(name)).replace(/[^\w-]/, '');
}

export function nextOrdinalString(baseString: string, strings: string[]) {
  const highestOrdinal = strings.reduce((max, value) => {
    const trim = value.trim();
    if (!trim.startsWith(baseString)) {
      return max;
    }
    const postfix = trim.slice(baseString.length);
    if (!/^[1-9]\d*$/.test(postfix)) {
      return max;
    }
    const ordinal = parseInt(postfix, 10);
    if (Number.isNaN(ordinal)) {
      return max;
    }
    return Math.floor(ordinal);
  }, 0);
  return `${baseString}${highestOrdinal + 1}`;
}
