import { AttributeEntity } from "@/main/webapp/vue/model/api/AttributeEntity";
import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { Json2TypescriptConverter } from "@znapio/znapson/json2typescriptAdapter";
import { JsonDiscriminator, JsonField } from "@znapio/znapson";

@JsonDiscriminator(AttributeEntity, 'image')
export class ContentEntryAttributeEntity extends AttributeEntity<ItemContent> {
  constructor(id: number,
              name: string,
              type: string,
              @JsonField('values', [ItemContent], { customConverter: new Json2TypescriptConverter(ItemContent) })
              public values: ItemContent[],
              value?: string,
              unit?: string,
              nav?: NavigationLinks) {
    super(id, name, type, value, unit, nav);
  }
}
