
  import Vue from 'vue';
  import messages from './messages.json';

  export default Vue.extend({
    props: {
      collapsed: {
        type: Boolean,
        default: true
      },
      minHeight: {
        type: Number,
        default: 170
      }
    },
    data() {
      return {
        showContent: !this.collapsed as boolean,
        previewContainer: undefined as HTMLDivElement | undefined,
        enableCollapsibleButton: false as boolean
      };
    },
    methods: {
      toggleShowContent(): void {
        this.showContent = !this.showContent;
        this.$emit('show-content', this.showContent);
      },
      checkIfOverflow(): void {
        if (this.previewContainer && this.collapsed) {
          if (this.previewContainer.scrollHeight - 10 > this.minHeight) {
            this.enableCollapsibleButton = true;
          } else {
            if (this.showContent) {
              this.enableCollapsibleButton = true;
            } else {
              this.enableCollapsibleButton = false;
            }

          }
        }
      }
    },
    mounted(): void {
      this.previewContainer = this.$refs.previewContainer as HTMLDivElement;
      this.checkIfOverflow();

      window.addEventListener('resize', this.checkIfOverflow);
    },
    i18n: {
      messages: messages
    }
  });
