
  import Vue from 'vue';

  import navigationSection from '@/main/webapp/vue/components/ui/navigation/section/index.vue';
  import navigationSectionElement from '@/main/webapp/vue/components/ui/navigation/section/element/index.vue';

  import federatedLoginManagement
    from '@/main/webapp/vue/components/admin/user-configurator/federated-login-management/index.vue';

  export default Vue.extend({
    components: {
      navigationSection,
      navigationSectionElement,
      federatedLoginManagement
    },
    data() {
      return {

      };
    },
    computed: {

    }
  });
