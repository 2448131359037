import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import dreambrokerVideoWidget from '@/main/webapp/vue/components/learning/widget/widgets/dreambroker-video/widget/index.vue';
import dreambrokerVideoWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/dreambroker-video/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

const DEFAULT_URL_PREFIX: string = 'https://dreambroker.com';

export class DreambrokerVideoWidgetData {
  constructor(@JsonField('src', String)
              public src: string = DEFAULT_URL_PREFIX,
              @JsonField('channelId', String)
              public channelId: string = '',
              @JsonField('videoId', String)
              public videoId: string = '') {}
}

export class DreambrokerVideoWidgetProperties {
  constructor(@JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'DreambrokerVideo')
export default class DreambrokerVideoWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection: DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', DreambrokerVideoWidgetData)
              public data = new DreambrokerVideoWidgetData(),
              @JsonField('properties', DreambrokerVideoWidgetProperties)
              public properties = new DreambrokerVideoWidgetProperties(),
              @JsonField('defaultProperties', DreambrokerVideoWidgetProperties, true)
              public defaultProperties = new DreambrokerVideoWidgetProperties(
                new GenericStyleWidgetProperties()
              ),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.MEDIA,
  DreambrokerVideoWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'play_circle_outline',
  dreambrokerVideoWidget,
  dreambrokerVideoWidgetProperties,
  (id: WidgetIdType) => new DreambrokerVideoWidgetModel(id, DraggableSection.BODY, new WidgetMetadata())
);
