
  import Vue from 'vue';

  import glyphiconText from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-text/index.vue';

  export default Vue.extend({
    components: {
      glyphiconText
    },
    props: {
      label: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      absoluteTitle: {
        type: Boolean,
        default: false
      }
    }
  });
