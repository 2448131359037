import { JsonObject, JsonProperty } from "json2typescript";

export enum SearchDefinitionType {
  INPUT_TEXT = "INPUT_TEXT",
  TIMESTAMP = "TIMESTAMP",
  LIST_TOGGLE ="LIST_TOGGLE",
  LIST_MULTI ="LIST_MULTI",
  LIST_SINGLE ="LIST_SINGLE",
  SWITCH = "SWITCH"
}

export enum SearchDatePresetType {
  TODAY = "TODAY",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  THIS_YEAR = "THIS_YEAR",
  ALL = "ALL"
}

@JsonObject('SearchDefinition')
export class SearchDefinition {

  @JsonProperty('key', String, true)
  key?: string = undefined;

  @JsonProperty('name', String, true)
  name?: string = undefined;

  @JsonProperty('entity', String, true)
  entity?: string = undefined;

  @JsonProperty('type', String, true)
  type?: SearchDefinitionType = undefined;

  @JsonProperty('label', String, true)
  label?: string = undefined;

  @JsonProperty('labelKey', String, true)
  labelKey?: string = undefined;

  @JsonProperty('priority', Number, true)
  priority?: number = 0;

  @JsonProperty('withData', Boolean, true)
  withData?: boolean = false;

  @JsonProperty('dataRemoteSearch', Boolean, true)
  dataRemoteSearch?: boolean = false;

  @JsonProperty('dataLocalSearch', Boolean, true)
  dataLocalSearch?: boolean = false;

  @JsonProperty('inCountBadge', Boolean, true)
  inCountBadge?: boolean = false;

}
