
  import Vue, { PropType } from 'vue';
  import { oc as Optional } from "ts-optchain";
  import messages from './messages.json';

  import textBadge from '@/main/webapp/vue/components/ui/text/text-badge/index.vue';
  import bootstrapPopover from '@/main/webapp/vue/components/ui/bootstrap-popover/index.vue';
  import textDate from '@/main/webapp/vue/components/ui/text/text-date/index.vue';
  import basic from "@/main/webapp/vue/components/ui/modal/basic/index.vue";
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { ItemLike } from "@/main/webapp/vue/model/api/ItemLike";
  import { ItemLikes } from "@/main/webapp/vue/model/api/ItemLikes";
  import { Item } from "@/main/webapp/vue/model/api/Item";

  export enum LikeDisplayType {
    Popover = "popover",
    Modal = "modal"
  }

  export default Vue.extend({
    components: {
      textBadge,
      bootstrapPopover,
      textDate,
      basic,
      loadingIcon
    },
    i18n: {
      messages: messages
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      type: {
        type: String as PropType<LikeDisplayType>,
        default: LikeDisplayType.Popover
      },
      showText: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        likes: [] as ItemLike[],
        likedByUser: false,
        types: {
          popover: LikeDisplayType.Popover,
          modal: LikeDisplayType.Modal
        },
        loading: false as boolean,
        likesFetched: false as boolean,
        onHoverState: false as boolean
      };
    },
    methods: {
      showLikesModal(): void {
        if (this.submission) {
          this.$bvModal.show(`bv-modal-likes-${this.submission.id}`);
        }
      },
      fetchLikes(): void {
        if (this.loading) {
          return;
        }

        if (this.submission.likes > 0) {
          this.loading = true;

          BackendIntegrationService.fetchLikes(this.submission)
            .then((response: ItemLikes) => {
              let likes: ItemLike[] | undefined = Optional(response).likes();
              if (likes && likes.length > 0) {
                if (response.likedByUser) {
                  this.likedByUser = response.likedByUser;
                }

                this.addLikes(likes);
              }
            }).finally(() => {
              this.loading = false;
            });
        }

        if (!this.likesFetched) {
          this.likesFetched = true;
        }
      },
      postLike(): void {
        if (this.loading) {
          return;
        }

        this.loading = true;
        BackendIntegrationService.postLike(this.submission)
          .then((like: ItemLike) => {
            this.likedByUser = true;

            // display submitted like as the topmost like
            this.likes.unshift(like);
            this.submission.likes++;
          }).catch((error: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Add likes failed', error);
            }
          }).finally(() => {
            this.loading = false;
          });
      },
      addLikes(likes: ItemLike[]): void {
        this.likes.push.apply(this.likes, likes);
      },
      checkToPostLike(): void {
        if (!this.likedByUser) {
          this.postLike();
        }
      },
      checkToFetchLikes(): void {
        if (!this.likesFetched) {
          this.fetchLikes();
        }
      }
    }
  });
