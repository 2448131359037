
  import Vue, { PropType } from 'vue';

  import thumbnailImage from '@/main/webapp/vue/components/ui/content/thumbnail-image/index.vue';
  import filetypeIcon from '@/main/webapp/vue/components/ui/content/filetype-icon/index.vue';
  import collapsibleContent from '@/main/webapp/vue/components/ui/collapsible/collapsible-content/index.vue';

  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

  export default Vue.extend({
    components: {
      thumbnailImage,
      filetypeIcon,
      collapsibleContent
    },
    props: {
      contents: {
        type: Array as PropType<ItemContent[]>,
        default: () => [] as ItemContent[]
      },
      enableCollapse: {
        type: Boolean,
        default: true
      },
      defaultLink: {
        type: String,
        default: "#"
      },
      itemIndex: {
        type: Number
      }
    },
    data() {
      return {
        maxDisplayPictures: this.enableCollapse ? (this as any).$properties.content.container.display.max : (this.contents?.length + 1),
        show: false as boolean
      };
    },
    computed: {
      parsedContents: function(): ItemContent[] {
        let contentLength: number = this.contents?.length;
        if (contentLength > 0) {
          return this.contents;
        } else {
          return [
            ItemContent.prototype.generateNoImageContent(
              true,
              (this as any).$properties.content.image.placeholder.noImage,
              this.$props.defaultLink)
          ];
        }
      }
    }
  });
