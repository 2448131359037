import WidgetModel, {
  DraggableSection,
  WidgetIdType,
  WidgetMetadata
} from '@/main/webapp/vue/model/learning/WidgetModel';
import { registerWidget } from '@/main/webapp/vue/components/learning/widget/model/Widgets';
import WidgetFamily from '@/main/webapp/vue/components/learning/widget/model/WidgetFamily';
import TextStyleModel, { TEXT_SIZE } from '@/main/webapp/vue/components/ui/style/text/model/TextStyleModel';
import linkExternalWidget from '@/main/webapp/vue/components/learning/widget/widgets/link-external/widget/index.vue';
import linkExternalWidgetProperties
  from '@/main/webapp/vue/components/learning/widget/widgets/link-external/properties/index.vue';
import { JsonDiscriminator, JsonField } from '@znapio/znapson';
import GenericStyleWidgetProperties from "@/main/webapp/vue/model/learning/GenericStyleWidgetProperties";
import translationUtil from "@/main/webapp/vue/util/translationUtil";
import messages from '../messages.json';
import NamedModel from "@/main/webapp/vue/model/learning/NamedModel";

export class LinkExternalData {
  constructor(@JsonField('text', String)
              public text = '',
              @JsonField('destination', String)
              public destination = '#') {}
}

export class LinkExternalProperties {
  constructor(@JsonField('activeState', TextStyleModel, true)
              public activeState = new TextStyleModel(),
              @JsonField('genericStyle', GenericStyleWidgetProperties, true)
              public genericStyle = new GenericStyleWidgetProperties()) {}
}

@JsonDiscriminator(WidgetModel, 'linkExternal')
export default class LinkExternalWidgetModel extends WidgetModel {
  constructor(id: WidgetIdType,
              draggableSection = DraggableSection.BODY,
              metadata = new WidgetMetadata(),
              @JsonField('data', LinkExternalData)
              public data = new LinkExternalData(),
              @JsonField('properties', LinkExternalProperties)
              public properties = new LinkExternalProperties(),
              @JsonField('defaultProperties', LinkExternalProperties, true)
              public defaultProperties = new LinkExternalProperties(
                new TextStyleModel(TEXT_SIZE.CONTENT, '#0000FF'),
                new GenericStyleWidgetProperties()),
              @JsonField('activities', [NamedModel], true)
              public activities: NamedModel[] = []) {
    super(id, draggableSection, metadata);
  }
}

registerWidget(
  WidgetFamily.NAVIGATION,
  LinkExternalWidgetModel,
  translationUtil.translate('text.widget-name', messages),
  'link',
  linkExternalWidget,
  linkExternalWidgetProperties
);
