
  import Vue from 'vue';
  import { mapState } from "vuex";

  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { ShopRegistryClient } from "@/main/webapp/vue/model/api/ShopRegistryClient";

  import formInput from '@/main/webapp/vue/components/ui/form/input/index.vue';

  export default Vue.extend({
    components: {
      formInput
    },
    props: {
      client: {
        type: ShopRegistryClient,
        required: true
      }
    },
    data() {
      return {
        linkedClient: this.client as ShopRegistryClient
      };
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      updateClientId(clientId: string): void {
        this.linkedClient.provider.data.clientId = clientId;
        this.updateClientData();
      },
      updateClientSecret(clientSecret: string): void {
        this.linkedClient.provider.data.clientSecret = clientSecret;
        this.updateClientData();
      },
      updateClientData(): void {
        BackendIntegrationService.updateShopRegistryClientForProvider(this.userDetails.organization, this.linkedClient)
          .then((client: ShopRegistryClient) => {
            this.linkedClient.provider.data.clientId = client.provider.data.clientId;
            this.linkedClient.provider.data.clientSecret = client.provider.data.clientSecret;
            notification.success("Data source provider data updated!");
          }).catch((error: Error) => {
            notification.fail("Could not update data source data");
          });
      },
      validateClientSecret(clientSecret: string): boolean {
        return (clientSecret !== undefined && clientSecret.length > 0) || !this.linkedClient.provider.data.clientId;
      },
      validationErrorClientSecret(clientSecret: string): void {
        notification.fail("Cannot unset client secret, unset client ID first");
      }
    }
  });
