
  import Vue, { PropType } from 'vue';
  import { oc as Optional } from "ts-optchain";
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import markerItemMetadata from '@/main/webapp/vue/components/map/marker/marker-item-metadata/index.vue';
  import textDate from '@/main/webapp/vue/components/ui/text/text-date/index.vue';

  import { MapSubmissionDetails } from "@/main/webapp/vue/model/api/Map/MapSubmissionDetails";
  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
  import { ItemContent, ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";
  import TranslationUtil from "@/main/webapp/vue/util/translationUtil";

  export default Vue.extend({
    props: {
      item: {
        type: Object as PropType<MapSubmissionDetails>
      }
    },
    components: {
      markerItemMetadata,
      textDate
    },
    data() {
      return {
        thumbWidth: (this as any).$properties.content.image.size.thumbnail.width,
        thumbHeight: (this as any).$properties.content.image.size.thumbnail.height,
        imgStyle: {
          'text-align': 'center',
          'width': `${(this as any).thumbWidth}px`,
          'line-height': `${(this as any).thumbHeight}px`,
          'height': `${(this as any).thumbHeight}px`
        }
      };
    },
    computed: {
      getShop(): HierarchicalEntity | null {
        if (this.item && this.item.itemMetadata) {
          return this.item.itemMetadata.filter((metadata: HierarchicalEntity) =>
            metadata.metadata && metadata.metadata.type === "shop")[0];
        }

        return null;
      },
      computedImgSrc(): string {
        if (this.item.contents && this.item.contents.length > 0) {
          let content: ItemContent = this.item.contents[0];
          if (content && content.nav) {
            return Optional(content.nav.getLink(ItemContentType.THUMBNAIL)).href("/img/no-photo.jpg");
          }
        }

        return "/img/no-photo.jpg";
      }
    },
    methods: {
      moreItemsTranslation(shopName: string): string { // i1n8 is not working in popup
        return TranslationUtil.translate('text.more-items', messages, shopName);
      }
    }
  });
