
  import Vue from 'vue';

  import navigationSection from '@/main/webapp/vue/components/ui/navigation/section/index.vue';
  import navigationSectionElement from '@/main/webapp/vue/components/ui/navigation/section/element/index.vue';
  import billing from "@/main/webapp/vue/components/admin/organization-configurator/billing/index.vue";
  import auditLog from "@/main/webapp/vue/components/admin/organization-configurator/audit-log/index.vue";

  export default Vue.extend({
    components: {
      navigationSection,
      navigationSectionElement,
      billing,
      auditLog
    }
  });
