import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject('MapDefaultEntity')
export class MapDefaultEntity {
    @JsonProperty('id', Number, true)
    id?: number = undefined;

    @JsonProperty('name', String, true)
    name?: string = undefined;
}
