
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import notification from "@/main/webapp/vue/notification";
  import Console from '@/main/webapp/vue/components/ui/console/index.vue';

  import moduleFeature from './module-feature/index.vue';
  import formSwitch from '@/main/webapp/vue/components/ui/form/switch/index.vue';

  import { OrganizationModule } from "@/main/webapp/vue/model/api/OrganizationModule";
  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { OrganizationModuleFeature } from "@/main/webapp/vue/model/api/OrganizationModuleFeature";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";

  import messages from "@/main/webapp/vue/components/admin/module-configurator/module-configuration/messages.json";

  export default Vue.extend({
    components: {
      moduleFeature,
      formSwitch,
      Console
    },
    props: {
      module: {
        type: Object as PropType<OrganizationModule>,
        required: true
      }
    },
    computed: {
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      featureValueUpdated: function(feature: OrganizationModuleFeature) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`featureEnabledUpdated() - featureType '${feature.name}' - value '${feature.value}'`);
        }

        this.postFeature(this.module.type!, feature);
      },
      moduleUpdated: function(enabled: boolean) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`moduleUpdated() - moduleType '${this.module.type}' - enabled: '${enabled}'`);
        }

        this.module.enabled = enabled;
        this.postModule(this.module);
      },
      postModule: function(module: OrganizationModule) {
        BackendIntegrationService.postOrganizationModule(this.userDetails.organization, module).then((response: OrganizationModule) => {
        });
      },
      postFeature: function(moduleType: string, feature: OrganizationModuleFeature) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`postFeature() - moduleType '${moduleType}', feature '${feature.name}', feature.value '${feature.value}'`);
        }
        BackendIntegrationService.updateOrganizationModuleFeature(this.userDetails.organization, moduleType, feature).then((response: OrganizationModuleFeature) => {
        }).catch((error: BackendError) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Updating organization module feature '${feature.name}' with value '${feature.value}' failed`, error);
          }

          let exception: string = error.exception!;
          if (exception === 'organization-url-already-in-use') {
            notification.fail(this.$t('text.error.organization-url-already-in-use'));
          }
        });
      }
    },
    i18n: {
      messages: messages
    }
  });
